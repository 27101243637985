import request from '@/router/axios';
const prefix = '/api/atlas';
export const getTaskList = () => {
  return request({
    url:'/json/metadata/task.json',
    method: 'get',
    params:{

    }
  })
};
export const getDataBase = (params) => {
  return request({
    url:'/api/convergeflexschduler/convergeflexschduler/datasources/list',
    method: 'get',
    params
  })
};
