import request from '@/router/axios';

export const getList = (params) => {
  return request({
    url: '/api/jida-meta/event-de-model/list',
    method: 'get',
    params: params
  })
}


export const getDetail = (id) => {
  return request({
    url: '/api/jida-meta/event-de-model/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/jida-meta/event-de-model/remove',
    method: 'post',
    params:ids
  })
}

export const add = (row) => {
  return request({
    url: '/api/jida-meta/event-de-model/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/jida-meta/event-de-model/update',
    method: 'post',
    data: row
  })
}
export const eventRun = (modelId,row) => {
  return request({
    url: '/api/jida-meta/common-event/run/'+modelId,
    method: 'post',
    data: row
  })
}

