const mode = 'yl';

const logos = {

  default: {
    logo: 'logo.png',
    logoTh: 'logo_th.png',
    topLeft: 'top_logo_left.png',
    topRight: 'top_logo_right.png',
    topLeftTh: 'top_logo_left_th.png',
    topRightTh: 'top_logo_right_th.png',
    topLeftYl: 'top_logo_left_yl.png',
    topRightYl: 'top_logo_right_yl.png',
    title:"智慧运营"
  },
  yl: {
    logo: 'logo_yl.png',
    topLeft: 'top_logo_left_yl.png',
    topRight: 'top_logo_right_yl.png',
    topLeftTh: 'top_logo_left_th.png',
    topRightTh: 'top_logo_right_th.png',
    topLeftYl: 'top_logo_left_yl.png',
    topRightYl: 'top_logo_right_yl.png',
    title:"智慧运营"
  },

  getLogos(){
    if(mode && logos[mode]){
      return logos[mode];
    }else{
      return logos['default'];
    }
  }

}


export default {

  ...logos.getLogos()

}