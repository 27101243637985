<template>
    <div class="avue-top">
        <div class="top-bar__left cur-p" :class="{ 'w-220': !isCollapse, 'w-80': isCollapse }">
            <logo class="dis-ib ov-h" :class="{ 'w-200': !isCollapse, 'w-50': isCollapse }"></logo>
            <div class="avue-breadcrumb pull-right" v-if="showMenus && showCollapse">
                <i class="dis-b cur-p" :class="{
            'el-icon-s-fold': !isCollapse,
            'el-icon-s-unfold': isCollapse,
          }" @click="setCollapse">
                </i>
            </div>
        </div>
        <div class="top-bar__title" :class="{ 'mar-l-80': isCollapse }">
            <div class="top-bar__item top-bar__item--show" v-if="showMenu">
                <top-menu ref="topMenu"></top-menu>
            </div>
            <div class="top-bar__item" style="position: absolute" v-if="viewEdit">
                <!-- <el-dropdown> -->
                <el-button type="primary" class="editBtn" size="small" @click="openNewPage('table')" icon="el-icon-plus">
                    添加菜单
                </el-button>
                <!-- <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              ><span @click="openNewPage('table')">表格</span></el-dropdown-item
            >
            <el-dropdown-item
              ><span @click="openNewPage('form')">表单</span></el-dropdown-item
            >
          </el-dropdown-menu> -->
                <!-- </el-dropdown> -->
            </div>
            <!-- <span class="top-bar__item" v-if="showSearch">
        <top-search></top-search>
      </span> -->

            <!-- <el-tooltip effect="dark" content="页面配置" placement="bottom">
        <div
          class="top-bar__item cur-p"
          @click="changeEdit"
          :class="{ viewEditBtn: isViewEdit }"
        >
          <i class="el-icon-edit"></i>
        </div>
      </el-tooltip> -->
        </div>

        <div class="top-bar__right">
            <!-- <el-tooltip v-if="isDev"
                  effect="dark"
                  :content="logsFlag?$t('navbar.bug'):logsLen+$t('navbar.bugs')"
                  placement="bottom">
        <div class="top-bar__item">
          <top-logs></top-logs>
        </div>
      </el-tooltip> -->

            <!-- <el-tooltip
        v-if="showColor"
        effect="dark"
        :content="$t('navbar.color')"
        placement="bottom"
      >
        <div class="top-bar__item">
          <top-color></top-color>
        </div>
      </el-tooltip> -->
            <el-tooltip v-if="showDebug" effect="dark" :content="logsFlag ? $t('navbar.bug') : logsLen + $t('navbar.bugs')" placement="bottom">
                <div class="top-bar__item">
                    <top-logs></top-logs>
                </div>
            </el-tooltip>
            <div class="top-bar__item">
                <span>{{ time }}</span>
            </div>
            <!-- <el-tooltip
        v-if="showWeather"
        effect="dark"
        content="天气"
        placement="bottom"
      > -->
            <!-- <div class="top-bar__item" v-if="showWeather">
          <top-weather></top-weather>
        </div> -->
            <!-- </el-tooltip> -->
            <!-- <el-tooltip
        effect="dark"
        content="页面配置"
        placement="bottom"
        v-if="userInfo.tenant_id == '000000'"
      >
        <div
          class="top-bar__item cur-p"
          @click="changeEdit"
          :class="{ viewEditBtn: viewEdit }"
        >
          <i class="el-icon-edit"></i>
        </div>
      </el-tooltip> -->

            <el-tooltip v-if="showLock" effect="dark" :content="$t('navbar.lock')" placement="bottom">
                <div class="top-bar__item">
                    <top-lock></top-lock>
                </div>
            </el-tooltip>
            <!-- <el-tooltip
        v-if="showTheme"
        effect="dark"
        :content="$t('navbar.theme')"
        placement="bottom"
      >
        <div class="top-bar__item top-bar__item--show">
          <top-theme></top-theme>
        </div>
      </el-tooltip> -->
            <!-- <el-tooltip
        v-if="showTheme"
        effect="dark"
        :content="$t('navbar.theme')"
        placement="bottom"
      >
        <div class="top-bar__item top-bar__item--show">
          <top-theme></top-theme>
        </div>
      </el-tooltip> -->
            <!-- <el-tooltip
        v-if="isDev"
        effect="dark"
        :content="'icon'"
        placement="bottom"
      >
        <div class="top-bar__item top-bar__item--show">
          <top-icon></top-icon>
        </div>
      </el-tooltip> -->
            <!-- <el-tooltip
        effect="dark"
        :content="$t('navbar.language')"
        placement="bottom"
      >
        <div class="top-bar__item top-bar__item--show">
          <top-lang></top-lang>
        </div>
        <el-badge :value="3" class="item">
  <el-button size="small">回复</el-button>
</el-badge>

      </el-tooltip> -->
            <el-tooltip effect="dark" :content="$t('navbar.notice')" placement="bottom">
                <div class="top-bar__item top-bar__item--show">
                    <top-notice></top-notice>
                </div>
            </el-tooltip>
            <el-tooltip v-if="showFullScren" effect="dark" :content="
          isFullScren ? $t('navbar.screenfullF') : $t('navbar.screenfull')
        " placement="bottom">
                <div class="top-bar__item cur-p">
                    <i :class="isFullScren ? 'icon-tuichuquanping' : 'icon-quanping'" @click="handleScreen"></i>
                </div>
            </el-tooltip>
            <el-dropdown>
                <span class="el-dropdown-link cur-p">
                    <img class="top-bar__img" src="../../../../public/assets/images/icon/tx.png" />
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <span @click="openUser('/info/update/index')">{{
              $t("navbar.userinfo")
            }}</span>
                        <!-- <router-link to="/info/update/index">{{$t('navbar.userinfo')}}</router-link> -->
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <span @click="openUser('/info/update/password')">修改密码</span>
                        <!-- <router-link to="/info/update/password">修改密码</router-link> -->
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="logout" divided>{{
            $t("navbar.logOut")
          }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

        </div>
        <el-dialog aria-setsize="small" width="30%" append-to-body destroy-on-close :visible.sync="addPageVisible">
            <div slot="title" class="custom-box-title fts-16">添加菜单</div>
            <topNewpage @saveNewPage="saveNewPage"></topNewpage>
        </el-dialog>
    </div>
</template>
<script>
import { resetRouter } from '@/router/router';
import { mapGetters, mapState } from 'vuex';
import { fullscreenToggel, listenfullscreen } from '@/util/util';
import topLock from './top-lock';
import topMenu from './top-menu';
import topSearch from './top-search';
import topTheme from './top-theme';
import topLogs from './top-logs';
import topNotice from './top-notice';
import topLang from './top-lang';
import topIcon from './top-icon';
import topColor from './top-color';
import logo from '../logo';
import topNewpage from './top-newpage';
import topWeather from './top-weather';
import { isDev } from '@/config/env';
import { dateFormat } from '@/util/date';
import { getPicListByIds } from '@/api/busiMode/metaCommon';
export default {
    components: {
        topLock,
        topMenu,
        topSearch,
        topTheme,
        topLogs,
        topNotice,
        topLang,
        topIcon,
        topColor,
        topNewpage,
        logo,
        topWeather,
    },
    name: 'top',
    props: {
        showMenus: Boolean,
    },
    data() {
        return {
            isDev: isDev,
            addPageVisible: false,
            isViewEdit: false,
            menuList: [],
            menuLoad: true,
            menuId: '',
            havaChil: false,
            breadcrumb: '',
            time: null,
            timer: null,
            imgform: '',
        };
    },
    filters: {},
    created() {},
    inject: ['indexMain'],
    mounted() {
        this.isViewEdit = this.viewEdit;
        listenfullscreen(this.setScreen);
        this.timer = setInterval(() => {
            this.time = dateFormat(new Date());
        }, 1000);
        if (this.userInfo['avatar']) {
            getPicListByIds(this.userInfo['avatar']).then(res => {
                console.log(res.data.code);
                if (res.data.code == 200 && res.data.data[0]) {
                    this.imgform = res.data.data[0].link;
                }
            });
        }
    },
    computed: {
        ...mapState({
            showDebug: state => state.common.showDebug,
            showTheme: state => state.common.showTheme,
            showLock: state => state.common.showLock,
            showFullScren: state => state.common.showFullScren,
            showCollapse: state => state.common.showCollapse,
            showSearch: state => state.common.showSearch,
            showMenu: state => state.common.showMenu,
            showColor: state => state.common.showColor,
            viewEdit: state => state.common.viewEdit,
        }),
        ...mapGetters([
            'menu',
            'userInfo',
            'isFullScren',
            'tagWel',
            'tagList',
            'isCollapse',
            'tag',
            'logsLen',
            'logsFlag',
            'showWeather',
        ]),
    },
    methods: {
        saveNewPage(obj) {
            this.menuId = obj[2];

            if (obj[3] == '') {
                this.menu.push(obj[0]);
                this.$store.commit('SET_MENU', this.menu);
            } else {
                this.menuList = JSON.parse(JSON.stringify(this.menu));
                this.getMenuPara(this.menuList, obj[0]);
                this.$store.commit('SET_MENU', JSON.parse(JSON.stringify(this.menuList)));
            }

            for (let i = 0; i < this.$breadcrumb.breadcrumbs.length; i++) {
                this.$breadcrumb.pop();
            }
            let par = obj[3];
            let path = '';
            if (obj[1] == 'table') {
                path = '/data/table/tableCommon';
            } else if (obj[1] == 'form') {
                path = '/data/form/formCommon';
            } else {
                path = '/mypages/design';
            }
            this.$breadcrumb.add({
                name: obj[0].name,
                path: path,
            });
            this.$router.push({
                path: path + '?menuId' + obj[2],
            });
            this.addPageVisible = false;
        },

        getMenuPara(menu, obj) {
            let t = this;
            let name = '';
            t.menuLoad = true;
            t.havaChil = false;
            menu.forEach(function (value) {
                if (t.menuLoad) {
                    if (!t.havaChil) {
                        console.log(name, value.name);
                        t.breadcrumb = t.breadcrumb + '/' + value.name;
                    }
                    if (value.id === obj.parentId) {
                        let o = {
                            id: t.menuId,
                            parentId: obj.parentId,
                            code: obj.code,
                            name: obj.name,
                            alias: obj.alias,
                            path: obj.path,
                            source: obj.source,
                            sort: obj.sort,
                            category: obj.category,
                            action: 0,
                            isOpen: 1,
                            remark: obj.remark,
                            isDeleted: 0,
                            hasChildren: false,
                            parentName: '',
                            categoryName: '',
                            actionName: '',
                            isOpenName: '',
                            children: [],
                        };
                        value.children.push(o);
                        t.menuLoad = false;
                    } else if (value.children && value.children.length != 0) {
                        t.havaChil = true;
                        t.getMenuPara(value.children, obj);
                    }
                }
                console.log(t.breadcrumb);
            });
        },
        openNewPage(type) {
            this.addPageVisible = true;
        },
        changeEdit() {
            this.isViewEdit = !this.isViewEdit;
            this.$store.commit('SET_VIEWEDIT', this.isViewEdit);
        },
        handleScreen() {
            fullscreenToggel();
        },
        setCollapse() {
            this.$store.commit('SET_COLLAPSE');
        },
        setScreen() {
            this.$store.commit('SET_FULLSCREN');
        },
        openUser(path) {
            let menus = {
                name: '个人中心',
                path: '',
                children: [
                    {
                        name: '个人信息',
                        alias: 'menu',
                        path: '/info/update/index',
                        children: [],
                        hasChildren: false,
                        code: '/info/update/index',
                        id: 'user-info',
                        source: 'iconfont iconicon_principal',
                    },
                    {
                        name: '修改密码',
                        alias: 'menu',
                        path: '/info/update/password',
                        children: [],
                        hasChildren: false,
                        code: '/info/update/password',
                        id: 'user-password',
                        source: 'iconfont icon-mima',
                    },
                ],
            };
            let currentMenu = null;
            menus.children.forEach(item => {
                if (item.path === path) {
                    currentMenu = item;
                }
            });
            this.$breadcrumb.add({
                type: 'top-menu',
                meta: menus,
                name: menus.name,
            });
            this.$breadcrumb.add({
                type: 'sidebar-menu-root',
                meta: currentMenu,
                name: currentMenu.name,
                path: currentMenu.path,
            });
            this.$router.push(path);
            this.$store.commit('SET_SIDEBAR_MENU', menus);
        },
        logout() {
            this.$confirm(this.$t('logoutTip'), this.$t('tip'), {
                confirmButtonText: this.$t('submitText'),
                cancelButtonText: this.$t('cancelText'),
                type: 'warning',
            }).then(() => {
                this.$store
                    .dispatch('LogOut')
                    .then(() => {
                        resetRouter();
                        this.$router.push({ path: '/login' });
                    })
                    .catch(() => {
                        localStorage.clear();
                        window.location.reload();
                    });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.theme-th {
    .avue-breadcrumb {
        display: none;
    }
}
.viewEditBtn {
    // width: 50px;
    color: #f18b62 !important;
    background-color: #f18b62 !important;
    color: #fff !important;
}
.editBtn {
    text-align: center;
    border-color: #f18b62 !important;
    color: #f18b62 !important;
    /* background-color: #f18b62 !important; */
    /* color: #fff !important; */
    border-style: dashed;
}
/deep/ .top-bar__item {
    padding: 0 15px;
    margin: 0;
}
</style>
