import request from '@/router/axios';
const prefix = '/api/jida-resource/';

export const getLogList = (params) => {
  return request({
    method: 'get',
    params: params,
    url: prefix + 'attachlogs/list'
  })
}

export const viewLog = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'save'
  })
}
