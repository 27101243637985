<template>
  <basic-container class="pad-15">
    <el-row>
      <el-col :span="24">
        <el-descriptions
          title="入驻申请信息"
          :column="4"
          :colon="false"
          direction="vertical"
          :labelStyle="labelStyle"
        >
          <el-descriptions-item label="入驻企业">
            {{ companyBaseInfo.name }}</el-descriptions-item
          >
          <el-descriptions-item label="入驻时间">{{
            ruleForm.planCheckinTime | formatDate("YYYY-MM-DD")
          }}</el-descriptions-item>

          <el-descriptions-item label="入驻房间" v-if="ruleForm.roomNames">
            {{ ruleForm.roomNames.toString() }}
          </el-descriptions-item>
          <el-descriptions-item label="申请人">{{
            ruleForm.applicant
          }}</el-descriptions-item>

          <el-descriptions-item label="联系电话">{{
            ruleForm.phoneNum
          }}</el-descriptions-item>
          <el-descriptions-item label="申请时间">{{
            ruleForm.createTime
          }}</el-descriptions-item>

          <el-descriptions-item label="合同情况">
            <span v-if="ruleForm.type == 1">线上</span>
            <span v-if="ruleForm.type == 2">线下</span>
            <span v-if="ruleForm.type == 3">无合同</span></el-descriptions-item
          >
          <el-descriptions-item
            label="合同编码"
            v-if="ruleForm.type == 2 || ruleForm.type == 1"
            >{{ ruleForm.contractCode }}</el-descriptions-item
          >
          <el-descriptions-item label="原因说明" v-if="ruleForm.type == 3">
            {{ ruleForm.reason }}
          </el-descriptions-item>
        </el-descriptions>
        <template v-if="imgform.imgUrl.length > 0">
          <el-divider v-if="ruleForm.type == 2" content-position="left"
            >合同照片</el-divider
          >
          <avue-form
            class="mar-t-20"
            v-if="ruleForm.type == 2"
            :option="option1"
            v-model="imgform"
          ></avue-form>
        </template>
        <el-divider></el-divider>
        <el-descriptions
          title="企业信息"
          :column="4"
          direction="vertical"
          :colon="false"
          :labelStyle="labelStyle"
        >
          <el-descriptions-item label="企业名称">{{
            companyBaseInfo.name
          }}</el-descriptions-item>
          <el-descriptions-item label="统一社会信用代码">{{
            companyBaseInfo.uniform_social_credit_code
          }}</el-descriptions-item>
          <el-descriptions-item label="企业法人">{{
            companyBaseInfo.legal_person
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">
            {{ companyBaseInfo.legal_contact }}
          </el-descriptions-item>
          <el-descriptions-item label="企业规模">
            {{ getName(companyBaseInfo.firm_size, firmSizeList) || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="注册资金">{{
            companyBaseInfo.registered_capital
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="企业类型">
            <el-tag size="small">{{
              getNameByDict(enterpriseTypes, ruleForm.type)
            }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="企业资质">
            <el-tag size="small">{{
              getNameByDict(qualifications, ruleForm.qualification)
            }}</el-tag>
          </el-descriptions-item> -->
          <el-descriptions-item label="负责人">{{
            companyBaseInfo.principal
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            companyBaseInfo.phone_num
          }}</el-descriptions-item>
          <el-descriptions-item label="注册地址">{{
            companyBaseInfo.registered_address
          }}</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <el-descriptions
          title="企业人员"
          :column="4"
          direction="vertical"
          :colon="false"
          :labelStyle="labelStyle"
        >
        </el-descriptions>
        申请开通账户数：{{ employeeNums }}
        <div class="mar-l-15f mar-r-15f">
          <employee
            :identity="999"
            :companyNode="{ id: companyInfoObj.companyId }"
            :height="`400`"
            :readOnly="true"
          ></employee>
        </div>
      </el-col>
      <!-- 
      <el-col :span="6" class="mar-t-50" v-if="!isTodo">
        <div class="sm">
          <h6>企业入驻说明</h6>
          我这里是企业入驻说明
        </div>
      </el-col> -->
    </el-row>
  </basic-container>
</template>
<script>
import enterpriseEnterBaseInfo from "./enterpriseEnterBaseInfo";
import { getMetaCommonList } from "@/api/busiMode/metaCommon";
import { getMetaCommonInfo, getPicListByIds } from "@/api/busiMode/metaCommon";
import { dateFormat } from "@/util/date";
import employee from "../employee/employee";
import * as dictbizService from "@/api/system/dictbiz";

export default {
  components: { enterpriseEnterBaseInfo, employee },
  data() {
    return {
      info: {},
      ruleForm: {},
      companyList: [],
      imgform: {
        imgUrl: [],
      },
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      model: "wisdom_park.wp_employee",
      entModel: "wisdom_park.wp_company",
      companyModel: "wisdom_park.wp_company_checkin",
      employeeNums: "",
      companyBaseInfo: {},
      companyInfoObj: {},
      firmSizeList: [],
      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        disabled: true,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
    };
  },
  props: {
    companyInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    id: {
      type: String,
      default: "",
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler(val) {
        this.companyInfoObj = val;
        if (val.attachmentId) {
          this.getCompanyId();
        }
        if (val.companyId) {
          this.getComInfo();
        }
        if (val.companyId) {
          this.findCompanyBaseInfo(val.companyId);
        }

        this.ruleForm = val;
      },
    },
    currentRow: {
      immediate: true,
      handler(val) {
        if (val.id) {
          //this.ruleForm.companyId = val.id;
        }
      },
    },
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getMetaCommonInfo();
        }
      },
    },
  },
  mounted() {},
  created() {
    this.getMetaCommonList();
    this.getDicList();
  },
  methods: {
    getName(v, list) {
      let name = "";
      list.forEach((e) => {
        if (v == e.value) {
          name = e.name;
        }
      });
      return name;
    },
    getDicList() {
      dictbizService
        .getDictionary({
          code: "firmSize",
        })
        .then((res) => {
          this.firmSizeList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
    },
    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.id,
      };
      if (this.id) {
        getMetaCommonInfo(this.companyModel, obj).then((res) => {
          let data = res.data.data;
          data.approvalStatus = data.approval_status;
          data.approvalType = data.approval_type;
          data.attachmentId = data.attachment_id;
          data.companyId = data.company_id;
          data.contractCode = data.contract_code;
          data.createTime = data.create_time;
          data.planCheckinTime = data.plan_checkin_time;
          data.roomIds = data.room_ids;
          data.roomNames = data.room_names;
          data.phoneNum = data.phone_num;
          this.ruleForm = data;
          this.companyInfoObj = data;
          if (data.attachmentId) {
            this.getCompanyId();
          }
          if (data.companyId) {
            this.getComInfo();
          }
          this.findCompanyBaseInfo(this.ruleForm.companyId);
        });
      }
    },

    findCompanyBaseInfo(companyId) {
      let body = {
        current: 1,
        size: 20,
        wheres: [{ field: "id", value: companyId }],
      };
      getMetaCommonList(this.entModel, body).then((res) => {
        this.companyBaseInfo = res.data.data.records[0];
      });
    },
    dateFormats(data) {
      let d = "";
      if (data) {
        d = dateFormat(data, "yyyy-MM-dd");
      }
      return d;
    },
    getComInfo() {
      let body = {
        current: 1,
        size: 20,
        wheres: [
          { field: "company_id", value: this.companyInfoObj.companyId },
          // { field: "account_status", op: "IN", value: [0] },
        ],
      };
      getMetaCommonList(this.model, body).then((res) => {
        this.employeeNums = res.data.data.total;
      });
    },
    getCompanyId() {
      getPicListByIds(this.companyInfoObj.attachmentId).then((res) => {
        if (res.data.code == 200) {
          let arr = [];
          res.data.data.forEach((pic) => {
            arr.push({
              label: pic.id,
              value: pic.link,
            });
          });
          this.imgform.imgUrl = arr;
        }
      });
    },
    getCom(id) {
      let name = "";
      this.companyList.forEach((e) => {
        if (e.id == id) {
          name = e.name;
        }
      });
      return name;
    },
    getMetaCommonList() {
      getMetaCommonList(this.entModel, {
        size: 100,
        current: 1,
        total: 0,
      }).then((res) => {
        const data = res.data.data;
        this.companyList = data.records;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}

.sm {
  height: 350px;
  background: #eff6ff;
  padding: 10px 15px;
  position: absolute;
  width: 23%;
  border: 1px solid #a8c9eb;
  border-radius: 2px;
}
/deep/ .avue-crud__menu {
  display: none;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
/deep/ .el-table__body-wrapper {
  height: auto !important;
}
</style>
