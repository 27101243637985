const prefix = '/api/interactive/';
const prefix2 = '/api/interactive-v1/';
const prefix3 = '/api/'

export default {
  _prefix: prefix,
  _prefix2: prefix2,
  datasourceAuth: prefix + 'datasourceAuth',
  // query: prefix + 'query',
  presto: prefix + 'presto',
  // prestoAsync: prefix + 'prestoAsync',
  format: prefix + 'format',
  queryStatus: prefix + 'queryStatus',
  historyStatus: prefix + 'historyStatus',
  history: prefix + 'history',
  kill: prefix + 'kill',
  // log: prefix2 + 'v1/query/',
  getCatalogueList: prefix3 + 'data-dev-platform/datascript/getCatalogueList',
  getSqlFile: prefix3 + 'data-dev-platform/data-dev-platform/datascript/detail',
  saveDir: prefix3 + 'data-dev-platform/datascriptcatalogue/submit',
  deleteDir: prefix3 + 'data-dev-platform/datascriptcatalogue/remove',
  saveFile: prefix3 + 'data-dev-platform/datascript/submit',
  deleteFile: prefix3 + 'data-dev-platform/datascript/remove',
  prestoAsync: prefix3 + 'data-dev-platform/interactive/prestoAsync',
  query: prefix3 + 'data-dev-platform/interactive/query',
  log: prefix3 + 'data-dev-platform/interactive/v1/query',
  updateState: prefix3 + 'data-dev-platform/datascript/updateState',
  download: prefix3 + 'data-dev-platform/datascript/download/'
}
