export const ProcessCode = {
  BIZ: {
    CONTRACT: 'BIZ_CONTRACT', // 合同
    COMPANY: 'BIZ_COMPANY', // 企业
    EMP: 'BIZ_EMP', // 人员
    VISITOR: 'BIZ_VISITOR', // 访客预约
    ASSET_COLLECTION: 'BIZ_ASSET_COLLECTION', // 资产领用
    ASSET_RETURN: 'BIZ_ASSET_RETURN', // 资产退回
    ASSET_SCRAP: 'BIZ_ASSET_SCRAP', // 资产作废
    ASSET_STORAGE: 'BIZ_ASSET_STORAGE', // 资产入库
    ASSET_INVENTORY: 'BIZ_ASSET_INVENTORY', // 资产盘点
    ASSET_REPARIR: 'BIZ_ASSET_REPARIR', //资产维保
    RECEIPT: 'BIZ_RECEIPT', // 账单
  },
  ACTIONS: {
    SCRAP: 'SCRAP', // 作废
    CHECKIN: 'CHECKIN', // 入驻
    CHECKOUT: 'CHECKOUT', // 退出
    CHANGE: 'CHANGE', // 变更
    SUBMIT: 'SUBMIT', // 提交
  }
}

const processCode = {
  install(Vue){
    Vue.prototype.$PCode = ProcessCode;
  }
}

export default processCode;