<template>
  <el-select
    v-model="selectValue"
    filterable
    remote
    clearable
    placeholder="输入关键词搜索"
    :remote-method="searchUser"
    :loading="loading"
    :disabled="disabled"
    :size="size || 'small'"
    class="dis-b"
  >
    <el-option v-for="item of userList" :key="item.id" :label="item.deptName +'_'+ item.postName + '_' + item.name" :value="item.id">
      <span style="float: left">{{ item.name }}{{item.postName ? `(${item.postName})` : ''}}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
    </el-option>
  </el-select>
</template>
<script>
import { searchList } from '@/api/system/user'
export default {
  data(){
    return {
      loading: false,
      userList: [],
      selectValue: null
    }
  },
  watch: {
    selectValue(){
      this.$emit('input' , this.selectValue);
      let data = {
        value: this.selectValue,
        item: null
      }
      this.userList.forEach(
        item => {
          if(item.id === this.selectValue){
            data.item = item;
          }
        }
      )
      this.$emit('change' , data);
    },
    value: {
      immediate: true,
      handler(){
        if(this.selectValue !== this.value && (this.value ?? '') !== ''){

        }
        this.selectValue = this.value;
      }
    }
  },
  props: {
    value: {},
    tenantId: {
      type: String,
      default: '000000'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  mounted(){
    // this.selectValue = this.value;
  },
  methods: {
    searchUser(query){
      if(query){
        this.loading = true;
        searchList({
          name: query,
          tenantId: this.tenantId,
          size: 1,
          page: 100
        }).then(
          res => {
            this.userList = res.data.data.records || [];
            this.loading = false;
          }
        )
      }else{
        this.userList = [];
      }
    },
  }
}
</script>