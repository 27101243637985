import request from '@/router/axios';
import qs from 'qs';

import APIS from './apiUrl';

export const FlowApiUrls = APIS;

export const modelList = (current, size, params) => {
  return request({
    url: '/api/jida-flow/model/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const managerList = (current, size, params) => {
  return request({
    url: '/api/jida-flow/manager/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const followList = (current, size, params) => {
  return request({
    url: '/api/jida-flow/follow/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


export const removeModel = (ids) => {
  return request({
    url: '/api/jida-flow/model/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const deployModel = (params) => {
  return request({
    url: '/api/jida-flow/model/deploy',
    method: 'post',
    params
  })
}

export const changeState = (params) => {
  return request({
    url: '/api/jida-flow/manager/change-state',
    method: 'post',
    params
  })
}

export const deployUpload = (category, tenantIds, files) => {
  const formData = new FormData();
  formData.append('category', category);
  formData.append('tenantIds', tenantIds);
  files.forEach(file => {
    formData.append('files', file);
  });
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: '/api/jida-flow/manager/deploy-upload',
    method: 'post',
    data: formData
  })
}

export const deleteDeployment = (deploymentIds) => {
  return request({
    url: '/api/jida-flow/manager/delete-deployment',
    method: 'post',
    params: {
      deploymentIds,
    }
  })
}

export const deleteProcessInstance = (params) => {
  return request({
    url: '/api/jida-flow/follow/delete-process-instance',
    method: 'post',
    params
  })
}

export const exportBpmn20 = (id , params) => {
  return request({
    url: APIS.export(id),
    method: 'get',
    params
  })
}

export const createModel = (data) => {
  return request({
    url: APIS.createNewModel,
    method: 'post',
    data
  })
}

export const startFlow = (businessId , businessType , operationType , variables) => {
  let body = {
    businessId: businessId
  };
  if((variables ?? '') !== ''){
    body['variables'] = variables;
  }
  if((operationType ?? '') !== ''){
    body['operationType'] = operationType;
  }
  if((businessType ?? '') !== ''){
    body['businessType'] = businessType;
  }
  return request({
    url: APIS.start,
    method: 'post',
    data: body
  })
}

export const completeTask = (data) => {
  return request({
    url: APIS.completeTask,
    method: 'post',
    data
  })
}

export const queryHistoryList = (params) => {
  return request({
    url: APIS.queryHistoryList,
    method: 'get',
    params: params
  })
}

export const getTodoList = (params) => {
  return request({
    url: APIS.todoList,
    method: 'get',
    params: params
  })
}

export const getTaskInfo = (params) => {
  return request({
    url: APIS.taskInfo,
    method: 'get',
    params: params
  })
}


export const saveModel = (id , data) => {
  return request({
    url: APIS.saveModel(id),
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}
