<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :before-open="beforeOpen"
      :permission="permissionList"
      :before-close="beforeClose"
      @row-del="rowDel"
      @row-update="(row, index, done, loading) => rowSave(row, done, loading)"
      @row-save="rowSave"
      @refresh-change="refreshChange"
      @on-load="onLoad"
      @tree-load="treeLoad"
    >
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          icon="el-icon-delete"
          size="small"
          @click.stop="handelDel(scope.row, scope.index)"
          v-if="(!scope.row.children || (scope.row.children && scope.row.children.length === 0)) && permissionList.delBtn"
          >删除
        </el-button>
        <el-button
          type="text"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click.stop="handleAdd(scope.row, scope.index)"
          v-if="permissionList.childAdd"
          >新增子项
        </el-button>
      </template>
      <template slot="icon_urlForm" slot-scope="{ disabled, size }" >
        <form-file v-model="form.icon_url" :disabled="disabled" :max="1" accept="image/*"></form-file>
      </template>
      <template slot-scope="{ row }" slot="deptCategory">
        <el-tag>{{ row.deptCategoryName }}</el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import {
  getMetaCommonDetail,
  getMetaCommonListTree,
  updateMetaCommon,
  saveMetaCommon,
  delMetaCommon
} from "@/api/busiMode/metaCommon";
import {
  getTreeListAPI,
  addTreeListAPI,
  updTreeListAPI,
  delCatgNode,
} from "@/api/spcod/prncatg.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      parentId: 0,
      option: {
        height: 'auto',
        lazy: true,
        tip: false,
        // simplePage: true,
        searchShow: true,
        searchMenuSpan: 6,
        tree: true,
        border: true,
        index: true,
        // selection: true,
        delBtn: false,
        viewBtn: true,
        menuWidth: 300,
        dialogClickModal: false,
        rowKey:'id',
        rowParentKey:'pid',
        column: [
          {
            label: "分类名称",
            prop: "name",
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
            span: 24,
            row: true,
          },
          {
            label: "分类编码",
            prop: "code",
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
            span: 24,
            row: true,
          },
          {
            label: "上级分类",
            prop: "pid",
            dicData: [],
            type: "tree",
            hide: true,
            addDisabled: false,
            disabled: true,
            props: {
              label: "name",
              value: "id",
            },
            rules: [
              {
                required: false,
                message: "请选择上级科目",
                trigger: "click",
              },
            ],
            span: 24,
            row: true,
          },
          {
            label: "分类图标",
            prop: "icon_url",
            type: 'upload',
            hide: true,
            showColumn: false,
            viewDisabled: true,
            span: 24,
            component: "form-file",
            params: {
              accept: "image/*",
              multiple: false,
              max: 1,
              placeholder: '',
            },
          },
          {
            label: "分类备注",
            prop: "memo",
            type: "textarea",
            rules: [
              {
                required: false,
                message: "请输入备注",
                trigger: "blur",
              },
            ],
            hide: true,
            span: 24,
            row: true,
          },
        ],
      },
      data: [],
      model: "wisdom_park.wp_prn_catg",
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.prncatg_add, false),
        viewBtn: this.vaildData(this.permission.prncatg_view, false),
        delBtn: this.vaildData(this.permission.prncatg_del, false),
        editBtn: this.vaildData(this.permission.prncatg_edit, false),
        childAdd: this.vaildData(this.permission.prncatg_child_add, false),
      };
    },
  },
  methods: {
    initData() {
      getMetaCommonListTree(this.model, {
        labelField: "name",
        valueField: "id",
        releaseField: "pid",
        isLazy: false,
      }).then((res) => {
        const column = this.findObject(this.option.column, "pid");
        column.dicData = res.data.data;
      });
    },
    handelDel(row) {
      this.$refs.crud.rowDel(row);
    },
    handleAdd(row) {
      this.parentId = row.id;
      const column = this.findObject(this.option.column, "pid");
      column.value = row.id;
      column.addDisabled = true;
      this.$refs.crud.rowAdd();
    },

    rowSave(row, done, loading) {
      let body = {};
      this.option.column.forEach(
        item => {
          if(row[item.prop] !== undefined){
            body[item.prop] = row[item.prop];
          }else{
            body[item.prop] = '';
          }
        }
      );
      body['pid'] = body['pid'] ? body['pid'] : 0;
      if(row.id){
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: body
        }
      }
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        // 获取新增数据的相关字段
        const data = res.data.data;
        row.id = data.id;
        row.code = data.code;
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        // 数据回调进行刷新
        done(row);
        // this.parentId = 0;
        // this.onLoad();
      }, error => {
        window.console.log(error);
        loading();
      });
      // let data = {};
      // Object.keys(row).forEach(
      //   key => {
      //     let keyName = '';
      //     key.split('_').forEach(
      //       (a , index) => {
      //         if(index > 0){
      //           let arr = a.split('');
      //           arr[0] = a[0].toLocaleUpperCase();
      //           keyName += arr.join('');
      //         }else{
      //           keyName += a;
      //         }
      //       }
      //     );
      //     data[keyName] = row[key];
      //   }
      // );
      // (data.id ? updTreeListAPI : addTreeListAPI)(data);
    },
    rowDel(row , index , done) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          // 数据回调进行刷新
          done(row);
          // this.onLoad();
        });
    
    },
    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData();
      }
      if (["edit", "view"].includes(type)) {
        getMetaCommonDetail(this.model, {
          pk: "id",
          id: this.form.id,
        }).then((res) => {
          this.form = res.data.data;
          if(!this.form.icon_url){
            this.form.icon_url = '';
          }
          if(this.form.icon_url.includes('http')){
            this.form.icon_url = '';
          }
          console.log(this.form);
          done();
        });
      }else{
        this.form.icon_url = '';
        done();
      }
    },
    beforeClose(done) {
      this.parentId = "";
      const column = this.findObject(this.option.column, "pid");
      column.value = "";
      column.addDisabled = false;
      done();
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getMetaCommonListTree(this.model, {
        labelField: "name",
        valueField: "id",
        releaseField: "pid",
        // isLazy: false,
      }).then((res) => {
        this.data = res.data.data;
        this.loading = false;
      });
      // getTreeListAPI().then((res) => {
      //   let handle = (data) => {
      //     return (data || []).map((item) => {
      //       let newItem = {
      //         ...item,
      //       };
      //       if (item.subs) {
      //         newItem.children = handle(item.subs);
      //       }
      //       delete newItem.subs;
      //       return newItem;
      //     });
      //   };
      //   this.data = handle(res.data.data);
      //   this.loading = false;
      // });
    },
    treeLoad(tree, treeNode, resolve) {
      const parentId = tree.id;
      getMetaCommonListTree(this.model, {
        labelField: "name",
        valueField: "id",
        releaseField: "pid",
        rootValue: parentId,
      }).then((res) => {
        resolve(res.data.data);
      });
    },
  },
};
</script>

<style></style>
