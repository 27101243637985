
import * as dictService from "@/api/system/dictbiz";

   export const optionEnter = {
    height:"auto",

      search:false,
      dateBtn: false,
      border: true,
      tip: true,
      searchShow: false,
      addBtn: false,
      refreshBtn: true,
      addRowBtn: false,
      columnBtn: true,
      delBtn: false,
      editBtn: false,
      cancelBtn: false,
      rowKey: "",
      showPage: true,
      index: true,
      indexLabel: "序号",
      selection: false,
      showHeader: true,
      calcHeight: 0,
      expand: false,
      highlightCurrentRow: true,
      stripe: false,
      emptyBtn:false,
      menuWidth: 100,
      reserveSelection: true,
      column: [
        {
          width: "auto",
          overHidden: true,
          align: "right",
          label: "主键id",
          prop: "id",
          hide: true,
        },
        {
          width: "200",
          overHidden: true,
          align: "left",
          label: "入驻企业",
          fixed: true,
          prop: "name",
          search:false,
          hide: false,
          searchSpan: 6,
        },
      
        {
          width: "200",
          sortable:true,
          overHidden: true,
          align: "left",
          type: "date",
          label: "入驻时间",
          prop: "plan_checkin_time",
          format: "yyyy年MM月dd日",
          valueFormat: "yyyy-MM-dd",
        },
        {
          width: "300",
          overHidden: true,
          align: "left",
          label: "入驻房间",
          prop: "room_names",
        },
        {
          width: "150",
          overHidden: true,
          align: "left",
          label: "申请人",
          prop: "applicant",
          hide: false,
        },
        {
          width: "200",
          overHidden: true,
          align: "left",
          label: "联系电话",
          prop: "phone_num",
          hide: false,
        },
       
        {
          width: "200",
          sortable:true,
          overHidden: true,
          align: "left",
          type: "date",
          label: "申请时间",
          prop: "create_time",
          format: "yyyy年MM月dd日",
          valueFormat: "yyyy-MM-dd",
        },
     
        {
          width: "200",
          overHidden: true,
          align: "left",
          label: "合同编码",
          prop: "contract_code",
          hide: false,
        },
 
      ],
    
    }

 
  
  
  