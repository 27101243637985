import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import enLocale from './en'
import zhLocale from './zh'

import AvueZh from './avue/zh';
import AvueEn from './avue/en';

import { getStore } from '@/util/store'
Vue.use(VueI18n)
// const Avue = window.AVUE;
const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
    ...AvueEn
    // ...Avue.locale.en,
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
    ...AvueZh,

  }
}

function getNavigatorLanguage(){
  let language = 'zh-CN';
  if(navigator && navigator.language){
    let lang = navigator.language;
    lang.replace('-' , '_');
    if(messages[lang]){
      language = lang;
    }
  }
  return language;
}
const i18n = new VueI18n({
  locale: getStore({ name: 'language' }) || getNavigatorLanguage(),
  messages,
  silentTranslationWarn: true,
})
i18n.$t = i18n.t;
i18n.globalScope = {
  LOCALE: i18n.locale
}

export default i18n