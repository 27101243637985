import request from '@/router/axios';
import apiUrl from './apiUrl';

export default {
    getList(params){
        return request({
            method: 'get',
            params: params,
            url: apiUrl.authorize.list
        })
    },
    save(data){
        return request({
            method: 'post',
            data: data,
            url: apiUrl.authorize.save
        })
    },
    get(params){
        return request({
            method: 'get',
            params: params,
            url: apiUrl.authorize.get
        })
    },
    remove(ids){
        return request({
            url: apiUrl.authorize.remove,
            method: 'post',
            params: ids
        })
    }
}