<template>
  <img v-if="!isError" class="custom-el-upload-view-item__image" :src="src" alt="" @error="isError = true;" @load="isError = false;"/>
  <img v-else src="/assets/images/icon/file/file.png">
</template>
<script>
export default {
  data(){
    return {
      isError: false
    }
  },
  props: {
    src: {
      type: String,
      default: ''
    }
  }
}
</script>