import { vuePlugin } from "./packages/highlight";
import MyPD from "./packages/index.js";

import "./packages/theme/index.scss";
import "highlight.js/styles/atom-one-dark-reasonable.css";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";

export default function install(Vue){
  Vue.use(vuePlugin);
  Vue.use(MyPD);
}