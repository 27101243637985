import request from '@/router/axios';

export const getDeliveryMan = (current, size, params) => {
  return request({
    url: '/api/park-spcod/deliveryman/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/xpsexpressorder/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/xpsexpressorder/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/xpsexpressorder/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/park-spcod/xpsexpressorder/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/xpsexpressorder/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/xpsexpressorder/submit',
    method: 'post',
    data: row
  })
}


//{addrTag:地址标签,bizType:XPS快递,bizId:当前ID,deliveryTitle:标题,memo:}
export const createDeliveryOrder = (row) => {
  return request({
    url: '/api/park-spcod/spcoddeliveryorder/createorder',
    method: 'post',
    data: row
  })
}

export const toogleStatus = (id,status) => {
  return request({
    url: '/api/park-spcod/xpsexpressorder/tooglestatus/' + id + "?status=" + status,
    method: 'get'
  })
}

export const dispath2XPS = (params) => {
  return request({
    url: '/api/park-spcod/xpsexpressorder/dispath2xps',
    method: 'post',
    data: params
  })
}
