import request from '@/router/axios';
const prefix = '/api/metadata-proxy/api/meta';
const prefixFile = '/api/jida-resource/';
export const getMetaList = (obj) => {
  return request({
    //url:'/json/dataMaintenance/fileList.json',
    url: prefix + '/v2/entity/search/basic',
    method: 'post',
    data:obj
  })
};
export const getFileList = (params) => {
  return request({
    method: 'get',
    params: params,
    url: prefixFile + 'attachfile/list'
  })
}
export const saveDataFile = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefixFile + '/attachfile/submitwith',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export const removeDataFile = (ids) => {
  return request({
    method: 'post',
    params: {
      ids,
    },
    url: prefixFile + 'attachfile/remove'
  })
}

export const inDBDataFile = (obj) => {
  return request({
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    }],
    data: obj,
    url: prefixFile + 'attachfile/putin'
  })
}
