<template>
  <div>
    <el-menu-item
      class="lh-50 h-50 menu-top"
      v-if="!menu['children'] || menu['children'].length === 0"
      :index="menu.id"
      @click="open(menu)"
    >
      <template slot="title">
        <i :class="menu[iconKey]"></i>
        <span slot="title"> {{ generateTitle(menu) }}</span>
      </template>
    </el-menu-item>
    <el-submenu v-else :index="menu.id" class="lh-50 h-50">
      <template slot="title">
        <i :class="menu[iconKey]"></i>
        <span slot="title"> {{ generateTitle(menu) }}</span>
      </template>
      <template v-for="(item, index) of menu.children">
        <TopMenuItem
          :key="item.id + index"
          :menuMode="menuMode"
          :props="props"
          :menu="item"
        ></TopMenuItem>
      </template>
    </el-submenu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isURL, validatenull } from "@/util/validate";
import { translateUrl } from '@/util/util';
import config from "../sidebar/config";

export default {
  name: "TopMenuItem",
  data() {
    return {
      config: config,
    };
  },
  props: {
    menu: {
      type: Object,
      default: null,
    },
    menuMode: String,
    props: {
      type: Object,
      default: () => {
        return {};
      },
    },
    screen: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(["roles"]),
    labelKey() {
      return this.props.label || this.config.propsDefault.label;
    },
    pathKey() {
      return this.props.path || this.config.propsDefault.path;
    },
    iconKey() {
      return this.props.icon || this.config.propsDefault.icon;
    },
    childrenKey() {
      return this.props.children || this.config.propsDefault.children;
    },
    isOpenKey() {
      return this.props.isOpen || this.config.propsDefault.isOpen;
    },
    nowTagValue() {
      return this.$router.$avueRouter.getValue(this.$route);
    },
  },
  mounted() {},
  methods: {
    generateTitle(item) {
      return this.$router.$avueRouter.generateTitle(
        item[this.labelKey],
        (item.meta || {}).i18n
      );
    },
    validatenull(val) {
      return validatenull(val);
    },
    validIsOpen(item) {
      if (item[this.isOpenKey] === 2 && isURL(item[this.pathKey])) {
        return true;
      }
    },
    open(item) {
      if (this.screen <= 1) this.$store.commit("SET_COLLAPSE");
      if (this.validIsOpen(item)) {
        window.open(translateUrl(item[this.pathKey] , {
          ...this.$store.state.user
        }));
      } else {
        this.$router.$avueRouter.group = item.group;
        this.$router.$avueRouter.meta = item.meta;
        let path = this.$router.$avueRouter.getPath(
          {
            name: item[this.labelKey],
            src: item[this.pathKey],
          },
          item.meta
        );
        this.$router.push({
          path: path,
          query: item.query,
        });
        this.$breadcrumb.add({
          name: item[this.labelKey],
          path: path,
          type: this.$store.state.user.sidebarMenuType,
          meta: item,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.menu-top:hover {
  background: rgba(0, 0, 0, 0.1);
}
.menu-top span {
  font-size: 14px !important;
}
.menu-top i {
  font-size: 16px !important;
}
.el-menu--horizontal > div {
  float: left;
}
</style>
