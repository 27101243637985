import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/prnpolicy/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
    return request({
      url: '/api/park-spcod/prnpolicy/page',
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }

export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/prnpolicy/detail/'+id,
    method: 'get',
    params: {
      id
    }
  })
}

export const getProcess = (processId) => {
  return request({
    url: '/api/park-spcod/prnpolicy/process/'+processId,
    method: 'get'
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/park-spcod/prnpolicy/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/prnpolicy/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/prnpolicy/submit',
    method: 'post',
    data: row
  })
}

// updateapptstatus/12345?status=1
export const aduitPolicy = (prnpolicyId,status,memo) => {
  let d = {};
  if(memo)
     d.memo = memo;
  return request({
    url:  '/api/park-spcod/prnpolicy/aduitpolicy/' + prnpolicyId + '?status=' + status,
    method: 'post',
    params: d
  })
}

//公司通知关联,{policyId:123,corpIds:[1,2,3]}
export const addCorpNofity = (dataDTO) => {
  return request({
    url:  '/api/park-spcod/prnpolicynotify/addnofity',
    method: 'post',
    data: dataDTO
  })
}

export const getCorpNofityPage = (current, size, params) => {
  return request({
    url:  '/api/park-spcod/prnpolicynotify/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


export const doCheckRemove = (id,forcePurge) => {
  return request({
    url: '/api/park-spcod/prnpolicy/removeone/'+id,
    method: 'post',
    params:{
      'forcePurge':forcePurge
    }
  })
}
