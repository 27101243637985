<template>
  <div class="top-menu w-100p">
    <el-menu :default-active="activeIndex" mode="horizontal" text-color="#333">
      <!--<el-menu-item index="0" @click.native="openHome(itemHome)" key="0">-->
      <!--<template slot="title">-->
      <!--<i :class="itemHome.source"></i>-->
      <!--<span>{{generateTitle(itemHome)}}</span>-->
      <!--</template>-->
      <!--</el-menu-item>-->
      <!--<template v-for="(item,index) in items">-->
      <!--<el-menu-item :index="item.id+''" @click.native="openMenu(item)" :key="index + 1">-->
      <!--<template slot="title">-->
      <!--<i :class="item.source" style="padding-right: 5px;"></i>-->
      <!--<span>{{generateTitle(item)}}</span>-->
      <!--</template>-->
      <!--</el-menu-item>-->
      <!--</template>-->
      <template v-if="indexMain.menuMode !== 'top'">
        <el-menu-item
          v-for="(item, index) in menu"
          :index="item.id + ''"
          @click.native="openMenu(item)"
          :key="index + 1"
          :class="{
            active: activeTopMenuName && item.name === activeTopMenuName,
          }"
        >
          <template slot="title">
            <i :class="item.source" style="padding-right: 5px"></i>
            <span>{{ generateTitle(item) }} </span>
          </template>
        </el-menu-item>
      </template>
      <template v-if="indexMain.menuMode === 'top'">
        <draggable
          class="widget-form-group__body"
          ghost-class="ghost"
          group="people"
          :animation="200"
          @start="drag = true"
          @end="drag = false"
        >
          <template v-for="(item, index) of menu">
            <button style="background: transparent; border: 0px">
              <TopMenuItem
                :screen="screen"
                :props="website.menu.props"
                :key="'menu_' + item.id"
                :menu="item"
              >
              </TopMenuItem>
            </button>
          </template>
        </draggable>
      </template>
    </el-menu>
  </div>
</template>

<script>
import TopMenuItem from "./top-menu-item.vue";
import Draggable from "vuedraggable";
import website from "@/config/website";
import { mapGetters, mapState } from "vuex";
import { translateUrl } from '@/util/util';
function isURL(s) {
  if (s.includes("html")) return true;
  return /^http[s]?:\/\/.*/.test(s);
}

export default {
  components: { TopMenuItem, Draggable },
  name: "top-menu",
  data() {
    return {
      addPageVisible: false,
      itemHome: {
        name: "首页",
        source: "el-icon-menu",
      },
      activeIndex: "0",
      items: [],
      firstAvailMenu: {},
      activeTopMenuName: "",
    };
  },
  inject: ["indexMain"],
  created() {
    if (this.$store.state.user) {
      this.activeIndex = this.$store.state.user.menuId;
    }
    if (this.indexMain.menuMode === "top") {
      window.document.getElementsByTagName("body")[0].className = "menuTopDark";
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      viewEdit: (state) => state.common.viewEdit,
    }),
    ...mapGetters(["tagCurrent", "menu", "screen", "website"]),
  },
  watch: {
    "$store.state.user.menuLoad": function () {
      if (
        this.$router.currentRoute &&
        this.$store.state.user.menuLoad === true
      ) {
        if (
          this.$router.currentRoute &&
          this.$router.currentRoute.path != website.fistPage.value
        ) {
          this.$router.push({
            path: this.$router.$avueRouter.getPath({
              name: this.$router.currentRoute.name,
              src: this.$router.currentRoute.path,
            }),
            query: {
              ...this.$router.currentRoute.query,
            },
          });
        } else {
          this.openFirst();
        }
      }
    },
    "$store.state.user.menuId": function (val) {
      console.log(val);
      if (val) {
        this.activeIndex = val;
      }
    },
  },
  methods: {
    openFirst() {
      let firstMenu = null;
      for (let i = 0; i < this.menu.length; i++) {
        if (this.activeIndex == this.menu[i].id) {
          //  if (!isURL(this.menu[i].path)) {
          firstMenu = this.menu[i];
          this.activeIndex = this.menu[i].id;
          break;
          //  }
        }
      }
      if (firstMenu) {
        this.openMenu(firstMenu);
      } else {
        this.$store.dispatch("GetMenu").then((res) => {
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              if (!isURL(res[i].path)) {
                firstMenu = res[i];
                this.activeIndex = res[i].id;
                break;
              }
            }
            this.openMenu(firstMenu);
          }
        });
      }
    },
    changeRoute() {
      let topMenuName = this.$route.matched[0].name || "";
      this.activeTopMenuName = topMenuName;
    },
    openHome(itemHome) {
      this.$breadcrumb.add({
        type: "index",
        meta: itemHome,
        name: itemHome.name,
      });
      this.indexMain.openMenu(itemHome);
      this.$router.push({
        path: this.$router.$avueRouter.getPath(
          { name: itemHome.name, src: "" },
          {}
        ),
      });
    },
    openMenu(item) {
      if (isURL(item.path)) {
        window.open(translateUrl(item.path , {
          ...this.$store.state.user
        }));
      } else {
        let showMenus = this.indexMain.showMenus;
        this.indexMain.showMenus = true;
        this.$breadcrumb.add({
          type: "top-menu",
          meta: item,
          name: item.name,
        });
        this.$store.commit("SET_SIDEBAR_MENU_TYPE", "sidebar-menu-root");
        this.$store.commit("SET_SIDEBAR_MENU", item);
        this.$store.commit("SET_MENU_ID", item.id);
        this.activeIndex = this.$store.state.user.menuId;
        if (item["children"] && item["children"].length) {
          this.getFirstMenu(item["children"]);
          let firstMenu = this.firstAvailMenu;
          this.$router
            .push({
              path: this.$router.$avueRouter.getPath({
                name: firstMenu.name,
                src: firstMenu.path,
              }),
            })

            .catch(() => {
              this.indexMain.showMenus = showMenus;
            });
          if (!this.$breadcrumb.excludeRoutes.includes(firstMenu.path)) {
            this.$breadcrumb.add({
              type: "sidebar-menu-root",
              meta: firstMenu,
              name: firstMenu.name,
              path: firstMenu.path,
            });
          }
        } else {
          this.indexMain.showMenus = false;
          this.$router
            .push({
              path: this.$router.$avueRouter.getPath(
                { name: item.name, src: item.path },
                {}
              ),
            })
            .catch(() => {
              this.indexMain.showMenus = showMenus;
            });
        }
        //this.openMenu(item);
      }
    },
    getFirstMenu(arr) {
      let _t = this;
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.children && item.children.length > 0) {
          let flag = this.getFirstMenu(item.children);
          if (!flag) {
            break;
          }
        } else {
          if (item["path"]) {
            _t.firstAvailMenu = item;
            return false;
          }
        }
      }
    },
    getMenu() {
      this.$store.dispatch("GetTopMenu").then((res) => {
        this.items = res;
      });
    },
    generateTitle(item) {
      return this.$router.$avueRouter.generateTitle(
        item.name,
        (item.meta || {}).i18n
      );
    },
  },
};
</script>
<style scoped>
.editBtn {
  text-align: center;
  border-color: #f18b62 !important;
  color: #f18b62 !important;
  border-style: dashed;
}
.w-500-oa {
  width: 500px;
  overflow: auto;
}
</style>
