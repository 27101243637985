export const firstToUpper = (val) => {
  if(typeof val === 'string'){
    return val.trim().toLowerCase().replace(val[0], val[0].toUpperCase());
  }else if(val instanceof Array){
    let values = [];
    val.forEach(
      item => {
        values.push(firstToUpper(item));
      }
    )
    return values;
  }
}