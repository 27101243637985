import request from '@/router/axios';
const prefix = '/api/jida-resource/oss/endpoint/';
// put-file-attach

export const uploadFile = (file) => {
  return request({
    url: prefix + 'put-file-attach',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: file
  })
}