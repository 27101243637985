<template>
  <div id="pageIndex">
    <pageIndex v-if="website.layoutType == 'pageIndex'"></pageIndex>
    <custIndex v-if="website.layoutType == 'custIndex'"></custIndex>

    <el-dialog
      :size="50"
      :visible.sync="dialogVisible"
      destroy-on-close
      :with-header="false"
      :close-on-click-modal="false"
      :show-close="false"
      title="设置新登录密码"
      width="60%"
    >
      <el-row>
        <updatePass></updatePass>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import custIndex from "./custIndex";
import pageIndex from "./pageIndex";
import updatePass from "../../views/system/updatePass";

export default {
  components: {
    pageIndex,
    custIndex,
    updatePass,
  },
  name: "index",
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["website", "userInfo"]),
    // breadcrumbs(){
    //   return this.$breadcrumb.breadcrumbs;
    // }
  },
  mounted() {
    if (this.userInfo.is_loginin === 0) {
      this.dialogVisible = true;
    }
    //this.init();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 10px 20px 40px 20px;
}
#pageIndex {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
