import request from '@/router/axios';
const prefix = '/api/sentinel';
export const getFlowRules = (params) => {
  return request({
    url: prefix + `/v1/flow/rules`,
    method: 'get',
    params
  })
};
export const saveFlowRule = (params) => {
  return request({
    url: prefix + `/v1/flow/rule`,
    method: 'post',
    data:params
  })
};
export const updateFlowRule = (params) => {
  return request({
    url: prefix + `/v1/flow/save.json`,
    method: 'put',
    params
  })
};
export const delFlowRule = (params) => {
  return request({
    url: prefix + `/v1/flow/delete.json`,
    method: 'delete',
    params
  })
};

