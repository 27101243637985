<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.prnpolicyconsult_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>


        <template slot-scope="scope" slot="menu">
          <template v-if="scope.row.status == 1">
            <el-button
                type="text"
                size="small"
                icon="el-icon-close"
                class="none-border"
                @click="confirmToggle(scope.row)"
                >关闭
              </el-button>
          </template>
          <template v-if="scope.row.status == 0">
            <el-button
                type="text"
                size="small"
                icon="el-icon-open"
                class="none-setting"
                @click="confirmToggle(scope.row)"
                >开放
              </el-button>
          </template>
          <template>
            <el-button
                type="text"
                size="small"
                icon="el-icon-s-order"
                class="none-border"
                @click="gotoRelpyPage(scope.row.id)"
                >回复列表
              </el-button>
              <el-button @click.stop="handleReply(scope.row)" icon="el-icon-info" size="mini" type="text">回复</el-button>
        </template>
      </template>
    </avue-crud>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="60%"
      append-to-body
      @close="dialogCancel"
    >
    <avue-form :option="formReplyOption" @submit="replySubmit" v-model="formReply"></avue-form>
    </el-dialog>

  </basic-container>
</template>

<script>
  import {getList,getPage, getDetail, add, update, remove, toogleStatus} from "@/api/spcod/prnpolicyconsult";
  import {addReply} from "@/api/spcod/prnpolicyconsultreply";
  import {mapGetters} from "vuex";

  export default {
    data() {

        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
          if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
            callback(new Error("请输入正确的手机号格式"));
          } else {
            callback();
          }
        };
      return {
        form: {},
        query: {},
        loading: true,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        curConsultId:0,
        formReply:{},
        formReplyOption: {
          column: [
          {
              label: "回复内容",
              prop: "replyContent",
              span: 24,
              minRows:6,
              type:"textarea",
              rules: [{
                required: true,
                message: "请输入回复内容",
                trigger: "blur"
              }]
            },
            /*{
              label: "专家id",
              prop: "expertId",
              type: 'select',
              dicUrl: '/api/park-spcod/prnexpert/list?current=1&size=10000000',
               props: {
                label: 'expertName',
                value: 'id'
              },
              dicFormatter:(res)=>{
                let datalist = res.data.records;
                datalist.forEach(ele => {
                  ele.id = parseInt(ele.id);
                });
                return datalist;
              },
              rules: [{
                required: false,
                message: "请输入外键到楼栋ID",
                trigger: "blur"
              }]
            }*/
          ]
        },
        dialogTitle: '回复咨询',
        dialogVisible: false,
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          addBtn: false,
          editBtn:false,
          viewBtn: true,
          delBtn:false,
          dialogClickModal: false,
          column: [
            // 不要
            // {
            //   label: "政策id",
            //   prop: "policyId",
            //   display:false,
            //   rules: [{
            //     required: true,
            //     message: "请输入政策id",
            //     trigger: "blur"
            //   }]
            // },
            {
              label: "咨询人",
              prop: "consultName",
              rules: [{
                required: true,
                message: "请输入咨询人名字",
                trigger: "blur"
              }]
            },
            {
              label: "电话",
              prop: "consultPhone",
              search:true,
              rules: [{
                required: true,
                message: "请输入电话",
                trigger: "blur"
              },{
                trigger: "blur",
                validator: validateMobile
                }]
            },
            {
              label: "咨询公司",
              prop: "consultCorp",
              search:true,
              rules: [{
                required: true,
                message: "请输入咨询公司",
                trigger: "blur"
              }]
            },
            {
              label: "咨询时间",
              prop: "consultTime",
              type: "datetime",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
              rules: [{
                required: true,
                message: "请输入咨询时间",
                trigger: "blur"
              }]
            },
            {
              label: "咨询内容",
              prop: "consultContent",
              span: 24,
              minRows:6,
              type:"textarea",
              rules: [{
                required: true,
                message: "请输入咨询内容",
                trigger: "blur"
              }]
            },
            {
              label: "回复数量",
              prop: "replyNum",
              rules: [{
                required: false,
                message: "请输入回复内容",
                trigger: "blur"
              }]
            },
            {
              label: "回复时间",
              prop: "replyTime",
              type: "datetime",
              format: "yyyy-MM-dd HH:mm:ss",
              valueFormat: "yyyy-MM-dd HH:mm:ss",
              rules: [{
                required: false,
                message: "请输入回复时间",
                trigger: "blur"
              }]
            },
            {
              label: "所属用户",
              prop: "userName",
              hide:true,
              rules: [{
                required: true,
                message: "请输入用户ID，如果用户登录则记录，否则此id=0",
                trigger: "blur"
              }]
            },
            {
              label: "状态",
              prop: "status",
              search: true,
              searchSpan: 4,
              addDisplay:false,
              editDisplay:false,
              type: 'select',
              dicData: [
                {
                  value: 1,
                  label: '开放'
                },
                {
                  value: 0,
                  label: '关闭'
                }
              ],
              rules: [{
                required: false,
                message: "状态",
                trigger: "blur"
              }]
            },
          ]
        },
        data: []
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.prnpolicyconsult_add, false),
          viewBtn: this.vaildData(this.permission.prnpolicyconsult_view, false),
          delBtn: this.vaildData(this.permission.prnpolicyconsult_delete, false),
          editBtn: this.vaildData(this.permission.prnpolicyconsult_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    methods: {
      confirmToggle(row){
        let _this = this;
        this.$confirm("确认修改状态", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            //修改状态
            toogleStatus(row.id).then(res=>{
              if(row.status == 1)
                row.status = 0;
              else
                row.status = 1;
              _this.$forceUpdate();
              _this.$message({
                type: "success",
                message: "操作成功!"
              });
            });
          });

      },
      rowSave(row, done, loading) {
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      gotoRelpyPage(consultId){
          this.$router.push({
                  path: '/prnpolicyconsult/prnpolicyconsultreply?consultId=' + consultId,
                  query: {
                  }
                });
      },
      handleReply(row){
        this.dialogVisible = true;
        this.formReply.replyContent = '';
        this.curConsultId = row.id;
      },
      dialogCancel() {
        this.dialogVisible = false;
      },
      replySubmit(){
        this.formReply.consultId = this.curConsultId;
        addReply(this.formReply).then(res=>{
          this.dialogVisible = false;
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "添加回复成功!"
          });
        });
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.selectionClear();
        });
      }
    }
  };
</script>

<style>
</style>
