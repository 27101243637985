import request from '@/router/axios';

export const getTreeListAPI = () => {
  return request({
    url: '/api/park-epmall/itemcat/catgtree',
    method: 'get'
  })
}
// 新增分类
export const addTreeListAPI = (data) => {
  return request({
    url: '/api/park-epmall/itemcat/save',
    method: 'post',
    data
  })
}

// 修改分类
export const updTreeListAPI = (data) => {
  return request({
    url: `/api/park-epmall/itemcat/update`,
    method: 'post',
    data
  })
}

// 删除分类
export const delTreeNode = (id) => {
  return request({
    url: `/api/park-epmall/itemcat/delitemcat/` + id,
    method: 'get'
  })
}
