import basicBlock from './basic-block/main';
import basicContainer from './basic-container/main';
import basicFormButton from './basic-form-button/main';
import avueUeditor from 'avue-plugin-ueditor';
import thirdRegister from './third-register/main';
import basicContextmenu from './basic-contextmenu/main';
import DynamicComponents from './dynamic';
import noData from './noData/main';
import BasicBpmn from './basic-bpmn/main';
import BasicDropdown from './basic-dropdown/main';

import BasicPageHeader from './basic-page-header/main';
import BasicEcharts from './basic-echarts/main';

import VueSmartWidget from 'vue-smart-widget';

import CodeEditor from './code-editor/main';
import basicIcon from './basic-icon/main';
import RichText from './rich-text/main';

import BasicComponents from './basic-components/index.js';

function install(Vue , component){
  Vue.prototype[component.name] = component.export;
}


export default function RegisterGlobalComponents(Vue){
  let components = {
    VueSmartWidget: {
      component: VueSmartWidget,
      use: true
    },
    noData,
    basicContainer,
    basicContextmenu,
    basicFormButton,
    basicBlock,
    avueUeditor,
    thirdRegister,
    BasicBpmn,
    BasicDropdown,
    BasicPageHeader,
    BasicEcharts,
    CodeEditor,
    basicIcon,
    RichText,
    ...DynamicComponents,
    ...BasicComponents
  }

  Object.keys(components).forEach(
    key => {
      if(components[key].name && components[key].isExpand){
        install(Vue , components[key])
      }else if(components[key].use){
        Vue.use(components[key].component);
      }else{
        Vue.component(components.name || key, components[key]);
      }
    }
  )
}
