<template>
    <div>
        <el-dialog :visible="true" :top="'5vh'" width="80%" @close="$emit('close')" class="manageForm" v-loading="pageLoading" :close-on-click-modal="false">
            <div slot="title">
                <span class="fts-16">{{formTitle}}</span>
                <div class="mar-l-40 dis-ib w-500 fts-14">
                    <span class="mar-r-10" :class="{'text-primary': active === 1}">1.基本信息</span>
                    <span class="mar-r-10" :class="{'text-primary': active === 2}">2.金额条款</span>
                    <span v-if="!isBusiness" class="mar-r-10" :class="{'text-primary': active === 3}">3.收款计划</span>
                </div>
            </div>
            <div class="w-100p" v-if="!pageLoading">
                <!-- 基本信息 -->
                <div v-show="active === 1">
                    <el-form ref="form1" class="contract_form" :model="form" :rules="rules1" size="small" label-width="100px" label-position="top">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="合同编号" prop="code">
                                    <el-input v-model="form.code"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="合同属性" prop="contract_attribute">
                                    <el-select :disabled="isRelet" v-model="form.contract_attribute">
                                        <el-option v-for="item of dictData.contract_attribute" :key="item.id" :label="item.dictValue" :value="item.dictKey"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="合同类型" prop="contract_type">
                                    <el-select disabled v-model="form.contract_type">
                                        <el-option v-for="item of dictData.contract_type" :key="item.id" :label="item.dictValue" :value="item.dictKey"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="签约类型" prop="offer_type">
                                    <!-- <el-select :disabled="isRelet" v-model="form.offer_type">
                    <el-option v-for="item of dictData.offer_type" :key="item.id" :label="item.dictValue" :value="item.dictKey"></el-option>
                  </el-select> -->
                                    <el-radio v-model="form.offer_type" v-for="item of dictData.offer_type" :key="item.id" :label="item.dictKey">{{item.dictValue}}</el-radio>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="合同模板" prop="contract_template_id">
                                    <!-- <el-input placeholder="请选择" v-model="form.contract_template_id" class="input-with-select">
                    <el-button slot="append">选择</el-button>
                  </el-input> -->
                                    <!-- <el-select
                    v-model="form.contract_template_id"
                    filterable
                    remote
                    clearable
                    :disabled="isRelet"
                    placeholder="输入关键词搜索"
                    :remote-method="(query) => {searchModel(query , 'wp_contract_template' , 'template')}"
                    :loading="loading.templateLoading"
                  >
                    <el-option v-for="item of dictData.template" :key="item.id" :label="item.template_name" :value="item.id"></el-option>
                  </el-select> -->
                                    <model-search-select v-model="form.contract_template_id" model="wp_contract_template" labelKey="template_name" valueKey="id" :params="{template_type: '1'}" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-divider content-position="left"><span class="is-required-text">租赁位置</span></el-divider>
                            </el-col>
                            <el-col class="mar-t-10" :span="24">
                                <el-table :data="selectRoomList" size="small" border>
                                    <el-table-column type="index" width="50">
                                        <template slot="header">
                                            <el-button size="mini" @click="addSelectRoomList()" type="primary" icon="el-icon-plus" circle></el-button>
                                        </template>
                                        <template slot-scope="scope">
                                            <el-button size="mini" @click="selectRoomList.splice(scope.$index , 1);calculateRoom();calculate();" type="danger" icon="el-icon-minus" circle></el-button>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="楼宇" width="150px">
                                        <template slot-scope="scope">
                                            <el-select class="dis-b" size="small" filterable v-model="scope.row.building" @change="roomSelectChange(scope.row , 'building');getFloor(scope.$index)">
                                                <el-option v-for="item of dictData.building" :key="item.id" :value="item.id" :label="item.name"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="楼层" width="150px">
                                        <template slot-scope="scope">
                                            <el-select :placeholder="scope.row.building ? '请选择' : '请先选择楼宇'" class="dis-b" size="small" filterable v-model="scope.row.floor"
                                                       @change="roomSelectChange(scope.row , 'floor');getRoom(scope.$index)">
                                                <el-option v-for="item of scope.row.floorData" :key="item.id" :value="item.id" :label="item.name"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="房间">
                                        <template slot-scope="scope">
                                            <el-row type="flex">
                                                <el-col class="w-80 lh-30 text-center mar-0 pad-0">
                                                    <el-link type="primary" :underline="false" @click="selectAllRoom(scope.row , scope.$index)">选中整层</el-link>
                                                    <el-divider direction="vertical"></el-divider>
                                                </el-col>
                                                <el-col :span="1" class="w-100p pad-0">
                                                    <el-cascader class="dis-b w-100p" :key="scope.row.floor" v-model="scope.row.room" @change="roomSelectChange(scope.row , 'room')" size="small"
                                                                 :options="scope.row.roomData" :props="{multiple: true , label: 'name' , value: 'id' , emitPath: false , checkStrictly: true}" clearable
                                                                 filterable>
                                                    </el-cascader>
                                                </el-col>
                                            </el-row>
                                            <!-- <el-checkbox-group v-if="scope.row.roomData.length" v-model="scope.row.room" :disabled="isRelet" @change="setRowRoom(scope.row);roomSelectChange(scope.row , 'room');calculate();">
                        <el-checkbox v-for="item of scope.row.roomData" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
                      </el-checkbox-group>
                      <template v-else>
                        <div class="text-center">
                          <span v-if="scope.row.loading.room"><i class="el-icon-loading"></i>正在加载数据...</span>
                          <span v-else-if="!scope.row.floor">请先选择楼宇和楼层信息</span>
                          <span v-else>暂无数据</span>
                        </div>
                      </template> -->
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div v-if="selectRoomListError" style="position: static" class="el-form-item__error">请选择租赁位置</div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">出租方信息</el-divider>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="甲方签订人" prop="lessor_signed">
                                    <!-- <el-select
                    v-model="form.lessor_signed"
                    @change="modelChange('lessor_signed' , 'staff' , [{code: 'lessor_signed_contact' , name: 'contact_details'}])"
                    filterable
                    remote
                    clearable
                    placeholder="输入关键词搜索"
                    :remote-method="(query) => {searchModel(query , 'wp_employee' , 'staff')}"
                    :loading="loading.staffLoading"
                  >
                    <el-option v-for="item of dictData.staff" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select> -->
                                    <model-search-select v-if="!form.lessor_signed_contact" v-model="form.lessor_signed" format="{name}_{deptName}_{postName}" :params="{tenantId: '000000'}"
                                                         :fillModel.sync="form" :fills="[{source: 'phone' , target: 'lessor_signed_contact'}]">
                                        <template slot-scope="{item}">
                                            <span style="float: left">{{ item.name }}{{item.postName ? `(${item.postName})` : ''}}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
                                        </template>
                                    </model-search-select>
                                    <model-search-select v-if="form.lessor_signed_contact" v-model="form.lessor_signed" format="{name}_{deptName}_{postName}" :params="{tenantId: '000000'}">
                                        <template slot-scope="{item}">
                                            <span style="float: left">{{ item.name }}{{item.postName ? `(${item.postName})` : ''}}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
                                        </template>
                                    </model-search-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="联系方式" prop="lessor_signed_contact">
                                    <el-input v-model="form.lessor_signed_contact" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">租客信息</el-divider>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="租客类型" prop="renters_type">
                                    <el-input v-model="form.renters_type" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="入驻企业" prop="company_id">
                                    <model-search-select v-model="form.company_id" model="wp_company" :fillModel.sync="form" :fills="[
                      {target: 'renters_name' , source: 'name'},
                      {target: 'corporation_personality' , source: 'legal_person'},
                      {target: 'corporation_personality_contact' , source: 'legal_contact'}
                    ]" @change="getReductionPolicy()">
                                        <template slot="empty">
                                            <div class="pad-tb-15 text-center fts-14">
                                                <div v-if="!loading.enterpriseLoading">
                                                    <span>暂无企业，去</span>
                                                    <!-- <span class="cur-p text-blue" @click="enterpriseDrawer = true">添加</span> -->
                                                </div>
                                            </div>
                                        </template>
                                    </model-search-select>
                                    <!-- <model-search-select
                    v-model="form.company_id"
                    model="wp_company"
                    @change="(ev) => {modelChange('company_id' , ev , [
                      {code: 'renters_name' , name: 'name'} ,
                      {code: 'corporation_personality' , name: 'legal_person'} ,
                      {code: 'corporation_personality_contact' , name: 'legal_contact'}
                    ]);getReductionPolicy()}"
                  >
                    <template slot="empty">
                      <div class="pad-tb-15 text-center fts-14">
                        <div v-if="!loading.enterpriseLoading">
                          <span>暂无企业，去</span> <span class="cur-p text-blue" @click="enterpriseDrawer = true">添加</span>
                        </div>
                      </div>
                    </template>
                  </model-search-select> -->
                                    <!-- <el-select
                    v-model="form.company_id"
                    @change="modelChange('company_id' , 'enterprise' , [
                      {code: 'corporation_personality' , name: 'legal_person'} ,
                      {code: 'corporation_personality_contact' , name: 'legal_contact'}
                      ])"
                    filterable
                    :disabled="isRelet"
                    remote
                    clearable
                    placeholder="输入关键词搜索"
                    :remote-method="(query) => {searchModel(query , 'wp_company' , 'enterprise')}"
                    :loading="loading.enterpriseLoading">
                    <el-option
                      v-for="item in dictData.enterprise"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                    <template slot="empty">
                      <div class="pad-tb-15 text-center fts-14">
                        <div v-if="!loading.enterpriseLoading">
                          <span>暂无企业，去</span> <span class="cur-p text-blue" @click="enterpriseDrawer = true">添加</span>
                        </div>
                        <div v-if="loading.enterpriseLoading">
                          <span>加载中...</span>
                        </div>
                      </div>
                    </template>
                  </el-select> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="企业法人" prop="corporation_personality">
                                    <el-input v-model="form.corporation_personality" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="法人联系方式" prop="corporation_personality_contact">
                                    <el-input v-model="form.corporation_personality_contact" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="签订人" prop="renters_signed">
                                    <el-input v-model="form.renters_signed"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="签订人联系方式" prop="renters_signed_contact">
                                    <el-input v-model="form.renters_signed_contact"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联系地址" prop="renters_address">
                                    <el-input v-model="form.renters_address"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">租赁周期</el-divider>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="周期类型" prop="rent_date_type">
                                    <el-radio-group v-model="form.rent_date_type" @change="calculate()">
                                        <el-radio label="1">默认(整月/年)</el-radio>
                                        <el-radio label="2">自定义</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item v-if="form.rent_date_type == 1" label="租赁时间" prop="rent_period">
                                    <el-input placeholder="请输入内容" type="number" @change="calculateEndData('start_date' , 'end_date' , 'rent_period');calculateRoomDate();calculate();"
                                              v-model.number="form.rent_period">
                                        <template slot="append">月</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="开始日期" prop="start_date">
                                    <el-date-picker class="w-100p" value-format="yyyy-MM-dd"
                                                    @change="(form.rent_date_type == 1 && calculateEndData('start_date' , 'end_date' , 'rent_period'));calculateRoomDate();calculate();"
                                                    v-model="form.start_date" type="date" placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="结束日期" prop="end_date">
                                    <el-date-picker :disabled="form.rent_date_type == 1 ? true : false" @change="form.rent_date_type == 1 ? null : calculate();calculateRoomDate();"
                                                    :picker-options="pickerOptions" class="w-100p" value-format="yyyy-MM-dd" v-model="form.end_date" type="date"
                                                    placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="签定日期" prop="signed_date">
                                    <el-date-picker class="w-100p" value-format="yyyy-MM-dd" v-model="form.signed_date" :picker-options="pickerOptions1" type="date"
                                                    placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="提前续租申请" prop="renewal_apply">
                                    <el-input placeholder="请输入" type="number" v-model.number="form.renewal_apply">
                                        <template slot="append">个月</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="!isBusiness">
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">减免优惠</el-divider>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label-width="0px" prop="preferential_way">
                                    <el-radio v-model="form.preferential_way" v-for="item of dictData.preferential_way" :key="item.value" :label="item.value">{{item.name}}</el-radio>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="form.preferential_way == 1">
                            <el-col :span="24">
                                <el-form-item label-width="0px">
                                    <!-- reductionPolicy -->
                                    <el-tag :key="index" v-for="(tag , index) in reductionPolicy" closable class="mar-r-5" :disable-transitions="false" @close="reductionPolicy.splice(index , 1)">
                                        {{tag.reductionPolicyTitle}}
                                    </el-tag>
                                    <model-search-select class="dis-ib w-200" model="wp_reduction_policy_company" labelKey="reduction_policy_title" valueKey="reduction_policy_id"
                                                         @change="addReductionPolicy" :params="{company_id: form.company_id}" v-if="showAddReductionPolicy"
                                                         :disabledList="reductionPolicy.map(item => item.reductionPolicyId)" emptyText="未匹配到与入驻企业绑定的优惠政策">
                                    </model-search-select>
                                    <el-button v-if="form.renters_name && !showAddReductionPolicy" icon="el-icon-plus" size="small" @click="showAddReductionPolicy = true">添加减免政策</el-button>
                                    <div v-if="!form.renters_name">请选择入驻企业</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="form.preferential_way == 2">
                            <!-- <el-col class="mar-tb-10" :span="24">
                <el-divider content-position="left">租赁周期</el-divider>
              </el-col> -->
                            <el-col :span="6">
                                <el-form-item label="免租期" prop="rent_free_period">
                                    <el-input placeholder="请输入免租月数" type="number" @change="set_init_date('rent_free_start_date' , 'rent_free_end_date' , 'rent_free_period');calculate();"
                                              v-model.number="form.rent_free_period">
                                        <template slot="append">月</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="免租开始日期" prop="rent_free_start_date">
                                    <el-date-picker :picker-options="pickerOptions" class="w-100p" value-format="yyyy-MM-dd"
                                                    @change="calculateEndData('rent_free_start_date' , 'rent_free_end_date' , 'rent_free_period');calculate();" v-model="form.rent_free_start_date"
                                                    type="date" placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="免租结束日期" prop="rent_free_end_date">
                                    <el-date-picker :picker-options="pickerOptions" class="w-100p" value-format="yyyy-MM-dd" v-model="form.rent_free_end_date" type="date"
                                                    placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="!isRelet">
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">装修相关</el-divider>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="装修进场日期" prop="fix_date">
                                    <el-date-picker :picker-options="pickerOptions" class="w-100p" value-format="yyyy-MM-dd" v-model="form.fix_date" type="date"
                                                    placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="计划装修开始时间" prop="start_fix_date">
                                    <el-date-picker :picker-options="pickerOptions" class="w-100p" @change="calculate()" value-format="yyyy-MM-dd" v-model="form.start_fix_date" type="date"
                                                    placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="计划装修结束时间" prop="end_fix_date">
                                    <el-date-picker :picker-options="pickerOptions" class="w-100p" @change="calculate()" value-format="yyyy-MM-dd" v-model="form.end_fix_date" type="date"
                                                    placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="提前通知调整进场日天数" prop="fix_notice">
                                    <el-input placeholder="请输入" type="number" v-model.number="form.fix_notice">
                                        <template slot="append">天</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="!isBusiness">
                            <el-col :span="6">
                                <el-form-item label="装修期是否免租" prop="fix_rent_free">
                                    <el-radio-group v-model="form.fix_rent_free" @change="calculate()">
                                        <el-radio label="1">是</el-radio>
                                        <el-radio label="2">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>

                <!-- 金额条款 -->
                <div v-show="active === 2">
                    <el-form ref="form2" :model="form" :rules="rules2" size="small" label-width="100px" label-position="top">
                        <el-row>
                            <el-col :span="24">
                                <el-divider content-position="left">价格明细</el-divider>
                            </el-col>
                            <el-col class="mar-t-10" :span="24">
                                <el-table :data="roomSelected" size="small" border>
                                    <el-table-column label="楼宇" width="180px" prop="building_name"></el-table-column>
                                    <el-table-column label="楼层" width="50px" prop="floor_name"></el-table-column>
                                    <el-table-column label="房间" width="200px" prop="room_name"></el-table-column>
                                    <el-table-column label="房间面积" width="180px">
                                        <span slot="header" class="is-required-text">房间面积</span>
                                        <template slot-scope="scope">
                                            <el-input size="small" type="number" placeholder="请输入" @change="calculate();" v-model="scope.row.room_area">
                                                <template slot="append">㎡</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="!isBusiness" label="单价" width="180px">
                                        <span slot="header" class="is-required-text">单价</span>
                                        <template slot-scope="scope">
                                            <el-input size="small" type="number" placeholder="请输入" @change="calculate();" v-model="scope.row.rental_unit_price_day">
                                                <template slot="append">元/㎡/日</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="!isBusiness" label="是否递增" width="180px">
                                        <span slot="header" class="is-required-text">是否递增</span>
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="scope.row.incremental_identification">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="2">否</el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column label="是否释放房间" width="180px">
                                        <span slot="header" class="is-required-text">是否释放房间</span>
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="scope.row.is_released">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="0">否</el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column> -->

                                    <el-table-column v-if="!isBusiness" label="递增基础单价" width="180px">
                                        <span slot="header" class="is-required-text">递增基础单价</span>
                                        <template slot-scope="scope">
                                            <el-input size="small" type="number" placeholder="请输入" @change="calculate();" v-model="scope.row.incremental_unit_price">
                                                <template slot="append">元/㎡/日</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="!isBusiness" label="物业管理费" width="180px">
                                        <span slot="header" class="is-required-text">物业管理费</span>
                                        <template slot-scope="scope">
                                            <el-input size="small" type="number" placeholder="请输入" @change="calculate();" v-model="scope.row.property_management_fee">
                                                <template slot="append">元/㎡/月</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="160px" label="租赁开始时间">
                                        <span slot="header" class="is-required-text">租赁开始时间</span>
                                        <template slot-scope="scope">
                                            <el-date-picker :picker-options="{disabledDate: (time) => {
                        return roomDatePickerPropsDisabledDate('start' , scope.row , time);
                      }}" size="small" class="w-100p" value-format="yyyy-MM-dd" @change="calculate();" v-model="scope.row.start_date" type="date" placeholder="年 / 月 / 日"></el-date-picker>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="160px" label="租赁结束时间">
                                        <span slot="header" class="is-required-text">租赁结束时间</span>
                                        <template slot-scope="scope">
                                            <el-date-picker :picker-options="{disabledDate: (time) => {
                        return roomDatePickerPropsDisabledDate('end' , scope.row , time);
                      }}" size="small" class="w-100p" value-format="yyyy-MM-dd" @change="calculate();" v-model="scope.row.end_date" type="date" placeholder="年 / 月 / 日"></el-date-picker>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column label="租金" width="150px" prop="amount">
                    <template slot-scope="scope">
                      <span>￥{{scope.row.amount}}</span>
                    </template>
                  </el-table-column> -->
                                </el-table>
                                <div v-if="roomSelectedError" style="position: static" class="el-form-item__error">请输入房间面积{{isBusiness ? '' : '、单价和物业管理费'}}</div>
                            </el-col>
                        </el-row>
                        <el-row v-if="!isBusiness">
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">租金信息</el-divider>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="付款方式" prop="frequency">
                                    <el-input placeholder="请输入内容" type="number" @change="calculate()" v-model.number="form.frequency" class="input-with-select">
                                        <template slot="append">
                                            <el-select v-model="form.frequency_unit" @change="calculate()" placeholder="请选择">
                                                <el-option v-for="item of dictData.frequency_unit" :key="item.id" :label="item.dictValue" :value="item.dictKey"></el-option>
                                            </el-select>
                                        </template>
                                    </el-input>
                                    <!-- <el-input-number v-model="form.frequency" @change="calculate()" controls-position="right" :min="1"></el-input-number> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="账单生成策略" prop="receipt_strategy">
                                    <el-select class="dis-b w-100p" v-model="form.receipt_strategy" @change="calculate()">
                                        <el-option v-for="item of dictData.receipt_strategy" :label="item.dictValue" :value="item.dictKey"></el-option>
                                        <!-- <el-option label="按自然年" value="2"></el-option> -->
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="物业费缴纳方式" prop="property_fee_way">
                                    <el-select class="dis-b w-100p" v-model="form.property_fee_way" @change="calculate()">
                                        <el-option v-for="item of dictData.property_fee_way" :label="item.dictValue" :value="item.dictKey"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="租金总额" prop="amount">
                                    <el-input placeholder="请输入" type="number" v-model.number="form.amount"></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="6">
                <el-form-item label="付款频次单位" prop="frequency_unit">
                  <el-select v-model="form.frequency_unit" @change="calculate();">
                    <el-option v-for="item of dictData.frequency_unit" :key="item.id" :label="item.dictValue" :value="item.dictKey"></el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
                        </el-row>
                        <el-row v-if="!isBusiness">
                            <el-col :span="6">
                                <el-form-item label="提前缴费类型" prop="before_payment_type">
                                    <el-radio-group v-model="form.before_payment_type" @change="calculate()">
                                        <el-radio :label="1">提前天数</el-radio>
                                        <el-radio :label="2">固定日期</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="form.before_payment_type === 1" :span="6">
                                <el-form-item label="提前天数" prop="payment_before_day">
                                    <el-input placeholder="请输入" type="number" v-model.number="form.payment_before_day" @change="calculate()">
                                        <template slot="append">天</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="form.before_payment_type === 2" :span="6">
                                <el-form-item label="固定日期" prop="plan_payment_date">
                                    <el-input :min="1" :max="31" placeholder="请输入" type="number" v-model.number="form.plan_payment_date" @change="calculate()">
                                        <template slot="prepend">每月</template>
                                        <template slot="append">日</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="!isBusiness">
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">年租金递增规则</el-divider>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="开始递增时间" prop="year_increase">
                                    <el-input placeholder="请输入" type="number" min="1" @change="calculate();" v-model.number="form.year_increase">
                                        <template slot="prepend">第</template>
                                        <template slot="append">年后</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="每年递增率" prop="increase_rate">
                                    <el-input placeholder="请输入" type="number" @change="calculate();" v-model="form.increase_rate">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="递增策略" prop="incremental_strategy">
                                    <el-select class="dis-b w-100p" v-model="form.incremental_strategy" @change="calculate()">
                                        <el-option v-for="item of dictData.receipt_strategy" :label="item.dictValue" :value="item.dictKey"></el-option>
                                        <!-- <el-option label="按自然年" value="2"></el-option> -->
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- <el-row>
              <el-col class="mar-tb-10" :span="24">
                <el-divider content-position="left">租金预览</el-divider>
              </el-col>
              <el-col :span="24">
                <el-table
                  :data="amountList"
                  size="small"
                  border
                >
                  <el-table-column label="时间" width="80px" prop="building_name">
                    <template slot-scope="scope">
                      第{{scope.row.year}}年
                    </template>
                  </el-table-column>
                  <el-table-column label="开始日期" width="90px" prop="start_date"></el-table-column>
                  <el-table-column label="结束日期" width="90px" prop="end_date"></el-table-column>
                  <el-table-column label="基础租金" prop="basic_amount"></el-table-column>
                  <el-table-column label="递增租金" prop="increase_amount"></el-table-column>
                  <el-table-column label="租金总额" prop="total_amount"></el-table-column>
                  <el-table-column label="免租金额" prop="free_amount"></el-table-column>
                  <el-table-column label="应收租金">
                    <template slot-scope="scope">
                      ￥<span class="text-red">{{scope.row.amount}}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row> -->
                        <el-row>
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">其他相关</el-divider>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="保证金缴费状态" prop="deposit_pay_status">
                                    <el-radio-group v-model="form.deposit_pay_status" @change="depositPayStatusChange()">
                                        <el-radio v-for="item of dictData.deposit_pay_status" :key="item.id" :label="item.dictKey">{{item.dictValue}}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="form.deposit_pay_status == 2" :span="12">
                                <el-form-item label="保证金来源" prop="deposit_resource">
                                    <model-search-select class="dis-b w-100p" v-model="form.deposit_resource" multiple filterable allow-create model="wp_contract" labelKey="code" valueKey="code"
                                                         @change="countDepositAmount">
                                    </model-search-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="保证金缴纳类型" prop="deposit_type">
                                    <el-radio-group :disabled="form.deposit_pay_status == 2 ? true : false" v-model="form.deposit_type" @change="calculate()">
                                        <el-radio label="0">默认</el-radio>
                                        <el-radio label="1">自定义</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <template v-if="form.deposit_type == 1">
                                <el-col :span="6">
                                    <el-form-item label="保证金" prop="deposit_amount">
                                        <el-input placeholder="请输入" type="number" v-model.number="form.deposit_amount" @change="calculate()">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </template>
                            <template v-if="form.deposit_type == 0">
                                <el-col :span="6">
                                    <el-form-item label="保证金" prop="deposit">
                                        <el-input placeholder="请输入" type="number" min="1" v-model.number="form.deposit" @change="calculate()">
                                            <template slot="append">个月租金</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </template>
                            <el-col :span="6">
                                <el-form-item label="保证金缴纳日期" prop="deposit_payment_date">
                                    <el-date-picker :picker-options="pickerOptions" class="w-100p" value-format="yyyy-MM-dd" v-model="form.deposit_payment_date" @change="calculate()" type="date"
                                                    placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="保证金补足期限" prop="deposit_supply_date">
                                    <el-date-picker :picker-options="pickerOptions" class="w-100p" value-format="yyyy-MM-dd" v-model="form.deposit_supply_date" type="date"
                                                    placeholder="年 / 月 / 日"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">收款账户</el-divider>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="收款账户" prop="collection_account_id">
                                    <model-search-select v-model="form.collection_account_id" model="wp_collection_account" format="{account_bank}_{collection_account}" :fillModel.sync="form" :fills="[
                      {source: 'deposit_bank' , target: '$account_bank'},
                      {source: 'collection_account' , target: '$collection_account'}
                    ]">
                                        <template slot-scope="{item}">
                                            <span style="float: left">{{ item.collection_account }}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.account_bank }}</span>
                                        </template>
                                    </model-search-select>
                                    <!-- <el-select
                    v-model="form.collection_account_id"
                    @change="modelChange('collection_account_id' , 'collection_account' , [
                      {code: '$account_bank' , name: 'account_bank'},
                      {code: '$collection_account' , name: 'collection_account'}
                    ])"
                  >
                    <el-option v-for="item of dictData.collection_account" :key="item.id" :label="item.account_name" :value="item.id"></el-option>
                  </el-select> -->
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="6">
                <el-form-item label="开户名称" prop="account_name">
                  <el-input disabled v-model="form.account_name"></el-input>
                </el-form-item>
              </el-col> -->
                            <el-col :span="6">
                                <el-form-item label="收款账号" prop="$collection_account">
                                    <el-input disabled v-model="form.$collection_account"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="开户银行" prop="$account_bank">
                                    <el-input disabled v-model="form.$account_bank"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="mar-tb-10" :span="24">
                                <el-divider content-position="left">合同附件</el-divider>
                            </el-col>
                            <el-col :span="24">
                                <ManageFormFile v-model="form.attachment_ids"></ManageFormFile>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                </el-form>
                <div v-show="active === 3">
                    <el-form v-if="!isBusiness" ref="form3" :model="form" size="small" label-width="100px" label-position="top">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="收款计划策略" prop="collection_plan_strategy">
                                    <el-radio-group v-model="form.collection_plan_strategy" @change="form.collection_plan_strategy == 1 ? calculate() : clearFeeData()">
                                        <el-radio label="1">自动</el-radio>
                                        <el-radio label="2">手动填写</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-row v-for="(name , index) of ['deposit' , 'rent']">
                        <!-- v-if="form.property_fee_way == 1 ? name == 'rent' : true" -->
                        <el-col class="mar-tb-10" :span="24" v-if="form.deposit_pay_status != 1 ? name == 'rent' : true">
                            <el-divider content-position="left">{{name === 'deposit' ? '保证金' : '租金'}}</el-divider>
                        </el-col>
                        <!-- v-if="form.property_fee_way == 1 ? name == 'rent' : true" -->
                        <el-col :span="24" v-if="form.deposit_pay_status != 1 ? name == 'rent' : true">
                            <div class="pad-b-15">
                                <el-button v-if="form.collection_plan_strategy == 2" @click="addFeeData(name)" type="primary" size="small" icon="el-icon-plus">添加</el-button>
                            </div>
                            <el-table :data="feeData[name] || []" size="small" border>
                                <el-table-column v-if="form.collection_plan_strategy == 2" width="50px">
                                    <template slot-scope="scope">
                                        <el-button size="mini" @click="removeFeeDataItem(scope.$index , name)" icon="el-icon-minus" circle></el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column label="期数" width="80px" prop="name"></el-table-column>
                                <el-table-column label="应收日期" width="150px" prop="receivableDate">
                                    <template slot-scope="scope">
                                        <span v-if="!editMoney && form.collection_plan_strategy == 1">{{scope.row.receivableDate}}</span>
                                        <el-date-picker v-if="editMoney || form.collection_plan_strategy == 2" size="small" class="w-100p" value-format="yyyy-MM-dd" v-model="scope.row.receivableDate"
                                                        type="date" placeholder="年 / 月 / 日"></el-date-picker>
                                    </template>
                                </el-table-column>
                                <template v-if="name === 'rent'">
                                    <el-table-column label="账单周期" width="375px">
                                        <template slot-scope="scope">
                                            <span v-if="form.collection_plan_strategy == 1">
                                                {{scope.row.startTime | formatDate('YYYY-MM-DD')}} 至 {{scope.row.endTime | formatDate('YYYY-MM-DD')}}
                                            </span>
                                            <template v-if="form.collection_plan_strategy == 2">
                                                <el-date-picker size="small" v-model="scope.row.times" @change="(ev) => feeDataTableTimeChange(ev , scope.row)" type="daterange"
                                                                value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" :unlink-panels="true" end-placeholder="结束日期">
                                                </el-date-picker>
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="form.property_fee_way == 2" label="物业费(元)" prop="propertyManagementFeeTotal">
                                        <template slot-scope="scope">
                                            <span v-if="!editMoney && form.collection_plan_strategy == 1">{{scope.row.propertyManagementFeeTotal}}</span>
                                            <el-input size="small" v-if="editMoney || form.collection_plan_strategy == 2" type="number" v-model="scope.row.propertyManagementFeeTotal"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="租金(元)" prop="amountReceivable">
                                        <template slot-scope="scope">
                                            <span v-if="!editMoney && form.collection_plan_strategy == 1">{{scope.row.amountReceivable}}</span>
                                            <el-input size="small" v-if="editMoney || form.collection_plan_strategy == 2" type="number" v-model="scope.row.amountReceivable"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="本期应收金额(元)" header-align="right" align="right">
                                        <template slot-scope="scope">
                                            ￥<span class="text-red">{{getAllMoney(scope.row)}}</span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <template v-if="name === 'deposit'">
                                    <el-table-column label="本期应收金额(元)" header-align="right" align="right" prop="amountReceivable">
                                        <template slot-scope="scope">
                                            <span v-if="!editMoney">{{scope.row.amountReceivable}}</span>
                                            <el-input class="w-200" size="small" v-if="editMoney" type="number" v-model="scope.row.amountReceivable"></el-input>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                            <div class="text-right fts-18 pad-10 bor bor-t-n">
                                应收总额 ￥{{feeData[name] ? getAllMoney(feeData[name] , true) : 0}}元
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div slot="footer" class="text-left bor-t pad-tb-8 pad-lr-15 ov-h">
                <div class="pull-left w-50p">
                    <el-button size="small" v-if="active === 1" @click="$emit('close')">取消</el-button>
                    <el-button size="small" v-if="active > 1 && !editMoney" @click="active--">上一步</el-button>
                    <el-button size="small" v-if="editMoney" @click="cancelEditMoney()">取消</el-button>
                </div>
                <div class="pull-right w-50p text-right">
                    <el-button size="small" v-if="(!isBusiness && active < 3) || (isBusiness && active < 2)" @click="nextStep()" type="primary">下一步</el-button>
                    <!-- <el-button size="small" v-if="active === 2" @click="nextStep()" type="primary">下一步</el-button> -->
                    <!-- calculate -->
                    <el-button size="small" v-if="active === 3 && !editMoney && !isBusiness" @click="setEditMoney">修改金额</el-button>
                    <el-button size="small" v-if="active === 3 && editMoney" @click="cancelEditMoney(true)" type="primary">确认修改</el-button>
                    <el-button size="small" :loading="saveLoading" v-if="(active === 3 || (isBusiness && active === 2)) && !editMoney && form.storage != 1" @click="save(0)"
                               type="primary">暂存</el-button>
                    <el-button size="small" :loading="saveLoading" v-if="(active === 3 || (isBusiness && active === 2)) && !editMoney" @click="save(1)" type="primary">提交</el-button>
                </div>
            </div>
        </el-dialog>
        <el-drawer title="添加企业" :visible.sync="enterpriseDrawer">
            <enterpriseForm @close="enterpriseDrawer = false" @save="saveEnterpriseForm"></enterpriseForm>
        </el-drawer>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import { startFlow } from '@/api/flow/flow.js';
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import { save, update, detail, calculate, queryCollectionPlan, countDepositAmount } from '@/api/contract/index.js';
import { getDictionary } from '@/api/system/dictbiz.js';
import enterpriseForm from './enterpriseForm.vue';
import ManageFormFile from './manageFormFile.vue';
import mathjs from '@/util/math.js';
import { formartPieData } from '../dataService/chart-option';

window.mathjs = mathjs;
window.dayjs = dayjs;

export default {
    components: { enterpriseForm, ManageFormFile },
    data() {
        return {
            editMoney: false,
            dayjs: dayjs,
            saveLoading: false,
            pageLoading: true,
            loading: {
                staffLoading: false,
                enterpriseLoading: false,
                templateLoading: false,
            },
            enterpriseDrawer: false,
            active: 1,
            cache: {
                company_name: '',
            },
            form: {
                storage: 0,
                code: '',
                contract_attribute: '',
                offer_type: '',
                contract_template_id: '',
                lessor_signed: '',
                lessor_signed_contact: '',
                renters_type: '企业',
                company_id: '',
                renters_name: '',
                corporation_personality: '',
                corporation_personality_contact: '',
                renters_signed: '',
                renters_signed_contact: '',
                renters_address: '',
                rent_period: null,
                start_date: dayjs().format('YYYY-MM-DD'),
                end_date: '',
                signed_date: dayjs().format('YYYY-MM-DD'),
                rent_free_period: null,
                rent_free_start_date: '',
                rent_free_end_date: '',
                renewal_apply: null,
                fix_notice: null,
                fix_date: '',
                payment_type: '',
                amount: null,
                payment_before_day: 30,
                year_increase: null,
                increase_rate: null,
                deposit: 1,
                deposit_supply_date: null,
                deposit_payment_date: null,
                collection_account_id: '',
                $account_bank: '',
                $collection_account: '',
                attachment_ids: '',
                preferential_way: 1,
                frequency: 1,
                incremental_strategy: '1',
                frequency_unit: '1',
                property_fee_way: '1',
                deposit_type: '0',
                deposit_amount: null,
                receipt_strategy: '1',
                rent_date_type: '1',
                start_fix_date: '',
                end_fix_date: '',
                deposit_pay_status: '1',
                fix_rent_free: '2',
                deposit_resource: '',
                collection_plan_strategy: '1',
                contract_type: '',
                before_payment_type: 1,
                plan_payment_date: 1,
            },
            rules1: {
                code: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
                contract_attribute: [{ required: true, message: '请选择合同属性', trigger: 'blur' }],
                offer_type: [{ required: true, message: '请选择签约类型', trigger: 'blur' }],
                contract_type: [{ required: true, message: '请选择合同类型', trigger: 'blur' }],
                contract_template_id: [{ required: true, message: '请选择合同模板', trigger: 'blur' }],
                lessor_signed: [{ required: true, message: '请选择甲方签订人', trigger: 'blur' }],
                lessor_signed_contact: [{ required: true, message: '请输入甲方签订人联系方式', trigger: 'blur' }],
                company_id: [{ required: true, message: '请选择入驻企业', trigger: 'blur' }],
                // renters_signed: [
                //   { required: true, message: '请输入租客签订人', trigger: 'blur' }
                // ],
                // renters_signed_contact: [
                //   { required: true, message: '请输入租客签订人联系方式', trigger: 'blur' }
                // ],
                // renters_address: [
                //   { required: true, message: '请输入租客签订人联系地址', trigger: 'blur' }
                // ],
                rent_period: [{ required: true, message: '请输入租赁时间', trigger: 'blur' }],
                start_date: [{ required: true, message: '请选择租赁开始日期', trigger: 'blur' }],
                end_date: [{ required: true, message: '请选择租赁结束日期', trigger: 'blur' }],
                signed_date: [{ required: true, message: '请选择签定日期', trigger: 'blur' }],
            },
            rules2: {
                amount: [{ required: true, message: '请输入租金总额', trigger: 'blur' }],
                payment_type: [{ required: true, message: '请选择付款方式', trigger: 'blur' }],
                collection_account_id: [{ required: true, message: '请选择收款账户', trigger: 'blur' }],
            },
            dictData: {
                contract_attribute: [],
                offer_type: [],
                payment_type: [],
                building: [],
                staff: [],
                enterprise: [],
                collection_account: [],
                template: [],
                preferential_way: [
                    { name: '优惠政策', value: 1 },
                    { name: '自定义策略', value: 2 },
                ],
                frequency_unit: [],
                receipt_strategy: [],
                contract_type: [],
                property_fee_way: [],
                deposit_pay_status: [],
            },
            roomSelectSwitch: true,
            selectRoomList: [],
            selectRoomListError: false,
            roomSelectedError: false,
            roomSelected: [],
            amountList: [],
            feeData: { deposit: [], rent: [] },
            pickerOptions: {
                disabledDate: time => {
                    let start_time = dayjs(this.form.start_date).valueOf();
                    return time.getTime() < start_time;
                },
            },
            pickerOptions1: {
                // disabledDate: time => {
                //     let start_time = Date.now();
                //     return time.getTime() > start_time;
                // },
            },
            initRelations: [
                // {code: 'company_id' , model: 'wp_company' , prop: 'enterprise'},
                { code: 'lessor_signed', model: 'wp_employee', prop: 'staff' },
            ],
            reductionPolicy: [],
            showAddReductionPolicy: false,
        };
    },
    props: {
        type: {
            type: String,
            default: 'add',
        },
        model: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        contractType: {
            type: String,
            default: '',
        },
    },
    computed: {
        isBusiness() {
            console.log(this.form.contract_type);
            return Number(this.contractType ? this.contractType : this.form.contract_type) === 2 ? true : false;
        },
        formTitle() {
            if (this.type === 'add' || this.type === 'template') {
                return '新增合同';
            }
            if (this.type === 'edit') {
                return '编辑合同';
            }
            if (this.type === 'relet') {
                return '续签合同';
            }
            return '';
        },
        isEdit() {
            return this.type === 'edit' ? true : false;
        },
        isRelet() {
            return this.type === 'relet' ? true : false;
        },
        isTemplate() {
            return this.type === 'template' ? true : false;
        },
    },
    mounted() {
        // getModelRule(this.model).then(
        //   res => {
        //     this.rules = res;
        //   }
        // )
        this.getDictData();
        if (this.id) {
            this.getDetail();
        } else {
            this.form.contract_type = this.contractType;
            this.pageLoading = false;
        }
    },
    methods: {
        removeFeeDataItem(index, typeName) {
            this.feeData[typeName].splice(index, 1);
            this.feeData[typeName].forEach((item, index) => {
                item.name = `第${index + 1}期`;
            });
        },
        feeDataTableTimeChange(ev, row) {
            if (ev && ev.length) {
                row.startTime = `${ev[0]} 00:00:00`;
                row.endTime = `${ev[1]} 23:59:59`;
            }
        },
        addFeeData(typeName) {
            this.feeData[typeName] = this.feeData[typeName] || [];
            this.feeData[typeName].push({
                name: `第${this.feeData[typeName].length + 1}期`,
                receivableDate: '',
                startTime: '',
                endTime: '',
                amountReceivable: 0,
                collectionItem: typeName,
                days: 0,
                propertyManagementFeeTotal: 0,
                times: [],
            });
        },
        clearFeeData() {
            this.feeData['deposit'] = [];
            this.feeData['rent'] = [];
        },
        countDepositAmount() {
            this.$nextTick(() => {
                countDepositAmount({
                    contractNo: this.form.deposit_resource,
                }).then(res => {
                    this.form.deposit_amount = res.data.data;
                });
            });
        },
        depositPayStatusChange() {
            if (this.form.deposit_pay_status == 2) {
                this.form.deposit_type = '1';
            }
            this.calculate();
        },
        selectAllRoom(row, index) {
            let rooms = [].concat(row.room);
            let handle = data => {
                data.forEach(room => {
                    if (room.disabled != true) {
                        if (row.room.includes(room.id) === false) {
                            if (room.children) {
                                handle(room.children);
                            } else {
                                rooms.push(room.id);
                            }
                        }
                    }
                });
            };
            handle(row.roomData);
            if (row.room.length !== rooms.length) {
                row.room = rooms;
                this.roomSelectChange(row, 'room');
            }
            // if(change > 0){
            //   this.roomSelectChange(row , 'room');
            // }
        },
        calculateRoomDate() {
            let start = this.form.start_date ? dayjs(this.form.start_date).valueOf() : 0;
            let end = this.form.end_date ? dayjs(this.form.end_date).valueOf() : 0;
            if (start && end) {
                this.roomSelected.forEach(item => {
                    // let itemStart = item.start_date ? dayjs(`${item.start_date} 00:00:00`).valueOf() : 0;
                    // let itemEnd = item.end_date ? dayjs(`${item.end_date} 23:59:59`).valueOf() : 0;
                    item.start_date = dayjs(this.form.start_date).format('YYYY-MM-DD');
                    // if(itemStart < start || itemStart > end){
                    //   item.start_date = dayjs(this.form.start_date).format('YYYY-MM-DD');
                    // }
                    item.end_date = dayjs(this.form.end_date).format('YYYY-MM-DD');
                    // if(itemEnd > end || itemEnd < start){
                    // item.end_date = dayjs(this.form.end_date).format('YYYY-MM-DD');
                    // }
                });
            }
        },
        roomDatePickerPropsDisabledDate(type, row, time) {
            let Time = time.getTime();
            let startDate = dayjs(this.form.start_date).valueOf();
            let endDate = dayjs(this.form.end_date).valueOf();
            if (type === 'end') {
                startDate = dayjs(row.start_date).valueOf();
            }
            return Time < startDate || Time > endDate;
        },
        addSelectRoomList() {
            this.selectRoomList.push({
                building: '',
                buildingName: '',
                floor: '',
                floorName: '',
                room: [],
                roomSelected: [],
                floorData: [],
                roomData: [],
                loading: {
                    building: false,
                    floor: false,
                    room: false,
                },
            });
        },
        addReductionPolicy(ev) {
            if (ev && (ev.value ?? '') !== '') {
                ev.data.forEach(item => {
                    if (item[ev.valueKey] === ev.value) {
                        this.reductionPolicy.push({
                            reductionPolicyTitle: item.reduction_policy_title,
                            reductionPolicyId: item.reduction_policy_id,
                        });
                    }
                });
            }
            this.showAddReductionPolicy = false;
        },
        getReductionPolicy() {
            this.reductionPolicy = [];
            // if(this.form.company_id){
            //   this.getMetaCommonList('wp_reduction_policy_company' , null , {
            //     wheres: [{field: 'company_id' , value: this.form.company_id}]
            //   }).then(
            //     res => {
            //       this.reductionPolicy = res.data.data.records;
            //     }
            //   )
            // }
        },
        nextStep() {
            if (!this.roomSelected.length) {
                this.selectRoomListError = true;
                this.$message.warning('请检查表单，并完善表单必填项');
                return false;
            } else {
                this.selectRoomListError = false;
            }
            if (this.active === 1) {
                this.roomSelected.forEach(item => {
                    if (!item['start_date'] && this.form.end_date) {
                        item['start_date'] = dayjs(this.form.start_date).format('YYYY-MM-DD');
                        item['end_date'] = dayjs(this.form.end_date).format('YYYY-MM-DD');
                    }
                });
            }
            if (this.active === 2) {
                let error = false;
                let checkFields = this.isBusiness
                    ? ['room_area']
                    : ['room_area', 'rental_unit_price_day', 'property_management_fee'];
                this.roomSelected.forEach(item => {
                    checkFields.forEach(name => {
                        if (item[name] === undefined || item[name] === null || item[name] === '') {
                            error = true;
                        }
                    });
                });
                this.roomSelectedError = error;
                if (error) {
                    this.$message.warning('请检查表单，并完善表单必填项');
                    return false;
                }
            }
            this.$refs[`form${this.active}`].validate(valid => {
                if (valid) {
                    if (
                        this.type === 'add' &&
                        this.active === 1 &&
                        (this.form.amount ?? '') === '' &&
                        !this.isBusiness
                    ) {
                        this.calculate();
                    }
                    this.active++;
                } else {
                    this.$message.warning('请检查表单，并完善表单必填项');
                }
            });
        },
        queryCollectionPlan() {
            queryCollectionPlan({
                contractId: this.id,
            }).then(res => {
                if (res && res.data && res.data.data) {
                    if (this.form.collection_plan_strategy == 1) {
                        this.feeData = res.data.data;
                    } else {
                        let feeData = {};
                        Object.keys(res.data.data).forEach(type => {
                            feeData[type] = [];
                            res.data.data[type].forEach(item => {
                                if (type === 'rent' && item.startTime && item.endTime) {
                                    feeData[type].push({
                                        ...item,
                                        times: [item.startTime.split(' ')[0], item.endTime.split(' ')[0]],
                                    });
                                } else {
                                    feeData[type].push({
                                        ...item,
                                    });
                                }
                            });
                        });
                        this.feeData = feeData;
                    }
                }
            });
        },
        getDetail() {
            if (this.id) {
                this.pageLoading = true;
                detail({ id: this.id })
                    .then(res => {
                        if (res.data) {
                            let form = {};
                            Object.keys(res.data.data).forEach(keyName => {
                                let name = keyName.replace(/([A-Z])/g, '_$1').toLowerCase();
                                if (name !== 'contract_subjects') {
                                    form[name] = res.data.data[keyName];
                                } else {
                                    this.setRoomList(res.data.data[keyName]);
                                }
                            });
                            Object.keys(this.form).forEach(keyName => {
                                if (typeof form[keyName] === 'undefined') {
                                    form[keyName] = this.form[keyName];
                                }
                            });
                            this.reductionPolicy = form.contract_reduction_policies || [];
                            this.form = this.handleForm(form);
                            console.log(this.form);

                            this.initRelations.forEach(item => {
                                if (
                                    this.form[item.code] !== '' &&
                                    this.form[item.code] !== null &&
                                    this.form[item.code] !== undefined
                                ) {
                                    this.getInitModel(this.form[item.code], item.model, item.prop);
                                }
                            });
                            if (!this.form['id']) {
                                this.calculate();
                            } else {
                                this.queryCollectionPlan();
                            }
                            this.modelChange('collection_account_id', 'collection_account', [
                                { code: '$account_bank', name: 'account_bank' },
                                { code: '$collection_account', name: 'collection_account' },
                            ]);
                            this.pageLoading = false;
                        }
                    })
                    .catch(e => {
                        this.pageLoading = false;
                        this.$message.error('数据加载失败，请重新加载');
                        this.$emit('close');
                    });
            }
        },
        handleForm(form) {
            console.log(form);
            if (form) {
                Object.keys(form).forEach(key => {
                    if (typeof this.form[key] === 'string') {
                        form[key] = `${form[key] === null ? '' : form[key]}`;
                    }
                });
            }
            if (this.isRelet || this.isTemplate) {
                form['start_date'] = dayjs(form.end_date).format('YYYY-MM-DD');
                form['end_date'] = dayjs(form.start_date).add(form['rent_period'], 'month').format('YYYY-MM-DD');
                form['signed_date'] = dayjs().format('YYYY-MM-DD');
                form['fix_date'] = '';
                form['fix_notice'] = null;
                form['rent_free_period'] = null;
                form['rent_free_start_date'] = '';
                form['rent_free_end_date'] = '';
                form['code'] = '';
                form['attachment_ids'] = '';
                delete form['id'];
                [{ start: 'start_date', end: 'end_date', prop: 'rent_period' }].forEach(item => {
                    this.calculateEndData(item.start, item.end, item.prop);
                });
            }
            if (this.isRelet) {
                form['contract_attribute'] = '2';
            }
            return form;
        },
        save(storage) {
            this.saveLoading = true;
            let body = {
                ...this.form,
                contractSubjects: this.roomSelected,
                collectionPlans: this.feeData,
                contractReductionPolicies: this.reductionPolicy.map(item => {
                    let newItem = {
                        ...item,
                    };
                    if (this.form.id) {
                        newItem['contractId'] = this.form.id;
                    }
                    return newItem;
                }),
                storage: storage,
                deposit_resource: this.form.deposit_resource,
            };
            [
                { code: 'start_date', type: 1 },
                { code: 'end_date', type: 2 },
                { code: 'fix_date', type: 1 },
            ].forEach(item => {
                let val = body[item.code].split(' ')[0];
                if (val) {
                    body[item.code] = item.type === 1 ? `${val} 00:00:00` : `${val} 23:59:59`;
                }
            });
            delete body['reduction_policy_list'];
            (body.id ? update : save)(body)
                .then(res => {
                    if (storage == 1) {
                        if (res.data.data.offerType == 1) {
                            this.startFlow(res.data.data);
                        } else {
                            this.saveLoading = false;
                            this.$message.success('保存成功');
                            this.$emit('save');
                        }
                    } else {
                        this.saveLoading = false;
                        this.$message.success('保存成功');
                        this.$emit('save');
                    }
                })
                .catch(e => {
                    this.saveLoading = false;
                });
        },
        calculate() {
            if (this.isBusiness) {
                return;
            }
            if (this.form['collection_plan_strategy'] == 2) {
                return;
            }
            let body = {
                ...this.form,
                contractSubjects: this.roomSelected,
                contractReductionPolicies: this.reductionPolicy.map(item => {
                    let newItem = {
                        ...item,
                    };
                    if (this.form.id) {
                        newItem['contractId'] = this.form.id;
                    }
                    return newItem;
                }),
                deposit_resource: this.form.deposit_resource,
            };
            [
                { code: 'start_date', type: 1 },
                { code: 'end_date', type: 2 },
                { code: 'fix_date', type: 1 },
            ].forEach(item => {
                if (body[item.code]) {
                    let val = body[item.code].split(' ')[0];
                    body[item.code] = item.type === 1 ? `${val} 00:00:00` : `${val} 23:59:59`;
                }
            });
            if (!this.form.start_date || !this.form.end_date) {
                this.feeData = { deposit: [], rent: [] };
                return false;
            }
            if (this.form.rent_free_period && (!this.form.rent_free_start_date || !this.form.rent_free_end_date)) {
                this.feeData = { deposit: [], rent: [] };
                return false;
            }
            if (!this.roomSelected.length) {
                this.feeData = { deposit: [], rent: [] };
                return false;
            }
            delete body['reduction_policy_list'];
            delete body['reduction_policy_ids'];
            calculate(body).then(res => {
                if (res.data.data) {
                    this.feeData = res.data.data.rent
                        ? res.data.data
                        : {
                              deposit: [],
                              rent: [],
                          };
                    this.form.amount = this.getAllMoney(this.feeData.rent, true);
                }
            });
        },
        getMetaCommonList(code, prop, params) {
            return getMetaCommonList(`wisdom_park.${code}`, {
                current: 1,
                size: 500,
                ...params,
            }).then(res => {
                if (res.data && res.data.data && prop) {
                    this.dictData[prop] = res.data.data.records;
                }
                return res;
            });
        },
        getFloor(index, notClear) {
            return new Promise((resolve, reject) => {
                let building = this.selectRoomList[index].building;
                if (!notClear) {
                    this.selectRoomList[index].floor = '';
                    this.selectRoomList[index].floorData = [];
                    this.selectRoomList[index].room = [];
                    this.selectRoomList[index].roomData = [];
                }
                if (building) {
                    console.log(this.selectRoomList[index]);
                    this.selectRoomList[index].loading.floor = true;
                    this.getMetaCommonList('wp_floor', null, {
                        wheres: [{ field: 'building_id', value: building }],
                        orders: [{ field: 'code+1000', sort: 'DESC' }],
                    })
                        .then(res => {
                            if (res.data && res.data.data) {
                                this.selectRoomList[index].loading.floor = false;
                                this.selectRoomList[index].floorData = res.data.data.records.map(item => {
                                    return {
                                        ...item,
                                        name: item.name ? item.name : `${item.code}层`,
                                    };
                                });
                            }
                            resolve();
                        })
                        .catch(e => {
                            reject(e);
                        });
                }
            });
        },
        getRoom(index, notClear) {
            return new Promise((resolve, reject) => {
                let building = this.selectRoomList[index].building,
                    floor = this.selectRoomList[index].floor;
                if (!notClear) {
                    this.selectRoomList[index].roomData = [];
                    this.selectRoomList[index].room = [];
                }
                let getDisabled = item => {
                    // item.status !== 0
                    if (this.roomSelectSwitch) {
                        if (this.roomSelected.find(a => a.room_id === item.id)) {
                            return false;
                        } else {
                            return item.status !== 0;
                        }
                    } else {
                        return false;
                    }
                };
                if (building && floor) {
                    this.selectRoomList[index].loading.room = true;
                    this.getMetaCommonList('wp_room', null, {
                        wheres: [
                            { field: 'building_id', value: building },
                            { field: 'floor_id', value: floor },
                            { field: 'is_cancel', value: '0' },
                        ],
                    })
                        .then(res => {
                            const statusType = {
                                0: '未租',
                                1: '已租',
                                2: '已售',
                                3: '自用',
                            };
                            this.selectRoomList[index].loading.room = false;
                            if (res.data && res.data.data) {
                                let rooms = {};
                                (res.data.data.records || []).forEach(item => {
                                    if (!item.parent_id) {
                                        let disabled = getDisabled(item);
                                        rooms[item.id] = {
                                            ...item,
                                            disabled: disabled,
                                            name: disabled ? `【${statusType[item.status]}】${item.name}` : item.name,
                                        };
                                    }
                                });
                                (res.data.data.records || []).forEach(item => {
                                    if (item.parent_id) {
                                        let disabled = getDisabled(item);
                                        rooms[item.parent_id]['children'] = rooms[item.parent_id]['children'] || [];
                                        rooms[item.parent_id].children.push({
                                            ...item,
                                            disabled: disabled,
                                            name: disabled ? `【${statusType[item.status]}】${item.name}` : item.name,
                                        });
                                        rooms[item.parent_id]['children'] = rooms[item.parent_id]['children'].sort(
                                            (a, b) => (b.disabled ? -1 : 1),
                                        );
                                    }
                                });
                                this.selectRoomList[index].roomData = Object.values(rooms).sort((a, b) =>
                                    b.disabled ? -1 : 1,
                                );
                            }
                            resolve();
                        })
                        .catch(e => {
                            reject;
                        });
                }
            });
        },
        getInitModel(id, model, prop) {
            this.getMetaCommonList(model, prop, {
                wheres: [{ field: 'id', value: id }],
            });
        },
        searchModel(queryStr, modelCode, prop, searchFieldName) {
            let params = {};
            if (queryStr) {
                params = {
                    wheres: [{ field: searchFieldName || 'name', op: 'LIKE', value: queryStr }],
                };
            }
            this.loading[prop + 'Loading'] = true;
            this.getMetaCommonList(modelCode, prop, params).then(res => {
                this.loading[prop + 'Loading'] = false;
            });
        },
        getDictData() {
            getDictionary({
                code: 'RoomSelectSwitch',
            }).then(res => {
                (res.data.data || []).forEach(item => {
                    if (item.dictValue === 'SwitchOption') {
                        this.roomSelectSwitch = item.dictKey === '1' ? true : false;
                    }
                });
            });
            [
                { code: 'ContractAttribute', prop: 'contract_attribute' },
                { code: 'ContractType', prop: 'contract_type' },
                { code: 'ReceiptStrategy', prop: 'receipt_strategy' },
                { code: 'OfferType', prop: 'offer_type' },
                // {code: 'PaymentType' , prop: 'payment_type'},
                { code: 'FREQUENCY_UNIT', prop: 'frequency_unit' },
                { code: 'PropertyFeeWay', prop: 'property_fee_way' },
                { code: 'DepositPayStatus', prop: 'deposit_pay_status' },
            ].forEach(item => {
                getDictionary({
                    code: item.code,
                }).then(res => {
                    if (res.data && res.data.data) {
                        this.dictData[item.prop] = res.data.data;
                    }
                });
            });
            [
                { code: 'wp_building', prop: 'building' },
                { code: 'wp_collection_account', prop: 'collection_account' },
                // {code: 'wp_contract_template' , prop: 'template'}
            ].forEach(item => {
                this.getMetaCommonList(item.code, item.prop).then(res => {
                    if (this.id && item.code === 'wp_collection_account') {
                        this.modelChange('collection_account_id', 'collection_account', [
                            { code: '$account_bank', name: 'account_bank' },
                            { code: '$collection_account', name: 'collection_account' },
                        ]);
                    }
                });
            });
        },
        modelChange(idName, dictName, props) {
            let data = [];
            if (typeof dictName === 'object') {
                data = dictName.data || [];
            } else {
                data = this.dictData[dictName];
            }
            data.forEach(item => {
                if (item.id === this.form[idName]) {
                    if (Array.isArray(props)) {
                        props.forEach(prop => {
                            if (this.cache[prop.code] !== undefined) {
                                this.cache[prop.code] = item[prop.name] || '';
                            } else {
                                this.form[prop.code] = item[prop.name] || '';
                            }
                        });
                    }
                }
            });
        },
        saveEnterpriseForm(data) {
            this.form.company_id = data.id;
            this.form.renters_name = data.name;
            this.form.corporation_personality_contact = data.legal_contact;
            this.form.corporation_personality = data.legal_person;
            this.searchModel(data.name, 'wp_enterprise', 'enterprise');
            this.enterpriseDrawer = false;
        },
        roomSelectChange(row, name) {
            let data = [];
            if (name === 'room') {
                this.calculateRoom();
            } else {
                if (name === 'building') {
                    data = this.dictData.building;
                } else {
                    data = row[`${name}Data`] || [];
                }
                if (data) {
                    data.forEach(item => {
                        if (item.id === row[name]) {
                            row[`${name}Name`] = item.name;
                        }
                    });
                }
            }
        },
        findRoom(roomData, roomId) {
            let result = null;
            roomData.forEach(room => {
                if (room.id === roomId) {
                    result = room;
                }
                if (!result && room.children) {
                    room.children.forEach(childrenItem => {
                        if (childrenItem.id === roomId) {
                            result = childrenItem;
                        }
                    });
                }
            });
            return result;
        },
        calculateRoom() {
            let roomSelected = [];
            this.selectRoomList.forEach(itemRoom => {
                itemRoom.room.forEach(itemRoomId => {
                    let currentRoom = this.findRoom(itemRoom.roomData, itemRoomId);
                    // 合并子房间
                    // if(currentRoom && currentRoom.parent_id){
                    //   let parentRoom = this.findRoom(itemRoom.roomData , currentRoom.parent_id);
                    //   let child = [];
                    //   parentRoom.children.forEach(
                    //     childItem => {
                    //       if(itemRoom.room.indexOf(childItem.id) > -1){
                    //         child.push(childItem.id)
                    //       }
                    //     }
                    //   );
                    //   if(child.length === parentRoom.children.length){
                    //     currentRoom = parentRoom;
                    //   }
                    // }
                    if (currentRoom && !roomSelected.find(item => item.room_id === currentRoom.id)) {
                        let currentBuilding = this.dictData.building.find(item => item.id === itemRoom.building);
                        let oldRoomItem = this.roomSelected.find(item => item.room_id === currentRoom.id) || {};
                        if (currentRoom && currentBuilding) {
                            let tempFee = 0;
                            if (oldRoomItem.property_management_fee >= 0) {
                                tempFee = oldRoomItem.property_management_fee;
                            } else {
                                tempFee = currentBuilding.property_management_fee
                                    ? currentBuilding.property_management_fee
                                    : 0;
                            }
                            let roomObj = {
                                building_id: itemRoom.building,
                                building_name: itemRoom.buildingName,
                                floor_id: itemRoom.floor,
                                floor_name: itemRoom.floorName,
                                room_id: currentRoom.id,
                                room_name: currentRoom.name,
                                room_area: oldRoomItem.room_area || currentRoom.room_area || 0,
                                rental_unit_price_day:
                                    oldRoomItem.rental_unit_price_day || currentRoom.rental_unit_price_day || 0,
                                incremental_unit_price:
                                    oldRoomItem.incremental_unit_price || currentRoom.incremental_unit_price || 0,
                                incremental_identification:
                                    oldRoomItem.incremental_identification ||
                                    currentRoom.incremental_identification ||
                                    1,
                                // is_released: oldRoomItem.is_released || currentRoom.is_released || 0,
                                // property_management_fee: oldRoomItem.property_management_fee || currentBuilding.property_management_fee ||  0,
                                property_management_fee: tempFee,
                                amount: 0,
                                start_date:
                                    oldRoomItem.start_date ||
                                    (this.form.start_date ? dayjs(this.form.start_date).format('YYYY-MM-DD') : null),
                                end_date:
                                    oldRoomItem.end_date ||
                                    (this.form.end_date ? dayjs(this.form.end_date).format('YYYY-MM-DD') : null),
                            };
                            roomSelected.push(roomObj);
                        }
                    }
                });
            });
            this.roomSelected = roomSelected;
        },
        getFreeDay(start_date, end_date) {
            let freeDay = 0;
            let rent_free_start_date = dayjs(this.form.rent_free_start_date),
                rent_free_end_date = dayjs(this.form.rent_free_end_date);
            if (rent_free_end_date.valueOf() >= start_date.valueOf()) {
                let current_rent_free_end_date =
                    rent_free_end_date.valueOf() < end_date.valueOf() ? rent_free_end_date : end_date;
                if (rent_free_start_date.valueOf() < start_date.valueOf()) {
                    freeDay = dayjs(current_rent_free_end_date).diff(start_date, 'day');
                } else if (
                    rent_free_start_date.valueOf() >= start_date.valueOf() &&
                    rent_free_start_date.valueOf() < end_date.valueOf()
                ) {
                    freeDay = dayjs(current_rent_free_end_date).diff(rent_free_start_date, 'day');
                } else {
                    freeDay = 0;
                }
            }
            return freeDay;
        },
        getIncreasePrice(start_date, price) {
            let year_increase = this.form.year_increase || null,
                increase_rate = this.form.increase_rate || null,
                current_year_increase = 0,
                oldPrice = price,
                increase_price = 0;
            if (year_increase && increase_rate) {
                let increase_time = dayjs(this.form.start_date).add(year_increase, 'year');
                if (start_date.valueOf() >= increase_time.valueOf()) {
                    current_year_increase = Math.ceil((start_date.diff(increase_time, 'month') + 1) / 12);
                    if (current_year_increase) {
                        price =
                            price *
                            Math.pow(mathjs.chain(increase_rate).divide(100).add(1).done(), current_year_increase);
                        increase_price = mathjs.chain(price).subtract(oldPrice).done();
                    }
                }
            }
            return {
                price: price,
                increase_price: increase_price,
            };
        },
        calculateEndData(startName, endName, valueName) {
            if (this.form[valueName] !== null && this.form[startName]) {
                this.form[endName] = dayjs(this.form[startName])
                    .add(this.form[valueName], 'M')
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD');
            }
        },
        set_init_date(prop, endName, valueName) {
            let start_date = dayjs(this.form.start_date);
            if (!this.form[prop]) {
                this.form[prop] = start_date.format('YYYY-MM-DD');
            }
            if (prop && endName && valueName) {
                this.calculateEndData(prop, endName, valueName);
            }
        },
        startFlow(row) {
            startFlow(row.id, this.$PCode.BIZ.CONTRACT, this.$PCode.ACTIONS.SUBMIT, {
                companyName: row.rentersName,
                roomName: row.roomName,
            }).then(() => {
                this.saveLoading = false;
                this.$message.success('保存成功');
                this.$emit('save');
            });
        },
        setRoomList(roomList) {
            let floors = {},
                roomSelected = [];
            roomList.forEach(item => {
                if (!floors[item.floorId]) {
                    floors[item.floorId] = {
                        building: item.buildingId,
                        buildingName: item.buildingName,
                        floor: item.floorId,
                        floorName: '',
                        room: [],
                        roomSelected: [],
                        floorData: [],
                        roomData: [],
                        loading: {
                            building: false,
                            floor: false,
                            room: false,
                        },
                    };
                }
                floors[item.floorId].room.push(item.roomId);
                roomSelected.push({
                    room_id: item.roomId,
                    room_area: item.roomArea,
                    rental_unit_price_day: parseFloat(item.rentalUnitPriceDay),
                    property_management_fee: parseFloat(item.propertyManagementFee),
                    start_date: item.startDate || null,
                    end_date: item.endDate || null,
                    incremental_unit_price: item.incrementalUnitPrice,
                    incremental_identification: item.incrementalIdentification || 1,
                    // is_released: item.isReleased || 0,
                });
            });
            this.roomSelected = roomSelected;
            this.selectRoomList = Object.values(floors);
            let len = 0;
            this.selectRoomList.forEach((item, index) => {
                this.getFloor(index, true).then(res => {
                    this.roomSelectChange(item, 'floor');
                });
                this.getRoom(index, true).then(res => {
                    // let newRoom = [];
                    // this.selectRoomList[index].room.forEach(
                    //   roomId => {
                    //     let currentRoom = this.findRoom(this.selectRoomList[index].roomData , roomId);
                    //     if(currentRoom.children){
                    //       currentRoom.children.forEach(
                    //         item => {
                    //           newRoom.push(item.id);
                    //         }
                    //       )
                    //     }else{
                    //       newRoom.push(currentRoom.id)
                    //     }
                    //   }
                    // );
                    // if(newRoom.length){
                    //   this.selectRoomList[index].room = newRoom;
                    // }
                    len += this.selectRoomList[index].room.length;
                    if (len >= this.roomSelected.length) {
                        this.roomSelectChange(null, 'room');
                    }
                });
            });
        },
        getAllMoney(data, isList) {
            if (isList) {
                let amount = 0;
                data.forEach(item => {
                    amount = mathjs
                        .chain(amount)
                        .add(item.propertyManagementFeeTotal || 0)
                        .add(item.amountReceivable || 0)
                        .done();
                });
                return amount;
            } else {
                return mathjs.add(data.propertyManagementFeeTotal || 0, data.amountReceivable || 0);
            }
        },
        setEditMoney() {
            this.$confirm(
                '修改当期应收金额可能会导致应收总额与金额条款内的租金总额和租金预览不相等,需要手动修改租金总额,确定继续吗？',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                },
            )
                .then(() => {
                    Object.values(this.feeData).forEach(val => {
                        val.forEach(item => {
                            ['propertyManagementFeeTotal', 'amountReceivable'].forEach(name => {
                                item[name + '_old'] = item[name];
                            });
                        });
                    });
                    this.editMoney = true;
                })
                .catch(() => {
                    this.editMoney = false;
                });
        },
        cancelEditMoney(save) {
            Object.values(this.feeData).forEach(val => {
                val.forEach(item => {
                    ['propertyManagementFeeTotal', 'amountReceivable'].forEach(name => {
                        if (!save) {
                            item[name] = item[name + '_old'];
                        }
                        delete item[name + '_old'];
                    });
                });
            });
            this.editMoney = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.manageForm {
    /deep/.el-dialog__body {
        height: calc(90vh - 110px);
        overflow: auto;
        padding: 15px;
    }
    /deep/.el-dialog__footer {
        text-align: left;
        padding: 0;
    }
    /deep/.el-input-group__append,
    /deep/.el-input-group__prepend {
        padding: 0 10px;
    }
    .input-with-select {
        /deep/.el-input {
            width: 80px;
        }
    }
}
.contract_form {
    /deep/.el-select {
        width: 100%;
    }
}
</style>