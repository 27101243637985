<template>
<div>
  <avue-crud
    ref="table"
    :data="tableData"
    :page.sync="page"
    :option="tableOption"
    :table-loading="tableLoading"
    :search.sync="search"
    @search-change="onLoad"
    @on-load="onLoad"
  >
    <template slot-scope="scope" slot="amount" >
      ￥{{scope.row.amount | numConvertToThousandths}}元
    </template>
    <template slot-scope="{ row }" slot="attachment_id">
      <form-file
        v-if="row.attachment_id"
        :disabled="true"
        :value="row.attachment_id"
      ></form-file>
    </template>
    <template slot-scope="{row}" slot="menu">
      <el-button
        type="text"
        size="small"
        icon="el-icon-delete"
        v-if="row.process_instance_id"
        @click="viewProcessInstance(row)"
        >审批记录
      </el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-delete"
        v-if="row.payment_type == 5 && receiptStatus != 2 && isCompany === false && row.status === 1 && (approvalStatus === 1 || approvalStatus === 4)"
        @click="rowDel(row)"
        >删除
      </el-button>
    </template>
  </avue-crud>
  <el-drawer :visible.sync="todoDrawer" :size="400" :withHeader="false" append-to-body>
      <el-container v-if="todoDrawer" class="h-100p">
          <el-header class="bor-b" height="50px">
              <el-page-header class="lh-50" @back="todoDrawer = false" content="审批记录"></el-page-header>
          </el-header>
          <el-main class="pad-15">
              <TodoTask :id="currentRow.process_instance_id"></TodoTask>
          </el-main>
      </el-container>
  </el-drawer>
</div>
</template>
<script>
import TodoTask from '@/views/flow/todo-task.vue';
import {
  getMetaCommonList,
  delMetaCommon
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";
export default {
  components: { TodoTask },
  data(){
    return {
      todoDrawer: false,
      model: 'wisdom_park.wp_payment',
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: '250px',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        viewBtn: true,
        // menu:false,
        menuWidth: 200,
        header: true,
        refreshBtn: false,
        columnBtn: false,
        column: [
          {
            label: "支付方式",
            prop: "payment_type",
            dataType: 'number',
            dicUrl: dictService.getUrl("PayType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 150,
            type: 'select'
          },
          {
            label: "状态",
            prop: "status",
            dataType: 'number',
            dicUrl: dictService.getUrl("PayStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            type: 'select'
          },
          {
            label: "实收金额",
            prop: "amount",
            width: 200,
          },
          {
            label: "缴费成功时间",
            prop: "success_time",
            width: 150
          },
          {
            label: "第三方流水号",
            prop: "serial_number",
            width: 150
          },
          {
            label: "缴费账号",
            prop: "account",
            width: 150
          },
          {
            label: "缴费银行",
            prop: "account_bank",
            width: 150,
            dicUrl: dictService.getUrl("BankType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            type: 'select'
          },
          {
            label: "缴费开户行",
            prop: "deposit_bank"
          },
          {
            label: "附件",
            prop: "attachment_id",
            width: 150,
            component: 'form-file',
            span: 24,
            placeholder: ' '
          },
          {
            label: "备注",
            prop: "note",
            span: 24,
            minWidth: 200
          },
        ]
      },
      currentRow: null
    }
  },
  props: {
    receiptNo: {
      type: String,
      default: ''
    },
    receiptStatus: {
      type: Number,
      default: 0
    },
    approvalStatus: {
      type: Number,
      default: 1
    },
    isCompany: {
      type: Boolean,
      default: true
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
  },
  mounted(){
    
  },
  methods: {
    viewProcessInstance(row){
      this.currentRow = row;
      this.todoDrawer = true;
    },
    onLoad(){
      this.tableLoading = true;
      this.tableData = [];
      let wheres = [
        {field: 'receipt_no' , value: this.receiptNo},
        {field: 'status', value: [1,2,3,5,6,8] , op: 'IN'}
      ];
      if(this.isTodo){
        wheres.push({
          field: 'process_instance_id',
          value: this.processInstanceId
        })
      }
      getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres
      }).then(
        res => {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
        })
        .then(() => {
          this.onLoad();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
  }
}
</script>