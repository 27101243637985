import store from '@/store/index';

const excludeRoutes = [];

export class Breadcrumb{

  _storageName = 'portal-breadcrumb';
  breadcrumbs = [];
  show = true;
  excludeRoutes = excludeRoutes;

  get breadcrumbsLength(){
    return this.breadcrumbs.length;
  }
  
  constructor(storgeName){
    this._storageName = storgeName || 'portal-breadcrumb';
    let breadcrumbs = [];
    try {
      breadcrumbs = JSON.parse(localStorage.getItem(this._storageName)) || [];
    } catch (error) {
      breadcrumbs = [];
    }
    breadcrumbs.forEach(
      item => {
        if(item){
          this.breadcrumbs.push(item);
        }
      }
    )
    this._setStorage();
  }
  _setStorage(){
    localStorage.setItem(this._storageName , JSON.stringify(this.breadcrumbs));
    return this;
  }
  _findRouterPath(menus , menu){
    let has = false , paths = [];
    for(let i = 0 , len = menus.length; i < len; i++){
      let item = menus[i];
      if(item['id'] == menu['id']){
        has = true;
        break;
      }
      if(item['children'] && item['children'].length){
        let info = this._findRouterPath(item['children'] , menu);
        if(info.has){ 
          has = true;
          paths = [{
            name: item.name,
            path: '',
            type: 'sidebar'
          }].concat(info.paths)
          break;
        }
      }
    }
    return {has: has , paths: paths};
  }
  _checkName(name){
    let ok = true;
    this.breadcrumbs.forEach(
      item => {
        if(item.name === name){
          ok = false;
        }
      }
    )
    return ok;
  }
  setShow(show){
    store.commit('SET_SHOW_BREADCRUMB' , show)
    return this;
  }
  add(data){
    if(data.type){
      if(data.type === 'index'){
        this.breadcrumbs.splice(0 , this.breadcrumbsLength , data);
      }
      if(data.type === 'top-menu'){
        this.breadcrumbs.splice(0 , this.breadcrumbsLength , data);
      }
      if(data.type === 'sidebar-menu-root' || data.type === 'sidebar-menu' || data.type === 'sidebar-menu-children'){
        let paths = this._findRouterPath(store.getters.menuAll , data.meta);
        if(paths.has){
          let startIndex = data.startIndex || 0;
          if(data.type === 'sidebar-menu-root' || data.type === 'sidebar-menu'){
            this.breadcrumbs.splice(0 , this.breadcrumbsLength);
          }else if(data.type === 'sidebar-menu-children'){
            this.breadcrumbs.splice(3 , this.breadcrumbsLength);
            startIndex = data.startIndex || 3;
          }
          paths.paths.forEach(
            (item , index) => {
              if(index >= startIndex || startIndex === 0){
                this.breadcrumbs.push(item);
              }
            }
          )
          this.breadcrumbs.push(data);
        }else{
          this.breadcrumbs.splice(1 , this.breadcrumbsLength , data);
        }
      }
    }else{
      this.breadcrumbs.push(data);
    }
    return this._setStorage();
  }
  remove(index , all){
    all = all || false;
    let removeLen = 1;
    if(all){
      removeLen = this.breadcrumbs.length;
    }
    this.breadcrumbs.splice(index , removeLen);
    return this._setStorage();
  }
  replace(index){
    return {
      data: (data) => {
        this.breadcrumbs.splice(index , 1 , data);
        return this._setStorage();
      }
    }
  }
  replaceLast(data){
    let index = this.breadcrumbsLength - 1;
    this.breadcrumbs.splice(index , 1 , data);
    return this._setStorage();
  }
  clear(data){
    this.breadcrumbs.splice(0 , this.breadcrumbsLength);
    return data ? this.add(data) : this._setStorage();
  }
  pop(data){
    this.breadcrumbs.pop();
    return data ? this.add(data) : this._setStorage();
  }
  get(index){
    return this.breadcrumbs[index];
  }
  set(index , data){
    this.breadcrumbs[index] = data;
    return this._setStorage();
  }
  end(data){
    let routerData = this.get(this.breadcrumbsLength - 1);
    if(data){
      routerData = {
        ...routerData,
        ...data
      }
      this.set(this.breadcrumbsLength - 1 , routerData);
    }
    return this.get(this.breadcrumbsLength - 1);
  }
}

const breadcrumb = {
  install(Vue){
    var breadcrumbInstance = new Breadcrumb();
    Vue.prototype.$breadcrumb = breadcrumbInstance;
  }
}

export default breadcrumb;