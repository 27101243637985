<template>
    <el-popover
    :placement="placement"
    :width="width"
    :trigger="iconDisabled ? 'manual' : trigger"
    :class="iconDisabled ? 'is-disabled' : ''"
    >
        <el-tabs v-model="activeName">
            <el-tab-pane v-for="(item , index) of iconList" :key="index" :label="item.label" :name="(''+index)">
                <el-row class="h-300 ov-a">
                    <el-col :span="4" v-for="(icon , index) of item.list" :key="index">
                        <div @click="iconValue = icon" class="w-100p h-60 icon-item cur-p" >
                            <i class="fts-30" :class="icon"></i>
                        </div>
                    </el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
        <div slot="reference" class="icon-selector" :class="'pad-' + padding">
            <i :class="'fts-' + size + ' ' + (iconValue || defaultIcon)"></i>
        </div>
    </el-popover>
</template>
<script>
import iconList from '@/config/iconList'
export default {
    name: 'basic-icon',
    data(){
       return {
            activeName: '0',
            iconList: iconList
       }
    },
    inject: {
      elForm: {
        default: ''
      }
    },
    computed: {
        iconValue: {
            get(){
                return this.value;
            },
            set(val){
                this.$emit('change' , val);
                this.$emit('input' , val);
            }
        },
        iconDisabled() {
            return this.disabled || (this.elForm || {}).disabled;
        },
    },
    props: {
        size: {
            type: Number,
            default: 40
        },
        padding: {
            type: Number,
            default: 20
        },
        width: {
            type: Number,
            default: 450
        },
        placement: {
            type: String,
            default: 'right-start'
        },
        trigger: {
            type: String,
            default: 'click'
        },
        value: {
            type: String,
            default: ''
        },
        defaultIcon: {
            type: String,
            default: 'iconfont iconicon_roundadd'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/styles/default-vars.scss';
@import '@/styles/vars.scss';

.icon-item{
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        color: $--color-primary;
        background: $--background-color-base;
    }
}
.icon-selector{
    display: inline-block;
    border: 1px dashed $--border-color-base;
    border-radius: 5px;
}
</style>