<template>
  <el-container direction="vertical" class="h-100p avue-contail">
    <el-header
      height="50px"
      class="pad-0 avue-header"
      :class="{ 'avue-left-slide-hide': showMenus === false }"
    >
      <top ref="top" :showMenus="showMenus" />
    </el-header>
    <el-container
      v-if="approval.approval_status == 3 || userInfo.tenant_id != '000002'"
      style="height: calc(100% - 50px)"
    >
      <el-aside class="bor-r" :width="isCollapse ? '60px' : '220px'">
        <sidebar />
      </el-aside>
      <el-main>
        <el-container class="h-100p">
          <el-header height="54px">
            <el-row
              v-if="showBreadcrumb"
              :class="'mar-b-' + breadcrumbMarginBottom"
            >
              <el-col :span="24 - breadcrumbRightCol" class="mar-b-0">
                <el-breadcrumb separator="/">
                  <template v-for="(item, index) in breadcrumbs">
                    <el-breadcrumb-item :key="index">
                      <span v-if="!item.path">{{ item.name }}</span>
                      <a
                        v-if="item.path"
                        href="javascript:void(0)"
                        @click="breadcrumbClick(item, index)"
                        >{{ item.name }}</a
                      >
                    </el-breadcrumb-item>
                  </template>
                </el-breadcrumb>
              </el-col>
              <el-col
                v-if="breadcrumbRightComponent"
                :span="breadcrumbRightCol"
                class="mar-b-0"
              >
                <component :is="breadcrumbRightComponent"></component>
              </el-col>
            </el-row>
          </el-header>
          <el-main class="ov-a pad-lr-15 pr">
            <keep-alive>
              <router-view
                class="avue-view"
                :key="routerKey"
                v-if="$route.meta.keepAlive"
              />
            </keep-alive>
            <router-view
              class="avue-view"
              :key="routerKey"
              v-if="!$route.meta.keepAlive"
            />
          </el-main>
          <el-footer height="15px"></el-footer>
        </el-container>
      </el-main>
    </el-container>
    <template
      v-if="approval.approval_status != 3 && userInfo.tenant_id == '000002'"
    >
      <feedbackdash :approval="approval"> </feedbackdash>
    </template>
  </el-container>
</template>

<script>
import tags from "./tags";
import search from "./search";
import top from "./top/";
import sidebar from "./sidebar/";
import admin from "@/util/admin";
import { validatenull } from "@/util/validate";
import { calcDate } from "@/util/date.js";
import { getStore } from "@/util/store.js";
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
import feedbackdash from "../../views/enterprise/feedbackdash.vue";
import { mapGetters, mapState } from "vuex";

// import SchedulerNav from '@/view-module/scheduler/common/components/nav/nav';
export default {
  components: {
    top,
    tags,
    search,
    sidebar,
    feedbackdash,
  },
  name: "pageIndex",
  provide() {
    return {
      indexMain: this,
    };
  },
  data() {
    return {
      back: !!this.$route.query.back,
      //搜索控制
      isSearch: false,
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: "",
      breadcrumbs: this.$breadcrumb.breadcrumbs,
      breadcrumbRightComponent: null,
      breadcrumbRightCol: 0,
      breadcrumbMarginBottom: 0,
      showMenus: true,
      routerKey: this.$router.currentRoute.fullPath,
      model: "wisdom_park.wp_company",
      checkinModel: "wisdom_park.wp_company_checkin",
      approval: { approval_status: 3 },
      //menuMode: this.menu.menuType,
    };
  },
  created() {
    if (this.menuMode === "top") {
      this.openMenu({});
    }
    //实时检测刷新token
    this.refreshToken();
  },
  watch: {
    $route() {
      this.routerKey = this.$router.currentRoute.fullPath;
      this.back = !!this.$route.query.back;
    },
  },
  mounted() {
    this.init();
    this.getMetaCommonList(); //查看当前用户的权限
  },
  computed: {
    ...mapGetters([
      "menuMode",
      "isMenu",
      "isLock",
      "isCollapse",
      "website",
      "showTag",
      "menu",
      "showBreadcrumb",
      "sidebarMenu",
    ]),
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  props: [],
  methods: {
    goBack() {
      this.$router.back();
    },
    showCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    // 初始化
    init() {
      this.$store.commit("SET_SCREEN", admin.getScreen());
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit("SET_SCREEN", admin.getScreen());
        }, 0);
      };
      this.$store.dispatch("FlowRoutes").then(() => {});
      this.$root.$on("SetBreadcrumbComponent", (data) => {
        if (data) {
          this.breadcrumbRightComponent = data.component || null;
          this.breadcrumbRightCol = data.col !== undefined ? data.col : 8;
          this.breadcrumbMarginBottom =
            data.breadcrumbMarginBottom !== undefined
              ? data.breadcrumbMarginBottom
              : 0;
        } else {
          this.breadcrumbRightComponent = null;
          this.breadcrumbRightCol = 0;
          this.breadcrumbMarginBottom = 0;
        }
      });
    },
    breadcrumbClick(item, index) {
      if (item.path) {
        if (index < this.breadcrumbs.length - 1) {
          this.$router.push(item.path);
        }
        this.$breadcrumb.remove(index + 1, true);
      }
    },
    //打开菜单
    openMenu(item = {}) {
      this.$store.dispatch("GetMenu", item.id).then((data) => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true);
          this.$store.commit("SET_MENU_LOAD", true);
        }
        //当点击顶部菜单后默认打开第一个菜单
        if (!this.validatenull(item)) {
          let itemActive = {},
            childItemActive = 0;
          if (item.path) {
            itemActive = item;
          } else {
            if (this.menu[childItemActive].length === 0) {
              itemActive = this.menu[childItemActive];
            } else {
              itemActive = this.menu[childItemActive].children[childItemActive];
            }
          }
          this.$store.commit("SET_MENU_ID", item);
          this.$router.push({
            path: this.$router.$avueRouter.getPath(
              {
                name: itemActive.label || itemActive.name,
                src: itemActive.path,
              },
              itemActive.meta
            ),
          });
        }
      });
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token =
          getStore({
            name: "token",
            debug: true,
          }) || {};
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= this.website.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          this.$store
            .dispatch("refreshToken")
            .then(() => {
              this.refreshLock = false;
            })
            .catch(() => {
              this.refreshLock = false;
            });
        }
      }, this.website.refreshToken);
    },
    getMetaCommonList() {
      if (this.userInfo.dept_id) {
        let body = {
          current: 1,
          size: 20,
          wheres: [{ field: "dept_id", value: this.userInfo.dept_id }],
        };
        getMetaCommonList(this.model, body).then((res) => {
          console.log(res);
          if (res.data.data.records[0]) {
            this.getMetaCommonListcheckin(res.data.data.records[0].id);
            let data = this.userInfo;
            data.companyId = res.data.data.records[0].id + "";
            this.$store.commit("SET_USER_INFO", data);
          }
        });
      }
    },
    getMetaCommonListcheckin(id) {
      let body = {
        current: 1,
        size: 20,
        wheres: [{ field: "company_id", value: id }],
      };
      getMetaCommonList(this.checkinModel, body).then((res) => {
        if (res.data.data.records[0]) {
          let data = this.userInfo;
          data.companyName = res.data.data.records[0].name;
          console.log(
            data.companyName,
            "data.companyNamedata.companyNamedata.companyName"
          );
          this.$store.commit("SET_USER_INFO", data);
          this.approval = res.data.data.records[0];
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  padding: 20px 5px;
  // background: #fff;
  background: transparent;
}
.h-view {
  height: calc(100vh - 115px);
}
.max-avue-main {
  width: 100% !important;
  left: 0;
}
</style>
