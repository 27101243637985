<template>
  <div>
    <el-button @click="goBack" icon="el-icon-back" type="text" :size="size">返回</el-button>
    <el-divider v-if="title" direction="vertical"></el-divider>
    <span v-if="title">{{title}}</span>
    <div class="pull-right">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'basic-page-header',
  props: {
    size: {
      type: String,
      default: 'small'
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack(){
      this.$emit('back')
      this.$emit('click')
    }
  }
}
</script>