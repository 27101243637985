import dayjs from 'dayjs'

/**
 * Formatting time
 */
 export const formatDate = (value, fmt) => {
  fmt = fmt || 'YYYY-MM-DD HH:mm:ss'
  if (!value) {
    return '-'
  } else {
    return dayjs(value).format(fmt)
  }
}

export const timeDuration = (time1 , time2) => {
  if(!time1){
    return '-';
  }else{
    time1 = new Date(time1);
    if(!time2){
      time2 = new Date();
    }else{
      time2 = new Date(time2);
    }
    let diffTime = time2.getTime() - time1.getTime();
    if(diffTime){
      let day = Math.floor(diffTime / 24 / 3600 / 1000);
      diffTime -= day * 24 * 3600 * 1000;
      let hour = Math.floor(diffTime / 3600 / 1000);
      diffTime -= hour * 3600 * 1000;
      let min = Math.floor(diffTime / 60 / 1000);
      diffTime -= min * 60 * 1000;
      let sec = Math.floor(diffTime / 1000);
      diffTime -= sec * 1000;
      let ms = diffTime ? (diffTime / 1000).toFixed(2) : 0;
      let str = '';
      if(day) str += `${day}天`;
      if(day || hour) str += `${hour}小时`;
      if(day || hour || min) str += `${min}分钟`;
      if(day || hour || min || sec) str += `${sec+parseFloat(ms)}秒`;
      return str;
      // return (day && `${day}天`) + (hour && `${hour}小时`) + (min && `${min}分钟`) + (sec && `${sec+parseFloat(ms)}秒`);
    }else{
      return '0秒';
    }
  }
}