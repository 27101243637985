import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/spcodaccount/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/spcodaccount/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getAccDetail = (id) => {
  return request({
    url: '/api/park-spcod/spcodaccount/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/park-spcod/spcodaccount/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/spcodaccount/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/spcodaccount/submit',
    method: 'post',
    data: row
  })
}


//创建会员
export const createAccount = (userId) => {
  return request({
    url: '/api/park-spcod/spcodaccount/createaccount/' + userId,
    method: 'post',
    data: {}
  })
}


//{vipNo:会员号(当前不用选),operType:操作类型(1充值、2消费),bizType:COD会议/SPT文体/FOD餐食/XPS快递,memo:备注,value:金额}
export const procAccountDetail = (row) => {
  return request({
    url: '/api/park-spcod/spcodaccount/procaccountdetail',
    method: 'post',
      data: row
  })
}

export const getUserAccount = (account) => {
  return request({
    url: '/api/jida-user/detail',
    method: 'get',
    params: {
      account:account
    }
  })
}

//获取用户
export const getUser = (id) => {
  return request({
    url: '/api/jida-user/detail',
    method: 'get',
    params: {
      id:id
    }
  })
}
export const getCardType = (type) => {
  console.log("type",type)
  let url='/api/park-spcod/spcodaccountcard/getCardType'
  if(type==0||type==1){
    url=url+'?type='+type
  }
  return request({
    url: url,
    method: 'get'  })
}

export const getDetailAcc = (vipPhone) => {
  return request({
    url: '/api/park-spcod/mobspcodaccount/detailAcc',
    method: 'get',
    params: {
      vipPhone
    }
  })
}
export const getRandomId = () => {
  console.log(333123123123)
  return request({
    url: '/api/park-spcod/mobsports/randomId',
    method: 'get'
  })
}

