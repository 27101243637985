import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/xpscontact/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/xpscontact/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/xpscontact/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/park-spcod/xpscontact/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/xpscontact/submit',
    method: 'post',
    data: row
  })
}


export const update = (row) => {
  return request({
    url: '/api/park-spcod/xpscontact/submit',
    method: 'post',
    data: row
  })
}

export const getRegionDetail = (code) => {
  return request({
    url: '/api/jida-system/region/detail',
    method: 'get',
    params: {
      code
    }
  })
}

export const toogleStatus = (id) => {
  return request({
    url: '/api/park-spcod/xpscontact/tooglestatus/' + id,
    method: 'get'
  })
}

