const editorPrefix = '/api/jida-flowdesign/';
const prefix = '/api/jida-flow/'

export default {
  editorPrefix: editorPrefix,
  export: (id) => {
    return `${editorPrefix}app/model/${id}/bpmn20`
  },
  createNewModel: editorPrefix + 'app/model/createNewModel',
  saveModel: (id) => {
    return `${editorPrefix}app/model/editor/${id}`
  },
  start: prefix + 'work/start-flow',
  todoList: prefix + 'work/todo-list',
  taskInfo: prefix + 'work/task-info',
  completeTask: prefix + 'work/complete-task',
  queryHistoryList: prefix + 'work/query-history-list'
}