import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/codprice/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/codprice/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/codprice/detail/' + id,
    method: 'get',
    params: {

    }
  })
}

export const remove = (id) => {
  return request({
    url: '/api/park-spcod/codprice/remove/' + id,
    method: 'post'
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/codprice/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/codprice/submit',
    method: 'post',
    data: row
  })
}

export const addPrice = (row) => {
  return request({
    url: '/api/park-spcod/codprice/submit',
    method: 'post',
    data: row
  })
}

export const getTimeUnit = () => {
  return request({
    url: '/api/park-spcod/codprice/getTimeUnit',
    method: 'get'
  })
}
export const getTimeType= () => {
  return request({
    url: '/api/park-spcod/codprice/getTimeType',
    method: 'get'
  })
}
