import request from "@/router/axios";

export const getList = (current, size, params) => {
  return request({
    url: "/api/park-spcod/sporttype/list",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
export const getRefundOrderList = (current, size, params) => {
  return request({
    url: "/api/park-spcod/spcodorder-refund/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

//场馆场地类型及统计
export const allStatisTypes = () => {
  return request({
    url: "/api/park-spcod/sporttype/statisalltypes",
    method: "get",
    params: {
      current: 1,
      size: 1000,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: "/api/park-spcod/sporttype/detail",
    method: "get",
    params: {
      id,
    },
  });
};
export const getrefundDetail = (id) => {
  return request({
    url: "/api/park-spcod/spcodorder-refund/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/park-spcod/sporttype/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/park-spcod/sporttype/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/park-spcod/sporttype/submit",
    method: "post",
    data: row,
  });
};

export const del = (id) => {
  return request({
    url: "/api/park-spcod/sporttype/del/" + id,
    method: "post",
  });
};
export const retryRefund = (id) => {
  return request({
    url: "/api/park-spcod/spcodorder-refund/retry-refund?id=" + id,
    method: "get",
  });
};
export const confirmRefund = (data) => {
  return request({
    url: "/api/park-spcod/spcodorder-refund/confirm-refund",
    method: "post",
    data: data,
  });
};
