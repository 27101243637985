import request from '@/router/axios';
const prefix = '/api/jida-resource/';
export const saveUrl = prefix + 'attachtemplate/savewith';
export const updateUrl = prefix + 'attachtemplate/updatewith';
export const downUrl = prefix + 'attachtemplate/download';

export const downLoadTemplate = (params) => {
  return request({
    method: 'get',
    params: params,
    url: prefix + 'attachtemplate/download',
    responseType: 'blob'
  })
};

export const getTemplateList = (params) => {
  return request({
    method: 'get',
    params: params,
    url: prefix + 'attachtemplate/list'
  })
}

export const saveTemplate = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'attachtemplate/savewith',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export const updateTemplate = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'attachtemplate/updatewith',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export const removeTemplate = (ids) => {
  return request({
    method: 'post',
    params: {
      ids,
    },
    url: prefix + 'attachtemplate/remove'
  })
}
