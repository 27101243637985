<template>
  <basic-container isCard>
    <avue-crud
      v-if="init"
      :option="option"
      ref="crud"
      :data="data"
      :page.sync="page"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="name" slot-scope="scope">
        <p
          class="mar-r-3"
          v-if="scope.row.old_company_name != scope.row.new_company_name"
        >
          企业名称
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_usc_code != scope.row.new_usc_code"
        >
          统一社会信用代码
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_legal_person != scope.row.new_legal_person"
        >
          企业法人
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_registered_area != scope.row.new_registered_area"
        >
          注册地区
        </p>
        <p
          class="mar-r-3"
          v-if="
            scope.row.old_registered_address != scope.row.new_registered_address
          "
        >
          注册详细地址
        </p>
        <p
          class="mar-r-3"
          v-if="
            scope.row.old_business_license != scope.row.new_business_license
          "
        >
          营业执照图片
        </p>
      </template>
      <template slot="old_company_name" slot-scope="scope">
        <p
          class="mar-r-3"
          v-if="scope.row.old_company_name != scope.row.new_company_name"
        >
          {{ scope.row.old_company_name }}
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_usc_code != scope.row.new_usc_code"
        >
          {{ scope.row.old_usc_code }}
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_legal_person != scope.row.new_legal_person"
        >
          {{ scope.row.old_legal_person }}
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_registered_area != scope.row.new_registered_area"
        >
          {{ scope.row.old_registered_area }}
        </p>
        <p
          class="mar-r-3"
          v-if="
            scope.row.old_registered_address != scope.row.new_registered_address
          "
        >
          {{ scope.row.old_registered_address }}
        </p>
        <p
          class="mar-r-3"
          v-if="
            scope.row.old_business_license != scope.row.new_business_license
          "
        >
          {{ scope.row.old_business_license }}
        </p>
      </template>
      <template slot="new_company_name" slot-scope="scope">
        <p
          class="mar-r-3"
          v-if="scope.row.old_company_name != scope.row.new_company_name"
        >
          {{ scope.row.new_company_name }}
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_usc_code != scope.row.new_usc_code"
        >
          {{ scope.row.new_usc_code }}
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_legal_person != scope.row.new_legal_person"
        >
          {{ scope.row.new_legal_person }}
        </p>
        <p
          class="mar-r-3"
          v-if="scope.row.old_registered_area != scope.row.new_registered_area"
        >
          {{ scope.row.new_registered_area }}
        </p>
        <p
          class="mar-r-3"
          v-if="
            scope.row.old_registered_address != scope.row.new_registered_address
          "
        >
          {{ scope.row.new_registered_address }}
        </p>
        <p
          class="mar-r-3"
          v-if="
            scope.row.old_business_license != scope.row.new_business_license
          "
        >
          {{ scope.row.new_business_license }}
        </p>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  getMetaCommonList,
  delMetaCommon,
  updateMetaCommon,
  getMetaCommonInfo,
} from "@/api/busiMode/metaCommon";
import { mapGetters } from "vuex";
import addEnterprise from "./addEnterprise";
const viewPortHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;
export default {
  data() {
    return {
      data: [],
      loading: false,
      init: false,

      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      model: "wisdom_park.wp_company_change",
      option: {
        searchMenuSpan: 4,
        height: viewPortHeight * 0.6 - 60,
        searchMenuPosition: "right",
        dateBtn: false,
        border: true,
        tip: true,
        searchShow: true,
        addBtn: false,
        refreshBtn: true,
        addRowBtn: false,
        columnBtn: true,
        delBtn: false,
        editBtn: false,
        cancelBtn: false,
        rowKey: "",
        showPage: true,
        index: true,
        selection: false,
        showHeader: true,
        expand: false,
        highlightCurrentRow: true,
        stripe: false,
        emptyBtn: false,
        menu: false,
        reserveSelection: true,
        column: [
          {
            width: "auto",
            align: "left",
            label: "变更日期",
            prop: "update_time",
            width: 150,
          },
          {
            width: "200",
            overHidden: true,
            align: "left",
            label: "变更项目",
            prop: "name",
            slot: true,
          },

          {
            width: "auto",
            overHidden: true,
            align: "left",
            label: "变更前",
            prop: "old_company_name",
            slot: true,
          },
          {
            width: "auto",
            overHidden: true,
            align: "left",
            label: "变更后",
            prop: "new_company_name",
            slot: true,
          },
        ],
      },
    };
  },

  mounted() {},
  created() {
    //this.getDicList();
  },
  props: {
    currentRow: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    currentRow: {
      // immediate: true,
      handler(val) {
        if (val.id) {
          // this.onLoad();
        }
      },
    },
  },
  mounted() {
    this.init = true;
    // if (this.currentRow.id) {
    //   this.onLoad();
    // }
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
  },
  methods: {
    refreshChange() {
      this.onLoad(this.page);
    },

    onLoad() {
      // this.loading = true;
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: [],
        orders: [{ field: "create_time", sort: "DESC" }],
      };
      if (this.currentRow.id) {
        body.wheres.push({ field: "company_id", value: this.currentRow.id });
      }
      this.getMetaCommonList(body);
    },
    getMetaCommonList(body) {
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        data.records.forEach((e) => {
          if (e.type) {
            this.enterpriseTypes.forEach((type) => {
              if (e.type == type.value) {
                e.type = type.name;
              }
            });
          }
          if (e.qualification) {
            this.qualifications.forEach((q) => {
              if (e.qualification == q.value) {
                e.qualification = q.name;
              }
            });
          }
          if (e.firm_size) {
            this.firmSizeList.forEach((q) => {
              if (e.firm_size == q.value) {
                e.firm_size = q.name;
              }
            });
          }
          if (e.technical_type) {
            this.companyTechnicalTypes.forEach((q) => {
              if (e.technical_type == q.value) {
                e.technical_type = q.name;
              }
            });
          }
          if (e.is_public) {
            this.companyIsPublics.forEach((q) => {
              if (e.is_public == q.value) {
                e.is_public = q.name;
              }
            });
          }
        });
        this.data = data.records;
        this.loading = false;
      });
    },
  },
};
</script>
