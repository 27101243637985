<template>
  <div :id="echartId" class="w-100p h-100p"></div>
</template>
<script>

import * as echarts from 'echarts';

import echartsTheme from '@/util/echartsTheme';

import color from '@/util/color';

export default {
  name: 'BasicEcharts',
  data(){
    return {
      echartId: 'basic-charts-' + Math.floor(Date.now() * Math.random()),
      echartsInstance: null
    }
  },
  props: {
    option: {
      type: Object,
      default: null
    }
  },
  watch: {
    option: {
      deep: true,
      immediate: true,
      handler: function(){
        if(this.echartsInstance){
          this.setOption();
        }
      }
    }
  },
  mounted(){
    let theme = echartsTheme[color.theme] ? echartsTheme[color.theme] : null;
    this.echartsInstance = echarts.init(document.getElementById(this.echartId) , theme ? theme.theme : null, {
      width: 'auto',
      height: 'auto'
    });
    if(this.option){
      this.setOption();
    }
    this.echartsInstance.on('click' , this._handlerClick)
    window.addEventListener('resize' , this.resize.bind(this));
    this.echartsInstance.resize();
  },
  destroyed(){
    if(this.echartsInstance){
      this.echartsInstance.off('click' , this._handlerClick);
    }
    this.echartsInstance = null;
    window.removeEventListener('resize' , this.resize.bind(this));
  },
  methods: {
    _handlerClick(event){
      this.$emit('click' , event)
    },
    setOption(){
      this.echartsInstance.clear();
      let theme = echartsTheme[color.theme] ? echartsTheme[color.theme] : null;
      let _color = color.color;
      if(theme && theme.theme && theme.theme.color){
        _color = theme.theme.color;
      }
      this.echartsInstance.setOption({
        color: _color,
        ...this.option
      });
      this.echartsInstance.resize();
    },
    resize(){
      if(this.echartsInstance){
        this.echartsInstance.resize();
      }
    }
  }
}
</script>