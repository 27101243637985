import request from '@/router/axios';

const baseUrl = '/api/convergeflexschduler/convergeflexschduler/';

export const listDataRead = (params) => {
  return request({
    url:baseUrl + 'dataStructureType/list-paging',
    method: 'get',
    params: params
  })
};


export const queryDataBaseByDataReadId = (params) => {
  return request({
    url:baseUrl + 'dataStructureType/queryDataBaseByDataStructureTypeId',
    method: 'post',
    params: params
  })
}

export const queryTablesByDataBaseId = (params) => {
  return request({
    url:baseUrl + 'datamodel/queryTablesByDataBaseId',
    method: 'post',
    params: params
  })
}

export const createDataRead = (data) => {
  return request({
    url:baseUrl + 'dataStructureType/create',
    method: 'post',
    params: data
  })
}

export const updateDataRead = (data) => {
  return request({
    url:baseUrl + 'dataStructureType/update',
    method: 'post',
    params: data
  })
}

export const deleteDataRead = (params) => {
  return request({
    url:baseUrl + 'dataStructureType/delete',
    method: 'get',
    params: params
  })
}

export const queryDataByDataBaseIdAndTableName = (data) => {
  return request({
    url:baseUrl + 'datamodel/queryDataByDataBaseIdAndTableName',
    method: 'post',
    params: data
  })
}
