export const dicList = [{
    label: '输入框',
    value: 'input'
  }, {
    label: '选择框',
    value: 'select'
  }, {
    label: '密码框',
    value: 'password'
  }, {
    label: '数字框',
    value: 'number'
  }, {
    label: '文本框',
    value: 'textarea'
  }, {
    label: '上传框',
    value: 'upload'
  }, {
    label: '颜色选择器',
    value: 'color'
  }, {
    label: '图标选择器',
    value: 'icon-select'
  }, {
    label: '树框',
    value: 'tree'
  }, {
    label: '单选框',
    value: 'radio'
  }, {
    label: '多选',
    value: 'checkbox'
  }, {
    label: '开关框',
    value: 'switch'
  }, {
    label: '级联框',
    value: 'cascader'
  }, {
    label: '日期框',
    value: 'date'
  }, {
    label: '时间框',
    value: 'time'
  }, {
    label: '日期时间框',
    value: 'datetime'
  }, {
    label: '日期范围',
    value: 'daterange'
  }, {
    label: '时间范围',
    value: 'timerange'
  }, {
    label: '日期时间范围',
    value: 'datetimerange'
  }, {
    label: '年',
    value: 'year'
  }, {
    label: '月',
    value: 'month'
  }, {
    label: '周',
    value: 'week'
  }, {
    label: '多个日期',
    value: 'dates'
  }]
    
  export const menuLeftList = [{
    label: '新增',
    value: 'add',
    icon:"el-icon-plus",
    type:"primary",
    show:true
  },
  {
    label: '导入',
    value: 'important',
    type:"",
    icon:"el-icon-upload2",
    show:true
  }]
  export const menuList =[{
    label: '修改',
    value: 'edit',
    icon:"el-icon-edit",
    show:true
  }, {
    label: '删除',
    value: 'del',
    icon:"el-icon-delete",
    show:true
  }]
  export const btnTypeList = [{
    label: '新增',
    value: 'add',
    icon:"el-icon-plus"
  }, {
    label: '修改',
    value: 'edit',
    icon:"el-icon-edit"
  }, {
    label: '删除',
    value: 'del',
    icon:"el-icon-delete"
  }]
  export const ynList = [{
    label: '否',
    value: false
  }, {
    label: '是',
    value: true
  }]
  export const alignList = [{
    label: '居左',
    value: 'left'
  }, {
    label: '居中',
    value: 'center'
  }, {
    label: '居右',
    value: 'right'
  }]
  
  export const sizeList = [{
    label: '超小',
    value: 'mini'
  }, {
    label: '小型',
    value: 'small'
  }, {
    label: '中等',
    value: 'medium'
  }]
  
  // CONCATENATED MODULE: ./packages/crud/src/option.js
  export const openList = [{
    label: '弹窗',
    value: 'dialog'
  }, {
    label: '打开新页',
    value: 'target'
  }]
  export const menuOption = {
    menuBtn: false,
    size: 'mini',
    column: [
      {
        label: '按钮',
        prop: 'btnName',
        type: 'select',
        multiple: true,
        tip:'支持选择多个按钮',
        dicData:btnTypeList,
        span: 24
      },
      {
        label: '别名',
        prop: 'alias',
        type: 'input',
        span: 24
      },
      {
      label: '显示',
      prop: 'showBtn',
      type: 'radio',
      span: 24,
      dicData: ynList
    }, 
     {
      label: '对齐方式',
      prop: 'menuAlign',
      type: 'select',
      dicData: alignList,
      span: 24
    },
    {
      label: '打开方式',
      prop: 'openDialogType',
      type: 'radio',
      dicData: [{
        label: '弹窗',
        value: 'dialog'
      }, {
        label: '抽屉',
        value: 'drawer'
      },{
        label: '打开新页',
        value: 'target'
      }],
      span: 24
    },
     {
      label: '按钮类型',
      prop: 'menuType',
      type: 'select',
      dicData: [
        {
          label: '按钮',
          value: 'button'
        }, {
          label: '图标',
          value: 'icon'
        }, {
          label: '文字',
          value: 'text'
        }
      ],
      span: 24
    }
  ]
  }
 
  export const searchOption = {
    menuBtn: true,
    size: 'mini',
    column: [
      {
        label: '名称',
        prop: 'label',
        span: 24
      },
      {
        label: '是否必填',
        prop: 'index',
        type: 'radio',
        span: 24,
        dicData: ynList
      },
      {
        label: '数据来源',
        prop: 'dataList',
        span: 24
      },
      // {
      //   label: '类型',
      //   prop: 'type',
      //   span: 24,
      //   type: 'select',
      //   dicData: dicList
      // },
      // {
      //   label: '请求地址',
      //   prop: 'url',
      //   type: 'input',
      //   span: 24,
      //   display:false
      // },
    {
      label: '辅助文字',
      prop: 'tip',
      type: 'input',
      span: 24
    },
    // {
    //   label: '比例',
    //   prop: 'span',
    //   type: 'input',
    //   span: 24
    // },
    // {
    //   label: 'label宽度',
    //   prop: 'labelWidth',
    //   type: 'input',
    //   placeholder:"60",
    //   span: 24
    // },

    // {
    //   span: 24,
    //   label: '校验规则',
    //   prop: 'rules',
    //   formslot: true
    // }
  ]
  }
  export const tableOption = {
    menuBtn: false,
    size: 'mini',
    column: [
    {
      label: '表格高度',
      prop: 'height',
      type: 'number',
      span: 24,
    }, 
    {
      label: '显示序号',
      prop: 'index',
      type: 'radio',
      span: 24,
      dicData: ynList
    }, {
      label: '序号标题',
      span: 24,
      prop: 'indexLabel'
    }, {
      label: '支持多选',
      prop: 'selection',
      type: 'radio',
      span: 24,

      dicData: ynList
    }, {
      label: '显示边框',
      prop: 'border',
      type: 'radio',
      span: 24,
      dicData: ynList
    }, 
    // {
    //   label: '折叠',
    //   prop: 'expand',
    //   type: 'radio',
    //   span: 24,
    //   dicData: ynList
    // }, 
    {
      label: '表头对齐',
      prop: 'headerAlign',
      type: 'select',
      span: 24,
      dicData: alignList
    }, {
      label: '单元格对齐',
      prop: 'menuAlign',
      type: 'select',
      span: 24,
      dicData: alignList
    }, {
      label: '选中高亮',
      prop: 'highlightCurrentRow',
      type: 'radio',
      span: 24,
      dicData: ynList
    }, {
      label: '展示斑马线',
      prop: 'stripe',
      type: 'radio',
      span: 24,
      dicData: ynList
    },
    {
      label: '显示分页',
      prop: 'showPage',
      type: 'radio',
      span: 24,
      dicData: ynList
    }]
    // }, {
    //   labelWidth: 140,
    //   label: '搜索按钮单独成行',
    //   prop: 'searchMenuSpan',
    //   type: 'number',
    //   span: 12
    // }]
  }
  export const rowOption1={
    menuBtn: false,
    size: 'mini',
    column: [
      {
        label: 'prop值',
        prop: 'prop',
        span: 24,
        // readonly:true,
        rules: [
          { required: true, message: '请输入prop' }
        ]
      }, {
        label: '类型',
        prop: 'type',
        span: 24,
        type: 'select',
        dicData: dicList
      },
      {
        label: '宽度',
        prop: 'width',
        span: 24,
        type: 'input'
      }, {
        label: '最小宽度',
        prop: 'minwidth',
        span: 24,
        type: 'input'
      }, {
        label: '对齐方式',
        prop: 'align',
        type: 'select',
        span: 24,
        dicData: alignList
      }, {
        label: '列冻结',
        prop: 'fixed',
        type: 'radio',
        span: 24,
        dicData: ynList
      }, 
      // {
      //   label: '隐藏',
      //   prop: 'hide',
      //   type: 'radio',
      //   span: 24,
      //   dicData: ynList
      // },
      //  {
      //   label: '超出省略',
      //   prop: 'overHidden',
      //   type: 'radio',
      //   span: 24,
      //   dicData: ynList
      // }, {
      //   label: '筛选',
      //   prop: 'filter',
      //   type: 'radio',
      //   span: 24,
      //   dicData: ynList
      // },
      {
        label: '排序',
        prop: 'sortable',
        span: 24,
        type: 'radio',
        dicData: ynList
      },
  {
      labelWidth: '120',
      label: '多选时超出合并',
      prop: 'tags',
      type: 'radio',
      dicData: ynList,
      showBy: ['select', 'tree'],
      display: false
    }, {
      label: '精度',
      prop: 'precision',
      type: 'number',
      showBy: ['number'],
      display: false
    }, {
      labelWidth: '120',
      label: '前面的辅助文字',
      prop: 'prepend',
      showBy: ['input'],
      display: false
    }, {
      labelWidth: '120',
      label: '后面的辅助文字',
      prop: 'append',
      showBy: ['input'],
      display: false
    }, {
      label: '开始占位符',
      prop: 'startPlaceholder',
      showBy: ['daterange', 'timerange', 'datetimerange'],
      display: false
    }, {
      label: '结束占位符',
      prop: 'endPlaceholder',
      showBy: ['daterange', 'timerange', 'datetimerange'],
      display: false
    }, {
      label: '日期格式化',
      prop: 'format',
      showBy: ['date', 'time', 'datetime', 'daterange', 'timerange', 'datetimerange', 'week', 'month', 'year', 'dates'],
      display: false
    }, {
      label: '日期格式化值',
      prop: 'valueFormat',
      showBy: ['date', 'time', 'datetime', 'daterange', 'timerange', 'datetimerange', 'week', 'month', 'year', 'dates'],
      display: false
   
  }
]}
export const tableTHOption={
  menuBtn: true,
  size: 'mini',
  column: [
    {
      label: '名称',
      prop: 'label',
      span: 24,
    },
    {
      label: '排序',
      prop: 'sortable',
      type: 'switch',
      span: 24,
      dicData: ynList
    },
    //{
     // label: '列冻结',
     // prop: 'fixed',
     // type: 'switch',
     // span: 24,
     // dicData: ynList
    //}, 
     {
      label: '超出省略',
      prop: 'overHidden',
      type: 'switch',
      span: 24,
      dicData: ynList
    }, 
    {
      label: '对齐方式',
      prop: 'align',
      type: 'radio',
      dicData: alignList,
      span: 24
    },
]}
  export const option_option = {
    dateBtn: false,
    border: true,
    height: "auto",
    dragHandler: false,
    sortable: true,
    dialogClickModal: false,
    dialogType: "drawer",
    searchShow: true,
    searchMenuSpan: 12,
    addBtn:false,
    refreshBtn: true, //刷新按钮
    addRowBtn: false, //表格行内添加按钮
    columnBtn: true, //列显隐按钮
    delBtn: false, //	行内删除按钮
    editBtn: false, //行内编辑按钮
    cancelBtn: false, //行编辑取消按钮
    rowKey: "",
    showPage: true,
    index: true,
    indexLabel: "序号",
    selection: false,
    showHeader:true,
    expand: false,
    headerAlign: "center",
    align: "center",
    highlightCurrentRow: true,
    stripe: false,
    menuWidth:300,
    reserveSelection: true,//换页不清空当前的选中数据
    column: [
      {
        label: '姓名',
        prop: 'name',
        width: 200,
      },  {
        label: '性别',
        prop: 'sex',
        hide:false,
        width: 200,
      }, {
        label: '年龄',
        prop: 'age',
        width: 200,
        hide:false
      },
      {
        label: '学历',
        prop: 'school',
        width: 300,
        hide:false
      },
      {
        label: '学历1',
        prop: 'school',
        width: 300,
        hide:false
      },
    ]
  }
  export const pageList = [{
    label: '10页',
    value: '10'
  }, {
    label: '20页',
    value: '10'
  },
  {
    label: '30页',
    value: '10'
  }]
  export const showUpDOwnList = [{
    label: '上',
    value: 'up'
  }, {
    label: '下',
    value: 'down'
  },
]
export const showLeftRightList = [{
  label: '左',
  value: 'left'
}, {
  label: '右',
  value: 'right'
},
{
  label: '居中',
  value: 'center'
},
]
export const pageObj={
  pageSizes: [10, 20, 30, 40], //默认
  currentPage: 1,
  total: 10,
  pageSize: 10,
}
  export const pageOption = {
    menuBtn: false,
    size: 'mini',
    column: [
      // {
      //   label: '列表分页',
      //   prop: 'showPage',
      //   type: 'radio',
      //   span: 24,
      //   dicData: ynList
      // },
      // {
      //   label: '分页条数',
      //   prop: 'btnType',
      //   type: 'select',
      //   dicData: btnList,
      //   span: 24
      // },
      // {
      //   label: '显示位置',
      //   prop: 'showUpDO',
      //   type: 'select',
      //   dicData: showUpDOwnList,
      //   span: 24
      // },
      {
        label: '排版位置',
        prop: 'showLeftRight',
        type: 'select',
        dicData: showLeftRightList,
        span: 24
      },
    ]
  }
  export const newOption={
    column: [
      {
        type: 'input',
        label: '单行文本',
        span: 24,
        display: true,
        prop: 'a164663643016464328'
      },
      {
        type: 'password',
        label: '密码',
        span: 24,
        display: true,
        prop: 'a16466364320089145'
      },
      {
        type: 'textarea',
        label: '多行文本',
        span: 24,
        display: true,
        prop: 'a164663643363036266'
      },
      {
        type: 'number',
        label: '计数器',
        controls: true,
        span: 24,
        display: true,
        prop: 'a164664361489917795'
      }
    ],
    labelPosition: 'left',
    labelSuffix: '：',
    labelWidth: 120,
    gutter: 0,
    menuBtn: true,
    submitBtn: true,
    submitText: '查询',
    emptyBtn: true,
    emptyText: '清空',
    menuPosition: 'center'
  }
  export const search1Option={
    column: [
      {
        label: '姓名',
        prop: 'name',
        span:4,
        display:true
      },{
        label: '生日',
        prop: 'birthday',
        type: "datetime",
        span:4,
        display:true
      }
    ],
    labelPosition: 'left',
    labelSuffix: '：',
    labelWidth: 60,
    gutter: 15,
    menuBtn: true,
    menuSpan:8,
    menuPosition:"left",
    submitText: '搜索',
    emptyBtn: true,
    emptyText: '清空',
    btnLoading:false
  }