<template>
  <basic-container>
    <avue-form
      ref="form"
      v-model="obj"
      :option="option"
      @reset-change="emptytChange"
      @submit="submit"
      :append-to-body="true"
    >
    </avue-form>
  </basic-container>
</template>
<script>
import { getLazyList, remove, update, add, getMenu } from "@/api/system/menu";
import { mapGetters } from "vuex";
import iconList from "@/config/iconList";
import { getMenuTree } from "@/api/system/menu";

export default {
  data() {
    return {
      obj: { openType: "table" },
      parentId: 0,
      sizeValue: "",
    };
  },
  computed: {
    option() {
      return {
        size: this.sizeValue,
        mock: true,
        submitText: "保存",
        column: [
          {
            label: "菜单名称",
            prop: "name",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入菜单名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上级菜单",
            prop: "parentId",
            type: "tree",
            span: 24,
            hide: true,
            dicUrl: "/api/jida-system/menu/tree", // 动态字典 接口
            props: {
              label: "title",
            },
            addDisabled: false,
            rules: [
              {
                required: false,
                message: "请选择上级菜单",
                trigger: "click",
              },
            ],
          },
          {
            label: "菜单图标",
            prop: "source",
            type: "icon",
            slot: true,
            span: 24,
            iconList: iconList,
          },
          {
            label: "默认创建",
            prop: "openType",
            type: "radio",
            span: 24,
            dicData: [
              {
                label: "表单",
                value: "form",
              },
              {
                label: "表格",
                value: "table",
              },
              {
                label: "看板",
                value: "layout",
              },
            ],
          },
        ],
      };
    },
  },

  watch: {
    option: {
      handler(newName, oldName) {
        //this.getCommList();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.initData();
    this.obj.username = "smallwei";
    this.obj.switch = 0;
    this.obj.phone = "17547400800";
  },
  methods: {
    emptytChange() {
      //this.$message.success("清空方法回调");
    },
    submit(form, done) {
      console.log(form.$parentId);
      console.log(this.obj);
      let p = "";
      if (this.obj.openType == "table") {
        p = "/data/table/tableCommon";
      } else if (this.obj.openType == "form") {
        p = "/data/form/formCommon";
      } else {
        p = "/mypages/design";
      }

      let obj = {
        alias: this.obj.name,
        category: 1,
        code: "tableCommon" + this.obj.name + new Date().getTime(),
        isOpen: 1,
        name: this.obj.name,
        parentId: this.obj.parentId,
        path: p + "?menuId" + new Date().getTime(),
        remark: "",
        sort: this.$store.state.user.menu.length + 1,
        source: this.obj.name,
      };
      add(obj).then(
        (res) => {
          // 获取新增数据的相关字段
          const data = res.data.data;
          if (res.data.code == 200) {
            let o = obj;
            o.id = res.data.data.id;
            o.path = p + "?menuId" + res.data.data.id;
            update(o).then((r) => {
              this.$emit("saveNewPage", [
                obj,
                this.obj.openType,
                res.data.data.id,
                this.obj.$parentId,
              ]);
            });
          } else {
            done();
            this.$refs.form.resetForm();
            this.obj = { openType: "table" };
          }
        },
        (error) => {
          done();
          this.$refs.form.resetForm();
          this.obj = { openType: "table" };
        }
      );
    },
    initData() {
      getMenuTree().then((res) => {
        const column = this.findObject(this.option.column, "parentId");
        column.dicData = res.data.data;
      });
    },
  },
};
</script>
