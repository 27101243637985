import request from '@/router/axios';

export const getAppToken = (params) => {
    return request({
      url: '/dataShare/datashare/getAppToken',
      method: 'post',
      data:params
    })
  }
  export const qeuryAreaByMonth = (data) => {
    return request({
      url: '/api/park-statistics/statistics/qeuryAreaByMonth',
      method: 'post',
      params: data
    })
  }
  export const queryAreaBySpecialDate = (data) => {
    return request({
      url: '/api/park-statistics/statistics/queryAreaBySpecialDate',
      method: 'post',
      params: data
    })
  }
  export const getReceivable = (data) => {
    return request({
      url: '/api/park-statistics/statistics/getReceivable',
      method: 'get',
      params: data
    })
  }
  export const getPlanReceipt = (data) => {
    return request({
      url: '/api/park-statistics/statistics/getPlanReceipt',
      method: 'get',
      params: data
    })
  }
  export const getPlanReceiptMonth = (data) => {
    return request({
      url: '/api/park-statistics/statistics/getPlanReceiptMonth',
      method: 'get',
      params: data
    })
  }
  export const getCompanyNormalPayment = (data) => {
    return request({
      url: '/api/park-statistics/statistics/getCompanyNormalPayment',
      method: 'get',
      params: data
    })
  }
  
  export const getLeaseDetails= (data) => {
    return request({
      url: '/api/park-statistics/statistics/getLeaseDetails',
      method: 'get',
      params: data
    })
  }
  
  export const getBuildingReceipt = (data) => {
    return request({
      url: '/api/park-statistics/statistics/getBuildingReceipt',
      method: 'get',
      params: data
    })
  }
  
  
  