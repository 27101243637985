<template>
    <div class="h-100p">
        <div class="diaTitle p-a" :class="{ 'mar-l-15': doType == 'rz' }">
            <!-- <span
        v-if="doType != 'khxx'"
        class="fts-14 title titleavtive cur-p"
        @click="activeName = 'first'"
        :class="{ titleactive: activeName == 'first' }"
        >基本信息</span
      > -->

            <span v-if="doType == 'rz'" class="fts-14 title mar-l-15 cur-p" @click="activeName = 'employee'" :class="{ titleactive: activeName == 'employee' }">人员授权</span>
            <span v-if="doType == 'rz'" class="fts-14 title mar-l-15 cur-p" @click="activeName = 'contract'" :class="{ titleactive: activeName == 'contract' }">合同</span>
        </div>

        <div class="ov-a mar-t-10f pad-t-20" :class="{ diaDiv: doType != 'rz', drawerDiv: doType == 'rz' }">
            <template v-if="doType != 'khxx'">
                <baseInfo v-show="activeName == 'first'" ref="baseInfo" :currentRow="currentRow" :doType="doType"></baseInfo>
            </template>

            <bankInfo v-if="doType == 'khxx'" ref="bankInfo" :currentRow="currentRow" :doType="doType"></bankInfo>
            <employee v-show="activeName == 'employee'" @empoyees="getEmpoyees" :node="{ id: currentRow.dept_id }" :selection="true"></employee>
            <!-- <contract
        v-show="activeName == 'contract'"
        :companyId="currentRow.id"
      ></contract> -->
        </div>
        <div class="btnClass bor-t">
            <el-button size="small" class="pull-right mar-l-10 mar-t-10" type="" @click="close">取消</el-button>
            <el-button v-if="doType != 'rz'" size="small" class="pull-right mar-t-10" type="primary" :loading="saveLoading" @click="saveEnt">保存</el-button>
            <el-button v-if="doType == 'rz'" size="small" class="pull-right mar-t-10" type="primary" @click="saveEnt">提交入驻</el-button>
        </div>
    </div>
</template>

<script>
import { saveMetaCommon, updateMetaCommon } from '@/api/busiMode/metaCommon';
import baseInfo from './baseInfo.vue';
import bankInfo from './bankInfo.vue';
import enterInfo from './enterInfo.vue';
import employee from '../../employee/employee';
import contract from '../../contract/manage';
import { companycheckin, checkout, companySave } from '@/api/contract/index';
import standardService from '@/api/metadata/standard';
import { updateCompany, validatePhone } from '@/api/visitor/visitor';

export default {
    name: 'addEnterprise',
    components: { baseInfo, bankInfo, enterInfo, employee, contract },

    data() {
        return {
            entModel: 'wisdom_park.wp_company',
            changeModel: 'wisdom_park.wp_company_change',
            activeName: 'first',
            node: {},
            ruleModel: {},
            saveLoading: false,
            saveEntDisabled: false,
        };
    },
    props: {
        currentRow: {
            type: Object,
            default() {
                return {};
            },
        },
        doType: {
            type: String,
            default() {
                return '';
            },
        },
    },
    computed: {},
    mounted() {
        this.queryByModelCode();
    },
    methods: {
        queryByModelCode() {
            standardService.getModelRule(this.entModel).then(res => {
                this.ruleModel = res.data;
            });
        },
        close() {
            this.$emit('close');
        },
        getEmpoyees(e) {
            console.log(e);
        },
        toCamel(str) {
            return str.replace(/([^_])(?:_+([^_]))/g, function ($0, $1, $2) {
                return $1 + $2.toUpperCase();
            });
        },
        getBase64Img(img) {
            let cans = document.createElement('canvas');
            cans.width = img.width;
            cans.height = img.height;
            let ctx = cans.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height);
            let dataURL = cans.toDataURL('image/png');
            return dataURL;
        },

        imgUrlToBase64(url) {
            if (!url) {
                return;
            }
            let base64 = '';
            if (/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(url)) {
                // 图片地址
                const image = new Image();
                // 设置跨域问题
                image.setAttribute('crossOrigin', 'anonymous');
                // 图片地址
                image.src = url;
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.drawImage(image, 0, 0, image.width, image.height);
                    // 获取图片后缀
                    const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
                    // 转base64
                    let dataUrl = canvas.toDataURL(`image/${ext}`);
                    console.log(dataUrl);
                    base64 = dataUrl;
                };
            }
            return base64;
        },
        toHump(name) {
            return name.replace(/\_(\w)/g, function (all, letter) {
                return letter.toUpperCase();
            });
        },
        saveEnt() {
            if (this.doType == 'khxx') {
                Promise.all([this.$refs.bankInfo.save(this.currentRow.id)]).then(forms => {
                    console.log(forms);
                    this.$emit('close');
                });
            } else {
                Promise.all([this.$refs.baseInfo.submit()]).then(async forms => {
                    this.saveLoading = true;
                    let valid = true,
                        errorObj = null;
                    forms.forEach(itemForm => {
                        if (itemForm.valid !== true) {
                            valid = false;
                            if (!errorObj) {
                                errorObj = itemForm.error;
                            }
                        }
                    });
                    if (valid) {
                        let baseInfo = forms[0].form;
                        console.log(baseInfo);
                        let tempCode = '';
                        await new Promise(resolve => {
                            validatePhone({ phoneNum: baseInfo.phone_num }).then(({ data }) => {
                                tempCode = data.data;
                                resolve(data);
                            });
                        });
                        if (this.doType === 'add') {
                            if (tempCode > 0) {
                                this.$message.error('联系人电话不是唯一');
                                this.saveLoading = false;
                                return;
                            }
                        }
                        //营业执照图片
                        if (this.$refs.baseInfo.imgform) {
                            let imgList = [];
                            this.$refs.baseInfo.imgform.imgUrl.forEach(e => {
                                if (e.label) {
                                    imgList.push(e.label);
                                }
                            });
                            baseInfo.business_license = imgList.toString();
                        }
                        //企业logo
                        if (this.$refs.baseInfo.imgform1) {
                            let imgList = [];
                            if (this.$refs.baseInfo.imgform1.imgUrl.length > 0) {
                                const base64Str = this.imgUrlToBase64(this.$refs.baseInfo.imgform1.imgUrl[0].value);
                                baseInfo.company_logo_base64 = base64Str;
                            }

                            this.$refs.baseInfo.imgform1.imgUrl.forEach(e => {
                                if (e.label) {
                                    imgList.push(e.label);
                                }
                            });

                            baseInfo.company_logo = imgList.toString();
                        }
                        console.log(baseInfo.technical_type);
                        if (baseInfo.technical_type) {
                            if (baseInfo.technical_type.length > 0) {
                                baseInfo.technical_type = baseInfo.technical_type.toString();
                            } else if (baseInfo.technical_type.length == 0) {
                                baseInfo.technical_type = '';
                            }
                        }

                        if (baseInfo.id) {
                            let obj = {};
                            Object.keys(baseInfo).forEach(ele => {
                                obj[this.toHump(ele)] = baseInfo[ele];
                            });
                            updateCompany(obj)
                                .then(res => {
                                    if (res.data.code == 200) {
                                        //console.log(111);
                                        let oldCompany = JSON.parse(this.$refs.baseInfo.oldCompany);
                                        console.log('oldCompany' + oldCompany);
                                        if (
                                            oldCompany.name != baseInfo.name ||
                                            oldCompany.uniform_social_credit_code !=
                                                baseInfo.uniform_social_credit_code ||
                                            oldCompany.legal_person != baseInfo.legal_person ||
                                            oldCompany.registered_area != baseInfo.registered_area ||
                                            oldCompany.business_license != baseInfo.business_license ||
                                            oldCompany.registered_address != baseInfo.registered_address
                                        ) {
                                            let o = {
                                                company_id: baseInfo.id,

                                                old_company_name: oldCompany.name,
                                                old_usc_code: oldCompany.uniform_social_credit_code,
                                                old_legal_person: oldCompany.legal_person,
                                                old_registered_area: oldCompany.registered_area,
                                                old_business_license: oldCompany.business_license,
                                                old_registered_address: oldCompany.registered_address,

                                                new_company_name: baseInfo.name,
                                                new_usc_code: baseInfo.uniform_social_credit_code,
                                                new_legal_person: baseInfo.legal_person,
                                                new_registered_area: baseInfo.registered_area,
                                                new_business_license: baseInfo.business_license,
                                                new_registered_address: baseInfo.registered_address,
                                            };
                                            saveMetaCommon(this.changeModel, { values: o }).then(r => {
                                                if (res.data.code == 200) {
                                                    if (res.data.data.id) {
                                                        Promise.all([this.$refs.bankInfo.save(res.data.data.id)]).then(
                                                            forms => {
                                                                this.$emit('close');
                                                            },
                                                        );
                                                    } else {
                                                        this.$emit('close');
                                                    }
                                                    this.$emit('close');
                                                    // this.saveLoading = true;
                                                }
                                            });
                                        } else {
                                            if (res.data.data.id) {
                                                Promise.all([this.$refs.bankInfo.save(res.data.data.id)]).then(
                                                    forms => {
                                                        this.$emit('close');
                                                    },
                                                );
                                            } else {
                                                this.$emit('close');
                                            }
                                        }
                                    }
                                })
                                .catch(e => {
                                    this.saveLoading = false;
                                });
                        } else {
                            let obj = {};
                            Object.keys(baseInfo).forEach(ele => {
                                obj[this.toCamel(ele)] = baseInfo[ele];
                            });
                            companySave(obj)
                                .then(res => {
                                    if (res.data.code == 200) {
                                        if (res.data.data.id) {
                                            this.$emit('close', res.data.data);
                                        } else {
                                            this.$emit('close');
                                        }
                                        this.$emit('close');
                                    }
                                    this.saveLoading = true;
                                })
                                .catch(e => {
                                    this.saveLoading = false;
                                });
                        }
                    } else {
                        this.$message.error('请完善企业信息');
                        this.saveLoading = false;
                    }
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.btnClass {
    display: flow-root;
    height: 50px;
    line-height: 50px;
    padding: 0px 15px;
}
/deep/ .el-dialog__body {
    padding-top: 0px;
}
.titleactive {
    color: #475570 !important;
}
.title {
    color: #8d9ab2;
}
.p-a {
    position: absolute;
}
.diaTitle {
    top: 23px;
    left: 80px;
}
.diaDiv {
    height: 55vh;
    overflow-x: hidden !important;
}
.drawerDiv {
    height: calc(100% - 50px);
    overflow-x: hidden !important;
}
.titlediv {
    background: #fff;
    padding: 20px;
}
</style>
