<template>
  <transition name="el-notification-fade">
    <div class="basic-contextmenu" :style="_getStyle()">
      <ul class="basic-contextmenu-menu">
        <template v-for="item of menus">
          <li v-if="item[divider]">
            <el-divider :style="{ marigin: '3px 0 !important' }"></el-divider>
          </li>
          <li
            v-else
            @click="menuClick(item)"
            class="basic-contextmenu-menu-item"
          >
            <i
              v-if="item[icon]"
              class="mar-r-5"
              :class="
                item[icon] + ' ' + (item[type] ? 'text-' + item[type] : '')
              "
            ></i
            ><span :class="item[type] ? 'text-' + item[type] : ''">{{
              _$t(item[label])
            }}</span>
          </li>
        </template>
      </ul>
    </div>
  </transition>
</template>
<script>
import i18n from "@/lang";

export default {
  data() {
    return {
      isServer: false,
    };
  },
  computed: {
    label() {
      return this._getProps("label");
    },
    type() {
      return this._getProps("type");
    },
    icon() {
      return this._getProps("icon");
    },
    divider() {
      return this._getProps("divider");
    },
  },
  props: {
    menus: {
      type: Array,
      default: function () {
        return [];
      },
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    props: {
      type: Object,
      default: function () {
        return {
          label: "label",
          icon: "icon",
          type: "type",
          divider: "divider",
        };
      },
    },
  },
  methods: {
    _getProps(name) {
      return this.props[name] || name;
    },
    close() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
    _$t(text) {
      return i18n.$t(text) || text;
    },
    menuClick(item) {
      if (this.onClick) {
        this.onClick(item);
      }
    },
    _getStyle() {
      let style = null;
      if (this.isServer === false) {
        style = {
          left: this.x + "px",
          top: this.y + "px",
        };
      }
      return style;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/default-vars.scss";
@import "@/styles/vars.scss";

.basic-contextmenu {
  position: fixed;
  z-index: 1050;
  background: #fff;
  .basic-contextmenu-menu {
    z-index: 100;
    min-width: 150px;
    border: none;
    padding: 5px 0;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    list-style: none;
    position: relative;
    margin: 0;
    .el-divider {
      margin: 5px 0 !important;
    }
  }
  .basic-contextmenu-menu-item {
    background-color: #ffffff;
    float: none;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    color: $--color-text-regular;
    list-style: none;
    position: relative;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: $--color-primary;
      background: $--background-color-base;
    }
  }
}
</style>
