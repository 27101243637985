const prefix = '/api/data-dev-platform/';
const prefix2 = '/api/convergeflexschduler/convergeflexschduler/'
const catalogPrefix = '/api/jida-data-asset/catalog/';

export default {
    authorize: {
        remove: prefix + 'dataauthorizelog/remove',
        list: prefix + 'dataauthorizelog/list',
        save: prefix + 'dataauthorizelog/dataAuthorizeSubmit',
        get: prefix + 'dataauthorizelog/detail'
    },
    dataMap: {
        datasources: prefix2 + 'datamap/datasources-list',
        taskCount: prefix2 + 'datamap/taskCount',
        datahierarchy: prefix2 + 'datamap/datahierarchy-index',
        apps: '/api/jida-system/app/list-all',
        table: prefix2 + 'datamap/datahierarchy-index-details/'
    },
    catalog: {
        list: catalogPrefix + 'page',
        save: catalogPrefix + 'save',
        update: catalogPrefix + 'update',
        detail: catalogPrefix + 'detail',
        remove: catalogPrefix + 'remove',
        exportExcel: catalogPrefix + 'exportExcel',
        importExcel: catalogPrefix + 'importExcel',
        count: catalogPrefix + 'dailyCount',
        exportExcelAll: catalogPrefix + 'exportExcelAll'
    },
    taskQuota: {
        list: prefix2 + 'quota/list',
        save: prefix2 + 'quota/save',
        edit: prefix2 + 'quota/edit',
        delete: prefix2 + 'quota/del',
        detail: prefix2 + 'quota/queryQuotaByQuotaId'
    }
}