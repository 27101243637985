import request from '@/router/axios';
const prefix = '/api/jida-data-asset/';
export const getCatalogs = (params) => {
  return request({
    url: prefix + 'catalog/page',
    method: 'get',
    params: params
  })
};

export const saveCatalog = (params) => {
  let urlType = params.id ? 'update' : 'save';
  return request({
    url: prefix + 'catalog/' + urlType,
    method: 'post',
    data: params
  })
}
export const deleteCatalog = (data) => {
  return request({
    url: prefix + 'catalog/remove',
    method: 'post',
    params:data
  })
};

export const listCatalogs = (params) => {
  return request({
    url: prefix + 'catalog/list',
    method: 'get',
    params: params
  })
}

export const getDictDirTypes = () => {
  return request({
    url: '/api/jida-system/dict-biz/dictionary?code=CATALOG_TYPE',
    method: 'get'
  })
}
export const getDictStaticDirTypes = () => {
  return request({
    url: '/api/jida-system/dict-biz/dictionary?code=CATALOG_STATIC_TYPE',
    method: 'get'
  })
}
