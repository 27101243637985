import request from '@/router/axios';
const prefix = '/api/jida-system';
export const getDataAppList = (params) => {
  return request({
    url: prefix + `/app-model/page`,
    method: 'get',
    params
  })
};
export const getAppModelDetail = (params) => {
  return request({
    url: prefix + `/app-model/detail`,
    method: 'get',
    params
  })
};

export const saveAppMode = (params) => {
  return request({
    url: prefix + `/app-model/save`,
    method: 'post',
    data:params
  })
};
export const updateAppMode = (params) => {
  return request({
    url: prefix + `/app-model/update`,
    method: 'post',
    data:params
  })
};
export const getTree = (params) => {
  return request({
    url: prefix + `/app-model/tree`,
    method: 'get',
  })
};
//http://10.50.1.25:81/api/metadata-proxy/api/meta/v2/atlas-finder/findTableByDatasourceid?datasourceType=0&datasourceid=54

// export const findTableByDatasourceid = (params) => {
//   return request({
//     url:   `/api/metadata-proxy/api/meta/v2/atlas-finder/findTableByDatasourceid`,
//     method: 'get',
//     params
//   })
// };

export const findTableByDatasourceid = (params) => {
  return request({
    url:   `/api/metadata-proxy/api/meta/v2/atlas-finder/findTableByDatasourceCode`,
    method: 'get',
    params
  })
};

export const delAppMode = (params) => {
  return request({
    url: prefix + `/app-model/remove`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};


export const listPaging = (params) => {
  return request({
    url:  '/api/convergeflexschduler/convergeflexschduler/datasources/list-paging',
    method: 'get',
    params
  })
};

