import request from '@/router/axios';

export const getMetaItemList = (current, size, params) => {
  return request({
    url: '/api//jida-meta/meta-item/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getMetaList = (parentCode, params) => {
  return request({
    url: '/api/jida-meta/meta/list',
    method: 'get',
    params: {
      ...params,
      parentCode
    }
  })
}

export const getTempList = (code) => {
  return request({
    url: '/api/jida-meta/template-plan/list',
    method: 'get',
    params: {
      code
    }
  })
}
export const updateTempItem = (row) => {
  return request({
    url: '/api/jida-meta/template-plan/update',
    method: 'post',
    data: row
  })
}
export const removeTempItem = (ids) => {
  return request({
    url: '/api/jida-meta/template-plan/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const addTempItem = (row) => {
  return request({
    url: '/api/jida-meta/template-plan/save',
    method: 'post',
    data: row
  })
}

export const getTemList = (code) => {
  return request({
    url: '/api/jida-meta/template/list',
    method: 'get',
    params: {
      code
    }
  })
}
export const updateTemItem = (row) => {
  return request({
    url: '/api/jida-meta/template/update',
    method: 'post',
    data: row
  })
}
export const removeTemItem = (ids) => {
  return request({
    url: '/api/jida-meta/template/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const addTemItem = (row) => {
  return request({
    url: '/api/jida-meta/template/save',
    method: 'post',
    data: row
  })
}


export const getPlanList = (code) => {
  return request({
    url: '/api/jida-meta/plan/list',
    method: 'get',
    params: {
      code
    }
  })
}
export const updatePlan = (row) => {
  return request({
    url: '/api/jida-meta/plan/update',
    method: 'post',
    data: row
  })
}
export const removePlan = (ids) => {
  return request({
    url: '/api/jida-meta/plan/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const addPlan = (row) => {
  return request({
    url: '/api/jida-meta/plan/save',
    method: 'post',
    data: row
  })
}
export const getDetailByPlanId = (row) => {
  return request({
    url: '/api/jida-meta/dynamic-form/detail?',
    method: 'get',
    params: row
  })
}
export const getDynamicFormByPlanId = (row) => {
  return request({
    url: '/api/jida-meta/dynamic-form/listByPlanId',
    method: 'get',
    params: row
  })
}

export const getDynamicFormList = (row) => {
  return request({
    url: '/api/jida-meta/dynamic-form/list',
    method: 'get',
    params: row
  })
}
export const saveDynamicForm = (row) => {
  return request({
    url: '/api/jida-meta/dynamic-form/submit',
    method: 'post',
    data: row
  })
}
export const updateDynamicForm = (row) => {
  return request({
    url: '/api/jida-meta/dynamic-form/update',
    method: 'post',
    data: row
  })
}



export const updateMetaItem = (row) => {
  return request({
    url: '/api/jida-meta/meta-item/update',
    method: 'post',
    data: row
  })
}
export const removeMetaItem = (ids) => {
  return request({
    url: '/api/jida-meta/meta-item/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const addMetaItem = (row) => {
  return request({
    url: '/api/jida-meta/meta-item/save',
    method: 'post',
    data: row
  })
}
export const automatic = (params) => {
  return request({
    url: '/api/jida-meta/meta/automatic',
    method: 'get',
    params: params
  })
}
// export const getColumnsByTable = (params) => {
//   return request({
//     url: '/api/metadata-proxy/api/meta/atlasFinderClient/findByTableGuid',
//     method: 'get',
//     params: params
//   })
// }
export const getColumnsByTable = (params) => {
  return request({
    url: '/api/metadata-proxy/api/meta/atlasFinderClient/findTableByDatasourceCodeAndTableName',
    method: 'get',
    params: params
  })
}



export const getGlobalVariable = (params) => {
  return request({
    url: '/api/jida-meta/meta/global-variable',
    method: 'get',
    params: params
  })
}


export const getQuest = (url,params) => {
  return request({
    url:  url,
    method: 'get',
    data:params
  })
};
export const postQuest = (url,params) => {
  console.log(url,params)
  return request({
    url:  url,
    method: 'post',
    data:params
  })
};

export const saveModelBuild = (row) => {
  return request({
    url: '/api/jida-meta/model-build/save',
    method: 'post',
    data: row
  })
}
export const updateModelBuild = (row) => {
  return request({
    url: '/api/jida-meta/model-build/update',
    method: 'post',
    data: row
  })
}
export const getModelDetail = (row) => {
  return request({
    url: '/api/jida-meta/model-build/detail?',
    method: 'get',
    params: row
  })
}