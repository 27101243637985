<template>
  <div style="border: 1px solid #ccc;">
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="_ToolbarConfig"
        :mode="mode"
      />
      <Editor
        :style="{height: height}"
        style="overflow-y: hidden;"
        v-model="html"
        :defaultConfig="_EditorConfig"
        :mode="mode"
        @onCreated="onCreated"
        v-loading="uploading"
      />
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { uploadFile } from '@/api/resource/file.js'

import { htmlEncode , htmlDecode } from '@/util/util.js';

export default {
  name: 'RichText',
  components: {
    Editor, Toolbar
  },
  data(){
    let _this = this;
    return {
      uploading: false,
      editor: null,
      ToolbarConfig: {
        toolbarKeys: [
          'undo',
          'redo',
          '|',
          'headerSelect',
          'fontSize',
          'fontFamily',
          'lineHeight',
          '|',
          'bold',
          'underline',
          'italic',
          'clearStyle',
          'color',
          'bgColor',
          '|',
          {
            key: 'group-justify',
            title: '对齐',
            menuKeys: ['justifyLeft' , 'justifyRight' , 'justifyCenter' , 'justifyJustify']
          },
          {
            key: 'group-image',
            title: '图片',
            menuKeys: ['insertImage' , 'uploadImage']
          },
          {
            key: 'group-more-style',
            title: '插入',
            menuKeys: ['bulletedList' , 'numberedList' , 'todo']
          }
        ]
      },
      EditorConfig: {
        readOnly: this.isDisabled,
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            async customUpload(file , insertFn){
              let formData = new FormData();
              formData.append('file' , file);
              _this.uploading = true;
              uploadFile(formData).then(
                res => {
                  _this.uploading = false;
                  if(res.data && res.data.data){
                    let resData = res.data.data;
                    insertFn(resData.link , '');
                  }
                }
              )
            }
          }
        }
      },
      html: ''
    }
  },
  computed: {
    isDisabled(){
      if(this.disabled || this.disabled === 'disabled'){
        return true;
      }else{
        return false;
      }
    },
    _ToolbarConfig(){
      return Object.assign(this.ToolbarConfig , this.toolbarConfig);
    },
    _EditorConfig(){
      return Object.assign(this.EditorConfig , this.editorConfig);
    }
  },
  watch: {
    disabled(){
      if(this.editor){
        if(this.isDisabled){
          this.editor.disable();
        }else{
          this.editor.enable();
        }
      }
    },
    html(){
      let str = this.editor.getHtml();
      let temp = str.replace(/&/g,"&amp;");
      temp = temp.replace(/</g,"&lt;");
      temp = temp.replace(/>/g,"&gt;");
      temp = temp.replace(/\'/g,"&#39;");
      temp = temp.replace(/\"/g,"&quot;");
      if(this.value !== temp){
        this.$emit('input' , temp);
      }
    },
    value: {
      handler(val){
        let str = htmlDecode(val);
        if(str !== this.html){
          this.editor.setHtml(str);
          // this.html = str;
        }
        // if(this.editor){
        //   this.editor.setHtml(this.value);
        // }else{
        //   if(val !== this.html){
        //     this.html = val;
        //   }
        // }
        // let str = htmlDecode(val);
        // if(str !== this.html){
        //   this.html = str;
        // }
      }
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '250px'
    },
    disabled: {
      type: [Boolean , String],
      default: false
    },
    mode: {
      type: String,
      default: 'simple'
    },
    editorConfig: {
      type: Object,
      default(){
        return {}
      }
    },
    toolbarConfig: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  created(){
  },
  mounted(){
    console.log(this.value);
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
      this.html = this.value;
      // this.editor.setHtml(this.value);
      window.editor =  Object.seal(editor);
    }
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>