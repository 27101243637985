import { create , all } from 'mathjs'
const math = create(all , {
  number: 'BigNumber',
  precision: 32
})

class MathJS{
  num = 0;
  constructor(num){
    this.num = Number(math.bignumber(num));
  }
  add(x){
    this.num = MathJS.add(this.num , x);
    return this;
  }
  multiply(x){
    this.num = MathJS.multiply(this.num , x);
    return this;
  }
  subtract(x){
    this.num = MathJS.subtract(this.num , x);
    return this;
  }
  divide(x){
    this.num = MathJS.divide(this.num , x);
    return this;
  }
  done(){
    return Number(this.num);
  }
}

MathJS.add = (num1,num2) => {
  return Number(math.add(math.bignumber(num1),math.bignumber(num2)));
}
MathJS.multiply = (num1,num2) => {
  return Number(math.multiply(math.bignumber(num1),math.bignumber(num2)));
}
MathJS.subtract = (num1,num2) => {
  return Number(math.subtract(math.bignumber(num1),math.bignumber(num2)));
}
MathJS.divide = (num1,num2) => {
  return Number(math.divide(math.bignumber(num1),math.bignumber(num2)));
}

MathJS.chain = (num) => {
  return new MathJS(num);
}

export default MathJS;