import CustomPalette from "./CustomPalette";
import CustomRenderer from './CustomRenderer';


let __init__ = [];
let __exports__ = {};

let defaultExports = {
  customPalette: {
    __init__: ['customPalette'],
    customPalette: ['type', CustomPalette],
  },
  customRenderer: {
    __init__: ['customRenderer'],
    customRenderer: ['type', CustomRenderer]
  }
};


export default function(register){
  let plugins = Object.assign({} , defaultExports , register ? register.plugin : {});
  Object.keys(plugins).forEach(
    pluginName => {
      Object.keys(plugins[pluginName]).forEach(
        key => {
          if(key === '__init__'){
            let inits = plugins[pluginName][key];
            inits.forEach(
              item => {
                let index = __init__.indexOf(item);
                if(index > -1){
                  __init__.splice(index , 1);
                }
                __init__.push(item);
              }
            )
          }else{
            __exports__[key] = plugins[pluginName][key];
          }
        }
      )
    }
  )
  return {
    __init__: __init__,
    ...__exports__
  };
}
