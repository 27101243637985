import request from '@/router/axios';

export const listBuilding = (params) => {
  return request({
    url: '/api/park-spcod/mobcodappoint/listbuilding?current=1&size=10000000',
    method: 'get',
    params
  })
};
export const resOpenRoomCount = (params) => {
	return request({
	  url: '/api/park-spcod/mobcodappoint/resOpenRoomCount',
	  method: 'get'
	})
  };

// 罗列会议室
export const listRoom = (pageNo, pageSize,params) => {
	return request({
		url: '/api/park-spcod/mobcodappoint/listroom',
		method: 'get',
		params:{
		  ...params,
			'current':pageNo,
			'size':pageSize
		}
	})
}

//{roomId:,apptDay:}
export const listRoomAppoint = (pageNo, pageSize, params) => {
	return request({
		url: '/api/park-spcod/codappoint/listroomappoint',
		method: 'get',
		params: {
		  ...params,
		  'current':pageNo,
		  'size':pageSize
		}
	})
}
//{roomId:,apptDay:}
export const resAppoint = (params,appTime) => {
	return request({
		url: '/api/park-spcod/codroom/resAppoint',
		method: 'get',
		params: {
			roomId:params,
			appTime:appTime
		}
	})
}

//{roomId:,apptDay:}
export const resPrice = (roomId,unitHour,timeType) => {
	return request({
		url: '/api/park-spcod/codappoint/resPrice',
		method: 'get',
		params: {
			roomId:roomId,
			unitHour: unitHour,
			appTime:timeType

		}
	})
}



// 预留
export const submitPreAppoint = (params) => {
	return request({
		url: `/api/park-spcod/codappoint/submitpreappt`,
		method: 'post',
		data: {
		  ...params
		},
	})
}

// 取消预留
export const cancelPreAppoint = (id) => {
	return request({
		url: `/api/park-spcod/codappoint/cancelpreappt/` + id,
		method: 'post'
	})
}

//获得预约详情
export const getApptDetail = (id) => {
	return request({
	  url: '/api/park-spcod/codappoint/apptdetail/' + id,
	  method: 'get',
	  params: {
	  }
	})
  }


// #会议室预约
// payload:
// {"roomId":1,"beginTime":"2022-09-22 10:00:00","endTime":"2022-09-22 11:00:00","extraMemo":"额外要求","contactPhone":"联系人电话"}
export const addAppoint = (data) => {
	return request({
		url: `/api/park-spcod/mobcodappoint/addappoint`,
		method: 'post',
		data
	})
}

//获得最新的预订
export const getLastestAppoint = () => {
	return request({
		url: `/api/park-spcod/mobcodappoint/getlastestappoint`,
		method: 'get'
	})
}

//获得最新的预订
export const updateMeetingId = (id,meetingId) => {
	return request({
		url: `/api/park-spcod/codappoint/updatemeetingid/${id}?meetingId=${meetingId}`,
		method: 'get'
	})
}
