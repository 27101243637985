import Vue from 'vue';
import VueAxios from 'vue-axios';


import Element from 'element-ui';

import './import';


import RegisterGlobalComponents from '@/components/index';
import * as urls from '@/config/env';

import website from '@/config/website';
import crudCommon from '@/mixins/crud';

import installPlugins from '@/plugins/index';
import i18n from '@/lang';
import router from '@/router/router';
import axios from '@/router/axios';
import store from '@/store';

import AvueFormDesign from '@/components/dynamic/form';
Vue.use(AvueFormDesign)

import animate from 'animate.css'
Vue.use(animate)

window.axios = axios
import { 
  directive , 
  // clearDirective 
} from './directive';

// 注册bpmn组件
import BpmnInstall from '../bpmn/install.js';

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
});

BpmnInstall(Vue);

// 注册全局组件
RegisterGlobalComponents(Vue);

// 加载相关url地址
Object.keys(urls).forEach(key => {
  Vue.prototype[key] = urls[key];
});


// 注册全局crud驱动
window.$crudCommon = crudCommon;

// 加载website
Vue.prototype.website = website;

// 注入全局指令
Vue.use(directive);

// 加载Vue拓展
installPlugins(Vue);

// 清除Avue自带的权限校验指令
// clearDirective('permission' , window.AVUE.Crud , true);
// 重写Avue-crud getPermission方法
// window.AVUE.Crud.methods.getPermission = function(key, row, index){
//   return {key, row, index};
// }
// Vue.use(AVUE);
console.log(router)
router.beforeEach((to, from, next) => {
  const token = store.getters.token;
  if (token) {
    if (to.path === '/') {
      next('/dashboard/index')
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.use(window.AVUE, {
  size: 'small',
  tableSize: 'small',
  calcHeight: 50 + 54 + 15 + 16,
  i18n: (key, value) => i18n.t(key, value)
});



Vue.use(router);
Vue.use(VueAxios, axios);


import {loadStyle} from '@/util/util'
// 动态加载阿里云字体库
urls.iconfontVersion.forEach(ele => {
  loadStyle(urls.iconfontUrl.replace('$key', ele));
});

export default {
  router,
  store,
  i18n
}