const basePrefix = '/api/jida-data-asset/'
const prefix = basePrefix + 'label/';

export default {
  _basePrefix: basePrefix,
  _prefix: prefix,
  detail: prefix + 'detail',
  list: prefix + 'list',
  page: prefix + 'page',
  save: prefix + 'save',
  update: prefix + 'update',
  submit: prefix + 'submit',
  remove: prefix + 'remove',
  tree: prefix + 'tree'
}