import breadcrumb from './breadcrumb';
import copy from './copy';
import processCode from './process-code';

export default function installPlugins(Vue){
  [
    breadcrumb,
    copy,
    processCode
  ].forEach(
    item => {
      Vue.use(item);
    }
  )
}