<template>
  <basic-container class="h-100p" v-loading="!init">
    <template v-if="!(userInfo.companyId && userInfo.tenant_id == '000002')">
      <template v-if="!companyId">
        <el-tabs v-model="search.ACTIVENAME" @tab-click="tabsHandleClick">
          <el-tab-pane v-for="item of dictData.contractStatus" :label="item.dictValue" :name="item.dictKey"></el-tab-pane>
        </el-tabs>
      </template>
    </template>

    <avue-crud
      v-if="companyId || (!companyId && init)"
      ref="table"
      :data="tableData"
      :page.sync="page"
      :search.sync="search"
      :option="tableOption"
      :table-loading="tableLoading"
      :permission="permissionList"
      @on-load="onLoad()"
      @refresh-change="onLoad()"
      @search-change="searchChange"
    >
      <template slot="menuLeft" slot-scope="scope">
        <template v-if="!companyId && !(userInfo.companyId && userInfo.tenant_id == '000002')">
          <template v-if="website.permission">
            <!-- <el-button
              v-if="!companyId && permission.manage_add"
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="openDislog('add')"
              >新增合同
            </el-button> -->
            <el-dropdown size="small" v-if="!companyId && permission.manage_add" @command="(command) => openDislog('add', command)">
              <el-button size="small" type="primary"> 新增合同<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item of dictData.contractType" :command="item.dictKey">{{ item.dictValue }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <el-dropdown size="small" @command="(command) => openDislog('add', command)">
              <el-button size="small" type="primary"> 新增合同<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item of dictData.contractType" :command="item.dictKey">{{ item.dictValue }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="openDislog('add')"
              >新增合同
            </el-button> -->
          </template>
        </template>
        <el-button size="small" icon="el-icon-download" @click="exportContract()">导出 </el-button>
      </template>
      <template slot="menu" slot-scope="scope">
        <template v-if="website.permission">
          <el-button type="text" size="small" icon="el-icon-view" v-if="permission.manage_view" @click="viewRow(scope.row)">查看 </el-button>
          <el-button type="text" size="small" icon="el-icon-view" v-if="permission.manage_replenish" @click="supplementary(scope.row)"
            >补充协议({{ scope.row.contractSupplementaryNum }})
          </el-button>
          <el-button
            v-if="
              (scope.row.approvalStatus === 1 || scope.row.approvalStatus === 4 || scope.row.approvalStatus === 5) &&
              !isCompany &&
              permission.manage_sub_approve
            "
            type="text"
            size="small"
            icon="el-icon-circle-check"
            @click="startFlow(scope.row)"
            >提交审批
          </el-button>
          <el-button
            v-if="scope.row.processInstanceId && permission.manage_approve_recode && !website.permission"
            type="text"
            size="small"
            icon="el-icon-circle-check"
            @click="showTaskInstance(scope.row)"
            >审批记录
          </el-button>
        </template>
        <template v-else>
          <el-button type="text" size="small" icon="el-icon-view" @click="viewRow(scope.row)">查看 </el-button>
          <el-button type="text" size="small" icon="el-icon-view" @click="supplementary(scope.row)" v-if="!isCompany"
            >补充协议({{ scope.row.contractSupplementaryNum }})
          </el-button>
          <el-button
            v-if="(scope.row.approvalStatus === 1 || scope.row.approvalStatus === 4 || scope.row.approvalStatus === 5) && !isCompany"
            type="text"
            size="small"
            icon="el-icon-circle-check"
            @click="startFlow(scope.row)"
            >提交审批
          </el-button>
          <el-button v-if="scope.row.processInstanceId" type="text" size="small" icon="el-icon-circle-check" @click="showTaskInstance(scope.row)"
            >审批记录
          </el-button>
        </template>
      </template>
      <template slot-scope="{ disabled, size }" slot="areaSearch">
        <el-col class="pad-0" :span="11">
          <el-input :disabled="disabled" :size="size" type="number" v-model="search.startArea"></el-input>
        </el-col>
        <el-col class="pad-0 text-center" :span="2"> - </el-col>
        <el-col class="pad-0" :span="11">
          <el-input :disabled="disabled" :size="size" :min="search.startArea" type="number" v-model="search.endArea"></el-input>
        </el-col>
      </template>
      <template slot-scope="{ disabled, size }" slot="companyIdSearch">
        <model-search-select class="dis-b" v-model="search.companyId" model="v_wp_company_his_stat" labelKey="NAME" valueKey="company_id">
        </model-search-select>
        <!-- <el-select
          v-model="search.companyId"
          filterable
          :disabled="disabled"
          :size="size"
          remote
          clearable
          placeholder="输入关键词搜索"
          :remote-method="
            (query) => {
              searchModel(query, 'v_wp_company_his_stat', 'enterprise');
            }
          "
          :loading="loading.enterpriseLoading"
        >
          <el-option
            v-for="item in dictData.enterprise"
            :key="item.id"
            :label="item.NAME"
            :value="item.company_id"
          >
          </el-option>
        </el-select> -->
      </template>
      <template slot-scope="{ disabled, size }" slot="signedDateSearch">
        <el-date-picker
          v-model="search.signedDate"
          type="daterange"
          range-separator="至"
          unlink-panels
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </template>
      <template slot-scope="{ disabled, size }" slot="subPartIdSearch">
        <model-search-select v-model="search.subPartId" model="wp_part" @change="() => clearSearchValue(3)"></model-search-select>
      </template>
      <template slot-scope="{ disabled, size }" slot="buildingIdSearch">
        <model-search-select
          v-model="search.buildingId"
          model="wp_building"
          :notInitLoadData="true"
          :params="{ sub_part_id: search.subPartId }"
          @change="() => clearSearchValue(2)"
        ></model-search-select>
      </template>
      <template slot-scope="{ disabled, size }" slot="roomIdSearch">
        <model-search-select
          v-model="search.roomId"
          model="wp_room"
          :notInitLoadData="true"
          :params="{ floor_id: search.floorId, building_id: search.buildingId }"
        ></model-search-select>
      </template>
    </avue-crud>
    <ManageForm
      v-if="dialogVisible"
      @close="dialogVisible = false"
      @save="
        dialogVisible = false
        onLoad()
      "
      :type="formType"
      :id="currentRow ? currentRow.id : null"
      :model="model"
      :contractType="contractType"
    ></ManageForm>
    <el-drawer
      :title="drawerTypes[drawerType].title"
      :visible.sync="drawer"
      :size="drawerTypes[drawerType].size"
      class="manageDetail"
      append-to-body
      :withHeader="false"
    >
      <el-container v-if="drawer" class="h-100p">
        <el-header class="bor-b" height="50px">
          <el-page-header class="lh-50" @back="drawerBack()" :content="drawerTypes[drawerType].title"></el-page-header>
        </el-header>
        <el-container style="height: calc(100% - 50px)">
          <el-main>
            <template v-if="drawer && currentRow && currentRow.id">
              <ManageDetail v-show="drawer && drawerType === 1" :id="currentRow.id"></ManageDetail>
              <CollectionPlans v-show="drawer && drawerType === 2" :id="currentRow.id" :row="currentRow"></CollectionPlans>
              <div v-if="drawer && drawerType === 3 && currentRow" class="pad-15">
                <TodoTask :id="currentRow.processInstanceId"></TodoTask>
              </div>
            </template>
          </el-main>
          <el-footer v-if="!companyId && drawerType === 1" class="bor-t lh-49" height="50px">
            <div class="pull-left w-30p">
              <template v-if="drawerType === 1">
                <el-button v-if="getBtnDisplay('cancellation') && !isCompany" @click="openFormDialog(1)" size="small" type="danger"
                  >作废合同</el-button
                >
                <el-button v-if="getBtnDisplay('cancelRent') && !isCompany" @click="openFormDialog(2)" size="small" type="danger">退租</el-button>
              </template>
            </div>
            <div class="pull-right w-70p text-right">
              <template v-if="drawerType === 1">
                <el-button
                  size="small"
                  v-if="getBtnDisplay('edit') && !isCompany"
                  @click="
                    drawer = false
                    openDislog('edit')
                  "
                  >编辑</el-button
                >
                <!-- <el-button size="small" @click=" drawerType === 3">上传附件</el-button> -->
                <el-button
                  size="small"
                  v-if="getBtnDisplay('relet') && !isCompany"
                  @click="
                    drawer = false
                    openDislog('relet')
                  "
                  >续租</el-button
                >
                <el-button size="small" @click="drawerType = 2">收款计划</el-button>
                <!-- <el-button
                  size="small"
                  v-if="!isCompany"
                  @click="
                    drawer = false;
                    openDislog('template');
                  "
                  >以此模板新增</el-button
                > -->
                <el-button size="small" v-if="getBtnDisplay('close') && !isCompany" type="primary" @click="closeContract">完结</el-button>
                <el-button size="small" icon="el-icon-view" v-if="currentRow && currentRow.wordAttachmentId" @click="viewContract"
                  >查看合同</el-button
                >
                <el-button size="small" icon="el-icon-download" v-if="currentRow && currentRow.wordAttachmentId" @click="downloadContract"
                  >下载合同</el-button
                >
              </template>
            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-drawer>
  </basic-container>
</template>
<script>
import dayjs from "dayjs"
import ManageForm from "./manageForm.vue"
import website from "@/config/website"
import ManageDetail from "./manageDetail.vue"
import CollectionPlans from "./collectionPlans.vue"
import { mapGetters, mapState } from "vuex"

import { getMetaListUrl, getMetaCommonList, getPicListByIds } from "@/api/busiMode/metaCommon"
import { getList, detail, cancelRent, cancellation, getListByCompany, close, APIURL, queryContractAttachment } from "@/api/contract/index.js"
import * as dictService from "@/api/system/dictbiz"
import { startFlow } from "@/api/flow/flow.js"
import TodoTask from "@/views/flow/todo-task.vue"

import { downFileByResult } from "@/api/file"
import cacheSearch from "@/mixins/cacheSearch"

export default {
  components: { ManageForm, ManageDetail, CollectionPlans, TodoTask },
  mixins: [cacheSearch],
  data() {
    let modelPrefix = "wisdom_park."
    return {
      drawerTypes: {
        1: {
          size: "75%",
          title: "合同详情",
          back: true,
        },
        2: {
          size: "75%",
          title: "收款计划和账单",
          back: 1,
        },
        3: {
          size: 300,
          title: "审批记录",
          back: true,
        },
      },
      contractType: "",
      init: this.companyId ? true : false,
      activeName: "all",
      btn: {
        cancelRent: [2],
        cancellation: [2],
        edit: [1],
        relet: [2],
        close: [4],
      },
      tableLoading: false,
      drawer: false,
      drawerType: 1,
      tableData: [],
      search: {
        contractAttribute: "",
        buildingId: "",
        floorId: "",
        roomId: "",
        companyId: "",
        startArea: null,
        endArea: null,
        signedDate: "",
        subPartId: "",
        ACTIVENAME: "all",
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      dialogVisible: false,
      currentRow: null,
      formType: "",
      tableOption: {
        height: "auto",
        searchMenuSpan: 24,
        searchMenuPosition: "right",
        searchShow: this.companyId ? false : true,
        searchShowBtn: this.companyId ? false : true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        border: true,
        menuWidth: 220,
        searchIndex: 3,
        searchIcon: true,
        column: [
          {
            label: "合同编号",
            prop: "code",
            width: 250,
            search: true,
            searchSpan: 6,
          },
          {
            label: "租客名称",
            prop: "rentersName",
            width: 300,
          },
          {
            label: "房间名称",
            prop: "roomName",
            width: 300,
          },
          {
            label: "合同属性",
            prop: "contractAttribute",
            dicUrl: dictService.getUrl("ContractAttribute"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            search: true,
            searchSpan: 6,
            type: "select",
            width: 80,
          },
          // {
          //   label: "签约状态",
          //   prop: "signStatus",
          //   dicUrl: dictService.getUrl("SignStatus"),
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   dataType: "number",
          //   type: "select",
          //   width: 80,
          //   search: true,
          //   searchSpan: 6,
          // },
          {
            label: "审批状态",
            prop: "approvalStatus",
            dicUrl: dictService.getUrl("ApprovalStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            width: 100,
          },
          {
            label: "执行状态",
            prop: "executeStatus",
            dicUrl: dictService.getUrl("ContractExecuteStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            width: 80,
            type: "select",
            search: true,
            searchSpan: 6,
          },
          {
            label: "合同状态",
            prop: "contractStatus",
            dicUrl: dictService.getUrl("ContractStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            type: "select",
            search: true,
            searchSpan: 6,
          },
          {
            label: "租客名称",
            prop: "companyId",
            hide: true,
            showColumn: false,
            search: true,
            searchSpan: 6,
            type: "number",
          },
          {
            label: "所属地块",
            prop: "subPartId",
            // dicUrl: '/api/jida-meta/common-ops/list/wisdom_park.wp_part',
            // props: {
            //   label: "name",
            //   value: "id",
            //   res: 'data.records'
            // },
            // dicMethod: 'post',
            display: false,
            hide: true,
            showColumn: false,
            search: true,
            searchSpan: 6,
            type: "select",
            width: 80,
          },
          {
            label: "所属楼宇",
            prop: "buildingId",
            hide: true,
            showColumn: false,
            // dicUrl: getMetaListUrl(modelPrefix + "wp_building"),
            // props: {
            //   label: "name",
            //   value: "id",
            //   res: "data.records",
            // },
            // dicQuery: {
            //   current: 1,
            //   size: 500,
            // },
            // dicMethod: "post",
            search: true,
            searchSpan: 6,
            type: "select",
          },
          {
            label: "所属楼层",
            prop: "floorId",
            hide: true,
            showColumn: false,
            search: true,
            searchSpan: 6,
            type: "select",
            dicData: [],
            props: {
              label: "name",
              value: "id",
            },
          },
          {
            label: "所属房间",
            prop: "roomId",
            // dicUrl: '/api/jida-meta/common-ops/list/wisdom_park.wp_part',
            // props: {
            //   label: "name",
            //   value: "id",
            //   res: 'data.records'
            // },
            // dicMethod: 'post',
            display: false,
            hide: true,
            showColumn: false,
            search: true,
            searchSpan: 6,
            type: "select",
            width: 80,
          },
          {
            label: "面积",
            prop: "area",
            hide: true,
            showColumn: false,
            search: true,
            searchSpan: 6,
            type: "number",
          },
          {
            label: "开始日期",
            prop: "startDate",
            type: "date",
            format: "yyyy年MM月dd日",
            width: 120,
            search: true,
            searchSpan: 6,
            valueFormat: "yyyy-MM-dd 00:00:00",
            pickerOptions: {
              disabledDate: (time) => {
                if (this.search.endDate) {
                  return time.getTime() > dayjs(this.search.endDate).valueOf()
                }
                return false
              },
            },
          },
          {
            label: "结束日期",
            prop: "endDate",
            type: "date",
            format: "yyyy年MM月dd日",
            width: 120,
            search: true,
            searchSpan: 6,
            valueFormat: "yyyy-MM-dd 23:59:59",
            change: ({ column, value }) => {
              if (value && this.search.startDate) {
                if (dayjs(value).valueOf() < dayjs(this.search.startDate).valueOf()) {
                  this.search.startDate = null
                }
              }
            },
          },
          {
            label: "签订日期",
            prop: "signedDate",
            type: "date",
            format: "yyyy年MM月dd日",
            width: 120,
            search: true,
            searchSpan: 12,
            valueFormat: "yyyy-MM-dd HH:mm:ss",
          },
          {
            label: "租赁面积(㎡)",
            prop: "rentArea",
            width: 90,
          },
          // {
          //   label: "预警状态",
          //   prop: "tenant9",
          //   width: 80,
          // },
        ],
      },
      model: modelPrefix + "wp_contract",
      floorModel: modelPrefix + "wp_floor",
      loading: {
        enterpriseLoading: false,
      },
      dictData: {
        enterprise: [],
        contractStatus: [],
        contractType: [],
      },
      linkReplace: {
        source: "10.147.17.103",
        target: "10.100.170.103",
      },
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    // 获得用户信息来区分角色
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.manage_add, !website.permission),
        viewBtn: this.vaildData(this.permission.manage_view, !website.permission),
        delBtn: this.vaildData(this.permission.manage_delete, !website.permission),
        editBtn: this.vaildData(this.permission.manage_edit, !website.permission),
      }
    },
    isCompany() {
      return this.userInfo.tenant_id !== "000000" && this.userInfo.companyId
    },
    reletBtn() {
      if (this.currentRow && this.currentRow.executeStatus === 2) {
        return true
      }
      // if (
      //   this.currentRow &&
      //   this.currentRow.startDate &&
      //   this.currentRow.rentPeriod
      // ) {
      //   let renewalApply = this.currentRow.renewalApply || 1;
      //   let renewalDate = dayjs(this.currentRow.startDate)
      //       .add(this.currentRow.rentPeriod, "month")
      //       .subtract(renewalApply, "month")
      //       .valueOf(),
      //     now_time = dayjs()
      //       .hour(0)
      //       .minute(0)
      //       .second(0)
      //       .millisecond(0)
      //       .valueOf();
      //   if (now_time >= renewalDate) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
      return false
    },
    cancellationBtn() {
      if (this.currentRow && this.currentRow.executeStatus === 1) {
        return true
        // let start_time = dayjs(this.currentRow.startDate).valueOf(),
        //   now_time = dayjs()
        //     .hour(0)
        //     .minute(0)
        //     .second(0)
        //     .millisecond(0)
        //     .valueOf();
        // if (now_time < start_time) {
        //   return true;
        // }
        // return false;
      }
      return false
    },
    cancelRentBtn() {
      if (this.currentRow && (this.currentRow.executeStatus === 1 || this.currentRow.executeStatus === 2)) {
        return true
      }
      // if (this.currentRow && this.currentRow.startDate) {
      //   let start_time = dayjs(this.currentRow.startDate).valueOf(),
      //     now_time = dayjs()
      //       .hour(0)
      //       .minute(0)
      //       .second(0)
      //       .millisecond(0)
      //       .valueOf();
      //   if (now_time >= start_time) {
      //     return true;
      //   }
      //   return false;
      // }
      return false
    },
  },
  watch: {
    "search.buildingId": {
      immediate: true,
      handler(val) {
        this.setSeachFloorData()
      },
    },
    "search.floorId": {
      immediate: true,
      handler() {
        this.clearSearchValue(1)
      },
    },
  },
  mounted() {
    if (this.userInfo.companyId && this.userInfo.tenant_id == "000002") {
      this.tableOption.searchShow = false
      this.tableOption.searchShowBtn = false
    }
    dictService
      .getDictionary({
        code: "ContractType",
      })
      .then((res) => {
        this.dictData.contractType = res.data.data || []
      })
    if (!this.companyId) {
      dictService
        .getDictionary({
          code: "ApprovalStatus",
        })
        .then((res) => {
          this.dictData.contractStatus = [{ dictValue: "全部", dictKey: "all" }].concat(res.data.data || [])
          this.init = true
        })
    }
    this.searchModel("", "v_wp_company_his_stat", "enterprise")
  },
  created() {},
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  methods: {
    exportContract() {
      downFileByResult(APIURL.exportContract, "get", this.getQueryParams(), "合同.xls")
    },
    clearSearchValue(len) {
      ;["roomId", "floorId", "buildingId"].forEach((item, index) => {
        if (index < len) {
          this.search[item] = ""
        }
      })
    },
    supplementary(row) {
      this.$router.push("/contract/supplementary/" + row.id)
    },
    downloadContract() {
      queryContractAttachment(this.currentRow.id).then((r) => {
        if (r.data.data.wordAttachmentId) {
          getPicListByIds([r.data.data.wordAttachmentId]).then((res) => {
            console.log(res)
            if (res.data.data && res.data.data[0]) {
              let file = res.data.data[0]
              this.downFile(file.link, r.data.data.code + "_" + r.data.data.rentersName + "." + res.data.data[0].extension)
            }
          })
        }
      })
    },
    viewContract() {
      queryContractAttachment(this.currentRow.id).then((r) => {
        if (r.data.data.pdfAttachmentId) {
          getPicListByIds([r.data.data.pdfAttachmentId]).then((res) => {
            if (res.data.data && res.data.data[0]) {
              let file = res.data.data[0]
              window.open(`/pdfjs/web/viewer.html?file=${encodeURIComponent(file.link)}`)
            }
          })
        }
      })
    },
    showTaskInstance(row) {
      this.currentRow = row
      this.drawerType = 3
      this.drawer = true
    },
    startFlow(row) {
      this.$confirm("确定将该合同提交审批?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return startFlow(row.id, this.$PCode.BIZ.CONTRACT, this.$PCode.ACTIONS.SUBMIT, {
            companyName: row.companyName,
            roomName: row.roomName,
          })
        })
        .then(() => {
          this.onLoad()
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    tabsHandleClick() {
      this.page.currentPage = 1
      this.onLoad()
    },
    getBtnDisplay(code) {
      if (!this.currentRow) {
        return false
      }
      let btnShow = this[`${code}Btn`]
      if (btnShow === false) {
        return false
      }
      return this.btn[code].includes(this.currentRow.contractStatus)
    },
    searchChange(params, done) {
      if (params && params.startArea && params.endArea && Number(params.startArea) >= Number(params.endArea)) {
        this.$message.warning("开始面积应小于结束面积")
        done()
        return false
      }
      this.page.currentPage = 1
      this.onLoad(params)
        .then((res) => {
          done()
        })
        .catch((e) => {
          done()
        })
    },
    getQueryParams(params) {
      params = params || this.search
      let searchBody = {
        ...params,
      }

      if (this.companyId) {
        searchBody["companyId"] = this.companyId
      }
      if (this.search.ACTIVENAME !== "all") {
        searchBody["approvalStatus"] = this.search.ACTIVENAME
      }

      if (searchBody["signedDate"] && Array.isArray(searchBody["signedDate"])) {
        if (searchBody["signedDate"][0]) {
          searchBody["signStartDate"] = `${searchBody["signedDate"][0]} 00:00:00`
        }
        if (searchBody["signedDate"][1]) {
          searchBody["signEndDate"] = `${searchBody["signedDate"][1]} 23:59:59`
        }
      }

      delete searchBody["signedDate"]
      delete searchBody["ACTIVENAME"]
      return searchBody
    },
    onLoad() {
      this.tableLoading = true
      this.tableData = []
      let searchBody = this.getQueryParams()

      if (this.userInfo.companyId && this.userInfo.tenant_id != "000000") {
        return getListByCompany({
          current: this.page.currentPage,
          size: this.page.pageSize,
          ...searchBody,
        })
          .then((res) => {
            this.tableLoading = false
            if (res.data && res.data.code === 200) {
              this.tableData = res.data.data.records
              this.page.total = res.data.data.total
            } else {
              this.tableData = []
              this.page.total = 0
            }
          })
          .catch((e) => {
            this.tableLoading = false
          })
      } else {
        return getList({
          current: this.page.currentPage,
          size: this.page.pageSize,
          ...searchBody,
        })
          .then((res) => {
            this.tableLoading = false
            if (res.data && res.data.code === 200) {
              this.tableData = res.data.data.records
              this.page.total = res.data.data.total
            } else {
              this.tableData = []
              this.page.total = 0
            }
          })
          .catch((e) => {
            this.tableLoading = false
          })
      }
    },
    getMetaCommonList(code, prop, params) {
      return getMetaCommonList(`wisdom_park.${code}`, {
        current: 1,
        size: 500,
        ...params,
      }).then((res) => {
        if (res.data && res.data.data && prop) {
          this.dictData[prop] = res.data.data.records
        }
        return res
      })
    },
    searchModel(queryStr, modelCode, prop, searchFieldName) {
      let params = {}
      if (queryStr) {
        params = {
          wheres: [{ field: searchFieldName || "name", op: "LIKE", value: queryStr }],
        }
      }
      this.loading[prop + "Loading"] = true
      this.getMetaCommonList(modelCode, prop, params).then((res) => {
        this.loading[prop + "Loading"] = false
      })
    },
    setSeachFloorData() {
      let columnIndex = -1
      this.tableOption.column.forEach((item, index) => {
        if (item.prop === "floorId") {
          columnIndex = index
        }
      })
      if (this.search.buildingId) {
        getMetaCommonList(this.floorModel, {
          wheres: [{ field: "building_id", value: this.search.buildingId }],
          orders: [{ field: "code+1000", sort: "DESC" }],
          current: 1,
          size: 500,
        }).then((res) => {
          this.tableOption.column[columnIndex].dicData = (res.data.data.records || []).map((item) => {
            return {
              ...item,
              name: item.name ? item.name : `${item.code}层`,
            }
          })
        })
      } else {
        this.tableOption.column[columnIndex].dicData = []
      }
    },
    openDislog(type, contractType) {
      this.formType = type
      this.contractType = contractType || ""
      if (type === "add") {
        this.currentRow = null
      }
      this.dialogVisible = true
    },
    viewRow(row) {
      this.currentRow = row
      this.drawerType = 1
      this.drawer = true
    },
    drawerBack() {
      let back = true
      if (this.drawerTypes[this.drawerType]) {
        if (this.drawerTypes[this.drawerType].back !== undefined) {
          back = this.drawerTypes[this.drawerType].back
        }
      }
      if (back === true) {
        this.drawer = false
      } else {
        if (!isNaN(Number(back))) {
          this.drawerType = back
        } else {
          this.drawer = false
        }
      }
    },
    closeContract() {
      this.$confirm("确定要完结该合同吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          instance.confirmButtonLoading = false
          if (action === "confirm") {
            instance.confirmButtonLoading = true
            close({
              contractId: this.currentRow.id,
            })
              .then((res) => {
                this.onLoad(this.search)
                this.$message({
                  type: "success",
                  message: "操作成功!",
                })
                instance.confirmButtonLoading = false
                done()
              })
              .catch((e) => {
                instance.confirmButtonLoading = false
              })
          } else {
            done()
          }
        },
      })
    },
    openFormDialog(type) {
      let typeLabel = type === 1 ? "作废" : "退租"
      let label = `${typeLabel}原因`
      this.$prompt(label, typeLabel, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: (val) => {
          if (!val) {
            return `请填写${typeLabel}原因`
          }
          return true
        },
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true
            let fn = type === 1 ? cancellation : type === 2 ? cancelRent : null
            let body = null,
              value = instance.inputValue
            if (type === 1) {
              body = {
                cancellationReason: value,
              }
            }
            if (type === 2) {
              body = {
                cancelRentReason: value,
              }
            }
            if (fn && body) {
              fn({
                contractId: this.currentRow.id,
                ...body,
              })
                .then((res) => {
                  this.onLoad(this.search)
                  this.$message({
                    message: `${typeLabel}成功`,
                    type: "success",
                  })
                  instance.confirmButtonLoading = false
                  done()
                })
                .catch((e) => {
                  instance.confirmButtonLoading = false
                })
            }
          } else {
            done()
          }
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.manageDetail {
  /deep/.el-drawer__header {
    margin-bottom: 0;
  }
  /deep/.el-drawer__body {
    padding-top: 0;
  }
}
</style>
