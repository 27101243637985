import request from '@/router/axios';
import apiUrl from './apiUrl';
import { downloadFile } from '@/view-module/scheduler/common/download';


export default {

    apiUrl: apiUrl.catalog,

    getList(params){
        return request({
            method: 'get',
            params: params,
            url: apiUrl.catalog.list
        })
    },
    getDetail(params){
        return request({
          url: apiUrl.catalog.detail,
          method: 'get',
          params: params
        })
    },
    save(data){
        return request({
            method: 'post',
            data: data,
            url: apiUrl.catalog.save
        })
    },
    update(data){
        return request({
            method: 'post',
            data: data,
            url: apiUrl.catalog.update
        })
    },
    remove(data){
        return request({
            method: 'post',
            params: data,
            url: apiUrl.catalog.remove
        })
    },
    exportExcel(params){
        return downloadFile(apiUrl.catalog.exportExcel , params);
    },
    importExcel(data){
        return request({
            method: 'post',
            data: data,
            url: apiUrl.catalog.importExcel
        })
    },
    getCatalogCount(){
        return request({
            method: 'get',
            url: apiUrl.catalog.count
        })
    },

    exportExcelAll(params){
        return downloadFile(apiUrl.catalog.exportExcelAll , params);    
    }

}