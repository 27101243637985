import request from '@/router/axios';

import apiUrl from './apiUrl';

export default {
  catalogTop10(params){
    return request({
      method: 'get',
      params: params,
      url: apiUrl.catalog.top10
    })
  },
  totalAndIncrease(params){
    return request({
      method: 'get',
      params: params,
      url: apiUrl.index.totalAndIncrease
    })
  },
  dataRowProportion(params){
    return request({
      method: 'get',
      params: params,
      url: apiUrl.index.dataRowProportion
    })
  },
  dailyAndTotalCount(params){
    return request({
      method: 'get',
      params: params,
      url: apiUrl.index.dailyAndTotalCount
    })
  },
  taskCount(params){
    return request({
      method: 'get',
      params: params,
      url: apiUrl.index.taskCount
    })
  }
}

