import request from '@/router/axios';

export const syncMeetRooms = () => {
  return request({
    url: '/api/park-company/hk/syncMeetRooms',
    method: 'get'
  })
};

//创建会议
export const createMeeting = (d) => {
  return request({
    url: '/api/park-company/hk/createMeeting',
    method: 'post',
    data:d
  })
};

//更新会议
export const updateMeeting = (d) => {
  return request({
    url: '/api/park-company/hk/updateMeeting',
    method: 'post',
    data:d
  })
};

//更新会议状态
export const statusMeeting = (d) => {
  return request({
    url: '/api/park-company/hk/statusMeeting',
    method: 'post',
    data:d
  })
};

//添加用户账号到海康认证
//{"vipName":"JJJ","vipPhone":"13610001001","iconFile":"1610846260720308226"}
export const addSpcodAccountHk = (d) => {
  return request({
    url: '/api/park-company/hk/addSpcodAccountHk',
    method: 'post',
    data:d
  })
};

//添加人脸识别到
//{"roomId": "1602914378188443649","contactPhone": "13888889999","beginTime": "2023-01-05 09:15:00","endTime": "2023-01-05 10:00:00"}
export const addMeetDoorAuth = (d) => {
  return request({
    url: '/api/park-company/hk/addMeetDoorAuth',
    method: 'post',
    data:d
  })
};

//{"roomId": "1602914378188443649","contactPhone": "13888889999"}
export const deleteMeetDoorAuth = (d) => {
  return request({
    url: '/api/park-company/hk/deleteMeetDoorAuth',
    method: 'post',
    data:d
  })
};
