<template>
  <div class="mapWrap">
      <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  name: "mapIndex",
  data() {
    return {};
  },
  created() {
      
  },
  mounted() {},
  computed: {},
  props: [],
  methods: {},
};
</script>
