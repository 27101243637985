<template>
  <div>
    <template v-if="!currentRow.id">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="pad-l-5">
        <el-tab-pane label="全部" name="1"></el-tab-pane>

        <el-tab-pane label="待审批" name="2"></el-tab-pane>
        <el-tab-pane label="审批通过" name="3"></el-tab-pane>
        <el-tab-pane label="审批未通过" name="4"></el-tab-pane>
      </el-tabs>
    </template>

    <basic-container>
      <avue-crud
        :option="option"
        :table-loading="loading"
        :data="data"
        ref="crud"
        v-model="form"
        :page.sync="page"
        @selection-change="selectionChange"
        @refresh-change="refreshChange"
        @on-load="onLoad"
      >
        <template slot="menuLeft" slot-scope="scope" v-if="!currentRow.id">
          <el-button
            v-if="!register"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="fkyyy(0)"
            >访客预约
          </el-button>
          <el-button
            v-if="register"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="fkyyy(1)"
            >访客登记
          </el-button>
          <el-input
            v-model="searchText"
            size="small"
            placeholder="请输入访客姓名"
            type="text"
            style="width: 150px"
            clearable
            @clear="clearSearch"
          >
          </el-input>

          <el-cascader
            :options="options"
            size="small"
            :props="defaultProps"
            filterable
            clearable
            placeholder="请选择组织机构"
            ref="tree"
            class="mar-l-5"
            v-model="searchCompanyId"
            @change="getFullName"
            style="width: 250px"
          ></el-cascader>
          <el-button
            class="mar-l-5"
            icon="el-icon-search"
            type="primary"
            :loading="downLoading"
            @click="refreshChange"
            size="small"
            >查询</el-button
          >
          <el-button
            class="mar-l-5"
            icon="el-icon-delete"
            @click="clearSearch"
            size="small"
            >清空</el-button
          >
          <el-button
            class="mar-l-5"
            icon="el-icon-download"
            :loading="downLoading"
            @click="getMetaCommonListExport"
            size="small"
            >导出</el-button
          >
        </template>
        <template slot-scope="scope" slot="menu">
          <el-button
            type="text"
            size="small"
            icon="el-icon-view"
            class="none-border"
            @click="edit(scope.row)"
            >查看
          </el-button>

          <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            class="none-border"
            @click="rowDel(scope.row)"
            >删除
          </el-button>
          <el-button
            type="text"
            size="small"
            icon="el-icon-circle-check"
            @click="showTaskInstance(scope.row)"
            >审批记录
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
    <el-drawer
      :visible.sync="todoDrawer"
      :size="400"
      :withHeader="false"
      append-to-body
    >
      <el-container v-if="todoDrawer" class="h-100p">
        <el-header class="bor-b" height="50px">
          <el-page-header
            class="lh-50"
            @back="todoDrawer = false"
            content="审批记录"
          ></el-page-header>
        </el-header>
        <el-main class="pad-15">
          <TodoTask :id="todoDrawerRow.process_instance_id"></TodoTask>
        </el-main>
      </el-container>
    </el-drawer>
  </div>
</template>

<script>
import {
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonListExport,
} from "@/api/busiMode/metaCommon";
import standardService from "@/api/metadata/standard";
import { mapGetters, mapState } from "vuex";
import TodoTask from "@/views/flow/todo-task.vue";
import { getDeptTree, getDeptLazyTree } from "@/api/system/dept";
import { getDictionary } from "@/api/system/dict";

export default {
  components: { TodoTask },
  data() {
    return {
      searchText: "",
      form: {},
      disShowList: [],
      selectionList: [],
      loading: true,
      dialogVisible: false,
      detailDia: false,
      query: {},
      dialogType: "",
      size: "60%",
      title: "",
      searchCompanyId: [],
      activeName: "2",
      todoDrawer: false,
      downLoading: false,
      options: [],
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      defaultProps: {
        lazy: true,
        label: "title",
        value: "value",
        lazyLoad: (node, resolve) => {
          const parentId = node.level === 0 ? 0 : node.data.id;
          getDeptLazyTree(parentId).then((res) => {
            let arr = [];
            res.data.data.forEach((item) => {
              if (this.disShowList.indexOf(item.id) == -1) {
                arr.push({ ...item, leaf: !item.hasChildren });
              }
            });
            resolve(arr);
          });
        },
      },
      option: {
        height: "auto",
        dialogWidth: 900,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        // selection: true,
        labelWidth: 120,
        menuWidth: 200,

        viewBtn: false,
        dialogClickModal: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        cancelBtn: false,
        column: [
          {
            width: 120,
            overHidden: true,
            label: "被访人",
            prop: "respondent_name",
            hide: false,
          },
          {
            width: 300,
            overHidden: true,
            label: "被访公司",
            prop: "company_name",
            hide: false,
          },

          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "来访人姓名",
            prop: "visitor_name",
            hide: false,
          },
          {
            width: 150,
            overHidden: true,
            label: "来访人电话",
            prop: "contact",
          },
          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "来访人证件类型",
            prop: "id_card_type",
            hide: false,
            dicData: [
              { label: "居民身份证", value: 1 },
              { label: "士官证", value: 2 },
              { label: "学生证", value: 3 },
              { label: "驾驶证", value: 4 },
              { label: "护照", value: 5 },
              { label: "港澳通行证", value: 6 },
            ],
          },
          {
            width: 150,
            overHidden: true,
            align: "left",
            label: "来访人证件号码",
            prop: "id_card_no",
            hide: false,
          },
          {
            width: 150,
            overHidden: true,
            align: "left",
            label: "来访人单位",
            prop: "visitor_company",
            hide: false,
          },

          {
            width: 120,
            overHidden: true,
            label: "来访时间",
            prop: "visitor_time",
            type: "date",
            format: "yyyy年MM月dd日",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
          },
          {
            width: 220,
            overHidden: true,
            label: "访问事由",
            prop: "reason",
            hide: false,
          },
          // {
          //   width: 120,
          //   overHidden: true,
          //   label: "离开时间",
          //   prop: "departure_time",
          //   type: "date",
          //   format: "yyyy年MM月dd日",
          //   valueFormat: "yyyy-MM-dd HH:mm:ss",
          // },
          {
            width: 120,
            overHidden: true,
            label: "通行方式",
            prop: "in_out_type",
            dicData: [
              { label: "二维码", value: 0 },
              { label: "卡片", value: 1 },
              { label: "人脸识别", value: 2 },
              { label: "一键开闸", value: 3 },
            ],
          },
          {
            width: 120,
            overHidden: true,
            label: "审核状态",
            prop: "approval_status",
            dicData: [
              { label: "待提交", value: 1 },
              { label: "审批中", value: 2 },
              { label: "审批通过", value: 3 },
              { label: "审批未通过", value: 4 },
              { label: "已撤回", value: 5 },
            ],
          },
        ],
      },
      model: "wisdom_park.wp_visitor",
      data: [],
      todoDrawerRow: {},
      ruleModel: {},
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.queryByModelCode();
    this.getDisShow();
  },
  props: {
    currentRow: {
      type: Object,
      default() {
        return {};
      },
    },
    register: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    currentRow: {
      immediate: true,
      handler(val) {
        if (val.id) {
          this.onLoad();
        }
      },
    },
  },
  methods: {
    getDeptTree(tenantId) {
      getDeptTree(tenantId).then((res) => {
        this.options = res.data.data;
      });
    },
    getDisShow() {
      this.disShowList = [];
      getDictionary({ code: "default_spcod_account_dept" }).then((res) => {
        this.disShowList.push(res.data.data[0].dictValue);
      });
      getDictionary({ code: "default_visitor_dept" }).then((res) => {
        this.disShowList.push(res.data.data[0].dictValue);
      });
    },
    clearSearch() {
      this.searchCompanyId = [];
      this.fullCompanyName = "";
      this.searchText = "";
      this.onLoad(this.page);
    },
    getFullName() {
      this.fullCompanyName = this.$refs.tree.getCheckedNodes()[0].pathLabels[1];
    },
    getMetaCommonListExport() {
      this.downLoading = true;
      //let obj = this.getSearch();
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: [
          {
            field: "type",
            value: 0,
          },
        ],
      };
      if (this.userInfo.role_name.indexOf("administrator") < 0) {
        if (this.userInfo.companyId) {
          body["wheres"].push({
            field: "company_id",
            value: this.userInfo.companyId,
          });
        } else if (this.userInfo.dept_id) {
          //区分清高科
          body["wheres"].push({
            field: "dept_id",
            value: this.userInfo.dept_id,
          });
        }
      }
      if (this.searchText) {
        body["wheres"].push({
          field: "visitor_name",
          op: "LIKE",
          value: this.searchText,
        });
      }
      if (this.currentRow.id) {
        body["wheres"].push({ field: "company_id", value: this.currentRow.id });
      }
      if (this.activeName && this.activeName != 1) {
        body["wheres"].push({
          field: "approval_status",
          value: Number(this.activeName),
        });
      }
      if (this.searchCompanyId[1]) {
        body["wheres"].push({
          field: "dept_id",
          value: this.searchCompanyId[1],
        });
      }
      getMetaCommonListExport("wisdom_park.v_stat_visitor_export_stat", body)
        .then((res) => {
          this.downLoading = false;
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.download = "访客" + ".xls";
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.downLoading = false;
        });
    },
    fkyyy(type) {
      this.$router.push({
        path: "/visitor/visitorOrder",
        query: {
          type: type,
        },
      });
    },
    edit(obj) {
      console.log(obj);
      this.$router.push({
        path: "/visitor/visitorInfo",
        query: {
          visitorId: obj.id,
        },
      });
    },
    showTaskInstance(obj) {
      this.todoDrawerRow = obj;
      this.todoDrawer = true;
    },
    update(obj) {
      this.$router.push({
        path: "/visitor/visitorOrder",
        query: {
          visitorId: obj.id,
        },
      });
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.onLoad();
    },
    back() {
      this.detailDia = false;
      this.searchReset();
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    changName(row) {
      this.title = row.name;
    },

    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },

    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },

    refreshChange() {
      this.onLoad(this.page);
    },
    closeDia() {
      this.dialogVisible = false;
      this.refreshChange();
    },
    rowDel(row, index) {
      console.log(row);
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id";
          delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.onLoad(this.page);
          });
        })
        .catch(() => {});
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        orders: [{ field: "create_time", sort: "DESC" }],
        wheres: [
          {
            field: "type",
            value: 0,
          },
        ],
      };
      if (this.userInfo.role_name.indexOf("administrator") < 0) {
        if (this.userInfo.companyId) {
          body["wheres"].push({
            field: "company_id",
            value: this.userInfo.companyId,
          });
        } else if (this.userInfo.dept_id) {
          //区分清高科
          body["wheres"].push({
            field: "dept_id",
            value: this.userInfo.dept_id,
          });
        }
      }
      if (this.searchText) {
        body["wheres"].push({
          field: "visitor_name",
          op: "LIKE",
          value: this.searchText,
        });
      }
      if (this.searchCompanyId[1]) {
        body["wheres"].push({
          field: "dept_id",
          value: this.searchCompanyId[1],
        });
      }
      if (this.currentRow.id) {
        body["wheres"].push({ field: "company_id", value: this.currentRow.id });
      }
      if (this.activeName && this.activeName != 1) {
        body["wheres"].push({
          field: "approval_status",
          value: Number(this.activeName),
        });
      }
      getMetaCommonList(
        "wisdom_park.v_process_instance_visitor_stat ",
        body
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        data.records.forEach((e) => {
          // this.options.forEach((o) => {
          //   if (e.hight == o.value) {
          //     e.hight = o.label;
          //   }
          // });
        });
        this.loading = false;
        // this.selectionClear();
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
  font-size: 18px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
/deep/ .avue-crud .el-cascader {
  width: auto !important;
}

.buildDetail /deep/ .el-dialog__body {
  padding: 0px;
}
</style>
