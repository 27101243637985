import request from "@/router/axios";
import apiUrl from "./apiUrl";

export const getList = (params) => {
  return request({
    url: apiUrl.list,
    method: "get",
    params: params,
  });
};

export const getRentalList = (params) => {
  return request({
    url: apiUrl.rentalList,
    method: "get",
    params: params,
  });
};

export const checkApproval = (params) => {
  return request({
    url: apiUrl.checkApproval,
    method: "get",
    params: params,
  });
};

export const getNeedPayItem = (params) => {
  return request({
    url: apiUrl.needPayItem,
    method: "get",
    params: params,
  });
};

//企业查看我的账单
export const getListByCompany = (params) => {
  return request({
    url: apiUrl.listByCompany,
    method: "get",
    params: params,
  });
};

export const getPreferentialList = (params) => {
  return request({
    url: apiUrl.preferentialList,
    method: "get",
    params: params,
  });
};

export const statisticFee = (params) => {
  return request({
    url: apiUrl.total,
    method: "get",
    params: params,
  });
};

export const preferentiaStatisticFee = (params) => {
  return request({
    url: apiUrl.preferentiaStatisticFee,
    method: "get",
    params: params,
  });
};

export const getDetail = (params) => {
  return request({
    url: apiUrl.detail,
    method: "get",
    params: params,
  });
};

export const bankCheck = (params) => {
  return request({
    url: apiUrl.bankCheck,
    method: "get",
    params: params,
  });
};

export const reminderPay = (params) => {
  return request({
    url: apiUrl.reminderPay,
    method: "get",
    params: params,
  });
};

export const addPay = (params) => {
  return request({
    url: apiUrl.addPay,
    method: "post",
    data: params,
  });
};
export const calculatePay = (params) => {
  return request({
    url: apiUrl.calculatePay,
    method: "post",
    data: params,
  });
};

export const b2bPay = (params) => {
  return request({
    url: apiUrl.b2bPay,
    method: "post",
    data: params,
  });
};

export const saveReceipt = (params) => {
  return request({
    url: apiUrl.save,
    method: "post",
    data: params,
  });
};

export const updateReceipt = (params) => {
  return request({
    url: apiUrl.update,
    method: "post",
    data: params,
  });
};

export const updateReceiptInfo = (params) => {
  return request({
    url: apiUrl.updateInfo,
    method: "post",
    data: params,
  });
};

export const removeReceipt = (params) => {
  return request({
    url: apiUrl.remove,
    method: "post",
    data: params,
  });
};

export const receiptExport = (params) => {
  return request({
    url: apiUrl.receiptExport,
    method: "get",
    responseType: "blob",
    headers: { "Content-Type": "application/json" },
    params: params,
  });
};

export const receiptExportByCompany = (params) => {
  return request({
    url: apiUrl.receiptExportByCompany,
    method: "get",
    responseType: "blob",
    headers: { "Content-Type": "application/json" },
    params: params,
  });
};

export const getReductionPolicy = (params) => {
  return request({
    url: apiUrl.getReductionPolicy,
    method: "get",
    params: params,
  });
};

export const getSubsidyList = (params) => {
  return request({
    url: apiUrl.getSubsidyPage,
    method: "get",
    params: params,
  });
};

export const saveSubsidy = (params) => {
  return request({
    url: apiUrl.saveSubsidy,
    method: "post",
    data: params,
  });
};

export const updateSubsidy = (params) => {
  return request({
    url: apiUrl.updateSubsidy,
    method: "post",
    data: params,
  });
};

export const delSubsidy = (params) => {
  return request({
    url: apiUrl.delSubsidy,
    method: "post",
    data: params,
  });
};

export const additionalAmount = (params) => {
  return request({
    url: apiUrl.additionalAmount,
    method: "post",
    data: params,
  });
};
export const removeReceiptReductionDetail = (params) => {
  return request({
    url: apiUrl.removeReceiptReductionDetail,
    method: "get",
    params: params,
  });
};

export const importReceipt = (file , params) => {
  const formData = new FormData();
  formData.append('file', file);
  Object.keys(params).forEach(
    key => {
      formData.append(key , params[key]);
    }
  )
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: apiUrl.importReceipt,
    method: 'post',
    data: formData
  })
}
