var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basic-container',{attrs:{"isCard":""}},[(_vm.init)?_c('avue-crud',{ref:"crud",attrs:{"option":_vm.option,"data":_vm.data,"page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"refresh-change":_vm.refreshChange,"on-load":_vm.onLoad},scopedSlots:_vm._u([{key:"name",fn:function(scope){return [(scope.row.old_company_name != scope.row.new_company_name)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" 企业名称 ")]):_vm._e(),(scope.row.old_usc_code != scope.row.new_usc_code)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" 统一社会信用代码 ")]):_vm._e(),(scope.row.old_legal_person != scope.row.new_legal_person)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" 企业法人 ")]):_vm._e(),(scope.row.old_registered_area != scope.row.new_registered_area)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" 注册地区 ")]):_vm._e(),(
          scope.row.old_registered_address != scope.row.new_registered_address
        )?_c('p',{staticClass:"mar-r-3"},[_vm._v(" 注册详细地址 ")]):_vm._e(),(
          scope.row.old_business_license != scope.row.new_business_license
        )?_c('p',{staticClass:"mar-r-3"},[_vm._v(" 营业执照图片 ")]):_vm._e()]}},{key:"old_company_name",fn:function(scope){return [(scope.row.old_company_name != scope.row.new_company_name)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.old_company_name)+" ")]):_vm._e(),(scope.row.old_usc_code != scope.row.new_usc_code)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.old_usc_code)+" ")]):_vm._e(),(scope.row.old_legal_person != scope.row.new_legal_person)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.old_legal_person)+" ")]):_vm._e(),(scope.row.old_registered_area != scope.row.new_registered_area)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.old_registered_area)+" ")]):_vm._e(),(
          scope.row.old_registered_address != scope.row.new_registered_address
        )?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.old_registered_address)+" ")]):_vm._e(),(
          scope.row.old_business_license != scope.row.new_business_license
        )?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.old_business_license)+" ")]):_vm._e()]}},{key:"new_company_name",fn:function(scope){return [(scope.row.old_company_name != scope.row.new_company_name)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.new_company_name)+" ")]):_vm._e(),(scope.row.old_usc_code != scope.row.new_usc_code)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.new_usc_code)+" ")]):_vm._e(),(scope.row.old_legal_person != scope.row.new_legal_person)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.new_legal_person)+" ")]):_vm._e(),(scope.row.old_registered_area != scope.row.new_registered_area)?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.new_registered_area)+" ")]):_vm._e(),(
          scope.row.old_registered_address != scope.row.new_registered_address
        )?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.new_registered_address)+" ")]):_vm._e(),(
          scope.row.old_business_license != scope.row.new_business_license
        )?_c('p',{staticClass:"mar-r-3"},[_vm._v(" "+_vm._s(scope.row.new_business_license)+" ")]):_vm._e()]}}],null,false,1184834717)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }