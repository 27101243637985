import request from "@/router/axios";

export const getList = (current, size, params) => {
  return request({
    url: "/api/jida-desk/notice/list",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/jida-desk/notice/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/jida-desk/notice/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/jida-desk/notice/submit",
    method: "post",
    data: row,
  });
};

export const getNotice = (id) => {
  return request({
    url: "/api/jida-desk/notice/detail",
    method: "get",
    params: {
      id,
    },
  });
};

// 消息模板：
// /api/jida-system/messageTemplate/detail?id=xxx get 获取消息模板记录详细信息
// /api/jida-system/messageTemplate/list get 参数 MessageTemplate +分页参数 获取列表
// /api/jida-system/messageTemplate/save post 参数 MessageTemplate 对象 保存模板
// /api/jida-system/messageTemplate/update post 参数 MessageTemplate 对象 更新模板
// /api/jida-system/messageTemplate/remove get 参数ids，多个以逗号隔开 删除模板
export const getMessageTempList = (current, size, params) => {
  return request({
    url: "/api/jida-system/messageTemplate/list",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
export const getMessageTempDetail = (params) => {
  return request({
    url: "/api/jida-system/messageTemplate/detail?id=" + params,
    method: "get",
  });
};

export const saveMessageTemp = (params) => {
  return request({
    url: "/api/jida-system/messageTemplate/save",
    method: "post",
    data: params,
  });
};
export const updateMessageTemp = (params) => {
  return request({
    url: "/api/jida-system/messageTemplate/update",
    method: "post",
    data: params,
  });
};
export const removeMessageTemp = (params) => {
  return request({
    url: "/api/jida-system/messageTemplate/remove?ids=" + params,
    method: "post",
  });
};
// 消息相关：
// /api/jida-system/message/detail?id=xxx get 获取消息信息
// /api/jida-system/message/list get 参数 Message +分页参数 消息列表
// /api/jida-system/message/save post 参数 Message 对象 保存消息
// /api/jida-system/message/update post 参数 Message 对象 更新消息
// /api/jida-system/message/send get 消息主键ID 发送消息
// /api/jida-system/message/getUserMesageList get 获取当前用户消息列表
// /api/jida-system/message/getCountRedPoint get 获取当前用户红点未读消息数量
// /api/jida-system/message/getInstantUserMessageList get 获取当前用户24小时内的及时未读消息，用于弹窗
// /api/jida-system/message/readMessage get 参数ids，多个以逗号隔开 设置用户消息为已读
// /api/jida-system/message/removeUserMesage get 参数ids，多个以逗号隔开 删除用户消息
export const getMessageList = (current, size, params) => {
  return request({
    url: "/api/jida-system/message/list",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
export const getUserMessageList = (current, size, status) => {
  return request({
    url: "/api/jida-system/message/getUserMessageList",
    method: "get",
    params: {
      current,
      size,
      status,
    },
  });
};

export const getMessageDetail = (params) => {
  return request({
    url: "/api/jida-system/message/detail?id=" + params,
    method: "get",
  });
};

export const saveMessage = (params) => {
  return request({
    url: "/api/jida-system/message/save",
    method: "post",
    data: params,
  });
};
export const sendMessage = (params) => {
  return request({
    url: "/api/jida-system/message/send?id=" + params,
    method: "get",
  });
};

export const updateMessage = (params) => {
  return request({
    url: "/api/jida-system/message/update",
    method: "post",
    data: params,
  });
};
export const removeMessage = (params) => {
  return request({
    url: "/api/jida-system/message/remove?ids=" + params,
    method: "post",
  });
};
export const getCountRedPoint = () => {
  return request({
    url: "/api/jida-system/message/getCountRedPoint",
    method: "get",
  });
};

export const getInstantUserMessageList = () => {
  return request({
    url: "/api/jida-system/message/getInstantUserMessageList",
    method: "get",
  });
};
export const setAllRead = () => {
  return request({
    url: "/api/jida-system/message/setAllRead",
    method: "get",
  });
};
export const readMessage = (params) => {
  return request({
    url: "/api/jida-system/message/readMessage?ids=" + params,
    method: "get",
  });
};
