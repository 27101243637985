<template>
  <div class="basic_dropdown">
    <el-dropdown @command="handleCommand">
      <slot>
        <span class="el-dropdown-link">
          <!-- <span>{{ btntext || name }}</span> -->
          <i class="el-icon-setting divIcon"></i>
        </span>
      </slot>
      <template v-slot:dropdown>
        <slot name="dropdown" :options="items">
          <el-dropdown-menu>
            <template v-if="items.length">
              <template v-for="item in items">
                <el-dropdown-item
                  :class="item.command === value ? 'active_dropDown' : ''"
                  v-bind="item"
                  :key="item.id"
                  :command="item"
                >
                  <template v-if="item.type == 'switch'">
                    <el-switch v-model="item.default" class="mar-r-5">
                    </el-switch
                    >{{ item.label }}
                  </template>
                  <template v-else-if="item.type == 'text'">
                    <i class="item.icon"></i>{{ item.label }}
                  </template>
                  <template v-else>
                    {{ item.label }}
                  </template>
                </el-dropdown-item>
              </template>
            </template>
          </el-dropdown-menu>
        </slot>
      </template>
    </el-dropdown>
  </div>
</template>
<script>
function dataTypeJudge(val, type) {
  const dataType = Object.prototype.toString
    .call(val)
    .replace(/\[object (\w+)\]/, "$1")
    .toLowerCase();
  return type ? dataType === type : dataType;
}
import { uuid } from "@/util/util";

import { get } from "lodash";
export default {
  data() {
    return {
      items: [],
    };
  },
  computed: {
    attr() {
      const { trigger } = this;
      return { ...this.$attrs, trigger };
    },
    name: {
      get() {
        const itemOption = this.items.find(
          (item) => item.command === this.value
        );
        return (itemOption && itemOption.label) || "下拉菜单";
      },
    },
  },
  watch: {
    options: {
      //分页
      handler(newValue, oldName) {
        if (newValue) {
          this.handleOptions();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  props: {
    // 下拉选项数组
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    btntext: {
      type: String,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    command: {
      type: String,
    },
    trigger: {
      type: String,
      default: "click",
    },
  },
  mounted() {
    this.handleOptions();
  },
  methods: {
    handleOptions() {
      this.items = this.options.map((item) => {
        if (dataTypeJudge(item) === "string") {
          return {
            label: item,
            command: item,
            id: uuid(),
          };
        } else if (dataTypeJudge(item) === "object") {
          const { label, command } = this;
          if (label || command) {
            return {
              label: get(item, label || command),
              command: get(item, command || label),
              id: uuid(),
              ...item,
            };
          } else {
            return {
              ...item,
              id: uuid(),
            };
          }
        } else {
          return {
            label: "",
            command: "",
            id: uuid(),
          };
        }
      });
    },
    handleCommand(command) {
      this.$emit("command", command);
    },
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss" scoped>
.design {
  .designDiv {
    .basic_dropdown {
      .el-icon-setting {
        position: absolute;
        right: 10px;
        top: 1px;
        background: #f5896c !important;
        padding: 2px;
        color: #fff;
        // display: none;
      }
      .el-dropdown {
        display: block;
      }
      .el-icon-setting {
        top: -21px;
        right: -20px;
      }
    }
    &:hover {
      border-color: #f18b62 !important;
      border-style: dashed;
      .divIcon {
        display: block;
      }
    }
  }
}
.basic_dropdown {
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
.active_dropDown {
  color: #4b9ed8;
}
</style>
