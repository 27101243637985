<template>
  <avue-crud
    ref="table"
    :data="tableData"
    :page.sync="page"
    :option="tableOption"
    :table-loading="tableLoading"
    :search.sync="search"
    @search-change="onLoad"
    @on-load="onLoad"
    @row-save="saveTableForm"
    @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
    @row-del="rowDel"
  >
    <template slot-scope="scope" slot="amount" >
      ￥{{scope.row.amount | numConvertToThousandths}}元
    </template>
  </avue-crud>
</template>
<script>
import { getReductionPolicy , saveSubsidy , updateSubsidy , delSubsidy , getSubsidyList } from '@/api/finance/index.js'
// import * as dictService from "@/api/system/dictbiz";
export default {
  data(){
    return {
      model: 'wisdom_park.wp_receipt_subsidy',
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      reductionPolicys: [],
      tableOption: {
        height: '250px',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        // delBtn: false,
        // editBtn: false,
        // addBtn: false,
        // menu:false,
        header: true,
        refreshBtn: false,
        columnBtn: false,
        menuWidth: 150,
        column: [
          {
            label: "到账金额",
            prop: "amount",
            width: 150,
            type: 'number',
            rules: [
              {
                required: true,
                message: "请输入到账金额",
                trigger: "blur"
              }
            ]
          },
          {
            label: "到账时间",
            prop: "revenueTime",
            width: 100,
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            rules: [
              {
                required: true,
                message: "请选择到账时间",
                trigger: "blur"
              }
            ]
          },
          {
            label: "优惠政策",
            prop: "reductionPolicyName",
            display: false
          },
          {
            label: "优惠政策",
            prop: "reductionPolicyId",
            hide: true,
            showColumn: false,
            type: 'select',
            dicUrl: '/api/park-fee/receipt/get-reduction-policy?receiptNo=' + this.receiptNo,
            props: {
              label: "reductionPolicyName",
              value: "reductionPolicyId",
            },
            dicFormatter:(res)=>{
              return res.data;
            },
            rules: [
              {
                required: true,
                message: "请选择优惠政策",
                trigger: "blur"
              }
            ]
          },
        ]
      },
    }
  },
  props: {
    receiptNo: {
      type: String,
      default: ''
    }
  },
  mounted(){
    getReductionPolicy({
      receiptNo: this.receiptNo
    }).then(
      res => {
        this.reductionPolicys = res.data.data;
        // console.log(res);
      }
    )
  },
  methods: {
    onLoad(){
      this.tableLoading = true;
      this.tableData = [];
      getSubsidyList({
        current: this.page.currentPage,
        size: this.page.pageSize,
        receiptNo: this.receiptNo
      }).then(
        res => {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    saveTableForm(row, done, loading){
      let body = {
        ...row,
        receiptNo: this.receiptNo
      };
      this.reductionPolicys.forEach(
        item => {
          if(item.reductionPolicyId === row.reductionPolicyId){
            body['reductionPolicyName'] = item.reductionPolicyName;
          }
        }
      );
      // if(row.id){
      //   body = {
      //     update: body,
      //     primaryKeyValue: row.id,
      //     primaryKey: "id",
      //   };
      // }else{
      //   body = {
      //     values: body
      //   }
      // }
      (body.id ? updateSubsidy : saveSubsidy)(body).then((res) => {
        this.responeHandle(res, done, loading);
      }).catch((e) => {
        loading();
      });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return delSubsidy([row.id]);
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
  }
}
</script>