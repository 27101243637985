<template>
  <el-cascader
    v-bind="$attrs"
    separator="-"
    class="w-100p"
    ref="cascader"
    :props="{...defaultProps , ...props}"
    v-model="locations"
    @change="cascaderChange"
    clearable
  ></el-cascader>
</template>
<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon";
export default {
  name: 'LocationCascader',
  data(){
    return {
      defaultProps: {
        lazy: true,
        label: 'name',
        value: 'id',
        checkStrictly: true,
        lazyLoad: (node, resolve) => {
          const { level } = node;
          const modelCodes = ['wp_building' , 'wp_floor' , 'wp_room'];
          if(level > 2){
            resolve([])
            return;
          }
          let params = {
            wheres: []
          }
          if(level === 1){
            params = {
              wheres: [
                {field: 'building_id' , value: node.path[level - 1]},
              ],
              orders: [
                { field: "code+1000", sort: "DESC" }
              ]
            }
          }
          if(level === 2){
            params = {
              wheres: [
                {field: 'building_id' , value: node.path[0]},
                {field: 'floor_id' , value: node.path[1]},
              ]
            }
          }
          if(this.params && this.params[level]){
            params.wheres = [
              ...params.wheres,
              ...this.params[level]
            ]
          }
          getMetaCommonList(`wisdom_park.${modelCodes[level]}` , {
            current: 1,
            size: 500,
            ...params
          }).then(
            res => {
              let data = res.data.data.records;
              if(level === 1){
                data = data.map(
                  item => {
                    return {
                      ...item,
                      name: item.name ? item.name : (item.code + '层')
                    }
                  }
                )
              }
              if(level === 2){
                data = data.map(
                  item => {
                    return {
                      ...item,
                      leaf: true,
                      disabled: this.disabledList.includes(item.id)
                    }
                  }
                )
              }
              resolve(data)
            }
          ).catch(e => {
            resolve([]);
          })
        }
      },
      locations: []
    }
  },
  props: {
    value: {},
    props: {
      type: Object,
      default(){
        return {}
      }
    },
    disabledList: {
      type: Array,
      default(){
        return [];
      }
    },
    params: {
      type: Object,
      default(){
        return null
      }
    }
  },
  watch: {
    locations: {
      handler(){
        this.$emit('input' , this.locations);
      }
    },
    value: {
      immediate: true,
      handler(){
        if(this.locations !== this.value){
          this.locations = this.value;
        }
      }
    }
  },
  mounted(){
    this.locations = this.value;
  },
  methods: {
    cascaderChange(){
      this.$nextTick(() => {
        let nodes = this.$refs.cascader.getCheckedNodes();
        this.$emit('change' , {
          value: this.locations,
          label: this.$refs.cascader.presentText,
          nodes: nodes
        })
      })
    }
  }
}
</script>