import request from '@/router/axios';

const prefix = '/workspace/api/';

export default {
    contents(params , path){
        return request({
            url: prefix + 'contents' + (path || ''),
            method: 'get',
            params: params
        })
    },

}