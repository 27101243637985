<template>
    <basic-container>
        
    </basic-container>
</template>
<script>
export default {
    name: 'DynamicTable',
    data(){
        return {

        }
    }
}
</script>