/**
 * @description
 * 配置方式
 * [key<String>]: value<String[]>
 * key为主题名称,
 * value为颜色列表，数组字符串类型
 */

export const themeColors = {
    default: [
        '#39a0ff', '#fcd77c', '#36cbcb' , '#f5896d' , '#919de9' ,
        '#99c18c', '#dc69aa' , '#3eb07b', '#607d8b' , '#ced7dc'
    ]
};