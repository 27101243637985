const baseUrl = '/api/griffin/api/v1/';
const schedulerBaseUrl = '/api/convergeflexschduler/convergeflexschduler/';

export const ApiUrls = {
  measures: baseUrl + 'measures',
  jobs: baseUrl + 'jobs',
  jobInstances: baseUrl + 'jobs/instances',
  tables: {
    names: baseUrl + 'metadata/hive/dbs/tables/names',
    table: baseUrl + 'metadata/hive/table'
  },
  createHiveUdfFunc: baseUrl + 'udf/createHiveUdfFunc/',
  funcs: schedulerBaseUrl + 'resources/udf-func/list',
  getMeasuresList: baseUrl + 'measuresListByProjectId',
  getJobsList: baseUrl + 'jobsListByProjectId',
  getMeasures: baseUrl + 'measuresByProjectId',
  getJobs: baseUrl + 'jobsByProjectId'
}