<template>
  <!-- <div>
    <el-dialog title="选择" append-to-body :visible.sync="box" width="50%">
      <el-radio-group v-model="text" class="list">
        <el-row :span="24">
          <el-col
            v-for="(item, index) in list"
            :key="index"
            :md="4"
            :xs="12"
            :sm="4"
          >
            <el-radio :label="item.value">{{ item.name }}</el-radio>
          </el-col>
        </el-row>
      </el-radio-group>
    </el-dialog>

    <span>
      <i class="icon-zhuti" @click="open"></i>
    </span>
  </div> -->
  <el-dropdown trigger="click">
    <i class="icon-zhuti cur-p"></i>
    <el-dropdown-menu>
      <template v-for="(item, index) in list">
        <el-dropdown-item :key="index" :disabled="text === item.value">
          <span @click="setTopTheme(item.value)">{{ item.name }}</span>
        </el-dropdown-item>
      </template>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { setTheme } from "@/util/util";
import { mapGetters } from "vuex";

import themes from '@/config/theme';

export default {
  data() {
    return {
      box: false,
      text: "",
      list: themes || [
        // {
        //   name: "默认主题"
        // },
        // {
        //   name: "白色主题",
        //   value: "theme-white"
        // },
        // {
        //   name: "黑色主题",
        //   value: "theme-dark"
        // },
        // {
        //   name: "炫彩主题",
        //   value: "theme-star"
        // },
        // {
        //   name: "智能主题",
        //   value: "theme-bule"
        // },
        // {
        //   name: "iview主题",
        //   value: "theme-iview"
        // },
        // {
        //   name: "vip主题",
        //   value: "theme-vip"
        // },
        {
          name: "科技蓝",
          value: "theme-cool",
        },
        // {
        //   name: "d2主题",
        //   value: "theme-d2"
        // },
        // {
        //   name: "hey主题",
        //   value: "theme-hey"
        // },
        {
          name: "火焰蓝",
          value: "theme-fb",
        },
        {
          name: "星空黑",
          value: "theme-yl",
        },
      ],
    };
  },
  watch: {
    text: function (val) {
      this.$store.commit("SET_THEME_NAME", val);

      if (this.menuMode == "top") {
        setTheme(val + " menuTopDark");
      } else {
        setTheme(val);
      }
    },
  },
  computed: {
    ...mapGetters(["themeName", "menuMode"]),
  },
  mounted() {
    this.text = this.themeName;
    if (!this.text) {
      this.text = "";
    }
  },
  methods: {
    open() {
      this.box = true;
    },
    setTopTheme(val) {
      this.text = val;
      this.$store.commit("SET_THEME_NAME", val);

      if (this.menuMode == "top") {
        setTheme(val + " menuTopDark");
      } else {
        setTheme(val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  width: 100%;
}
</style>
