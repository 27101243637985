import { formatDate , timeDuration } from './formatDate';
import { filterNull } from './filterNull';
import { firstToUpper } from './firstToUpper';
import {
  numConvertToStr , numConvertToUnit ,
  numConvert , numConvertToThousandths ,
  formatBankNum , toFixed
} from './numConvert';

const _filters = {
  filterNull: filterNull,
  formatDate: formatDate,
  firstToUpper: firstToUpper,
  timeDuration: timeDuration,
  numConvertToStr: numConvertToStr,
  numConvertToUnit: numConvertToUnit,
  numConvert: numConvert,
  numConvertToThousandths: numConvertToThousandths,
  formatBankNum: formatBankNum,
  toFixed: toFixed
}

export {
  formatDate, filterNull, firstToUpper, timeDuration, numConvertToUnit , numConvertToStr, formatBankNum,
  numConvert, numConvertToThousandths, toFixed,
  _filters
}
