import request from '@/router/axios';
const prefix = '/api/jida-data-asset';
// API服务总览  的统计数据
export const generalOverview = (params) => {
  return request({
    url: prefix + `/dataShareLogStatistics/generalOverview`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    params:params
  })
};
// 获取API全景图
export const panoramaForSingleApi = (params) => {
  return request({
    url: prefix + `/dataShareLogStatistics/panoramaForSingleApi`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    params:params
  })
};
// 获取调用API列表
export const datasharePage = (params) => {
  return request({
    url: prefix + `/datashare/page`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    params:params
  })
};
// 热门top5
export const hotList = (params) => {
  return request({
    url: prefix + `/dataShareLogStatistics/hotList`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    params:params
  })
};
// API调用失败率
export const errorList = (params) => {
  return request({
    url: prefix + `/dataShareLogStatistics/errorList`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    params:params
  })
};
// m每日调用折线
export const findStatisticsByDay = (params) => {
  return request({
    url: prefix + `/dataShareLogStatistics/findStatisticsByDay`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    params:params
  })
};
