// 判断是否是3个连续的数字
// str 输入的密码
function ConsecutiveStr(str){
    let arr = str.split(''); // 字符串分隔为数组，如： '123' -> [1 , 2 , 3]
    let flag = false;
    for (let i = 1; i < arr.length-1; i++) {
        let firstIndex = arr[i-1].charCodeAt();
        let secondIndex = arr[i].charCodeAt();
        let thirdIndex = arr[i+1].charCodeAt();
        thirdIndex - secondIndex == 1;
        secondIndex - firstIndex==1;
        if((thirdIndex - secondIndex == 1) && (secondIndex - firstIndex==1)){
            flag =  true;
        }
    }
    return flag;
}

// 判断是否是3个连续的键盘符 如 asd !@#
// str 输入的密码
function IsKeyBoardContinuousChar(str) {
    str = str.toLocaleLowerCase(); // 将字符串转换为小写 ， 如: ABC -> abc
    let c1 = [
        ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+'],
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '{', '}', '|'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ':', '"'],
        ['z', 'x', 'c', 'v', 'b', 'n', 'm', '<', '>', '?']
    ];
    let c2 = [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '='],
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', '\''],
        ['z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/']
    ];
    str = str.split(""); // 将字符串分割为数组，如：'abc' -> ['a' , 'b' , 'c']
    //获取坐标位置
    let y = [];
    let x = [];
    for (let c = 0; c < str.length; c++) {
        y[c] = 0;//当做~`键处理
        x[c] = -1;
        for (let i = 0; i < c1.length; i++) {
            for (let j = 0; j < c1[i].length; j++) {
                if (str[c] == c1[i][j]) {
                    y[c] = i;
                    x[c] = j;
                }
            }
        }
        if (x[c] != -1) continue;
        for (let i = 0; i < c2.length; i++) {
            for (let j = 0; j < c2[i].length; j++) {
                if (str[c] == c2[i][j]) {
                    y[c] = i;
                    x[c] = j;
                }
            }
        }
    }
    //匹配坐标连线
    for (let c = 1; c < str.length - 1; c++) {
        if (y[c - 1] == y[c] && y[c] == y[c + 1]) {
            if ((x[c - 1] + 1 == x[c] && x[c] + 1 == x[c + 1]) || (x[c + 1] + 1 == x[c] && x[c] + 1 == x[c - 1])) {
                return true;
            }
        } else if (x[c - 1] == x[c] && x[c] == x[c + 1]) {
            if ((y[c - 1] + 1 == y[c] && y[c] + 1 == y[c + 1]) || (y[c + 1] + 1 == y[c] && y[c] + 1 == y[c - 1])) {
                return true;
            }
        }
    }
    return false;
}

// 校验密码规则
// str 输入的密码
// option 其他参数，可空
export default function(str , option){
    option = option || {
        maxLen: 20, // 允许最大长度
        minLen: 8 // 允许最小长度
    };
    let maxLen = option.maxLength || 20,
        minLen = option.minLength || 8;
    
    return new Promise((resolve , reject) => {
        let err = null;
        if(str.length < minLen || str.length > maxLen ){ 
            err = {
                type: "error",
                message: "密码长度为8-20位"
            }
        }else if(!(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])[^]{8,20}$/.test(str))){
            err = {
              type: "error",
              message: "密码必须同时包含字母、数字和特殊符号"
            }
        }else if(ConsecutiveStr(str)){
            err = {
                type: "error",
                message: "密码不能包含3位及以上连续字母或数字"
            }
        }else if(IsKeyBoardContinuousChar(str)){
            err = {
                type: "error",
                message: "密码不能包含3位及以上键盘序字符"
            }
        }

        if(err){
            reject(err);
        }else{
            resolve();
        }

    })


}