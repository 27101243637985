<template>
  <el-row>
    <el-col :sm="24">
      <el-result :icon="icon" title="警告提示" subTitle="请耐心等待">
        <template slot="title">
          <div class="fts-20">
            入驻申请已提交，当前状态 【
            <span v-if="approvalStatus == 1">待提交</span>
            <span v-if="approvalStatus == 2">审批中</span>
            <span v-if="approvalStatus == 3">审批通过</span>
            <span v-if="approvalStatus == 4">审批未通过</span>
            <span v-if="approvalStatus == 5">已撤回</span>】
          </div>
        </template>
        <!-- <template slot="extra">
          <el-button type="primary" size="small">催办</el-button>
        </template> -->
      </el-result>
    </el-col>
  </el-row>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      icon: "",
    };
  },
  mounted() {},
  created() {},
  methods: {},
  watch: {
    approvalStatus: {
      immediate: true,
      handler(val) {
        if (val == 2) {
          this.icon = "success";
        }
        if (val == 4 || val == 5) {
          this.icon = "error";
        }
      },
    },
  },
  props: {
    approvalStatus: {
      type: String,
      default() {
        return "";
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
