<template>
  <el-popover placement="bottom" width="450" trigger="click">
    <el-tabs v-model="activeName">
      <el-tab-pane
        v-for="(item, index) of iconList"
        :key="index"
        :label="item.label"
        :name="'' + index"
      >
        <el-row class="h-300 ov-a">
          <el-col :span="4" v-for="(icon, index) of item.list" :key="index">
            <div
              @click="iconClick(icon)"
              title="单击复制图标样式(className)"
              class="w-100p h-60 icon-item cur-p"
            >
              <i class="fts-30" :class="icon"></i>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <div slot="reference">
      <i class="el-icon-s-goods cur-p"></i>
    </div>
  </el-popover>
</template>
<script>
import iconList from "@/config/iconList";

export default {
  data() {
    return {
      activeName: "0",
      iconList: iconList,
    };
  },
  methods: {
    iconClick(icon) {
      this.$copy(icon).then(() => {
        this.$message.success("已成功复制图标: " + icon);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/default-vars.scss";
@import "@/styles/vars.scss";

.icon-item {
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: $--color-primary;
    background: $--background-color-base;
  }
}
</style>
