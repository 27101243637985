import request from '@/router/axios';
const prefix = '/api/jida-data-asset';
export const getDataAPICollection = (params) => {
  return request({
    url: prefix + `/apicollection/page`,
    method: 'get',
    params
  })
};
export const saveAPICollection = (params) => {
  return request({
    url: prefix + `/apicollection/save`,
    method: 'post',
    data:params
  })
};
export const updateAPICollection = (params) => {
  return request({
    url: prefix + `/apicollection/update`,
    method: 'post',
    data:params
  })
};
export const delAPICollection = (params) => {
  return request({
    url: prefix + `/apicollection/remove`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};

