<template>
  <el-container class="h-100p">
    <el-header v-if="!preview" class="bor-b lh-50" height="50px">
      <MyProcessDesignerHeader
        :preview="preview"
        @back="$emit('back')"
        @save="save"
        ref="processDesignerHeader"
      >
        <template slot="right">
          <el-button
            v-if="!panelShow"
            @click="panelShow = true"
            size="small"
            type="text"
            icon="el-icon-s-fold"
            >展开</el-button
          >
          <el-button
            v-if="panelShow"
            @click="panelShow = false"
            size="small"
            type="text"
            icon="el-icon-s-unfold"
            >收起</el-button
          >
        </template>
      </MyProcessDesignerHeader>
    </el-header>
    <el-container v-if="!preview" style="height: calc(100% - 50px)">
      <el-aside class="bor-r" :width="paletteWidth">
        <my-process-palette
          @paletteWidth="
            (ev) => {
              this.paletteWidth = ev;
            }
          "
        />
      </el-aside>
      <el-main>
        <my-process-designer
          :key="`designer-${reloadIndex}`"
          :options="{
            taskResizingEnabled: true,
            eventResizingEnabled: true,
          }"
          :register="register"
          :value="xmlString"
          v-bind="controlForm"
          keyboard
          ref="processDesigner"
          headerRefName="processDesignerHeader"
          @element-click="elementClick"
          @element-contextmenu="elementContextmenu"
          @init-finished="initModeler"
          @change="xmlChange"
        />
      </el-main>
      <el-aside
        style="transition: width 0.5s"
        :width="panelShow ? '400px' : '0px'"
        class="bor-l"
      >
        <my-properties-panel
          v-if="panelShow"
          :key="`penal-${reloadIndex}`"
          :width="400"
          :users="users"
          :roles="roles"
          :bpmn-modeler="modeler"
          :prefix="controlForm.prefix"
          class="process-panel"
        >
          <template v-slot:PropertiesPanel="data" v-if="$scopedSlots.PropertiesPanel">
            <slot name="PropertiesPanel" v-bind="data"></slot>
          </template>
        </my-properties-panel>
      </el-aside>
    </el-container>

    <el-header v-if="preview" class="bor-b lh-50" height="50px">
      <MyProcessDesignerHeader
        :preview="preview"
        @back="$emit('back')"
        @save="save"
        ref="processDesignerHeader"
      ></MyProcessDesignerHeader>
    </el-header>
    <el-main v-if="preview">
      <ProcessViewer
        ref="processDesigner"
        :value="xmlString"
        @init-finished="initModeler"
      ></ProcessViewer>
    </el-main>
  </el-container>
</template>
<script>

import axios from 'axios';

// 自定义元素选中时的弹出菜单（修改 默认任务 为 用户任务）
// import CustomContentPadProvider from "./packages/designer/plugins/content-pad";

import MyProcessDesignerHeader from "./packages/designer/my-process-designer-header";
import ProcessViewer from "./packages/designer/ProcessViewer";
// 自定义左侧菜单（修改 默认任务 为 用户任务）
import CustomPaletteProvider from "./packages/designer/plugins/palette";

// 小地图
// import minimapModule from "diagram-js-minimap";

export default {
  name: "BpmnMain",
  components: { MyProcessDesignerHeader, ProcessViewer },
  provide() {
    return {
      BpmnMain: this,
      http: this.register && this.register.http ? this.register.http : axios,
      dataSource: this.register && this.register.dataSource ? this.register.dataSource : null,
    };
  },

  data() {
    let customPaletteProvider = CustomPaletteProvider(this.register);
    return {
      xmlValue: '',
      paletteWidth: "200px",
      panelShow: true,
      init: false,
      xmlString: '',
      modeler: null,
      reloadIndex: 0,
      controlDrawerVisible: false,
      infoTipVisible: false,
      pageMode: false,
      controlForm: {
        processId: "",
        processName: "",
        simulation: true,
        labelEditing: false,
        labelVisible: false,
        prefix: "flowable",
        headerButtonSize: "mini",
        events: ["element.click", "element.contextmenu"],
        // additionalModel: []
        additionalModel: [
          // CustomContentPadProvider,
          customPaletteProvider,
          // minimapModule,
        ],
      },
      addis: {
        customPaletteProvider,
        // CustomContentPadProvider,
      },
      process: {
        id: "",
        name: "用户新增",
        desc: "",
        relation: "",
        taskList: [
          {
            taskId: "",
            taskKey: "",
            taskName: "",
            taskType: "", // orm kafaka mysql
            condition: {
              //限制
              expression: "", //表达式
              operation: [
                {
                  model: "",
                  fields: [
                    {
                      fieldName: "",
                      fieldValue: "",
                      operationType: "", //lt大于，小于gt 等于equal  like
                    },
                  ],
                  operationType: "", //或者or 并且and
                },
              ],
            },
            taskDo: {
              datasource: "",
              model: "",
              method: "add", //delete update
              fields: [
                {
                  fieldName: "",
                  fieldValue: "",
                  //全局参数(glob.xx) 用户级参数(user.xx) 入参(oldRow.xx) 新对象(taskKey.xx)
                },
                {
                  fieldName: "",
                  fieldValue: "",
                  //全局参数(glob.xx) 用户级参数(user.xx) 入参(oldRow.xx) 新对象(newRow.xx)
                },
              ],
            },
          },
        ],
      },
    };
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    preview: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => {
        return [];
      },
    },
    roles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dataSoure: {
      type: Object,
      default(){
        return null;
      }
    },
    register: {
      type: Object,
      default(){
        return null;
      }
    }
  },
  created() {
    this.xmlString = this.value;
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.value) {
          this.xmlValue = this.value;
          this.xmlString = this.value;
        }
      },
    }
  },
  mounted() {
    console.log(this);
  },
  methods: {
    xmlChange(xml){
      this.xmlValue = xml;
    },
    ready() {
      return new Promise((a) => {
        let timer = setInterval(() => {
          if (this.modeler) {
            a(this.modeler);
            clearInterval(timer);
            timer = null;
          }
        }, 0);
      });
    },
    save() {
      this.$emit("save", this.xmlValue);
    },
    initModeler(modeler) {
      setTimeout(() => {
        this.modeler = modeler;
        if (this.$refs.processDesignerHeader) {
          this.$refs.processDesignerHeader.setModeler(modeler);
          this.$refs.processDesignerHeader.setDesigner(this.$refs.processDesigner);
        }
      }, 10);
    },
    reloadProcessDesigner(notDeep) {
      this.controlForm.additionalModel = [];
      for (let key in this.addis) {
        if (this.addis[key]) {
          this.controlForm.additionalModel.push(this.addis[key]);
        }
      }
      !notDeep && (this.xmlString = undefined);
      this.reloadIndex += 1;
      this.modeler = null; // 避免 panel 异常
    },
    changeLabelEditingStatus(status) {
      this.addis.labelEditing = status
        ? { labelEditingProvider: ["value", ""] }
        : false;
      this.reloadProcessDesigner();
    },
    elementClick(element) {
      this.element = element;
    },
    elementContextmenu(element) {},
    changePageMode(mode) {
      const theme = mode
        ? {
            // dark
            stroke: "#ffffff",
            fill: "#333333",
          }
        : {
            // light
            stroke: "#000000",
            fill: "#ffffff",
          };
      const elements = this.modeler.get("elementRegistry").getAll();
      this.modeler.get("modeling").setColor(elements, theme);
    },
  },
  destroyed() {
    localStorage.removeItem("currentDataSource");
  },
};
</script>
<style lang="scss" scoped>
.bpmn-container {
  width: 100%;
  height: 100%;
  display: inline-grid;
  grid-template-columns: 100px auto max-content;
}
</style>
