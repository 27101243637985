import Contextmenu from './index';

/**
 * @example this.$contextmenu({
 *  menus: [// 要显示的菜单，暂不支持多级
 *  {label: '新增' , icon: 'el-icon-add'},
 *  {label: '修改' , icon: 'el-icon-edit'},
 *  {divider: true}, // 分割线
 *  {label: '删除' , icon: 'el-icon-delete', type: 'danger'}
 * ], 
 * props: { // 配置选项
 *  label: 'label', // 指定节点标签为节点对象的某个属性值，可空，默认label
 *  icon: 'icon', // 指定节点图标为节点对象的某个属性值，可空，默认icon
 *  type: 'type', // 指定节点类型为节点对象的某个属性值，可空，默认type
 *  divider: 'divider' // 指定节点分隔为节点对象的某个属性值，可空，默认divider
 * }
 *  x: 0, // 出现的x坐标，可空，会自动获取
 *  y: 0, // 出现的y坐标，可空，会自动获取
 * }).click((item) => {
 *  console.log(item);
 * })
 * 
 */

export default {
    isExpand: true,
    name: '$contextmenu',
    export: Contextmenu
}