<template>
  <avue-crud
    ref="table"
    :data="tableData"
    :page.sync="page"
    :option="tableOption"
    :table-loading="tableLoading"
    :search.sync="search"
    @search-change="onLoad"
    @on-load="onLoad"
  >
    <template slot-scope="scope" slot="amount_receivable"> ￥{{ scope.row.amount_receivable | numConvertToThousandths }}元 </template>
    <template slot-scope="scope" slot="amount_received"> ￥{{ scope.row.amount_received | numConvertToThousandths }}元 </template>
    <template slot-scope="scope" slot="quantity">
      {{ scope.row.quantity | numConvertToThousandths }}
    </template>
    <template slot-scope="scope" slot="unit_price"> ￥{{ scope.row.unit_price | numConvertToThousandths }}元 </template>
    <template slot-scope="{ row }" slot="remark">
      <pre>{{ row.remark }}</pre>
    </template>
    <template slot="menu" slot-scope="{ row }">
      <el-button type="text" size="small" icon="el-icon-edit" v-if="permissionList.invoiceBtn" @click="is_invoice(row)">开票 </el-button>
    </template>
  </avue-crud>
</template>
<script>
import website from "@/config/website"
import { mapGetters, mapState } from "vuex"
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonDetail,
  getMetaCommonListTree,
} from "@/api/busiMode/metaCommon"

export default {
  data() {
    return {
      model: "wisdom_park.wp_receipt_detail",
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableOption: {
        height: "250px",
        // calcHeight: 10,
        labelPosition: "top",
        searchMenuPosition: "right",
        searchMenuSpan: 24,
        border: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        // menu:false,
        header: true,
        refreshBtn: false,
        columnBtn: false,
        menuWidth: 150,
        column: [
          {
            label: "费用科目",
            prop: "fee_name",
            width: 150,
          },
          {
            label: "应收金额",
            prop: "amount_receivable",
            width: 150,
          },
          {
            label: "实收金额",
            prop: "amount_received",
            width: 150,
          },
          {
            label: "数量",
            prop: "quantity",
            width: 150,
          },
          {
            label: "单价",
            prop: "unit_price",
            width: 150,
          },
          {
            label: "单位",
            prop: "unit",
            width: 150,
          },
          {
            label: "实收时间",
            prop: "received_time",
            width: 150,
            formatter: (row, val) => (val ? val.split(" ")[0] : ""),
          },
          {
            label: "确认人",
            prop: "confirm_member",
            width: 150,
          },
          {
            label: "是否开票",
            prop: "is_invoice",
            width: 120,
            dicData: [
              { label: "否", value: 1 },
              { label: "是", value: 2 },
            ],
          },
          {
            label: "开票日期",
            prop: "invoice_date",
            width: 150,
          },
          {
            label: "摘要",
            prop: "remark",
            width: 200,
          },
          {
            label: "备注",
            prop: "note",
            width: 250,
          },
        ],
      },
      currentRow: null,
    }
  },
  props: {
    receiptNo: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        invoiceBtn: this.vaildData(this.permission.receipt_detail_invoice, !website.permission),
      }
    },
  },
  mounted() {},
  methods: {
    is_invoice(row) {
      let formOption = {
        column: [
          {
            label: "开票时间",
            prop: "invoice_date",
            type: "date",
            valueFormat: "yyyy-MM-dd",
            formar: "yyyy-MM-dd",
            prop: "invoice_date",
            value: row.invoice_date || dayjs().format("YYYY-MM-DD"),
            span: 24,
          },
          {
            label: "实收时间",
            prop: "received_time",
            type: "date",
            valueFormat: "yyyy-MM-dd",
            formar: "yyyy-MM-dd",
            value: row.received_time || dayjs().format("YYYY-MM-DD"),
            span: 24,
          },
          {
            label: "开票状态",
            prop: "is_invoice",
            type: "radio",
            dicData: [
              {
                label: "否",
                value: 1,
              },
              {
                label: "是",
                value: 2,
              },
            ],
            value: row.is_invoice === 2 ? 2 : 1,
            span: 24,
            rules: [{ required: true, message: "请选择 开票状态", trigger: "blur" }],
          },
        ],
      }
      this.$DialogForm.show({
        title: "开票",
        width: "500px",
        option: formOption,
        callback: (formRes) => {
          let data = {
            id: row.id,
          }
          formOption.column.forEach((item) => {
            data[item.prop] = formRes.data[item.prop]
          })
          let body = {
            update: data,
            primaryKeyValue: row.id,
            primaryKey: "id",
          }
          updateMetaCommon(this.model, body)
            .then((res) => {
              formRes.done()
              formRes.close()
              this.$message.success(res.data.msg)
              this.onLoad()
            })
            .catch((e) => {
              formRes.done()
            })
        },
      })
      this.currentRow = row
    },
    onLoad() {
      this.tableLoading = true
      this.tableData = []
      getMetaCommonList(this.model, {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: [{ field: "receipt_no", value: this.receiptNo }],
      })
        .then((res) => {
          this.tableLoading = false
          this.tableData = res.data.data.records
          this.page.total = res.data.data.total
        })
        .catch((e) => {
          this.tableLoading = false
        })
    },
  },
}
</script>
