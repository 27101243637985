// 1、将图片放在/public/assets/images/icon/ 下面的目录内(可新建目录)
  // 2、在项目根目录执行 node utils/createIcon.js 或 npm run createIcon 即可自动生成
  
  export default [
  "img-icon img-icon-theme-cw",
  "img-icon img-icon-theme-cxjs",
  "img-icon img-icon-theme-dwsw",
  "img-icon img-icon-theme-gtzy",
  "img-icon img-icon-theme-gyjt",
  "img-icon img-icon-theme-jjgl",
  "img-icon img-icon-theme-jsgf",
  "img-icon img-icon-theme-kjjy",
  "img-icon img-icon-theme-ldrs",
  "img-icon img-icon-theme-lyfw",
  "img-icon img-icon-theme-mzsq",
  "img-icon img-icon-theme-nysl",
  "img-icon img-icon-theme-qxsw",
  "img-icon img-icon-theme-symy",
  "img-icon img-icon-theme-whws",
  "img-icon img-icon-theme-wmxz",
  "img-icon img-icon-theme-xxcy",
  "img-icon img-icon-theme-zfjc",
  "img-icon img-icon-theme-zhdt",
  "img-icon img-icon-theme-zhzw",
  "img-icon img-icon-industry-hy_ck_icon",
  "img-icon img-icon-industry-hy_cs_icon",
  "img-icon img-icon-industry-hy_cy_icon",
  "img-icon img-icon-industry-hy_fdc_icon",
  "img-icon img-icon-industry-hy_gggl_icon",
  "img-icon img-icon-industry-hy_gj_icon",
  "img-icon img-icon-industry-hy_jm_icon",
  "img-icon img-icon-industry-hy_jr_icon",
  "img-icon img-icon-industry-hy_jy_icon",
  "img-icon img-icon-industry-hy_jz_icon",
  "img-icon img-icon-industry-hy_kxyj_icon",
  "img-icon img-icon-industry-hy_nl_icon",
  "img-icon img-icon-industry-hy_pf_icon",
  "img-icon img-icon-industry-hy_rq_icon",
  "img-icon img-icon-industry-hy_sl_icon",
  "img-icon img-icon-industry-hy_ws_icon",
  "img-icon img-icon-industry-hy_zl_icon",
  "img-icon img-icon-industry-hy_zzy_icon",
  "img-icon img-icon-ds-conditions",
  "img-icon img-icon-ds-data-assets",
  "img-icon img-icon-ds-data-development",
  "img-icon img-icon-ds-data-governance",
  "img-icon img-icon-ds-data-integration",
  "img-icon img-icon-ds-data-service",
  "img-icon img-icon-ds-dataquality",
  "img-icon img-icon-ds-datax",
  "img-icon img-icon-ds-dependent",
  "img-icon img-icon-ds-flink",
  "img-icon img-icon-ds-http",
  "img-icon img-icon-ds-mr",
  "img-icon img-icon-ds-procedure",
  "img-icon img-icon-ds-python",
  "img-icon img-icon-ds-shell",
  "img-icon img-icon-ds-spark",
  "img-icon img-icon-ds-sql",
  "img-icon img-icon-ds-sqoop",
  "img-icon img-icon-ds-sub_process",
  "img-icon img-icon-ds-waterdrop"
]
              