<template>
  <el-dropdown trigger="click" @command="handleSetLanguage">
    <i class="icon-zhongyingwen cur-p"></i>
    <el-dropdown-menu slot="dropdown" v-if="lang">
      <el-dropdown-item :disabled="lang === 'zh-CN'" command="zh-CN"
        >中文
      </el-dropdown-item>
      <el-dropdown-item :disabled="lang === 'en-US'" command="en-US"
        >English
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "top-lang",
  data() {
    return {
      lang: "",
    };
  },
  created() {},
  mounted() {
    this.lang = this.language;
    this.handleSetLanguage(this.language);
  },
  computed: {
    ...mapGetters(["language", "tag"]),
  },
  props: [],
  methods: {
    handleSetLanguage(lang) {
      this.lang = lang;
      this.$i18n.locale = lang;
      //if(lang=='')
      // if (this.$i18n.locale == "zh-CN" || this.$i18n.locale == "zh") {
      //   this.$i18n.locale = "en-US"; //关键语句
      //   this.lang = "en-US";
      // } else {
      //   this.$i18n.locale = "zh-CN"; //关键语句
      //   this.lang = "zh-CN";
      // }
      this.$store.commit("SET_LANGUAGE", lang);
      let tag = this.tag;
      let title = this.$router.$avueRouter.generateTitle(
        tag.label,
        (tag.meta || {}).i18n
      );
      //根据当前的标签也获取label的值动态设置浏览器标题
      this.$router.$avueRouter.setTitle(title);
    },
  },
};
</script>

<style lang="scss" scoped></style>
