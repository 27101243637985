import request from '@/router/axios';
const prefix = '/api/park-company/'

export const getItcToken = (data) => {
  return request({
    url: prefix + 'itcIntf/getItcToken',
    method: 'post',
    data: data
  })
}

export const getTgtLoginToken = (params) => {
  return request({
    url: prefix + 'hk/getTgtLoginToken',
    method: 'get',
    params
  })
}
