const baseUrl = '/api/data-asset/';
const prefix2 = '/api/convergeflexschduler/convergeflexschduler/'

export default {
  _baseUrl: baseUrl,
  _prefix2: prefix2,
  catalog: {
    top10: baseUrl + 'catalog/top10'
  },
  index: {
    totalAndIncrease: prefix2 + 'index/totalAndIncrease',
    dataRowProportion: prefix2 + 'dataDailyStatistics/dataRowProportion',
    dailyAndTotalCount: baseUrl + 'catalog/dailyAndTotalCount',
    taskCount: prefix2 + 'datamap/taskCount'
  }
}