import request from '@/router/axios';
import apiUrl from './apiUrl';

export default {
    datasourcesList(params){
        return request({
            method: 'get',
            params: params,
            url: apiUrl.dataMap.datasources
        })
    },
    taskCount(params){
        return request({
            method: 'get',
            params: params,
            url: apiUrl.dataMap.taskCount
        })
    },
    datahierarchy(params){
        return request({
            method: 'get',
            params: params,
            url: apiUrl.dataMap.datahierarchy
        })
    },
    getApps(params){
        return request({
            method: 'get',
            params: params,
            url: apiUrl.dataMap.apps
        })
    },
    getTables(id){
        return request({
            method: 'get',
            params: {},
            url: apiUrl.dataMap.table + id
        })
    }
}