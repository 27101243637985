<template>
  <el-popover placement="bottom" width="700" trigger="hover">
    <div slot="reference">
      <img
        src="/assets/images/app/main/map/weatherLingt/Sunny.png"
        width="24px"
        style="vertical-align: middle"
        alt=""
      />
      <span class="mar-lr-3">{{ dayWeather.weathertext }}</span>
      <span>{{ dayWeather.temperature }}℃</span>
    </div>
    <div
      class="weatherBox"
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
    >
      <el-carousel
        ref="carousel"
        v-if="weatherList.length > 0"
        trigger="click"
        height="210px"
        :autoplay="false"
        :loop="false"
      >
        <el-carousel-item
          v-for="(weatherDays, index) in weatherList"
          :key="index"
        >
          <div class="fiveday">
            <div class="day" v-for="(day, i) in weatherDays" :key="i">
              <h5>{{ handleWeatherTime(day.publictime) }}</h5>
              <p>
                <img
                  :src="handleWeatherImg(day.conditionDay.weathertext)"
                  :alt="day.conditionDay.weathertext"
                />
              </p>
              <p>{{ day.mintemp }}℃~{{ day.maxtemp }}℃</p>
              <p>
                {{
                  handleWeatherText(
                    day.conditionDay.weathertext,
                    day.conditionNight.weathertext
                  )
                }}
              </p>
              <p>
                {{ handleWindDir(day.conditionDay.winddir)
                }}{{ day.conditionDay.windlevel }}级
              </p>
              <p>湿度{{ day.conditionDay.humidity }}%</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <p v-if="weatherList.length == 0 && !loading" class="noData text-center">
        暂无数据
      </p>
    </div>
  </el-popover>
</template>

<script>
import { dateFormat } from "@/util/date";
export default {
  data() {
    return {
      loading: false,
      weatherList: [],
      time: null,
      dayWeather: {},
    };
  },
  created() {
    this.getMoreWeather();
  },
  methods: {
    iconClick(icon) {
      this.$copy(icon).then(() => {
        this.$message.success("已成功复制图标: " + icon);
      });
    },
    // 处理天气时间
    handleWeatherTime(time) {
      return (
        dateFormat(new Date(time), "yyyy-MM-dd") +
        "(周" +
        "日一二三四五六".charAt(new Date(time).getDay()) +
        ")"
      );
    },
    // 处理天气图片
    handleWeatherImg(imgName) {
      if (imgName.indexOf("晴天") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Sunny.png";
      } else if (imgName.indexOf("多云") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Cloudy.png";
      } else if (imgName.indexOf("阴") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Dreary.png";
      } else if (imgName.indexOf("阵雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Showers.png";
      } else if (imgName.indexOf("雷阵雨") >= 0) {
        return "/assets/images/app/main/weatherLingt/Thundershower.png";
      } else if (imgName.indexOf("雷阵雨伴有冰雹") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Thundershowerwithhail.png";
      } else if (imgName.indexOf("雨夹雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Rainandsnow.png";
      } else if (imgName.indexOf("小雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Lightrain.png";
      } else if (imgName.indexOf("中雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Moderaterain.png";
      } else if (imgName.indexOf("大雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Heavyrain.png";
      } else if (imgName.indexOf("暴雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Storm.png";
      } else if (imgName.indexOf("大暴雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Heavystorm.png";
      } else if (imgName.indexOf("特大暴雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Severestorm.png";
      } else if (imgName.indexOf("阵雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Snowshower.png";
      } else if (imgName.indexOf("小雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Lightsnow.png";
      } else if (imgName.indexOf("中雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Moderatesnow.png";
      } else if (imgName.indexOf("大雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Heavysnow.png";
      } else if (imgName.indexOf("暴雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Snowstorm.png";
      } else if (imgName.indexOf("雾") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Fog.png";
      } else if (imgName.indexOf("冻雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Freezingrain.png";
      } else if (imgName.indexOf("沙尘暴") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Duststorm.png";
      } else if (imgName.indexOf("小到中雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Lighttomoderaterain.png";
      } else if (imgName.indexOf("中到大雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Moderatetoheavyrain.png";
      } else if (imgName.indexOf("大到暴雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Heavyraintostorm.png";
      } else if (imgName.indexOf("暴雨到大暴雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Stormtoheavystorm.png";
      } else if (imgName.indexOf("大暴雨到特大暴雨") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Heavytoseverestorm.png";
      } else if (imgName.indexOf("小到中雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Lighttomoderatesnow.png";
      } else if (imgName.indexOf("中到大雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Moderatetoheavysnow.png";
      } else if (imgName.indexOf("大到暴雪") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Heavysnowtosnowstorm.png";
      } else if (imgName.indexOf("浮尘") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Dust.png";
      } else if (imgName.indexOf("扬沙") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Sand.png";
      } else if (imgName.indexOf("强沙尘暴") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Sandstorm.png";
      } else if (imgName.indexOf("霾") >= 0) {
        return "/assets/images/app/main/map/weatherLingt/Haze.png";
      } else {
        return;
      }
    },
    // 处理天气描述
    handleWeatherText(day, night) {
      if (day == night) {
        return day;
      } else {
        return day + "转" + night;
      }
    },
    // 处理风向
    handleWindDir(code) {
      switch (code) {
        case "NE":
          return "东北风";
          break;
        case "E":
          return "东风";
          break;
        case "SE":
          return "东南风";
          break;
        case "S":
          return "南风";
          break;
        case "SW":
          return "西南风";
          break;
        case "W":
          return "西风";
          break;
        case "NW":
          return "西北风";
          break;
        case "N":
          return "北风";
          break;
        default:
          return "";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/default-vars.scss";
@import "@/styles/vars.scss";

.icon-item {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $--color-primary;
    background: $--background-color-base;
  }
}
/deep/ .fiveday {
  display: flex;
  .day {
    flex: 1;
    > p,
    h5 {
      text-align: center;
      img {
        width: 40px;
        height: auto;
      }
    }
  }
}
</style>
