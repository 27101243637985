import {setToken, setRefreshToken, removeToken, removeRefreshToken} from '@/util/auth'
import {setStore, getStore} from '@/util/store'

const dynamic = {
  state: {
    guid: '',
    showNewPage:  false,

  },
  actions: {
    

  },
  mutations: {
    SET_GUID: (state, guid) => {
      // console.log(state , flag);
      state.guid = guid;
      setStore({name: 'guid', content: state.guid})
    },
    SET_SHOW_NEW: (state, showNewPage) => {
      // console.log(state , flag);
      state.showNewPage = showNewPage;
      setStore({name: 'showNewPage', content: state.showNewPage})
    },
   
  }

}
export default dynamic
