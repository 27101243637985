import request from '@/router/axios';

import { ApiUrls } from './api-url';

import store from '@/store';

class GriffinService{

  getMeasures(params){
    return this.get(ApiUrls.getMeasures , {
      projectId: store.state.dag.projectId,
      ...params
    });
  }
  getMeasuresList(params){
    return this.get(ApiUrls.getMeasuresList , params);
  }
  deleteMeasures(id){
    return this.delete(ApiUrls.measures + '/' + id);
  }
  saveMeasures(data){
    return this.post(ApiUrls.measures , data);
  }
  getJobs(params){
    return this.get(ApiUrls.getJobs , {
      projectId: store.state.dag.projectId,
      ...params
    });
  }
  getJobsList(params){
    return this.get(ApiUrls.getJobsList , params);
  }
  saveJob(data){
    return this.post(ApiUrls.jobs , data);
  }
  actionJob(id , param){
    if(param.action === 'trigger'){
      return this.post(ApiUrls.jobs + '/' + param.action + '/' + id , {});
    }else{
      return this.put(ApiUrls.jobs + '/' + id , {} , param);
    }
  }

  deleteJob(id){
    return this.delete(ApiUrls.jobs + '/' + id);
  }
  getJobInstances(params){
    return this.get(ApiUrls.jobInstances , params);
  }

  getTableNames(){
    return this.get(ApiUrls.tables.names);
  }

  getTableMetadata(params){
    return this.get(ApiUrls.tables.table , params);
  }

  createHiveUdfFunc(dbName , funName){
    return this.post(ApiUrls.createHiveUdfFunc + dbName + '/' + funName , {});
  }

  getFuncs(){
    return this.get(ApiUrls.funcs , {
      type: 'HIVE'
    });
  }

  post(url , body , params , option){
    option = option || {};
    return request({
      url: url,
      method: 'post',
      ...option,
      data: body || {},
      params: params || {}
    });
  }
  put(url , body , params , option){
    option = option || {};
    return request({
      url: url,
      method: 'put',
      ...option,
      data: body || {},
      params: params || {}
    });
  }

  delete(url , params , option){
    option = option || {};
    return request({
      url: url,
      method: 'delete',
      ...option,
      params: params || {}
    });
  }

  get(url , params , option){
    option = option || {};
    return request({
      url: url,
      method: 'get',
      ...option,
      params: params || {}
    });
  }

  getBrowserTimeZone() {
    let time = -(new Date().getTimezoneOffset() / 60);
    return "GMT" + (time >= 0 ? "+"+time : time) + ":00";
  }

}

export const griffinService = new GriffinService();