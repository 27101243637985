<template>
  <div class="my-process-palette h-100p">
    <div
      style="height: 40px; font-size: 14px; line-height: 40px; display: flex"
    >
      <span v-if="!minPanel" style="flex: 1"
        ><i class="el-icon-menu"></i> 面板</span
      >
      <div v-if="minPanel" class="text-center w-100p bor-b fts-26">
        <el-link @click="minPanel = false" :underline="false"
          ><i class="el-icon-s-unfold"></i
        ></el-link>
      </div>
      <div style="padding: 0 10px" v-if="!minPanel">
        <el-link @click="minPanel = true" :underline="false"
          ><i class="el-icon-s-fold"></i
        ></el-link>
      </div>
    </div>
    <el-collapse v-if="!minPanel">
      <template v-for="item of entries">
        <el-collapse-item
          v-if="item.groupCode !== 'tools'"
          :key="item.groupName"
          :title="item.groupName"
          :name="item.groupName"
          style="width: 100%"
        >
          <div style="padding: 10px 0">
            <div
              v-for="entrie of item.entries"
              :key="entrie.title"
              draggable="true"
              class="custom-button text-center"
              @click="entrieClick($event, entrie)"
              @mousedown="entrieClick($event, entrie)"
            >
              <i class="custom-button-icon" :class="entrie.className"></i>
              <span class="custom-button-text">{{ entrie.title }}</span>
            </div>
          </div>
        </el-collapse-item>
      </template>
    </el-collapse>

    <div v-if="minPanel" class="ov-a" style="height: calc(100% - 40px)">
      <template v-for="item of entries">
        <el-tooltip
          v-for="entrie of item.entries"
          :key="entrie.title"
          class="item"
          effect="light"
          :content="entrie.title"
          placement="right"
        >
          <div
            draggable="true"
            class="custom-button-min fts-26 lh-40 text-center"
            @click="entrieClick($event, entrie)"
            @mousedown="entrieClick($event, entrie)"
          >
            <i style="height: 40px" :class="entrie.className"></i>
          </div>
        </el-tooltip>
      </template>
    </div>
  </div>
</template>

<script>
import { assign } from "min-dash";

export default {
  name: "MyProcessPalette",
  inject: ["BpmnMain"],
  data() {
    return {
      entries: [],
      modeler: null,
      minPanel: false,
      groupCode: "process",
    };
  },
  props: {
    showType: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  watch: {
    minPanel: {
      immediate: true,
      handler() {
        this.$emit("paletteWidth", this.minPanel ? "60px" : "200px");
      },
    },
    showType: {
      immediate: true,
      handler(val) {
        if (val == "business") {
          this.minPanel = true;
          this.groupCode = "business";
        }
      },
    },
  },
  mounted() {
    this.BpmnMain.ready().then((modeler) => {
      if (modeler) {
        this.modeler = modeler;
        this.initEntries();
      }
    });
  },
  methods: {
    initEntries() {
      const Palette = this.modeler.get("customPalette");
      const translate = this.modeler.get("translate");
      const entries = {};
      if (Palette) {
        Object.values(Palette.getPaletteEntries()).forEach((item) => {
          if (!item.separator) {
            if (item.group && !entries[item.group]) {
              entries[item.group] = {
                groupName: translate(item.group),
                groupCode: item.group,
                entries: [],
              };
            }
            entries[item.group].entries.push(item);
          }
        });
      }
      this.entries = Object.values(entries);
    },
    entrieClick(event, item) {
      if (!item.type) {
        item.action.click(event);
      } else {
        this.createElement(event, item.type);
      }
    },
    createElement(event, type, options = {}) {
      const ElementFactory = window.bpmnInstances.elementFactory;
      const create = window.bpmnInstances.modeler.get("create");
      type = type.indexOf(':') > -1 ? type : `bpmn:${type}`;
      const shape = ElementFactory.createShape(
        assign({ type: type }, options)
      );
      if (options) {
        shape.businessObject.di.isExpanded = options.isExpanded;
      }
      create.start(event, shape);
    },
    startTool(event, type) {
      if (type === "handTool") {
        window.bpmnInstances.modeler.get("handTool").activateHand(event);
      }
      if (type === "lassoTool") {
        window.bpmnInstances.modeler.get("lassoTool").activateSelection(event);
      }
      if (type === "connectTool") {
        window.bpmnInstances.modeler.get("globalConnect").toggle(event);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.my-process-palette {
  box-sizing: border-box;
  padding: 8px;
  .custom-button {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    display: flex;
    line-height: 40px;
    .custom-button-icon {
      display: block;
      padding: 0 5px;
      font-size: 22px;
      width: 22px;
      box-sizing: content-box;
    }
    .custom-button-text {
      display: block;
      font-size: 12px;
    }
    // &:nth-child(2n) {
    //   margin-left: 4%;
    // }
    // &:first-child {
    //   margin-top: 8px;
    // }
    &:hover {
      border-color: rgba(24, 144, 255, 0.8);
    }
  }
  .custom-button-min {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    i {
      flex: 1;
      width: 100%;
      display: block;
    }
    &:hover {
      i {
        color: rgba(24, 144, 255, 0.8);
      }
    }
  }
}
</style>
