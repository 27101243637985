export default [
  {
    name: '科技蓝',
    code: 'theme-cool',
    default: true
  },
  {
    name: '火焰蓝',
    code: 'theme-fb'
  },
  {
    name: '星空黑',
    code: 'theme-yl'
  }
]