import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/codroom/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/codroom/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getSportgroundPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/codroom/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/codroom/detail/' + id,
    method: 'get',
    params: {
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/park-spcod/codroom/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/codroom/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/codroom/submit',
    method: 'post',
    data: row
  })
}

export const updateRoomStatus = (id) => {
  return request({
    url: '/api/park-spcod/codroom/updateroom/' + id,
    method: 'post',
    data: {}
  })
}

//检验后删除
export const doCheckRemove = (id) => {
  return request({
    url: '/api/park-spcod/codroom/removeone/'+id,
    method: 'post'
  })
}
