import request from '@/router/axios';

const baseUrl = '/api/convergeflexschduler/convergeflexschduler/';

export const listDataModel = (params) => {
  return request({
    url:baseUrl + 'datamodel/list-paging',
    method: 'get',
    params: params
  })
};


export const queryDataBaseByDataModelId = (params) => {
  return request({
    url:baseUrl + 'datamodel/queryDataBaseByDataModelId',
    method: 'post',
    params: params
  })
}

export const queryTablesByDataBaseId = (params) => {
  return request({
    url:baseUrl + 'datamodel/queryTablesByDataBaseId',
    method: 'post',
    params: params
  })
}

export const createDataModel = (data) => {
  return request({
    url:baseUrl + 'datamodel/create',
    method: 'post',
    params: data
  })
}

export const updateDataModel = (data) => {
  return request({
    url:baseUrl + 'datamodel/update',
    method: 'post',
    params: data
  })
}

export const deleteDataModel = (params) => {
  return request({
    url:baseUrl + 'datamodel/delete',
    method: 'get',
    params: params
  })
}

export const queryDataByDataBaseIdAndTableName = (data) => {
  return request({
    url:baseUrl + 'datamodel/queryDataByDataBaseIdAndTableName',
    method: 'post',
    params: data
  })
}
