import request from "@/router/axios";
const prefix = "/api/jida-meta";
export const getMetaCommonInfo = (modelName, params) => {
  return request({
    url: prefix + `/common-ops/info/` + modelName,
    method: "post",
    data: params,
  });
};
export const getPicListByIds = (ids) => {
  return request({
    url: `/api/jida-resource/attach/listByIds/` + "?ids=" + ids,
    method: "post",
    params: {},
  });
};
export const getMetaCommonList = (modelName, params) => {
  return request({
    url: prefix + `/common-ops/list/` + modelName,
    method: "post",
    data: params,
  });
};
export const getMetaCommonListExport = (modelName, params) => {
  return request({
    url: prefix + `/common-ops/listExport/` + modelName,
    responseType: "blob",
    headers: { "Content-Type": "application/json" },
    method: "post",
    data: params,
  });
};
export const getMetaCommonListByDict = (url, params) => {
  return request({
    url: url,
    method: "post",
    data: params,
  });
};
// /jida-meta/metaCommon/{metaModel}/list
export const getMetaCommonDetail = (metaModel, params) => {
  return request({
    url: prefix + `/common-ops/info/` + metaModel,
    method: "post",
    data: params,
  });
};

export const saveMetaCommon = (modelName, params) => {
  return request({
    url: prefix + `/common-ops/save/` + modelName,
    method: "post",
    data: params,
  });
};
export const updateMetaCommon = (modelName, params) => {
  return request({
    url: prefix + `/common-ops/updateById/` + modelName,
    method: "post",
    data: params,
  });
};
export const getMetaCommonTree = (modelName, params) => {
  return request({
    url: prefix + `/common-ops/tree/` + modelName,
    method: "post",
    data: params,
  });
};

export const getMetaCommonListTree = (modelName, params) => {
  return request({
    url: prefix + `/common-ops/list-tree/` + modelName,
    method: "post",
    data: params,
  });
};

export const delMetaCommon = (modelName, params) => {
  return request({
    url: prefix + `/common-ops/remove/` + modelName,
    method: "post",
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
    // transformRequest: [data => {
    //   let formData = new FormData();
    //   for(let key in data){
    //     formData.append(key, data[key])
    //   }
    //   return formData
    // }],
    data: params,
  });
};

export const endowAccess = (id) => {
  return request({
    url: "/api/park-company/companycheckin/addHKUserAndAuth",
    method: "get",
    params: {
      id,
    },
  });
};

export const getMetaListUrl = (modelName) => {
  return prefix + `/common-ops/list/` + modelName;
};

export const sendMsg = (data) => {
  return request({
    url: "/api/park-spcod/noticeMsg/sendMsg",
    method: "post",
    data: data,
  });
};
