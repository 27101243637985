export const numConvert = (num , toFixed , thousandths) => {
  toFixed = toFixed === undefined ? 2 : toFixed;
  thousandths = thousandths === undefined ? true : thousandths;
 return numConvertToStr(num , toFixed , thousandths) + numConvertToUnit(num);
}
export const numConvertToThousandths = (num , str) => {
  if(num !== null && num !== undefined){
    let re = /\d{1,3}(?=(\d{3})+$)/g;
    let n1 = (''+num).replace(/^(\d+)((\.\d+)?)$/,function(s,s1,s2){return s1.replace(re,"$&,")+s2;});
    return n1;
  }else{
    return str !== undefined ? str : '-';
  }
}
export const numConvertToStr = (num , toFixed , thousandths , unit) => {
  toFixed = toFixed === undefined ? 2 : toFixed;
  let resNum = null;
  if(isNaN(num)){
    return num;
  }
  if(num > 99999999){
    resNum = (num / 100000000).toFixed(toFixed);
  }else if(num < 100000000 && num > 9999){
    resNum = (num / 10000).toFixed(2);
  }else{
    resNum = num;
  }
  thousandths = thousandths === undefined ? false : !!thousandths;
  resNum = thousandths ? numConvertToThousandths(resNum) : Number(resNum);
  resNum = unit ? resNum + numConvertToUnit(num) : resNum;
  return resNum;
}
export const numConvertToUnit = (num) => {
  if(num > 99999999){
    return '亿';
  }else if(num < 100000000 && num > 9999){
    return '万'
  }else{
    return '';
  }
}

export const formatBankNum = (num , separator) => {
  num = `${num}`;
  separator = separator || ' ';
  return num.replace(/(\d{4})/g , ($1) => {
    return $1 + separator;
  });
}

export const toFixed = (num , toFixed) => {
  return Number(num).toFixed(toFixed || 2)
}