import axios from '@/router/axios'
import apiUrls from './apiUrls';

export default {
  apiUrls: apiUrls,
  listLabel: () => {
    return axios.get(apiUrls.list)
  },
  treeLabel: () => {
    return axios.get(apiUrls.tree)
  },
  saveLabel(){
    return axios.get(apiUrls.list)
  },
  listLabelResourceRelation(params){
    return axios({
      method: 'get',
      params: params,
      url: apiUrls._basePrefix + 'labelResourceRelation/list'
    })
  },
  removeLabelResourceRelation(params){
    return axios({
      method: 'post',
      params: params,
      url: apiUrls._basePrefix + 'labelResourceRelation/remove'
    })
  },
  saveLabelResourceRelation(params){
    return axios({
      method: 'post',
      data: params,
      url: apiUrls._basePrefix + 'labelResourceRelation/save'
    })
  }
}