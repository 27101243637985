import request from '@/router/axios';



export const getCommList = (model,code) => {
  return request({
    url: '/api/jida-meta/common-ops/'+model+'/list',
    method: 'post',
    data: code
    
  })
}
export const updateComm = (model,row) => {
  return request({
    url: '/api/jida-meta/common-ops/'+model+'/update',
    method: 'post',
    data: row
  })
}
export const removeComm = (model,ids) => {
  return request({
    url: '/api/jida-meta/common-ops/'+model+'/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const addComm = (model,row) => {
  return request({
    url: '/api/jida-meta/common-ops/'+model+'/save',
    method: 'post',
    data: row
  })
}


