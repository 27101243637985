<template>
  <el-form-item v-if="isForm" class="text-right mar-t-40 mar-r-20">
    <el-button
      v-for="(btn , index) of buttons"
      :disabled="btn.loading || _loading || loadings[btn.code] || (btn.code === 'submit' && submitLoading)"
      :loading="btn.loading || _loading || loadings[btn.code] || (btn.code === 'submit' && submitLoading)"
      :type=" btn.type ? btn.type : btn.code === 'submit' ? 'primary' : ''"
      :icon=" btn.icon ? btn.icon : btn.code === 'submit' ? submitIcon : btn.code === 'cancel' ? cancelIcon : ''"
      @click.prevent="buttonClick(btn)"
      :size="size"
      :key="index"
    >{{$t(btn.text ? btn.text : btn.code === 'submit' ? submitText : btn.code === 'cancel' ? cancelText : '')}}</el-button>
  </el-form-item>
  <div v-else class="text-right mar-t-40 mar-r-20">
    <el-button
      v-for="(btn , index) of buttons"
      :disabled="btn.loading || _loading || loadings[btn.code] || (btn.code === 'submit' && submitLoading)"
      :loading="btn.loading || _loading || loadings[btn.code] || (btn.code === 'submit' && submitLoading)"
      :type=" btn.type ? btn.type : btn.code === 'submit' ? 'primary' : ''"
      :icon=" btn.icon ? btn.icon : btn.code === 'submit' ? submitIcon : btn.code === 'cancel' ? cancelIcon : ''"
      @click.prevent="buttonClick(btn)"
      :size="size"
      :key="index"
    >{{$t(btn.text ? btn.text : btn.code === 'submit' ? submitText : btn.code === 'cancel' ? cancelText : '')}}</el-button>
  </div>
</template>
<script>

export default {
  name: 'basic-form-button',
  data(){
    return {
      onLoading: false
    }
  },
  props: {
    buttons: {
      type: Array,
      default: function() {
        return [
          {text: '' , code: 'cancel' , icon: '' , type: ''},
          {text: '' , code: 'submit' , icon: '' , type: 'primary'}
        ];
      }
    },
    submitText: {
      default: 'crud.saveBtn',
      type: String
    },
    cancelText: {
      default: 'crud.cancelBtn',
      type: String
    },
    submitIcon: {
      default: 'el-icon-circle-plus-outline',
      type: String
    },
    cancelIcon: {
      default: 'el-icon-circle-close',
      type: String
    },
    // 在非表单包裹时使用将该值配置为false
    isForm: {
      default: true,
      type: Boolean
    },
    // 按钮的尺寸
    size: {
      type: String,
      default: 'small'
    },
    // 控制所有按钮的loading状态
    loading: {
      type: Boolean,
      default: null
    },
    /**
     * @description 接受一个对象用于控制每个按钮的loading状态
     * @typedef {[buttonCode]: Boolean}
     * @example {
     *  submit: true, // 提交按钮显示loading状态
     *  cancel: false
     * }
     */
    loadings: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 单独控制code为submit的按钮loading状态
    submitLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _loading(){
      return this.loading === null ? this.onLoading : this.loading;
    }
  },
  methods: {
    buttonClick(btn){
      if(btn){
        if(btn.code !== 'cancel'){
          this.onLoading = true;
        }
        this.$emit(btn.code , () => {
          this.onLoading = false
        });
        this.$emit('click' , btn.code , () => {
          this.onLoading = false
        });
      }
    }
  }
}
</script>