import Vue from 'vue';
import Main from './main.vue';

let BasicContextmenuConstructor = Vue.extend(Main);
let instance = null , mouseX = 0 , mouseY = 0;

document.addEventListener('contextmenu' , function(ev){
    mouseX = ev.x;
    mouseY = ev.y;
} , true)

document.addEventListener('click' , function(ev){
    close();
} , true);

const close = function(){
    if(instance){
        instance.close();
        instance = null;
    }
}

const Contextmenu = function(options){

    if (Vue.prototype.$isServer) return;

    if(options instanceof Array){
        options = {
            menus: options
        }
    }

    let x = options.x || mouseX || 0;
    let y = options.y || mouseY || 0;

    // delete options.x;
    // delete options.y;

    close();

    instance = new BasicContextmenuConstructor({
        propsData: {
            ...options,
        },
        data: {
            _isServer: true
        }
    })
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.dom = instance.$el;
    console.log(instance.dom)
    instance.dom.style.left = x + 'px';
    instance.dom.style.top = y + 'px';
    return Contextmenu;
}

Contextmenu.open = function(options){
    return Contextmenu(options);
}
Contextmenu.close = function(){
    close();
    return Contextmenu;
}
Contextmenu.click = function(callback){
    if(instance){
        instance.onClick = callback;
    }
    return Contextmenu;
}


export default Contextmenu;