<template>
  <div>自定义</div>
</template>

<script>
export default {
  components: {},
  name: "custIndex",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  props: [],
  methods: {},
};
</script>
