import request from '@/router/axios';

export const getOverViewData = (params) => {
  return request({
    url:'/api/',
    method: 'get',
    params
  })
};
export const getLevelStaticsData = (params) => {
  return request({
    url:'/api/',
    method: 'get',
    params
  })
};

export const getFileTopData = (params) => {
  return request({
    url:'/api/',
    method: 'get',
    params
  })
};
export const getDBTopData = (params) => {
  return request({
    url:'/api/',
    method: 'get',
    params
  })
};
