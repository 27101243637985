<template>
  <basic-container
    class="h-100p"
    :body-style="{ padding: '0', height: '100%' }"
  >
    <bpmn-main
      v-on="$listeners"
      v-bind="$attrs"
    >
      <template v-for="(item , key) of $scopedSlots" v-slot:[key]="data">
        <slot :name="key" v-bind="data"></slot>
      </template>
    </bpmn-main>
  </basic-container>
</template>
<script>
import BpmnMain from "@/bpmn/main";

export default {
  components: { BpmnMain },
  data() {
    return {};
  },
  mounted() {

  },
  methods: {
    
  },
};
</script>
