<template>
    <div>
        <div style="height: 67vh; overflow-x: hidden !important" class="ov-a mar-t-10f pad-15">
            <el-form class="pad-10" :model="ruleForm" ref="ruleForm" :disabled="doType == 'detail'" label-width="100px" label-position="top" size="small" :rules="ruleModel"
                     :validate-on-rule-change="false">
                <el-row>
                    <el-col :span="6" v-if="!companyNode.id">
                        <el-form-item label="所属部门/公司" prop="title">
                            <el-input disabled v-model="
                  ruleForm.company_name || ruleForm.dept_name || node.title
                " placeholder="请输入所属部门/公司"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="员工姓名" prop="name" :rules="[{ required: true, message: '请输入员工姓名', trigger: 'blur' },
            { min: 0, max: 20, message: '长度最多是20个字符', trigger: 'blur' },
            { pattern:/^[\u4e00-\u9fa5a-zA-Z]+$/,message: '请勿输入数字或特殊字符' , trigger: 'blur'  }]">
                            <el-input v-model="ruleForm.name" placeholder="请输入员工姓名"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="工号" prop="job_num">
                            <el-input placeholder="请输入工号" v-model="ruleForm.job_num"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="入职时间">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="ruleForm.employment_date" type="date" placeholder="选择入职时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">

                        <el-form-item label="联系方式" prop="contact_details" :rules="[{ required: true, message: '请输入联系方式' , trigger: 'blur' , }, { pattern:
                                      /^1[3456789]\d{9}$/,
                                      message: '请输入正确的联系方式' , trigger: 'blur'  } ]">
                            <el-input :disabled="doType == 'update'" placeholder="请输入联系方式" v-model="ruleForm.contact_details"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="身份证号码" prop="identity_card" :rules="[ { pattern:
                                      /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
                                      message: '请输入正确的身份证号码' , trigger: 'blur'  } ]">
                            <el-input placeholder="请输入身份证号码" v-model="ruleForm.identity_card"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="政治面貌" prop="political_status">
                            <el-select v-model="ruleForm.political_status" size="small" placeholder="请选择政治面貌">
                                <el-option v-for="item in politicalStatuss" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="性别">
                            <el-radio-group v-model="ruleForm.sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <!-- <el-form-item label="年龄" prop="age"> -->
                        <!-- <el-input
                text="number"
                v-model.number="ruleForm.age"
                placeholder="请输入年龄"
              ></el-input> -->
                        <el-form-item label="出生日期">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="ruleForm.birthday" type="date" placeholder="选择出生日期">
                            </el-date-picker>
                        </el-form-item>
                        <!-- </el-form-item> -->
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="最高学历" prop="highest_education">
                            <el-select v-model="ruleForm.highest_education" size="small" placeholder="请选择最高学历">
                                <el-option v-for="item in highestDducationList" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="居住地址" prop="address">
                            <el-input type="textarea" :rows="5" placeholder="请输入居住地址" v-model="ruleForm.address"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row class="pad-l-25 pad-r-25">
                <el-divider content-position="left">照片</el-divider>
                <div class="pad-10" v-if="imgform1.imgUrl.length == 0">暂无照片</div>
                <avue-form :option="option" v-model="imgform1"></avue-form>
                <el-divider content-position="left">附件</el-divider>
                <div class="pad-10" v-if="imgform.imgUrl.length == 0">暂无附件</div>
                <avue-form :option="option1" v-model="imgform"></avue-form>
            </el-row>
        </div>

        <div class="btnClass bor-t">
            <el-button size="small" type="" class="pull-right mar-t-10" @click="close">取消</el-button>

            <el-button v-if="doType != 'detail'" size="small" class="pull-right mar-t-10 mar-r-10" type="primary" :loading="saveLoading" @click="save">保存</el-button>
        </div>
    </div>
</template>

<script>
import * as dictbizService from '@/api/system/dictbiz';
import standardService from '@/api/metadata/standard';
import { mapGetters, mapState } from 'vuex';

import { getMetaCommonInfo, saveMetaCommon, updateMetaCommon, getPicListByIds } from '@/api/busiMode/metaCommon';
import { getEmployeeDetail } from '@/api/user';
export default {
    data() {
        return {
            ruleModel: {},
            saveLoading: false,
            option1: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,

                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },

            option2: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            option: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,
                        limit: 1,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传一张jpg/png用户头像，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            imgform: {
                imgUrl: [],
            },
            imgform1: {
                imgUrl: [],
            },
            dialogImageUrl: '',
            dialogVisible: false,
            ruleForm: {},
            params: {
                zoom: 4,
                zoomEnable: false,
                dragEnable: true,
            },
            highestDducationList: [],
            enterpriseTypes: [],
            qualifications: [],
            politicalStatuss: [],
            entModel: 'wisdom_park.wp_employee',
            key: 1,
            companyName: '',
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    watch: {
        currentRow: {
            immediate: true,
            handler(val) {
                if (val.id) {
                    this.getMetaCommonInfo();
                }
            },
        },
        companyNode: {
            //企业入驻用到
            immediate: true,
            handler(val) {
                if (val.id) {
                }
            },
        },

        doType: {
            immediate: true,
            handler(val) {
                if (val == 'detail') {
                    this.option1.disabled = true;
                    this.option.disabled = true;
                    this.option1 = JSON.parse(JSON.stringify(this.option1));
                    this.option = JSON.parse(JSON.stringify(this.option));
                } else {
                    this.option1.disabled = false;
                    this.option.disabled = false;
                    this.option1 = JSON.parse(JSON.stringify(this.option1));
                    this.option = JSON.parse(JSON.stringify(this.option));
                }
                if (val == 'add') {
                    this.ruleForm = {};
                }
            },
        },
    },
    mounted() {
        this.getDicList();
        this.queryByModelCode();
        if (this.currentRow.id) {
            this.getMetaCommonInfo();
        }
        this.getMetaCommonInfos();
    },
    props: {
        identity: {
            type: Number,
            default() {
                return 0;
            },
        },
        doType: {
            type: String,
            default() {
                return '';
            },
        },

        currentRow: {
            type: Object,
            default() {
                return {};
            },
        },
        node: {
            type: Object,
            default() {
                return {};
            },
        },
        company: {
            type: Array,
            default() {
                return [];
            },
        },
        companyNode: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    methods: {
        validateQueryFormRules(rule, value, callback) {
            if (!this.specialCharacters(value)) return callback(new Error('不能含有特殊字符！！'));
            callback();
        },

        specialCharacters(str) {
            if (!str) return true;

            const specialKey = "[`~!#$^&*()=|{}': ; '\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？‘']";
            for (var i = 0; i < str.length; i++) if (specialKey.indexOf(str.substr(i, 1)) != -1) return false;

            return true;
        },
        getMetaCommonInfos() {
            if (this.userInfo.companyId) {
                let obj = {
                    pk: 'id',
                    id: this.userInfo.companyId,
                };
                getMetaCommonInfo('wisdom_park.wp_company', obj).then(res => {
                    if (res.data.code == 200) {
                        this.companyName = res.data.data.name;
                    }
                });
            }
        },
        queryByModelCode() {
            standardService.getModelRule(this.entModel).then(res => {
                this.ruleModel = res;
            });
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'highestDducation',
                })
                .then(res => {
                    this.highestDducationList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'politicalStatus',
                })
                .then(res => {
                    this.politicalStatuss = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        getMetaCommonInfo() {
            let obj = {
                pk: 'id',
                id: this.currentRow.id,
            };
            getMetaCommonInfo(this.entModel, obj).then(res => {
                if (res.data.code == 200) {
                    if (res.data.data.company_name == '') {
                        res.data.data.company_name = res.data.data.dept_name;
                    }
                    this.ruleForm = res.data.data;
                    this.height = res.data.data.floor_height;
                    if (res.data.data['attachment_id']) {
                        getPicListByIds(res.data.data['attachment_id']).then(res => {
                            if (res.data.code == 200) {
                                let arr = [];
                                res.data.data.forEach(pic => {
                                    arr.push({
                                        label: pic.id,
                                        value: pic.link,
                                    });
                                });
                                this.imgform.imgUrl = arr;
                            }
                        });
                    }
                    if (res.data.data['avatar']) {
                        getPicListByIds(res.data.data['avatar']).then(res => {
                            if (res.data.code == 200) {
                                let arr = [];
                                res.data.data.forEach(pic => {
                                    arr.push({
                                        label: pic.id,
                                        value: pic.link,
                                    });
                                });
                                this.imgform1.imgUrl = arr;
                            }
                        });
                    }
                }
            });
        },
        close() {
            this.$emit('close');
        },
        submit() {
            return new Promise(resolve => {
                this.$refs['ruleForm'].validate(valid => {
                    if (valid) {
                        resolve({
                            valid: true,
                            form: this.ruleForm,
                        });
                    } else {
                        let fields = this.$refs['ruleForm'].fields,
                            firstError = {
                                field: '',
                                message: '',
                                name: '',
                            };
                        for (let i = 0; i < fields.length; i++) {
                            if (fields[i].validateState === 'error') {
                                firstError.message = fields[i].validateMessage;
                                firstError.field = fields[i].prop;
                                firstError.name = fields[i].label;
                                break;
                            }
                        }
                        resolve({
                            valid: false,
                            form: this.ruleForm,
                            error: firstError,
                        });
                    }
                });
            });
        },
        save() {
            Promise.all([this.submit()]).then(forms => {
                let valid = true,
                    errorObj = null;
                forms.forEach(itemForm => {
                    if (itemForm.valid !== true) {
                        valid = false;
                        if (!errorObj) {
                            errorObj = itemForm.error;
                        }
                    }
                });
                if (valid) {
                    this.saveLoading = true;
                    console.log(forms);
                    //照片
                    if (this.imgform) {
                        let imgList = [];
                        this.imgform.imgUrl.forEach(e => {
                            if (e.label) {
                                imgList.push(e.label);
                            }
                        });
                        this.ruleForm.attachment_id = imgList.toString();
                    }
                    if (this.imgform1) {
                        let imgList = [];
                        this.imgform1.imgUrl.forEach(e => {
                            if (e.label) {
                                imgList.push(e.label);
                            }
                        });
                        this.ruleForm.avatar = imgList.toString();
                    }

                    if (this.company.length == 0) {
                        console.log(this.company, this.node, this.currentRow);
                        this.ruleForm.dept_id = this.currentRow.dept_id || this.node.id;
                        this.ruleForm.dept_name = this.currentRow.dept_name || this.node.title;
                        this.ruleForm.company_id = this.currentRow.company_id || this.node.id;
                        this.ruleForm.company_name = this.currentRow.company_name || this.node.title;
                    }
                    if (this.company.length > 0) {
                        this.company.forEach(e => {
                            if (e.dept_id == this.node.id) {
                                this.ruleForm.dept_id = e.dept_id;
                                this.ruleForm.dept_name = e.dept_name;
                                this.ruleForm.company_id = e.id;
                                this.ruleForm.company_name = e.dept_name;
                            }
                            if (e.dept_id == this.node.parentId) {
                                this.ruleForm.dept_id = e.dept_id;
                                this.ruleForm.dept_name = e.title;
                                this.ruleForm.company_id = e.id;
                                this.ruleForm.company_name = e.title;
                            }
                        });
                    }

                    if (this.identity || this.identity == 0) {
                        console.log(this.identity, 'identity');
                        this.ruleForm.company_identity = this.identity;
                    }
                    if (this.companyNode.id) {
                        console.log(this.companyNode);
                        this.ruleForm.company_id = this.companyNode.id;
                        this.ruleForm.company_name = this.companyName;
                        this.ruleForm.dept_name = this.companyName;
                        this.ruleForm.dept_id = this.companyNode.dept_id;
                    }
                    if (this.ruleForm.dept_id == 'all') {
                        delete this.ruleForm.dept_id;
                    }
                    delete this.ruleForm.age;
                    if (this.ruleForm.id) {
                        let o = {
                            update: this.ruleForm,
                            primaryKeyValue: this.ruleForm['id'],
                            primaryKey: 'id',
                        };
                        updateMetaCommon(this.entModel, o)
                            .then(res => {
                                if (res.data.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '操作成功',
                                    });
                                    if (res.data.data.id) {
                                        this.$emit('close');
                                    } else {
                                        this.$emit('close');
                                    }
                                }
                                this.saveLoading = false;
                            })
                            .catch(e => {
                                this.saveLoading = false;
                            });
                    } else {
                        if (!this.ruleForm.contact_details) {
                            this.$message({
                                type: 'error',
                                message: '请填手机号',
                            });
                            this.saveLoading = false;
                            return false;
                        }
                        if (!this.ruleForm.name) {
                            this.$message({
                                type: 'error',
                                message: '请填名称',
                            });
                            this.saveLoading = false;
                            return false;
                        }
                        getEmployeeDetail(this.ruleForm.contact_details).then(res => {
                            console.log(res.data.data);
                            if (res.data.data && JSON.stringify(res.data.data) != '{}') {
                                this.$message({
                                    type: 'error',
                                    message: '请校验手机号的唯一性',
                                });
                                this.saveLoading = false;
                            } else {
                                saveMetaCommon(this.entModel, {
                                    values: this.ruleForm,
                                })
                                    .then(res => {
                                        if (res.data.code == 200) {
                                            if (res.data.data.id) {
                                                this.$emit('close');
                                                this.$message({
                                                    type: 'success',
                                                    message: '操作成功',
                                                });
                                            } else {
                                                this.$emit('close');
                                            }
                                        }
                                        this.saveLoading = false;
                                    })
                                    .catch(e => {
                                        this.saveLoading = false;
                                    });
                            }
                        });
                    }
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
    margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
    left: 0px;
}
/deep/ .el-divider__text {
    padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
    width: 68px;
    height: 68px;
    line-height: 72px;
    vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 68px;
    height: 68px;
}
.btnClass {
    display: flow-root;
    height: 50px;
    line-height: 50px;
    padding: 0px 15px;
}
/deep/ .el-select {
    width: 100%;
}
</style>
