import request from '@/router/axios';
import website from "@/config/website";

export const loginByUsername = (tenantId, username, password, type, key, code) => request({
  url: '/api/jida-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Captcha-Key': key,
    'Captcha-Code': code,
  },
  params: {
    tenantId,
    username,
    password,
    grant_type: (website.captchaMode ? "captcha" : "password"),
    scope: "all",
    type
  }
});
export const getCaptchaMode = () => request({
  url: '/api/jida-system/param/captcha',
  method: 'get'
});
export const loginBySocial = (tenantId, source, code, state) => request({
  url: '/api/jida-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId
  },
  params: {
    tenantId,
    source,
    code,
    state,
    grant_type: "social",
    scope: "all",
  }
})

export const refreshToken = (refresh_token, tenantId) => request({
  url: '/api/jida-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId
  },
  params: {
    tenantId,
    refresh_token,
    grant_type: "refresh_token",
    scope: "all",
  }
});

export const registerGuest = (form, oauthId) => request({
  url: '/api/jida-user/register-guest',
  method: 'post',
  params: {
    tenantId: form.tenantId,
    name: form.name,
    account: form.account,
    password: form.password,
    oauthId
  }
});

export const getButtons = () => request({
  url: '/api/jida-system/menu/buttons',
  method: 'get'
});

export const getCaptcha = () => request({
  url: '/api/jida-auth/oauth/captcha',
  method: 'get'
});

export const logout = () => request({
  url: '/api/jida-auth/oauth/logout',
  method: 'get'
});

export const getUserInfo = () => request({
  url: '/api/jida-auth/oauth/user-info',
  method: 'get'
});

export const sendLogs = (list) => request({
  url: '/api/jida-auth/oauth/logout',
  method: 'post',
  data: list
});

export const clearCache = () => request({
  url: '/api/jida-auth/oauth/clear-cache',
  method: 'get'
});

export const companyImport = (companyId ,files) => {
  const formData = new FormData();
  formData.append('companyId', companyId);
  files.forEach(file => {
    formData.append('files', file);
  });
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: '/api/park-company/company/import',
    method: 'post',
    data: formData
  })
}
export const importCompanyBak= ( files) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: '/api/park-company/company/importCompanyBak',
    method: 'post',
    data: formData
  })
}



export const createUsers = (row) => {
  return request({
    url: '/api/park-company/company/createUsers',
    method: 'post',
    params: row
  })
}
export const delHKUserAndAuth = (id) => {
  return request({
    url: '/api/park-company/companycheckin/delHKUserAndAuth',
    method: 'get',
    params: {
      id
    }
  })
}

export const getEmployeeDetail = (contactDetails) => {
  return request({
    url: '/api/park-company/employee/detail',
    method: 'get',
    params: {
      contactDetails
    }
  })
}


