<template>
  <div class="receipt-table">
    <!-- <avue-data-display :option="dataBoxOption"></avue-data-display> -->
    <el-card v-if="ShowTotal" class="mar-b-15">
      <el-descriptions title="" :column="5" :colon="false" direction="vertical" :labelStyle="labelStyle" :contentStyle="{ padding: '0px' }">
        <el-descriptions-item label="应收金额">
          <span slot="label">
            <el-tooltip class="item" effect="dark" content="应收金额 = 实收金额 + 待缴金额 + 补贴金额" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
            应收金额
          </span>
          <span class="lh-28">￥{{ total.receivableAmount | numConvertToThousandths }}元</span>
        </el-descriptions-item>
        <el-descriptions-item label="实收金额">
          <span class="lh-28">￥{{ total.receivedAmount | numConvertToThousandths }}元</span>
        </el-descriptions-item>
        <el-descriptions-item label="待缴金额">
          <span class="lh-28">￥{{ total.paidAmount | numConvertToThousandths }}元</span>
        </el-descriptions-item>
        <el-descriptions-item label="补贴金额">
          <span class="lh-28">￥{{ total.amountReduction | numConvertToThousandths }}元</span>
        </el-descriptions-item>
        <el-descriptions-item label="逾期金额">
          <span class="lh-28">￥{{ total.overdueAmount | numConvertToThousandths }}元</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <div class="w-100p">
      <el-tabs class="w-100p" v-model="activeName" @tab-click="tabsHandleClick">
        <el-tab-pane v-for="item of statusDict" :label="item.dictValue" :name="item.dictKey"></el-tab-pane>
      </el-tabs>
    </div>
    <avue-crud
      v-if="init"
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="option"
      :table-loading="tableLoading"
      :search.sync="search"
      @search-change="onLoad"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot-scope="scope" slot="amountReceivable"> ￥{{ scope.row.amountReceivable | numConvertToThousandths }}元 </template>
      <template slot-scope="scope" slot="amountReceived"> ￥{{ scope.row.amountReceived | numConvertToThousandths }}元 </template>
      <template slot-scope="scope" slot="amountReduction"> ￥{{ scope.row.amountReduction | numConvertToThousandths }}元 </template>
      <template slot-scope="scope" slot="status">
        <el-tag size="small" :type="statusType[scope.row.status]" effect="dark">
          {{ scope.row.$status }}
        </el-tag>
      </template>
      <template slot-scope="scope" slot="menuLeft">
        <template v-if="website.permission">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="addRow()" v-if="addBtn && userInfo.tenant_id === '000000' && permission.receipt_add">新增收费单 </el-button>
        </template>
        <template v-else>
          <el-button type="primary" size="small" icon="el-icon-plus" v-if="addBtn && userInfo.tenant_id === '000000'" @click="addRow()">新增收费单 </el-button>
        </template>
        <template v-if="queryParams && queryParams.expenseAccountCode && receiptImportTemplate">
          <el-button
            v-if="receiptImportTemplate[queryParams.expenseAccountCode]"
            size="small"
            icon="el-icon-document-checked"
            @click="importTamplateDownload(receiptImportTemplate[queryParams.expenseAccountCode])"
            >导入模板下载
          </el-button>
          <el-button v-if="receiptImportTemplate[queryParams.expenseAccountCode]" icon="el-icon-upload2" :size="'small'" @click="receiptImportTemplateDialogVisible = true"
            >导入{{ receiptImportTemplate[queryParams.expenseAccountCode].name || "" }}数据</el-button
          >
        </template>
        <template>
          <el-button type="" size="small" icon="el-icon-download" @click="receiptExport()">导出 </el-button>
        </template>
      </template>
      <template slot-scope="scope" slot="menu">
        <template v-if="website.permission">
          <el-button type="text" size="small" icon="el-icon-view" v-if="permission.receipt_view" @click="viewRow(scope.row, null)">查看 </el-button>
          <el-button type="text" size="small" icon="el-icon-edit" v-if="scope.row.receiptResource == 100 && permission.receipt_edit" @click="editRow(scope.row)">编辑 </el-button>
          <el-button type="text" size="small" icon="el-icon-delete" v-if="scope.row.receiptResource == 100 && permission.receipt_delete" @click="rowDel(scope.row)">删除 </el-button>
          <el-button type="text" icon="el-icon-tickets" size="small" v-if="permission.receipt_bill" @click="viewRow(scope.row, 'list')">账单明细 </el-button>
          <el-button type="text" icon="el-icon-document" size="small" v-if="permission.receipt_pay" @click="viewRow(scope.row, 'pay')">缴费明细 </el-button>
          <!-- <el-button
            type="text"
            icon="el-icon-circle-check"
            size="small"
            v-if="scope.row.status == 1 && permission.submitApproval"
            @click="submitApproval(scope.row)"
            >提交凭证
          </el-button> -->
        </template>
        <template v-else>
          <el-button type="text" size="small" icon="el-icon-view" @click="viewRow(scope.row, null)">查看 </el-button>
          <el-button type="text" size="small" icon="el-icon-edit" v-if="scope.row.receiptResource == 100" @click="editRow(scope.row)">编辑 </el-button>
          <el-button type="text" size="small" icon="el-icon-delete" v-if="scope.row.receiptResource == 100" @click="rowDel(scope.row)">删除 </el-button>
          <el-button type="text" icon="el-icon-tickets" size="small" @click="viewRow(scope.row, 'list')">账单明细 </el-button>
          <el-button type="text" icon="el-icon-document" size="small" @click="viewRow(scope.row, 'pay')">缴费明细 </el-button>
          <!-- <el-button
            type="text"
            icon="el-icon-circle-check"
            size="small"
            v-if="scope.row.status == 1"
            @click="submitApproval(scope.row)"
            >提交凭证
          </el-button> -->
        </template>
        <template>
          <el-button v-if="scope.row.process_instance_id" icon="el-icon-circle-check" :size="size || 'small'" type="text" @click="showTodoTask(row)">审批记录</el-button>
        </template>
      </template>
    </avue-crud>
    <!-- <el-dialog
      title="提交付款凭证"
      :visible.sync="submitApprovalDialogVisible"
      width="50%"
    >
      <el-form v-if="submitApprovalDialogVisible" ref="form" :model="form" :rules="rules" size="small" label-width="80px">
        <el-form-item label="银行名称" prop="bankName">
          <el-input v-model="form.bankName" placeholder="请输入银行名称"></el-input>
        </el-form-item>
        <el-form-item label="附件" prop="attachmentIds">
          <form-file v-model="form.attachmentIds"></form-file>
        </el-form-item>
        <el-form-item class="mar-t-40 text-right">
          <el-button size="small" @click="submitApprovalDialogVisible = false" :loading="saveLoading">取消</el-button>
          <el-button size="small" @click="submitApprovalFn(1)" :loading="saveLoading" type="primary" >暂存</el-button>
          <el-button size="small" @click="submitApprovalFn(2)" :loading="saveLoading" type="primary" >保存并提交审批</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->
    <el-drawer :visible.sync="todoDrawer" :size="400" :withHeader="false">
      <el-container v-if="currentRow && todoDrawer" class="h-100p">
        <el-header class="bor-b" height="50px">
          <el-page-header class="lh-50" @back="todoDrawer = false" content="审批记录"></el-page-header>
        </el-header>
        <el-main class="pad-15">
          <TodoTask :id="currentRow.process_instance_id"></TodoTask>
        </el-main>
      </el-container>
    </el-drawer>
    <el-drawer title="账单详情" :visible.sync="drawer" size="75%" class="manageDetail" :withHeader="false" append-to-body>
      <el-container class="h-100p">
        <el-header class="bor-b" height="50px">
          <el-page-header class="lh-50" content="账单详情" @back="drawer = false"></el-page-header>
        </el-header>
        <el-main class="ov-h ov-a-y">
          <ReceiptDetail v-if="drawer && currentRow" :id="currentRow.receiptNo || null" @refreshTable="onLoad" :activeTab="activeTab"></ReceiptDetail>
        </el-main>
      </el-container>
    </el-drawer>
    <el-dialog title="导入数据" :visible.sync="receiptImportTemplateDialogVisible" width="400px">
      <div class="text-center">
        <el-upload v-if="receiptImportTemplateDialogVisible" drag action="#" :limit="1" :auto-upload="false" accept=".xlsx,.xls" ref="importFile">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <basic-form-button :isForm="false" @submit="importTemplateFile" @cancel="receiptImportTemplateDialogVisible = false"></basic-form-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getList, getListByCompany, statisticFee, removeReceipt, receiptExport, receiptExportByCompany, updateReceiptInfo, importReceipt } from "@/api/finance/index.js"
import * as dictService from "@/api/system/dictbiz"
import { getDictionary } from "@/api/system/dictbiz.js"
import mathjs from "@/util/math.js"
import ReceiptDetail from "./receipt-detail.vue"
import website from "@/config/website"
import { mapState, mapGetters } from "vuex"
import TodoTask from "@/views/flow/todo-task.vue"
import { getPicListByIds } from "@/api/busiMode/metaCommon"

export default {
  components: { ReceiptDetail, TodoTask },
  data() {
    return {
      todoDrawer: false,
      activeName: "all",
      init: false,
      activeTab: "",
      drawer: false,
      submitApprovalDialogVisible: false,
      saveLoading: false,
      currentRow: null,
      form: {
        attachmentIds: "",
        bankName: "",
      },
      rules: {
        bankName: [{ required: true, message: "请输入银行名称", trigger: "blur" }],
        attachmentIds: [{ required: true, message: "请上传附件", trigger: "blur" }],
      },
      labelStyle: {
        color: "#999",
        fontSize: "12px",
        // lineHeight: "28px",
        padding: "0px",
      },
      total: {
        receivableAmount: "0",
        receivedAmount: "0",
        overdueAmount: "0",
        paidAmount: "0",
        amountReduction: "0",
      },
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 50,
        currentPage: 1,
        total: 0,
      },
      statusType: {
        1: "",
        2: "success",
        3: "danger",
        4: "warning",
      },
      option: {
        height: "400px",
        // calcHeight: this.calcHeight,
        labelPosition: "top",
        searchMenuPosition: "right",
        searchMenuSpan: 24,
        border: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        menuWidth: 250,
        column: [
          {
            label: "账单对象",
            prop: "subject",
            minWidth: 200,
            fixed: true,
          },
          {
            label: "关联合同",
            prop: "contractNo",
            minWidth: 150,
            fixed: true,
          },
          {
            label: "关联房间",
            prop: "rooms",
            minWidth: 200,
            fixed: true,
          },
          {
            label: "类型",
            prop: "feeItemName",
            width: 120,
            // hide: this.isCompany ? false : true
          },
          {
            label: "账单状态",
            prop: "status",
            type: "select",
            dataType: "number",
            dicUrl: dictService.getUrl("ReceiptStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 100,
          },
          {
            label: "应收金额",
            prop: "amountReceivable",
            width: 120,
          },
          {
            label: "补贴金额",
            prop: "amountReduction",
            width: 120,
          },
          {
            label: "实收金额",
            prop: "amountReceived",
            width: 120,
          },
          {
            label: "开始时间",
            prop: "periodStartTime",
            width: 120,
            type: "date",
            format: "yyyy-MM-dd",
          },
          {
            label: "结束时间",
            prop: "periodEndTime",
            width: 120,
            type: "date",
            format: "yyyy-MM-dd",
          },

          {
            label: "应缴日期",
            prop: "receivableDate",
            width: 120,
            type: "date",
            format: "yyyy-MM-dd",
          },

          {
            label: "账单编号",
            prop: "receiptNo",
            width: 200,
          },
        ],
        ...this.tableOption,
      },
      statusDict: [],
      receiptImportTemplate: {},
      receiptImportTemplateDialogVisible: false,
      hideColumn: {},
    }
  },
  props: {
    queryParams: {
      type: Object,
      default() {
        return null
      },
    },
    requireQueryParams: {
      type: Boolean,
      default: false,
    },
    calcHeight: {
      type: Number,
      default: 34,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    addBtn: {
      type: Boolean,
      default: true,
    },
    tableOption: {
      type: Object,
      default() {
        return null
      },
    },
  },
  watch: {
    queryParams() {
      this.page.currentPage = 1
      this.page.total = 0
      this.setColumnShow()
    },
  },
  computed: {
    ...mapGetters(["permission"]),
    // 获得用户信息来区分角色
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.receipt_add, !website.permission),
        viewBtn: this.vaildData(this.permission.receipt_view, !website.permission),
        delBtn: this.vaildData(this.permission.receipt_delete, !website.permission),
        editBtn: this.vaildData(this.permission.receipt_edit, !website.permission),
      }
    },
    ShowTotal() {
      return this.userInfo.tenant_id !== "000000" ? false : this.showTotal
    },
    isCompany() {
      return this.userInfo.companyId && this.userInfo.tenant_id != "000000"
    },
  },
  created() {},
  mounted() {
    this.getTableColumnHide()
    this.getDictionary()
    this.getReceiptImportTemplate()
  },
  methods: {
    setColumnShow() {
      let expenseAccountCode = this.queryParams ? this.queryParams.expenseAccountCode : ""
      let columnsName = []
      if (expenseAccountCode && this.hideColumn[expenseAccountCode]) {
        columnsName = this.hideColumn[expenseAccountCode].columns
      }
      this.option.column.forEach((item) => {
        if (columnsName.includes(item.label)) {
          item.hide = true
        } else {
          item.hide = false
        }
      })
      this.$refs.table.refreshTable()
      this.$nextTick(() => {
        this.onLoad()
      })
    },
    getTableColumnHide() {
      getDictionary({
        code: "RECEIPT_TABLE_COLUMNS",
      }).then((res) => {
        let hideColumn = {}
        ;(res.data.data || []).forEach((item) => {
          hideColumn[item.dictKey] = {
            name: item.dictValue,
            columns: item.remark.split(","),
            code: item.dictKey,
          }
        })
        this.hideColumn = hideColumn
      })
    },
    importTemplateFile(done) {
      if (!this.$refs.importFile.uploadFiles.length) {
        this.$message.error("请选择文件")
        done()
      } else {
        importReceipt(this.$refs.importFile.uploadFiles[0].raw, {
          expenseAccountCode: this.queryParams.expenseAccountCode,
        })
          .then((res) => {
            this.$message.success("操作成功")
            this.receiptImportTemplateDialogVisible = false
            done()
            this.onLoad()
          })
          .catch((e) => {
            done()
          })
      }
    },
    importTamplateDownload(item) {
      if (item && item.fileId) {
        getPicListByIds([item.fileId]).then((res) => {
          if (res.data.data && res.data.data[0]) {
            let file = res.data.data[0]
            this.downFile(file.link, file.originalName)
          }
        })
      } else {
        this.$message.error("参数配置错误，请联系管理员")
      }
    },
    showTodoTask(row) {
      this.currentRow = row
      this.todoDrawer = true
    },
    submitApprovalFn(type) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true
          updateReceiptInfo({
            receiptNo: this.currentRow.receiptNo,
            ...this.form,
          })
            .then((res) => {
              return type === 2 ? startFlow(this.currentRow.receiptNo, this.$PCode.BIZ.RECEIPT, this.$PCode.ACTIONS.SUBMIT) : res
            })
            .then((res) => {
              this.onLoad()
              this.submitApprovalDialogVisible = false
              this.saveLoading = false
              this.$message.success("操作成功")
            })
            .catch((e) => {
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    submitApproval(row) {
      this.currentRow = row
      this.form.attachmentIds = row.attachmentIds || ""
      this.form.bankName = row.bankName || ""
      this.submitApprovalDialogVisible = true
    },
    addRow() {
      this.$router.push(`/finance/receipt/action/add${this.queryParams.expenseAccountCode ? "?expenseAccountCode=" + this.queryParams.expenseAccountCode : ""}`)
      this.$breadcrumb.add({
        name: "新增收费单",
        path: "",
      })
    },

    editRow(row) {
      this.$router.push(`/finance/receipt/action/edit?id=${row.receiptNo}`)
      this.$breadcrumb.add({
        name: "编辑收费单",
        path: "",
      })
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return removeReceipt([row.receiptNo])
        })
        .then(() => {
          this.onLoad()
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    getYSAmount() {
      return mathjs.subtract(this.total.receivableAmount || 0, this.total.receivedAmount || 0)
    },
    getReceiptImportTemplate() {
      let receiptImportTemplate = {}
      getDictionary({
        code: "ReceiptImportTemplate",
      }).then((res) => {
        ;(res.data.data || []).forEach((item) => {
          receiptImportTemplate[item.dictKey] = {
            name: item.dictValue,
            fileId: item.remark,
            code: item.dictKey,
          }
        })
      })
      this.receiptImportTemplate = receiptImportTemplate
    },
    getDictionary() {
      getDictionary({
        code: "ReceiptStatus",
      }).then((res) => {
        this.statusDict = [{ dictValue: "全部", dictKey: "all" }].concat(res.data.data || [])
        this.$nextTick(() => {
          this.init = true
        })
      })
    },
    tabsHandleClick(node) {
      this.page.currentPage = 1
      this.onLoad()
    },
    receiptExport() {
      let searchBody = {
        ...this.queryParams,
      }
      if (this.activeName !== "all") {
        searchBody["status"] = this.activeName
      }
      let exportFn = null
      if (this.userInfo.companyId && this.userInfo.tenant_id != "000000") {
        exportFn = receiptExportByCompany
      } else {
        exportFn = receiptExport
      }
      exportFn(searchBody).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let link = document.createElement("a")
        link.style.display = "none"
        link.href = url
        link.download = "账单列表" + ".xls"
        document.body.appendChild(link)
        link.click()
      })
    },
    onLoad() {
      this.tableData = []
      if (this.requireQueryParams && !Object.keys(this.queryParams).length) {
        return []
      }
      this.tableLoading = true
      if (this.queryParams) {
        if (this.queryParams.subjectType != "1") {
          delete this.queryParams.subjectId
        } else {
          if (this.queryParams.subject) {
            this.queryParams.subjectId = this.queryParams.subject
            delete this.queryParams.subject
          }
        }
      }

      if (this.ShowTotal) {
        statisticFee(this.queryParams).then((res) => {
          let data = res.data.data
          Object.keys(this.total).forEach((item) => {
            this.total[item] = data[item] || "0"
          })
        })
      }
      let searchBody = {
        ...this.queryParams,
      }
      Object.keys(searchBody).forEach((key) => {
        if ((searchBody[key] ?? "") === "") {
          delete searchBody[key]
        }
      })
      if (this.activeName !== "all") {
        searchBody["status"] = this.activeName
      }
      let queryFn = null
      if (this.userInfo.companyId && this.userInfo.tenant_id != "000000") {
        queryFn = getListByCompany
      } else {
        queryFn = getList
      }
      queryFn({
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...searchBody,
      })
        .then((res) => {
          this.tableData = res.data.data.records
          this.page.total = res.data.data.total
          this.tableLoading = false
        })
        .catch((e) => {
          this.tableLoading = false
        })
    },
    viewRow(row, activeTab) {
      this.currentRow = row
      this.activeTab = activeTab
      this.drawer = true
    },
  },
}
</script>
<style lang="scss" scoped>
.receipt-table {
  /deep/.el-drawer__header {
    margin-bottom: 0;
  }

  /deep/.el-drawer__body {
    padding-top: 0;
  }

  /deep/.avue-crud__left {
    flex: 1;
  }

  /deep/.el-table__body-wrapper.is-scrolling-left {
    z-index: 2;
  }
}
</style>
