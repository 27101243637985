import request from "@/router/axios";

export const submit = (data) => {
  return request({
    url: "/api/park-company/visitor/submit",
    method: "post",
    data: data,
  });
};
export const doorevents = (data) => {
  return request({
    url: "/api/park-company/doorevents/save",
    method: "post",
    data: data,
  });
};
export const doDoorControl = (data) => {
  return request({
    url: "/api/park-company/doorevents/doDoorControl",
    method: "post",
    data: data,
  });
};

export const publishscheduleSubmit = (data) => {
  return request({
    url: "/api/park-company/publishschedule/submit",
    method: "post",
    data: data,
  });
};

export const createSign = (data) => {
  return request({
    url: "/api/park-company/companycheckin/createSign",
    method: "get",
    params: data,
  });
};
export const fastCheckInComPanyProcess = (data) => {
  return request({
    url: "/api/park-company/companycheckin/fastCheckInComPanyProcess",
    method: "post",
    params: data,
  });
};
export const qeuryStatBuildMonthExpense = (data) => {
  return request({
    url: "/api/park-statistics/statistics/qeuryStatBuildMonthExpense",
    method: "post",
    params: data,
  });
};

export const getHkDoorEvents = (data) => {
  return request({
    url: "/api/park-company/doorevents/getHkDoorEvents",
    method: "post",
    data: data,
  });
};
export const syncReSourcesDoors = (data) => {
  return request({
    url: "/api/park-company/hk/syncReSourcesDoors",
    method: "get",
  });
};
export const updateCompany = (data) => {
  return request({
    url: "/api/park-company/company/update",
    method: "post",
    data: data,
  });
};

export const delCompany = (data) => {
  return request({
    url: "/api/park-company/company/del",
    method: "post",
    params: data,
  });
};

export const validatePhone = (data) => {
  // 企业档案提交时校验 联系电话唯一性
  return request({
    url: "/api/park-company/company/getPhoneCount",
    method: "get",
    params: {
      ...data,
    },
  });
};

export const joinInBlacklist = (data) => {
  // 加入黑名单
  return request({
    url: "/api/park-company/companycheckin/joinInBlacklist",
    method: "get",
    params: {
      ...data,
    },
  });
};
