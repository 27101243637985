import request from '@/router/axios';

/**
 * 
 * @param {Blob} fileData 下载文件的二进制数据
 * @param {String} fileName 文件名称
 * @returns 
 */
export const downloadBlob = (fileData, fileName = '') => {
  if (!fileData) {
    return
  }
  const blob = new Blob([fileData])
  const _fileName = `${decodeURIComponent(fileName)}`
  if ('download' in document.createElement('a')) { // 不是IE浏览器
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', _fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // 下载完成移除元素
    window.URL.revokeObjectURL(url) // 释放掉blob对象
  } else { // IE 10+
    window.navigator.msSaveBlob(blob, _fileName)
  }
}

export const downFileByResult = (apiUrl , method , params , fileName) => {
  method = method && typeof method === 'string' ? method.toLocaleLowerCase() : 'get';
  let option = {
    url: apiUrl,
    method: method,
  }
  if(method === 'post'){
    option['data'] = params || null;
  }else{
    option['params'] = params || {};
  }
  return new Promise((a , b) => {
    request({
      responseType: 'blob',
      headers:{ 'Content-Type': 'application/json'},
      ...option
    }).then(
      res => {
        
        let name = '';
        if(res.headers['content-disposition']){
          res.headers['content-disposition'].split(';').forEach(
            item => {
              let items = item.split('=');
              if(items[0].replace(/\s/g , '').indexOf('filename') > -1){
                name = items[1] || '';
              }
            }
          )
        }
        name = name || fileName;
        if(name){
          console.log(name);
          name = name.replace(/"/g , '').replace(/'/g , '').replace('utf-8' , '');
          downloadBlob(res.data , name);
        }
        a(res);
      }
    ).catch(e => {
      b(e);
    })
  })
}