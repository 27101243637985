import request from '@/router/axios';
import qs from 'qs';
import Apis from './apiUrl';
// import { downloadFile } from '@/view-module/scheduler/common/download'
export default {
  updateState(params){
    return request({
      url: Apis.updateState,
      method: 'PUT',
      data: qs.stringify(params),
    })
  },
  download(id){
    //return downloadFile(Apis.download + id)
    // return request({
    //   url: Apis.download + id,
    //   method: 'GET',
    // })
  },
  getDatasourceAuth(){
    return request({
      url: Apis.datasourceAuth,
      method: 'get',
      data: {
      }
    })
  },
  query(params){
    return request({
      url: Apis.query,
      method: 'POST',
      // data: qs.stringify(params),
      data: params,
      // headers: {
      //   'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      // }
    })
  },
  presto(params){
    return request({
      url: Apis.presto,
      method: 'POST',
      data: qs.stringify(params),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
  },
  prestoAsync(params){
    return request({
      url: Apis.prestoAsync,
      method: 'POST',
      // data: qs.stringify(params),
      data: params,
      // headers: {
      //   'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      // }
    })
  },
  queryStatus(params){
    return request({
      url: Apis.queryStatus,
      method: 'POST',
      data: qs.stringify(params),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
  },
  historyStatus(params){
    return request({
      url: Apis.historyStatus,
      method: 'GET',
      params: params,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
  },
  history(params){
    return request({
      url: Apis.history,
      method: 'GET',
      // data: qs.stringify(params),
      params: params,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
  },
  killQuery (params) {
    return request({
      url: Apis.kill,
      method: 'POST',
      data: qs.stringify(params),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
  },
  getLog(id) {
    return request({
      url: Apis.log,
      method: 'GET',
      // params: {},
      params: {
        queryid: id
      }
      // headers: {
      //   'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      // }
    })
  },
  getCatalogueList(params){
    return request({
      url: Apis.getCatalogueList,
      method: 'POST',
      data: params,
    })
  },
  getSqlFile(id){
    return request({
      url: Apis.getSqlFile,
      method: 'GET',
      params: {
        id: id
      }
    })
  },
  saveDir(params){
    return request({
      url: Apis.saveDir,
      method: 'POST',
      data: params,
    })
  },
  deleteDir(ids){
    return request({
      url: Apis.deleteDir + '?ids=' + ids.join(','),
      method: 'POST',
      data: {},
    })
  },
  saveFile(params){
    return request({
      url: Apis.saveFile,
      method: 'POST',
      data: params,
    })
  },
  deleteFile(ids){
    return request({
      url: Apis.deleteFile + '?ids=' + ids.join(','),
      method: 'POST',
      data: {},
    })
  },
}