<template>
  <div class="content">
    <!-- <div class="fts-20 text-center pad-t-15 pad-b-5" v-if="showtitle">
      <span v-if="approval.approval_status == 2"
        >入驻申请已提交，当前状态 【</span
      >
      <span v-if="approval.approval_status == 1">请提交入驻申请【</span>
      <span
        v-if="approval.approval_status == 4 || approval.approval_status == 4"
        >请重新提交入驻申请，当前状态【</span
      >
      <span v-if="approval.approval_status == 1">待提交</span>
      <span v-if="approval.approval_status == 2">审批中</span>
      <span v-if="approval.approval_status == 3">审批通过</span>
      <span v-if="approval.approval_status == 4">审批未通过</span>
      <span v-if="approval.approval_status == 5">已撤回</span>
      <span
        v-if="
          approval.approval_status == 2 ||
          approval. == 1 ||
          approval.approval_status == 4
        "
      >
        】</span
      >
    </div> -->
    <div class="no-o mar-t-20" :style="{ height: viewPortHeight - 30 + 'px' }">
      <basic-container
        isCard
        class="o-a"
        :style="{ height: viewPortHeight - 90 + 'px' }"
        v-if="approval.approval_status == 2"
      >
        <enterDetail :id="approval.id"></enterDetail>
      </basic-container>
      <div
        class="o-a"
        :style="{ height: viewPortHeight - 10 + 'px' }"
        v-if="
          approval.approval_status == 5 ||
          approval.approval_status == 1 ||
          approval.approval_status == 4
        "
      >
        <enterpriseEnter
          :height="viewPortHeight - 195 + 'px'"
          :currentRow="approval"
          :enterprise="true"
          @close="close"
        >
        </enterpriseEnter>
      </div>
    </div>
  </div>
</template>
<script>
import enterDetail from "./enterDetail.vue";
import enterpriseEnter from "./enterpriseEnter.vue";

export default {
  components: { enterpriseEnter, enterDetail },

  data() {
    return {
      icon: "",
      showtitle: true,
      viewPortHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
    };
  },
  mounted() {},
  created() {},
  methods: {
    close() {
      this.showtitle = false;
    },
  },
  watch: {
    approval: {
      immediate: true,
      handler(val) {
        if (val == 2) {
          this.icon = "success";
        }
        if (val == 4 || val == 5) {
          this.icon = "error";
        }
      },
    },
  },
  props: {
    approval: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin: 0 auto;
  width: 1023px;
  overflow: hidden;
}
.no-o {
  overflow: hidden;
}
</style>
