import request from '@/router/axios';
import apiUrl from './apiUrl';

const APIURL = apiUrl.subContract;

export const getSubContractList = (params) => {
  return request({
    url: APIURL.list,
    method: 'get',
    params: params
  })
}

export const getSubContractDetail = (params) => {
  return request({
    url: APIURL.detail,
    method: 'get',
    params: params
  })
}

export const saveSubContract = (data) => {
  return request({
    url: APIURL.save,
    method: 'post',
    data: data
  })
}

export const updateSubContract = (data) => {
  return request({
    url: APIURL.update,
    method: 'post',
    data: data
  })
}

export const removeSubContract = (data) => {
  return request({
    url: APIURL.remove,
    method: 'post',
    params: data
  })
}

export const getContractSubject = (params) => {
  return request({
    url: APIURL.subject,
    method: 'get',
    params: params
  })
}