<template>
  <div class="avue-logo">
    <img
      class="mar-lr-5 w-40 top-4"
      @click="goDashbord()"
      :src="logoConfig.topLeft"
      alt=""
    />
    <span
      class="color-white mar-l-10"
      @click="goDashbord()"
      style="color: #fff"
      >{{ logoConfig.title }}</span
    >
    <!-- <img v-if="!isCollapse" class="w-140" :src="'/assets/images/app/main/' + logoConfig.topRight" alt=""> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getDictionary } from "@/api/system/dict";
import logoConfig from "@/config/logo";
export default {
  name: "logo",
  data() {
    return {
      logoConfig: {
        topLeft: "",
        title: "智慧运营",
        topLeftTh: "",
      },
    };
  },
  inject: ["indexMain"],
  created() {
    this.getDictionary();
  },
  computed: {
    ...mapGetters(["website", "keyCollapse", "isCollapse"]),
  },
  methods: {
    getDictionary() {
      getDictionary({ code: "logos" }).then((res) => {
        if (res["status"] === 200) {
          res["data"]["data"].forEach((item) => {
            if (item.dictValue == "logo_top_right") {
              this.logoConfig.topLeftTh = item.dictKey;
            }
            if (item.dictValue == "logo_top_left") {
              this.logoConfig.topLeft = item.dictKey;
            }
            if (item.dictValue == "title") {
              this.logoConfig.title = item.dictKey;
            }
          });
          // this.versions = res['data']['data']
        }
      });
    },
    getFirstMenu(arr) {
      let _t = this;
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.children && item.children.length > 0) {
          let flag = this.getFirstMenu(item.children);
          if (!flag) {
            break;
          }
        } else {
          if (item["path"]) {
            _t.firstAvailMenu = item;
            return false;
          }
        }
      }
    },
    goDashbord() {
      this.$store.dispatch("GetMenu").then((res) => {
        if (res.length > 0) {
          let item = res[0];
          let showMenus = this.indexMain.showMenus;
          this.indexMain.showMenus = true;
          this.$breadcrumb.add({
            type: "top-menu",
            meta: item,
            name: item.name,
          });
          this.$store.commit("SET_SIDEBAR_MENU_TYPE", "sidebar-menu-root");
          this.$store.commit("SET_SIDEBAR_MENU", item);
          this.$store.commit("SET_MENU_ID", item.id);
          this.activeIndex = this.$store.state.user.menuId;
          if (item["children"] && item["children"].length) {
            this.getFirstMenu(item["children"]);
            let firstMenu = this.firstAvailMenu;
            this.$router
              .push({
                path: this.$router.$avueRouter.getPath({
                  name: firstMenu.name,
                  src: firstMenu.path,
                }),
              })

              .catch(() => {
                this.indexMain.showMenus = showMenus;
              });
            if (!this.$breadcrumb.excludeRoutes.includes(firstMenu.path)) {
              this.$breadcrumb.add({
                type: "sidebar-menu-root",
                meta: firstMenu,
                name: firstMenu.name,
                path: firstMenu.path,
              });
            }
          } else {
            this.indexMain.showMenus = false;
            this.$router
              .push({
                path: this.$router.$avueRouter.getPath(
                  { name: item.name, src: item.path },
                  {}
                ),
              })
              .catch(() => {
                this.indexMain.showMenus = showMenus;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-active {
  transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.avue-logo {
  font-size: 18px;
  img {
    // height: 30px;
    position: relative;
    top: -5px;
    // display: block;
    // margin: 0 auto;
    // height: 40px;
    // position: relative;
    // top: 4px;
  }
}
.yl-logo {
  display: none;
}
.th-logo {
  display: none;
}
.theme-cool {
  .cool-logo {
    display: block;
  }
  .th-logo {
    display: none;
  }
  &.theme-th {
    .cool-logo {
      display: none;
    }
    .th-logo {
      display: block;
    }
    .w-30 {
      width: 30px;
    }
    .w-115 {
      width: 115px;
    }
    .mar-l-10 {
      margin-left: 10px;
    }
  }
}
// .avue-logo {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 240px;
//   height: 64px;
//   line-height: 64px;
//   background-color: #20222a;
//   font-size: 20px;
//   overflow: hidden;
//   box-sizing: border-box;
//   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
//   color: rgba(255, 255, 255, 0.8);
//   z-index: 1024;
//   &_title {
//     display: block;
//     text-align: center;
//     font-weight: 300;
//     font-size: 20px;
//   }
//   &_subtitle {
//     display: block;
//     text-align: center;
//     font-size: 18px;
//     font-weight: bold;
//     color: #fff;
//   }
// }
</style>
