<template>
  <el-cascader
    v-bind="attrs"
    class="w-100p"
    ref="cascader"
    :props="props"
    :options="data"
    v-model="val"
    @change="cascaderChange"
  >
  </el-cascader>
</template>
<script>
import { getMetaCommonListTree } from "@/api/busiMode/metaCommon";

export default {
  data(){
    return {
      val: null,
      props: {
        label: this.labelKey,
        value: this.valueKey,
        children: this.childrenKey,
        checkStrictly: true,
        ...this.option
      },
      data: [],
      attrs: {
        clearable: true,
        separator: '-',
        ...this.$attrs
      }
    }
  },
  watch: {
    val(){
      this.$emit('input' , this.getValue())
    },
    value(){
      if(this.value !== this.val){
        this.setValue();
      }
    }
  },
  props: {
    value: {},
    option: {
      type: Object,
      default(){
        return {}
      }
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    childrenKey: {
      type: String,
      default: 'children'
    },
    model: {
      type: String,
      default: ''
    },
    modelParams: {
      type: Object,
      default(){
        return {}
      }
    },
    getData: {
      type: Function,
      default: undefined
    },
    valueType: {
      type: String,
      default: ''
    },
    separator: {
      type: String,
      default: ','
    }
  },
  mounted(){
    this.setValue();
    if(this.model){
      this.getMetaCommonListTree();
    }
    if(this.getData){
      this.customData();
    }
  },
  methods: {
    setValue(){
      if(this.value && typeof this.value === 'string' && (this.value.indexOf(this.separator) > -1 || this.valueType === 'array')){
        this.val = this.value.split(this.separator);
      }else{
        this.val = this.value;
      }
    },
    getValue(){
      if(this.separator && Array.isArray(this.val)){
        return this.val.join(this.separator)
      }else{
        return this.val;
      }
    },
    cascaderChange(){
      this.$nextTick(() => {
        this.$emit('change' , {
          value: this.getValue(),
          label: this.$refs.cascader.presentText
        })
      })
    },
    customData(){
      this.getData({
        value: this.val,
        valueKey: this.valueKey,
        labelKey: this.labelKey,
        childrenKey: this.childrenKey
      } , (data) => {
        this.data = data;
      })
    },
    getMetaCommonListTree(){
      let name = `model-cascader_${this.model}`;
      if(localStorage[name]){
        this.data = JSON.parse(localStorage.getItem(name))
      }else{
        getMetaCommonListTree(`${this.model.indexOf('.') > -1 ? '' : 'wisdom_park.'}${this.model}` , {
          labelField: 'expense_account_name',
          valueField: 'id',
          releaseField: 'parent_id',
          isLazy: false,
          ...this.modelParams
        }).then(
          res => {
            this.data = res.data.data;
            localStorage.setItem(name , JSON.stringify(this.data));
          }
        )
      }
    }
  },
  destroyed(){
    let name = `model-cascader_${this.model}`;
    localStorage.clear(name);
  },
}
</script>