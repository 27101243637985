<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import axios from "axios";
import { setTheme } from "@/util/util";
export default {
  name: "app",
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {
    // axios.get('/api/jida-system/param/detail' , {
    //   params: {
    //     paramKey: 'page.config'
    //   }
    // }).then(
    //   res => {
    //     if(res.data && res.data.data && res.data.data.records.length){
    //       let pageConfig = res.data.data.records[0];
    //     }
    //   }
    // )
  },
  methods: {
    setTheme(val) {
      this.$store.commit("SET_THEME_NAME", val);
      if (this.menuMode == "top") {
        setTheme(val + " menuTopDark");
      } else {
        setTheme(val);
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
// .v-modal {
//   z-index: 1028 !important;
// }
</style>
