var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-form-container"},[_c('el-form',{ref:"widgetForm",attrs:{"label-position":_vm.data.labelPosition || 'right',"label-width":_vm.data.labelWidth ? `${_vm.data.labelWidth}px` : '100px',"label-suffix":_vm.data.labelSuffix,"model":_vm.form,"size":"small"}},[_c('el-row',{attrs:{"gutter":_vm.data.gutter}},[_c('draggable',{attrs:{"list":_vm.data.column,"group":{ name: 'form' },"ghost-class":"ghost","animation":300},on:{"add":_vm.handleWidgetAdd,"end":function($event){return _vm.$emit('change')}}},[_vm._l((_vm.data.column),function(column,index){return [(column.type == 'dynamic')?_c('div',{key:index,staticClass:"widget-form-table",class:{ active: _vm.selectWidget.prop == column.prop }},[_c('widget-form-table',{attrs:{"data":_vm.data,"column":column,"index":index,"select":_vm.selectWidget},on:{"update:select":function($event){_vm.selectWidget=$event},"change":function($event){return _vm.$emit('change')}}})],1):(column.type == 'group')?_c('div',{key:index,staticClass:"widget-form-group",class:{ active: _vm.selectWidget.prop == column.prop },on:{"click":function($event){return _vm.handleSelectWidget(index)}}},[_c('widget-form-group',{attrs:{"data":_vm.data,"column":column,"index":index,"select":_vm.selectWidget},on:{"update:select":function($event){_vm.selectWidget=$event},"change":function($event){return _vm.$emit('change')}}})],1):_c('el-col',{key:index,attrs:{"md":column.span || 12,"xs":24,"offset":column.offset || 0}},[_c('el-form-item',{staticClass:"widget-form-item",class:{
                itemActive: _vm.selectWidget.prop == column.prop,
                required: column.required,
              },attrs:{"label":column.label,"labelWidth":column.labelWidth && column.labelWidth + 'px',"prop":column.prop},nativeOn:{"click":function($event){return _vm.handleSelectWidget(index)}}},[_c('el-dropdown',{staticStyle:{"display":"block"}},[_c('i',{staticClass:"el-icon-setting czan"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.selectWidget.prop == column.prop)?_c('el-dropdown-item',[_c('span',{on:{"click":function($event){return _vm.handleWidgetDelete(index)}}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v(" 删除 ")])]):_vm._e(),(_vm.selectWidget.prop == column.prop)?_c('el-dropdown-item',[_c('span',{on:{"click":function($event){return _vm.handleWidgetClone(index)}}},[_c('i',{staticClass:"el-icon-document-copy"}),_vm._v(" 复制")])]):_vm._e(),(_vm.selectWidget.prop == column.prop)?_c('el-dropdown-item',[_c('span',{on:{"click":function($event){return _vm.openEdit(index)}}},[_c('i',{staticClass:"el-icon-edit-outline"}),_vm._v(" 编辑")])]):_vm._e()],1)],1),_c('i',{staticClass:"el-icon-rank dropIcon",class:{
                  active: _vm.selectWidget.prop == column.prop,
                  required: column.required,
                }}),_c('widget-form-item',{attrs:{"item":column,"params":column.params}})],1)],1)]}),(_vm.data.menuBtn && _vm.data.column.length > 0)?_c('el-col',{staticClass:"text-center",attrs:{"span":_vm.searchSpan || 24}},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.data.submitText))]),_c('el-button',{attrs:{"size":"small"}},[_vm._v(_vm._s(_vm.data.emptyText))])],1):_vm._e()],2)],1)],1),(_vm.rowEditVisible)?_c('div',[_c('el-dialog',{attrs:{"title":"编辑","width":"30%","append-to-body":"","visible":_vm.rowEditVisible,"destroy-on-close":""},on:{"update:visible":function($event){_vm.rowEditVisible=$event}}},[_c('avue-form',{ref:"rowForm",attrs:{"option":_vm.searchOption,"append-to-body":"true"},on:{"submit":_vm.submit},model:{value:(_vm.columnObj),callback:function ($$v) {_vm.columnObj=$$v},expression:"columnObj"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }