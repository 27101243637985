import request from '@/router/axios';
const prefix = '/api/jida-system';
export const getDataTopic = (params) => {
  return request({
    url: prefix + `/topic/page`,
    method: 'get',
    params
  })
};
export const saveTopic = (params) => {
  return request({
    url: prefix + `/topic/save`,
    method: 'post',
    data:params
  })
};
export const updateTopic = (params) => {
  return request({
    url: prefix + `/topic/update`,
    method: 'post',
    data:params
  })
};
export const delTopic = (params) => {
  return request({
    url: prefix + `/topic/remove`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};

