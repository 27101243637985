/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import i18n from '@/lang'
// import { resolveURL } from '@/view-module/scheduler/common/io'

import request from '@/router/axios';
import { downloadBlob } from '@/util/util'
const apiPrefix = '/api/convergeflexschduler/convergeflexschduler'
const reSlashPrefix = /^\/+/
const PUBLIC_PATH = '';

const resolveURL = (url) => {
  if (url.indexOf('http') === 0) {
    return url
  }
  if (url.charAt(0) !== '/') {
    return `${apiPrefix}/${url.replace(reSlashPrefix, '')}`
  }

  return url
}

/**
 * download file
 */
const downloadFile = ($url, $obj) => {
  const param = {
    url: resolveURL($url),
    obj: $obj
  }

  if (!param.url) {
    this.$message.warning(`${i18n.$t('Unable to download without proper url')}`)
    return
  }

  const generatorInput = function (obj) {
    let result = ''
    const keyArr = Object.keys(obj)
    keyArr.forEach(function (key) {
      result += "<input type='hidden' name = '" + key + "' value='" + obj[key] + "'>"
    })
    return result
  }
  return request({
    url: param.url,
    method: 'get',
    params: param.obj,
    responseType: 'blob'
  }).then(
    res => {
      let name = '';
      if(res.headers['content-disposition']){
        res.headers['content-disposition'].split(';').forEach(
          item => {
            let items = item.split('=');
            if(items[0].replace(/\s/g , '').indexOf('filename') > -1){
              name = items[1] || '';
            }
          }
        )
      }
      if(name){
        name = name.replace(/"/g , '').replace(/'/g , '').replace('utf-8' , '');
        downloadBlob(res.data , name);
      }
    }
  )
  // $(`<form action="${param.url}" target="_blank" method="get">${generatorInput(param.obj)}</form>`).appendTo('body').submit().remove()
}

export { downloadFile }
