<template>
    <avue-crud ref="table" :data="tableData" :page.sync="page" :option="tableOption" :table-loading="tableLoading" :search.sync="search" @search-change="onLoad" @on-load="onLoad">
        <template slot-scope="scope" slot="amount_receivable">
            ￥{{scope.row.amount_receivable | numConvertToThousandths}}元
        </template>
        <template slot-scope="scope" slot="amount_received">
            ￥{{scope.row.amount_received | numConvertToThousandths}}元
        </template>
        <template slot-scope="scope" slot="quantity">
            {{scope.row.quantity | numConvertToThousandths}}
        </template>
        <template slot-scope="scope" slot="unit_price">
            ￥{{scope.row.unit_price | numConvertToThousandths}}元
        </template>
        <template slot-scope="scope" slot="menu" v-if="scope.row.resource_type == 1 && !isTodo">
            <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="delReduction(scope.row)">删除</el-button>
        </template>
    </avue-crud>
</template>
<script>
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import { getUrl } from '@/api/system/dictbiz.js';
import { removeReceiptReductionDetail } from '@/api/finance/index.js';
export default {
    data() {
        return {
            model: 'wisdom_park.wp_receipt_reduction_detail',
            tableLoading: false,
            tableData: [],
            search: {},
            page: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            tableOption: {
                height: '250px',
                labelPosition: 'top',
                searchMenuPosition: 'right',
                searchMenuSpan: 24,
                border: true,
                delBtn: false,
                editBtn: false,
                addBtn: false,
                // menu: false,
                menuWidth: 100,
                header: true,
                refreshBtn: false,
                columnBtn: false,
                column: [
                    {
                        label: '费用科目',
                        prop: 'fee_name',
                        width: 150,
                    },
                    {
                        label: '优惠金额',
                        prop: 'reduction_amount',
                        width: 100,
                    },
                    {
                        label: '优惠类型',
                        prop: 'reduction_policy_type',
                        width: 150,
                        dataType: 'number',
                        dicUrl: getUrl('ReductionPolicyType'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                    },
                    {
                        label: '补贴到账状态',
                        prop: 'subsidy_status',
                        width: 90,
                        dataType: 'number',
                        dicUrl: getUrl('SubsidyStatus'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                    },
                    {
                        label: '备注',
                        prop: 'note',
                    },
                ],
            },
        };
    },
    props: {
        receiptNo: {
            type: String,
            default: '',
        },
        isTodo: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {},
    methods: {
        onLoad() {
            this.tableLoading = true;
            this.tableData = [];
            getMetaCommonList(this.model, {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: [{ field: 'receipt_no', value: this.receiptNo }],
            })
                .then(res => {
                    this.tableLoading = false;
                    this.tableData = res.data.data.records;
                    this.page.total = res.data.data.total;
                })
                .catch(e => {
                    this.tableLoading = false;
                });
        },
        delReduction(row) {
            console.log(row);
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let obj = { id: row.id };
                    removeReceiptReductionDetail(obj).then(res => {});
                })
                .then(() => {
                    this.onLoad();
                });
        },
    },
};
</script>