import request from "@/router/axios";

export const authLogNew = (d) => {
  return request({
    url: "/api/park-company/hk/authLogNew",
    method: "post",
    params: d,
  });
};
export const deleteAuthConfig = (d) => {
  return request({
    url: "/api/park-company/hk/deleteAuthConfig",
    method: "post",
    data: d,
  });
};
