import LocationCascader from './location-cascader.vue';
import BasicUserSelect from './user-select.vue';
import ModelCascader from './model-cascader.vue';
import DictSelect from './dict-select.vue'
import ModelSearchSelect from './model-search-select.vue';
import FormFile from './form-file.vue';

export default {
  LocationCascader,
  BasicUserSelect,
  ModelCascader,
  DictSelect,
  ModelSearchSelect,
  FormFile
}