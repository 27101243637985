import request from '@/router/axios';
export const getMonitorList = () => {
  return request({
    url:'/json/metadata/monitor.json',
    method: 'get',
    params:{

    }
  })
};
