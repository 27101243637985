import { setStore, getStore, removeStore } from "@/util/store";
import website from "@/config/website";

const common = {
  state: {
    language: "zh-CN", //默认语言
    isCollapse: false, //默认是否隐藏左边
    isMenu: true,
    isShade: false,
    screen: -1,
    isLock: getStore({ name: "isLock" }) || false, //显示锁屏按钮
    showTag: false, //显示已经选过的菜单
    showDebug: false,
    showCollapse: true, //是否显示可以切换屏幕的按钮
    showSearch: false, //是否显示搜索框
    showLock: false, //显示锁屏按钮
    showFullScren: false, //显示全屏按钮
    isFullScren: false, //是否全屏
    showTheme: true, //显示皮肤按钮
    showMenu: true,
    showColor: true, //换色
    menuMode: "left", //top 上下结构 left 左右结构
    colorName: getStore({ name: "colorName" }) || "#409EFF",
    themeName: getStore({ name: "themeName" }) || "theme-cool",
    lockPasswd: getStore({ name: "lockPasswd" }) || "",
    website: website,
    showBreadcrumb: true, //显示面包屑 true是tag
    vscode: "http://10.50.1.18:8082/?ew=true&folder=",
    gutter: 8, //默认间距
    viewEdit: false,
    size: "small",
    showWeather: true,
    pageSizes: [10, 30, 50, 100, 200],
    pagerCount: 5,

    pageConfig: {
      logo: "/assets/images/app/main/login_logo.png",
      name: "智慧运营",
    },
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      setStore({
        name: "language",
        content: state.language,
      });
    },
    SET_SHADE: (state, active) => {
      state.isShade = active;
    },
    SET_SHOW_BREADCRUMB: (state, show) => {
      state.showBreadcrumb = show;
    },
    SET_SHOW_TAGS: (state, show) => {
      state.showTag = show;
    },
    SET_COLLAPSE: (state, isCollapse) => {
      state.isCollapse =
        isCollapse !== undefined ? isCollapse : !state.isCollapse;
    },
    SET_FULLSCREN: (state) => {
      state.isFullScren = !state.isFullScren;
    },
    SET_VIEWEDIT: (state) => {
      state.viewEdit = !state.viewEdit;
    },
    SET_IS_MENU: (state, menu) => {
      state.isMenu = menu;
    },

    SET_MENU_MODEL: (state, menuMode) => {
      state.menuMode = menuMode;
    },
    SET_LOCK: (state) => {
      state.isLock = true;
      setStore({
        name: "isLock",
        content: state.isLock,
        type: "session",
      });
    },
    SET_SCREEN: (state, screen) => {
      state.screen = screen;
    },
    SET_COLOR_NAME: (state, colorName) => {
      state.colorName = colorName;
      setStore({
        name: "colorName",
        content: state.colorName,
      });
    },
    SET_THEME_NAME: (state, themeName) => {
      state.themeName = themeName;
      setStore({
        name: "themeName",
        content: state.themeName,
      });
    },
    SET_LOCK_PASSWD: (state, lockPasswd) => {
      state.lockPasswd = lockPasswd;
      setStore({
        name: "lockPasswd",
        content: state.lockPasswd,
        type: "session",
      });
    },
    CLEAR_LOCK: (state) => {
      state.isLock = false;
      state.lockPasswd = "";
      removeStore({
        name: "lockPasswd",
        type: "session",
      });
      removeStore({
        name: "isLock",
        type: "session",
      });
    },
  },
};
export default common;
