import Vue from 'vue';

import App from './App';

import mainConfig from './main/index';
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
 
Vue.prototype.$FileSaver = FileSaver; //设置全局
Vue.prototype.$XLSX = XLSX; //设置全局
Vue.config.productionTip = false;
Vue.config.devtools = true;
import fullscreen from 'vue-fullscreen'

Vue.use(fullscreen)

let app = new Vue({
  ...mainConfig,
  render: h => h(App)
});

app.$mount('#app');
