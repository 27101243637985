import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/sportcalendar/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/sportcalendar/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/sportcalendar/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  console.log(ids)
  return request({
    url: '/api/park-spcod/sportcalendar/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/sportcalendar/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/sportcalendar/submit',
    method: 'post',
    data: row
  })
}

export const filterCalendar = (yearMonth) => {
  return request({
    url: '/api/park-spcod/sportcalendar/filtercalendar/' + yearMonth,
    method: 'get',
    params : {}
  })
}

//yearMonthDay:2020-01-01
export const toogleCalendar = (yearMonthDay) => {
  return request({
    url: '/api/park-spcod/sportcalendar/toogle/' + yearMonthDay,
    method: 'get',
    params : {}
  })
}
