<template>
    <div>
        <!-- <el-steps :active="2" align-center>
      <el-step title="步骤1"></el-step>
      <el-step
        title="步骤2"
      ></el-step>
      <el-step
        title="步骤3"
      ></el-step>
      <el-step
        title="步骤4"
      ></el-step>
    </el-steps> -->
        <el-steps :active="activeName" simple finish-status="success" v-show="activeName != 4">
            <template>
                <el-step class="saveEnt" v-if="saveEnted.indexOf(0) > -1" @click.native="activeName = 0" title="填写入驻申请" icon="el-icon-edit"></el-step>
                <el-step v-if="saveEnted.indexOf(0) <= -1" title="填写入驻申请" icon="el-icon-edit"></el-step>
                <el-step v-if="saveEnted.indexOf(1) > -1" class="avue-view el-row saveEnt" @click.native="activeName = 1" title="完善企业信息" icon="el-icon-edit"></el-step>
                <el-step v-if="saveEnted.indexOf(1) <= -1" title="完善企业信息" icon="el-icon-edit"></el-step>
                <el-step class="avue-view el-row saveEnt" v-if="saveEnted.indexOf(2) > -1" @click.native="activeName = 2" title="维护员工信息" icon="el-icon-s-custom"></el-step>
                <el-step v-if="saveEnted.indexOf(2) <= -1" title="维护员工信息" icon="el-icon-edit"></el-step>
                <el-step v-if="saveEnted.indexOf(3) > -1" class="avue-view el-row saveEnt" @click.native="activeName = 3" title="预览申请表" icon="el-icon-s-custom"></el-step>
                <el-step v-if="saveEnted.indexOf(3) <= -1" title="预览申请表" icon="el-icon-edit"></el-step>
            </template>
        </el-steps>
        <template v-if="activeName != 4">
            <div class="ov-a diaDiv" :style="{ height: height }">
                <basic-container class="h-100p pad-0" isCard :body-style="{ padding: '0', height: '100%' }">
                    <el-col :span="24" v-show="activeName == 0">
                        <div class="fts-18 text-center mar-b-15 pad-t-15">填写入驻申请</div>
                        <contract ref="contract" :companyId="currentRow.id + ''" :enterprise="enterprise" @changeCurrentRowId="changeCurrentRowId" @getCompanyRooms="getCompanyRooms"></contract>
                    </el-col>
                    <el-col :span="24" v-show="activeName == 1">
                        <div v-show="activeName == 1" class="fts-18 pad-t-20 text-center mar-b-15">
                            完善企业信息
                        </div>
                        <baseInfo ref="baseInfo" :currentRow="currentRow" :doType="doType"></baseInfo>
                    </el-col>
                    <el-col :span="24" v-show="activeName == 2">
                        <div class="fts-18 text-center mar-b-15 pad-t-15">维护员工信息</div>
                        <employee v-show="activeName == 2" @empoyees="getEmpoyees" :companyInfo="companyInfo" :enterpriseEnter="true" :companyNode="{ id: currentRow.id }" :selection="false"
                                  :identity="2" :accountStatus="0" :height="String(viewPortHeight - 395)"></employee>
                    </el-col>
                    <el-col :span="24" v-if="activeName == 3">
                        <div class="fts-18 text-center mar-b-15 pad-t-15">预览申请表</div>
                        <enterDetail :companyInfo="companyInfo"></enterDetail>
                    </el-col>
                </basic-container>
            </div>
        </template>
        <div class="ov-a mar-t-10f pad-t-20 diaDiv" v-if="activeName == 4" :style="{ height: viewPortHeight + 'px' }">
            <feedback v-if="activeName == 4" :approvalStatus="approvalStatus"></feedback>
            <div class="text-center">
                <el-button type="primary" size="small" @click="$router.go(-1)" icon="el-icon-back">返回</el-button>
            </div>
        </div>

        <div class="btnClass" v-if="activeName != 4">
            <el-button size="small" @click="activeName--" v-if="activeName != 0" type="primary" class="mar-t-10" plain>上一步</el-button>
            <el-button size="small" v-if="activeName != 3" :disabled="!currentRow.id" class="text-center mar-t-10" @click="next" type="primary">下一步</el-button>

            <el-button v-if="activeName == 3" size="small" plain class="mar-t-10" type="primary" :disabled="saveDisabled" :loading="saveLoading" @click="saveEnt('interim')">暂存</el-button>
            <el-button v-if="activeName == 3" size="small" class="mar-t-10" type="primary" :disabled="saveDisabled" :loading="saveLoading" @click="saveEnt('saveEnt')">提交审核</el-button>

            <span class="errortitle">{{ errorTitle }}</span>
        </div>

        <el-dialog :visible.sync="detailDia" width="80%" class="enterDetail" destroy-on-close append-to-body>
            <div slot="title" class="header-title">
                <span class="fts-16">选择企业</span>
            </div>
            <div style="height: 57vh; overflow-x: hidden !important" class="ov-a mar-t-20f">
                <management :choose="true" @selectionChange="selectionChange"></management>
            </div>

            <div class="dialog-footer bor-t">
                <el-button @click="detailDia = false" size="small">取 消</el-button>
                <el-button type="primary" @click="chooseEnter" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { saveMetaCommon, updateMetaCommon } from '@/api/busiMode/metaCommon';
import baseInfo from './addEnterprise/baseInfo.vue';
import bankInfo from './addEnterprise/bankInfo.vue';
import enterInfo from './addEnterprise/enterInfo.vue';
import employee from '../employee/employee';
import contract from './contractDetail';
import management from './management';
import { dateFormat } from '@/util/date';
import { companycheckin, checkout, companySave } from '@/api/contract/index';
import feedback from './feedback';
import enterDetail from './enterDetail';
import { mapGetters, mapState } from 'vuex';

const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    name: 'addEnterprise',
    components: {
        baseInfo,
        bankInfo,
        enterInfo,
        employee,
        contract,
        management,
        feedback,
        enterDetail,
    },

    data() {
        return {
            entModel: 'wisdom_park.wp_company',
            activeName: 0,
            saveEnted: [],
            doType: '',
            node: {},
            chooseEnterList: [],
            detailDia: false,
            companyInfo: {},
            currentRow: {},
            saveDisabled: true,
            approvalStatus: 2,
            errorTitle: '',
            saveLoading: false,
            interimId: '', //暂存id
            viewPortHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
        };
    },
    props: {
        enterprise: {
            type: Boolean,
            default() {
                return false;
            },
        },
        height: {
            type: String,
            default() {
                return viewPortHeight - 230 + 'px';
            },
        },
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    watch: {
        'userInfo.companyId': {
            handler(val) {
                if (val && userInfo.tenant_id == '000002') {
                    this.currentRow = { id: this.userInfo.companyId };
                }
            },
        },
    },
    mounted() {},
    methods: {
        changeCurrentRowId(id) {
            this.currentRow = { id: id };
        },
        next() {
            if (this.currentRow.id) {
                this.saveEnted.push(this.activeName);
                this.activeName++;
            }
            this.companyInfo = this.$refs.contract.save();
            if (!this.companyInfo.roomIds || this.companyInfo.roomIds.length == 0) {
                this.errorTitle = '请选择入驻房间！';
                this.saveDisabled = true;
            } else {
                this.errorTitle = '';
                this.saveDisabled = false;
            }
        },
        getCompanyRooms(ids) {
            if (ids) {
                this.companyInfo.roomIds = ids;
                this.errorTitle = '';
                this.saveDisabled = false;
            }
        },
        chooseEnter() {
            this.currentRow = this.chooseEnterList[0];
            this.detailDia = false;
        },
        selectionChange(list) {
            this.chooseEnterList = list;
        },
        close() {
            this.$emit('close');
        },
        getEmpoyees(e) {
            console.log(e);
        },
        saveEnt(type) {
            Promise.all([this.$refs.baseInfo.submit()])
                .then(forms => {
                    this.saveLoading = true;
                    let valid = true,
                        errorObj = null;
                    forms.forEach(itemForm => {
                        if (itemForm.valid !== true) {
                            valid = false;
                            if (!errorObj) {
                                errorObj = itemForm.error;
                            }
                        }
                    });
                    if (valid) {
                        let baseInfo = forms[0].form;
                        //营业执照图片
                        if (this.$refs.baseInfo.imgform) {
                            let imgList = [];
                            this.$refs.baseInfo.imgform.imgUrl.forEach(e => {
                                if (e.label) {
                                    imgList.push(e.label);
                                }
                            });
                            baseInfo.business_license = imgList.toString();
                        }
                        //企业logo
                        if (this.$refs.baseInfo.imgform1) {
                            let imgList = [];
                            this.$refs.baseInfo.imgform1.imgUrl.forEach(e => {
                                if (e.label) {
                                    imgList.push(e.label);
                                }
                            });
                            baseInfo.company_logo = imgList.toString();
                        }
                        let companyCheckinObj = this.$refs.contract.save();
                        if (!companyCheckinObj.phoneNum) {
                            this.$message.error('请补全申请人联系电话');
                            this.saveLoading = false;
                            return false;
                        }
                        if (!companyCheckinObj.applicant) {
                            this.$message.error('请补申请人信息');
                            this.saveLoading = false;
                            return false;
                        }
                        companyCheckinObj.companyId = this.$refs.contract.companyId;
                        if (baseInfo.id) {
                            let companyCheckin = {
                                attachmentId: baseInfo.attachment_id || '',
                                companyId: baseInfo.id,
                                companyLogo: baseInfo.company_logo,
                                contactDetails: baseInfo.contact_details,
                                firmSize: baseInfo.firmSize,
                                industry: baseInfo.industry,
                                legalContact: baseInfo.legal_contact,
                                legalPerson: baseInfo.legal_person,
                                name: baseInfo.name,
                                phoneNum: baseInfo.phone_num,
                                principal: baseInfo.principal,
                                qualification: baseInfo.qualification,
                                registeredAddress: baseInfo.registered_address,
                                registeredArea: baseInfo.registered_area,
                                registeredCapital: baseInfo.registered_capital,
                                shortName: baseInfo.short_name,
                                status: 3,
                                type: baseInfo.type,
                                uniformSocialCreditCode: baseInfo.uniform_social_credit_code,
                            };

                            if (baseInfo.technical_type) {
                                baseInfo.technical_type = baseInfo.technical_type.join(',');
                            }

                            let o = {
                                update: baseInfo,
                                primaryKeyValue: baseInfo['id'],
                                primaryKey: 'id',
                            };

                            if (companyCheckinObj.planCheckinTime) {
                                if (typeof companyCheckinObj.planCheckinTime != 'string') {
                                    companyCheckinObj.planCheckinTime = dateFormat(
                                        companyCheckinObj.planCheckinTime,
                                        'yyyy-MM-dd',
                                    );
                                } else {
                                    console.log(123);
                                }
                            }

                            let com = Object.assign(companyCheckin, companyCheckinObj);
                            delete com.createTime;
                            //  delete com.planCheckinTime;
                            if (com.roomIds || com.roomIds.length == 0) {
                                com.roomIds = com.roomIds.toString();
                            }
                            if (com.roomNames || com.roomNames.length == 0) {
                                com.roomNames = com.roomNames.toString();
                            }
                            let t = this;
                            const promise = new Promise((resolve, reject) => {
                                com.approvalStatus = 1;
                                if (t.interimId) {
                                    com.id = t.interimId;
                                }
                                companycheckin(com)
                                    .then(r => {
                                        if (r.data.data.id) {
                                            t.interimId = r.data.data.id;
                                            resolve(r.data.data.id);
                                        }
                                    })
                                    .catch(e => {
                                        this.saveLoading = false;
                                    });
                            });
                            promise
                                .then(value => {
                                    if (type == 'saveEnt') {
                                        com.approvalStatus = 2;
                                        com.id = value;
                                        companycheckin(com)
                                            .then(r => {
                                                t.interimId = '';
                                                // t.$message.success("您的提交有问题,已经为您暂存了～");
                                                this.saveLoading = false;
                                            })
                                            .catch(e => {
                                                // t.$message.error("您的提交有问题,已经为您暂存了～");
                                                this.saveLoading = false;
                                            });
                                    }
                                })
                                .then(value => {
                                    updateMetaCommon(this.entModel, o).then(res => {
                                        if (type == 'saveEnt') {
                                            t.activeName = 4;
                                            t.$emit('close');
                                        } else {
                                            t.$message.success('您的提交已经为您暂存了～');
                                        }
                                        this.saveLoading = false;
                                    });
                                });
                        }
                    } else {
                        this.$message.error('请返回补全企业信息');
                        this.saveLoading = false;
                    }
                })
                .then({});
        },
    },
};
</script>
<style lang="scss" scoped>
.btnClass {
    display: flow-root;
    height: 60px;
    background: #fff;
    text-align: center;
}
/deep/ .el-dialog__body {
    padding-top: 0px;
}
/deep/ .el-steps--simple {
    padding: 13px 8%;
    border-radius: 4px;
    background: transparent;
}
.titleactive {
    color: #475570 !important;
}
.title {
    color: #8d9ab2;
}
.p-a {
    position: absolute;
}
.diaTitle {
    top: 23px;
    left: 80px;
}
.diaDiv {
    overflow-x: hidden !important;
}
.diaDivover {
    overflow: hidden !important;
}
.drawerDiv {
    height: calc(100% - 50px);
    overflow-x: hidden !important;
}
.titlediv {
    background: #fff;
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0px;
}
.saveEnt {
    /deep/ .el-step__main {
        cursor: pointer !important;
    }
}
/deep/ .el-step__main {
    cursor: not-allowed;
}

.dialog-footer {
    padding: 20px;
    padding-top: 10px;
    text-align: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.errortitle {
    color: #f56c6c;
    margin-left: 10px;
}
</style>
