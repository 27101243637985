// 海豚下的功能接口
import request from '@/router/axios';

// 数据采集任务---上线申请 --保存接口
export const dsProcessDefinitionFlowSave = (params) => {
  return request({
    url:'/api/business-flow/dsProcessDefinitionFlow/save',
    method: 'post',
    data: params
  })
};
// 数据采集任务---我的申请
export const workSendList = (params) => {
  return request({
    url:'/api/jida-flow/work/send-list',
    method: 'get',
    params: params
  })
};


// /jida-flow/work/delete-task

//  数据采集任务---我的申请 撤回/我的审核--审核完成
export const workCompleteTask = (params) => {
  return request({
    url:'/api/jida-flow/work/complete-task',
    method: 'post',
    data: params
  })
};

// 我的审核--待处理数据
export const workTodoList = (params) => {
  return request({
    url:'/api/jida-flow/work/todo-list',
    method: 'get',
    params: params
  })
};
// 我的审核-- 已处理数据
export const workDoneList = (params) => {
  return request({
    url:'/api/jida-flow/work/done-list',
    method: 'get',
    params: params
  })
};
// 获取任务类型字典表
export const flowList = (params) => {
  return request({
    url:'/api/jida-system/dict/dictionary?code=flow',
    method: 'get',
    params: params
  })
};
// 获取待办事务数据量
export const todoListSize = (params) => {
  return request({
    url:'/api/jida-flow/work/todo-list-size',
    method: 'get',
    params: params
  })
};
// 获取 审核记录
export const commentList = (params) => {
  return request({
    url:'/api/jida-flow/work/comment-list',
    method: 'get',
    params: params
  })
};
