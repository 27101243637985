<template>
  <div class="menu-wrapper">
    <template v-for="item in menu">
      <el-menu-item
        v-if="validatenull(item[childrenKey]) && vaildRoles(item)"
        :index="item[pathKey]"
        @click="open(item)"
        :key="item[labelKey]"
        :class="{ 'is-active': vaildActive(item) }"
      >
        <i :class="item[iconKey]"></i>
        <span slot="title" :alt="item[pathKey]">
          {{ generateTitle(item) }}
          <!-- {{$t('menu.'+item['code'])}} -->
        </span>
      </el-menu-item>
      <el-submenu
        v-else-if="!validatenull(item[childrenKey]) && vaildRoles(item)"
        :index="item[pathKey]"
        :key="item[labelKey]"
      >
        <template slot="title">
          <i :class="item[iconKey]"></i>
          <span slot="title" :class="{ 'el-menu--display': collapse && first }">
            {{ generateTitle(item) }}
            <!-- {{$t('menu.'+item['code'])}} -->
          </span>
        </template>
        <template v-for="(child, cindex) in item[childrenKey]">
          <el-menu-item
            :index="child[pathKey]"
            @click="open(child)"
            :class="{ 'is-active': vaildActive(child) }"
            v-if="validatenull(child[childrenKey])"
            :key="child[labelKey]"
          >
            <i :class="child[iconKey]"></i>
            <span slot="title">
              {{ generateTitle(child) }}
              <!-- {{$t('menu.'+child['code'])}} -->
            </span>
          </el-menu-item>
          <sidebar-item
            v-else
            :menu="[child]"
            :key="cindex"
            :props="props"
            :screen="screen"
            :collapse="collapse"
          ></sidebar-item>
        </template>
      </el-submenu>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isURL, validatenull } from "@/util/validate";
import { translateUrl } from '@/util/util';
import config from "./config.js";

export default {
  name: "sidebarItem",
  data() {
    return {
      config: config,
    };
  },
  props: {
    menu: {
      type: Array,
    },
    screen: {
      type: Number,
    },
    first: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: () => {
        return {};
      },
    },
    collapse: {
      type: Boolean,
    },
  },
  created() {},
  mounted() {
    console.log(this.$store.state);
  },
  computed: {
    ...mapGetters(["roles"]),
    labelKey() {
      return this.props.label || this.config.propsDefault.label;
    },
    pathKey() {
      return this.props.path || this.config.propsDefault.path;
    },
    iconKey() {
      return this.props.icon || this.config.propsDefault.icon;
    },
    childrenKey() {
      return this.props.children || this.config.propsDefault.children;
    },
    isOpenKey() {
      return this.props.isOpen || this.config.propsDefault.isOpen;
    },
    nowTagValue() {
      return this.$router.$avueRouter.getValue(this.$route);
    },
  },
  methods: {
    generateTitle(item) {
      return this.$router.$avueRouter.generateTitle(
        item[this.labelKey],
        (item.meta || {}).i18n
      );
    },
    vaildActive(item) {
      if (this.validIsOpen(item)) {
        return false;
      }
      const groupFlag = (item["group"] || []).some((ele) =>
        this.$route.path.includes(ele)
      );
      let paths = this.nowTagValue.split("?");
      let after = "";
      if (paths[1]) {
        if (paths[1].match(/scene=\d+/g)) {
          after = "?" + paths[1].match(/scene=\d+/g)[0];
        }
      }
      let nowTagValue = paths["0"] + after;
      return nowTagValue === item[this.pathKey] || groupFlag;
    },
    vaildRoles(item) {
      item.meta = item.meta || {};
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true;
    },
    validatenull(val) {
      return validatenull(val);
    },
    validIsOpen(item) {
      if (item[this.isOpenKey] === 2 && isURL(item[this.pathKey])) {
        return true;
      }
    },
    open(item) {
      if (this.screen <= 1) this.$store.commit("SET_COLLAPSE");
      if (this.validIsOpen(item)) {
        window.open(translateUrl(item[this.pathKey] , {
          ...this.$store.state.user
        }));
      } else {
        this.$router.$avueRouter.group = item.group;
        this.$router.$avueRouter.meta = item.meta;
        let path = this.$router.$avueRouter.getPath(
          {
            name: item[this.labelKey],
            src: item[this.pathKey],
          },
          item.meta
        );
        this.$router
          .push({
            path: path,
            query: item.query,
          })
          .then(() => {
            this.$breadcrumb.add({
              name: item[this.labelKey],
              path: path,
              type: this.$store.state.user.sidebarMenuType,
              meta: item,
            });
          });
      }
    },
  },
};
</script>
