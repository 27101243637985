<template>
    <div style="height: 100%">
        <avue-crud class="enter-deatil" :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" @selection-change="selectionChange" @row-del="rowDel"
                   @refresh-change="refreshChange" @search-reset="refreshChange" @on-load="onLoad" :rules="ruleModel">
            <template slot="menuLeft" slot-scope="scope">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="openDislog('add')">新增
                </el-button>
            </template>
        </avue-crud>
        <el-dialog :size="size" :visible.sync="dialogVisible" destroy-on-close :top="'8vh'" :body-style="{ padding: '0px' }" width="40%" append-to-body class="buildDetail">
            <template v-if="dialogVisible">
                <div slot="title" class="header-title">
                    <span class="fts-16">新增</span>
                </div>
            </template>
            <el-row>
                <el-form>
                    <el-col :span="22">
                        <el-col :span="24">
                            <el-form-item label="入驻房间" prop="reason" :rules="[
                  {
                    required: true,
                    message: '请选择入驻房间',
                    trigger: 'blur',
                  },
                ]">
                                <el-select v-model="ruleForm.roomIds" multiple filterable remote clearable reserve-keyword default-first-option placeholder="请输入入驻房间" :remote-method="remoteMethod"
                                           :loading="remoteLoad" @change="changeRooms">

                                    <el-option v-for="item in roomList" :key="item.room_id" :label="item.label" :value="item.room_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" v-if="ruleForm.roomNames">
                            <template v-for="(item, index) of ruleForm.roomNames">
                                <div class="roomtitle">
                                    {{ item }}
                                    <i class="el-icon-close" @click="deleteRoom(item, index)"></i>
                                </div>
                            </template>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item label="备注" prop="note">
                                <el-input type="textarea" v-model="note" />
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-form>
            </el-row>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button size="small" icon="el-icon-circle-plus-outline" type="primary" @click="save">保存</el-button>
                <el-button size="small" icon="el-icon-circle-close" @click="dialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getMetaCommonList, delMetaCommon, saveMetaCommon } from '@/api/busiMode/metaCommon';
import { addCompanyRoom, delCompanyRoom } from '@/api/spcod/room';

const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

export default {
    data() {
        return {
            searchText: '',
            form: {},
            model: 'wisdom_park.wp_company_room',
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            loading: false,
            data: {},
            ruleModel: {},
            dialogVisible: false,
            option: {
                height: viewPortHeight - 230,
                dialogWidth: 900,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                labelWidth: 120,
                menu: true,
                viewBtn: false,
                dialogClickModal: false,
                delBtn: true,
                editBtn: false,
                addBtn: false,
                cancelBtn: false,
                selection: false,
                column: [
                    {
                        width: 100,
                        overHidden: true,
                        align: 'right',
                        label: '主键',
                        prop: 'id',
                        hide: true,
                    },
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '楼宇名称',
                        prop: 'building_name',
                    },

                    {
                        overHidden: true,
                        align: 'left',
                        label: '房间名称',
                        prop: 'room_name',
                        span: 12,
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '备注',
                        prop: 'note',
                    },
                ],
            },
            roomIds: [],
            remoteLoad: false,
            roomList: [],
            ruleForm: {},
            note: '',
        };
    },
    props: {
        companyNode: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    watch: {
        companyNode: {
            immediate: true,
            handler(val) {
                if (val.id) {
                    this.onLoad();
                }
            },
        },
    },
    computed: {},
    mounted() {},
    methods: {
        changeRooms(val) {
            console.log(val);
            let roomNames = [];
            let roomids = [];
            this.roomList.forEach(e => {
                val.forEach(v => {
                    if (e.room_id == v) {
                        roomids.push(e.room_id);
                        roomNames.push(e.label);
                    }
                });
            });
            this.ruleForm.roomNames = roomNames;
            this.ruleForm.roomIds = roomids;
            this.$forceUpdate();
        },
        remoteMethod(val) {
            this.remoteLoad = true;
            this.getRooms(val);
        },
        openDislog(type, row) {
            this.note = '';
            this.ruleForm.roomNames = [];
            this.ruleForm.roomIds = [];
            this.dialogVisible = true;
        },
        getRooms(val) {
            this.remoteLoad = true;
            let body = {
                current: 1,
                size: 50,
            };
            body.wheres = [
                { field: 'room_name', op: 'LIKE', value: val },
                { field: 'status', value: 0 },
                { field: 'is_cancel', value: 0 },
            ];
            getMetaCommonList('wisdom_park.v_wp_room_stat', body).then(res => {
                let data = res.data.data;
                data.records.forEach(e => {
                    e.label = '';
                    if (e.building_name) {
                        e.label = e.building_name;
                    }
                    if (e.floor_name) {
                        e.label = e.label + '-' + e.floor_name;
                    } else {
                        if (e.floor_code) {
                            e.label = e.label + '-' + e.floor_code;
                        }
                    }
                    e.label = e.label + '-' + e.room_name;
                });
                console.log(data.records);
                this.roomList = data.records;
                this.remoteLoad = false;
            });
        },
        deleteRoom(item, index) {
            this.ruleForm.roomNames.splice(index, 1);
            this.ruleForm.roomIds.splice(index, 1);
        },
        rowDel(row, index) {
            console.log(row);
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let obj = {
                        companyId: row.company_id,
                        roomIds: row.room_id,
                    };
                    delCompanyRoom(obj).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    });
                })
                .catch(() => {});
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },

        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    let ids = [];
                    this.selectionList.forEach(e => {
                        if (e.id) {
                            ids.push(e.id);
                        }
                    });
                    delMetaCommon(this.model, { pk: pk, ids: ids }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.data.splice(index, 1);
                    });
                })
                .then(() => {
                    this.onLoad(this.page);

                    this.$refs.crud.toggleSelection();
                });
        },

        refreshChange() {
            this.form = {};
            this.onLoad();
        },
        searchChange(params, done) {
            this.page.currentPage = 1;
            this.form = params;
            this.onLoad(params);
            done();
        },

        save() {
            console.log(this.note);
            let obj = {
                companyId: this.companyNode.id,
                roomIds: this.ruleForm.roomIds.toString(),
                note: this.note,
            };
            if (this.ruleForm.roomIds.length == 0) {
                this.$message.error('请选择入驻的房间');
                return false;
            }
            addCompanyRoom(obj).then(res => {
                if (res.data.code == 200) {
                    this.dialogVisible = false;
                    this.onLoad();
                }
            });
        },

        onLoad() {
            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
            };
            let wheres = [];
            if (this.companyNode) {
                wheres.push({
                    field: 'company_id',
                    value: this.companyNode.id,
                });
            }
            body.wheres = wheres;
            this.loading = true;
            getMetaCommonList('wisdom_park.v_wp_company_room_stat', body).then(res => {
                this.data = [];
                const data = res.data.data;
                this.page.total = data.total;

                this.data = data.records;

                this.loading = false;
            });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/ .el-select {
    width: 100%;
}
.deleteIcon {
    font-size: 18px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
.enter-deatil {
    height: 100%;
}

.enter-deatil {
    /deep/.el-form {
        .el-table {
            // height: auto !important;
        }
    }
    /deep/.el-card {
        .el-table {
            height: 100%;
        }
    }
}
</style>
