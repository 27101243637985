import request from '@/router/axios';

const prefix = '/api/metadata-proxy/api/meta/v2/';

export default {
  prefix: prefix,
  getModelRule(modelCode){
    return new Promise((resolve , reject) => {
      let ruleModel = {};
      this.queryByModelCode({
        modelCode
      }).then(
        res => {
          if(res && res.data && res.data.data){
            Object.keys(res.data.data).forEach(
              keyName => {
                let rules = [] , item = res.data.data[keyName];
                if(!!item.isNotNull){
                  rules.push({ required: true, message: `必填项不能为空`, trigger: 'blur' })
                }
                if(item.dataLength){
                  rules.push({ max: item.dataLength, message: `只能输入${item.dataLength}个字符`, trigger: 'blur' })
                }
                ruleModel[keyName] = rules;
              }
            )
          }else{
            ruleModel = {};
          }
          resolve(ruleModel)
        }
      ).catch(reject)
    })
  },
  queryByModelCode(params){
    return request({
      url: prefix + 'metadatastandard/queryByModelCode',
      method: 'get',
      params: params
    })
  },
  getList(params){
    return request({
      url: prefix + 'metadatastandard/page',
      method: 'get',
      params: params
    })
  },
  getDetail(params){
    return request({
      url: prefix + 'metadatastandard/detail',
      method: 'get',
      params: params
    })
  },
  saveData(data){
    return request({
      url: prefix + 'metadatastandard/submit',
      method: 'post',
      data: data
    })
  },
  setStatus(data){
    return request({
      url: prefix + 'metadatastandard/enableOrDisable',
      method: 'post',
      data: data
    })
  },
  remove(data){
    return request({
      url: prefix + 'metadatastandard/remove',
      method: 'post',
      params: data
    })
  },
  // 元数据映射
  getMapList(params){
    return request({
      url: prefix + 'metadatastandardmapping/page',
      method: 'get',
      params: params
    })
  },
  getAtlasDataBaseList(params){
    return request({
      url: prefix + 'atlas-finder/getAtlasDataBaseList',
      method: 'get',
      params: params
    })
  },
  getAtlasDataTableList(params){
    return request({
      url: prefix + 'atlas-finder/getAtlasDataTableList',
      method: 'get',
      params: params
    })
  },
  getAtlasColumnList(params){
    return request({
      url: prefix + 'atlas-finder/getAtlasColumnList',
      method: 'get',
      params: params
    })
  },
  saveMap(data){
    return request({
      url: prefix + 'metadatastandardmapping/save',
      method: 'post',
      data: data
    })
  },
  removeMap(data){
    return request({
      url: prefix + 'metadatastandardmapping/remove',
      method: 'post',
      params: data
    })
  },
}