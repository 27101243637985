<template>
  <div class="basic-container"
       :class="{'basic-container--block':block}">
    <el-card v-if="isCard" class="h-100p ov-a" shadow="never" :body-style="bodyStyle">
      <slot></slot>
    </el-card>
    <slot v-else></slot>
  </div>
</template>

<script>
  export default {
    name: "basicContainer",
    props: {
      isCard: {
        type: Boolean,
        default: false
      },
      radius: {
        type: [String, Number],
        default: 10
      },
      background: {
        type: String,
        default: 'transparent'
      },
      block: {
        type: Boolean,
        default: false
      },
      bodyStyle: {
        type: Object,
        default: () => {
          return {
            height: '100%'
          }
        }
      }
    },
    computed: {
      styleName () {
        return {
          borderRadius: this.setPx(this.radius),
          background: this.background,
        }
      }
    }
  };
</script>

<style lang="scss">
.basic-container {
  padding: 10px 6px;
  box-sizing: border-box;
  &--block {
    height: 100%;
    .el-card {
      height: 100%;
    }
  }
  .el-card {
    width: 100%;
  }
  &:first-child {
    padding-top: 0;
  }
}
</style>
