import BpmnPackage from './bpmnPkgs';

function extendsType(node){
  if(node && node.props){
    BpmnPackage.types.forEach(
      itemType => {
        if(node.name === itemType.name){
          Object.keys(node.props).forEach(
            keyName => {
              let itemProp = node.props[keyName];
              if(!itemType[keyName]){
                itemType[keyName] = itemProp;
              }else{
                if(Array.isArray(itemProp)){
                  itemProp.forEach(
                    item => {
                      let has = itemType[keyName].find((val) => {
                        if(typeof val === 'object'){
                          return val.name === item.name
                        }else{
                          return val === item;
                        }
                      });
                      if(!has){
                        itemType[keyName].push(item);
                      }
                    }
                  )
                }
              }
            }
          )
        }
      }
    )
  }
}

function registerNode(node){
  let types = [];
  Object.keys(node.nodes).forEach(
    itemKey => {

      let item = node.nodes[itemKey] ,
          type = itemKey.replace('bpmn:' , '') ,
          rootProps = node['props'];
      if(item.extend){
        extendsType({
          name: itemKey,
          ...item
        });
      }else{
        let newType = {
          name: type,
          ...rootProps,
          ...item.props
        }
  
        types.push(newType);
      }
    }
  )
  BpmnPackage.types = [...BpmnPackage.types , ...types];
}



export default function(register){
  console.log(123);
  if(register){
    if(register.node){
      registerNode(register.node)
    }
  }
  return {
    bpmn: BpmnPackage
  };
}