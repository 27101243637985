import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/jida-system/datasource/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const collectionDatasource = (datasourceId) => {
  return request({
    url: '/api/mysql-bridge/metadata/mysql',
    method: 'get',
    params: {
      datasourceId
    },
    timeout: 0
  })
}


export const getAllData = () => {
  return request({
    url: '/api/jida-system/datasource/select',
    method: 'get'
  })
}
export const getDetail = (id) => {
  return request({
    url: '/api/jida-system/datasource/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/jida-system/datasource/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/jida-system/datasource/save',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/jida-system/datasource/update',
    method: 'post',
    data: row
  })
}

