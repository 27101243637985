/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import i18n from '@/lang'

/**
 * Operation bar config
 * @code code
 * @icon icon
 * @disable disable
 * @desc tooltip
 */
const toolOper = (dagThis) => {
  const disabled = !!dagThis.$store.state.dag.isDetails// Permissions.getAuth() === false ? false : !dagThis.$store.state.dag.isDetails
  return [
    {
      code: 'pointer',
      icon: 'el-icon-thumb',
      disable: disabled,
      desc: `${i18n.$t('Drag Nodes and Selected Items')}`
    },
    {
      code: 'line',
      icon: 'el-icon-top-right',
      disable: disabled,
      desc: `${i18n.$t('Select Line Connection')}`
    },
    {
      code: 'remove',
      icon: 'el-icon-delete',
      disable: disabled,
      desc: `${i18n.$t('Delete selected lines or nodes')}`
    },
    {
      code: 'download',
      icon: 'el-icon-download',
      disable: !dagThis.type,
      desc: `${i18n.$t('Download')}`
    },
    {
      code: 'screen',
      icon: 'el-icon-full-screen',
      disable: false,
      desc: `${i18n.$t('Full Screen')}`
    }
  ]
}

/**
 * Post status
 * @id Front end definition id
 * @desc tooltip
 * @code Backend definition identifier
 */
const publishStatus = [
  {
    id: 0,
    desc: `${i18n.$t('Unpublished')}`,
    code: 'NOT_RELEASE'
  },
  {
    id: 1,
    desc: `${i18n.$t('online')}`,
    code: 'ONLINE'
  },
  {
    id: 2,
    desc: `${i18n.$t('offline')}`,
    code: 'OFFLINE'
  }
]

/**
 * Operation type
 * @desc tooltip
 * @code identifier
 */
const runningType = [
  {
    desc: `${i18n.$t('Start Process')}`,
    code: 'START_PROCESS'
  },
  {
    desc: `${i18n.$t('Execute from the current node')}`,
    code: 'START_CURRENT_TASK_PROCESS'
  },
  {
    desc: `${i18n.$t('Recover tolerance fault process')}`,
    code: 'RECOVER_TOLERANCE_FAULT_PROCESS'
  },
  {
    desc: `${i18n.$t('Resume the suspension process')}`,
    code: 'RECOVER_SUSPENDED_PROCESS'
  },
  {
    desc: `${i18n.$t('Execute from the failed nodes')}`,
    code: 'START_FAILURE_TASK_PROCESS'
  },
  {
    desc: `${i18n.$t('Complement Data')}`,
    code: 'COMPLEMENT_DATA'
  },
  {
    desc: `${i18n.$t('Scheduling execution')}`,
    code: 'SCHEDULER'
  },
  {
    desc: `${i18n.$t('Rerun')}`,
    code: 'REPEAT_RUNNING'
  },
  {
    desc: `${i18n.$t('Pause')}`,
    code: 'PAUSE'
  },
  {
    desc: `${i18n.$t('Stop')}`,
    code: 'STOP'
  },
  {
    desc: `${i18n.$t('Recovery waiting thread')}`,
    code: 'RECOVER_WAITTING_THREAD'
  }
]

/**
 * Task status
 * @key key
 * @id id
 * @desc tooltip
 * @color color
 * @icoUnicode iconfont
 * @isSpin is loading (Need to execute the code block to write if judgment)
 */
const tasksState = {
  SUBMITTED_SUCCESS: {
    id: 0,
    desc: `${i18n.$t('Submitted successfully')}`,
    color: '#A9A9A9',
    icoUnicode: 'ri-record-circle-fill',
    isSpin: false
  },
  RUNNING_EXECUTION: {
    id: 1,
    desc: `${i18n.$t('Executing')}`,
    color: '#0097e0',
    icoUnicode: 'el-icon-s-tools',
    isSpin: true
  },
  READY_PAUSE: {
    id: 2,
    desc: `${i18n.$t('Ready to pause')}`,
    color: '#07b1a3',
    icoUnicode: 'ri-settings-3-line',
    isSpin: false
  },
  PAUSE: {
    id: 3,
    desc: `${i18n.$t('Pause')}`,
    color: '#057c72',
    icoUnicode: 'el-icon-video-pause',
    isSpin: false
  },
  READY_STOP: {
    id: 4,
    desc: `${i18n.$t('Ready to stop')}`,
    color: '#FE0402',
    icoUnicode: 'ri-stop-circle-fill',
    isSpin: false
  },
  STOP: {
    id: 5,
    desc: `${i18n.$t('Stop')}`,
    color: '#e90101',
    icoUnicode: 'ri-stop-circle-line',
    isSpin: false
  },
  FAILURE: {
    id: 6,
    desc: `${i18n.$t('Failed')}`,
    color: '#000000',
    icoUnicode: 'el-icon-circle-close',
    isSpin: false
  },
  SUCCESS: {
    id: 7,
    desc: `${i18n.$t('Success')}`,
    color: '#33cc00',
    icoUnicode: 'el-icon-circle-check',
    isSpin: false
  },
  NEED_FAULT_TOLERANCE: {
    id: 8,
    desc: `${i18n.$t('Need fault tolerance')}`,
    color: '#FF8C00',
    icoUnicode: 'el-icon-edit',
    isSpin: false
  },
  KILL: {
    id: 9,
    desc: `${i18n.$t('Kill')}`,
    color: '#a70202',
    icoUnicode: 'el-icon-remove-outline',
    isSpin: false
  },
  WAITTING_THREAD: {
    id: 10,
    desc: `${i18n.$t('Waiting for thread')}`,
    color: '#912eed',
    icoUnicode: 'ri-time-line',
    isSpin: false
  },
  WAITTING_DEPEND: {
    id: 11,
    desc: `${i18n.$t('Waiting for dependence')}`,
    color: '#5101be',
    icoUnicode: 'ri-send-to-back',
    isSpin: false
  },
  DELAY_EXECUTION: {
    id: 12,
    desc: `${i18n.$t('Delay execution')}`,
    color: '#5102ce',
    icoUnicode: 'ri-pause-circle-fill',
    isSpin: false
  },
  FORCED_SUCCESS: {
    id: 13,
    desc: `${i18n.$t('Forced success')}`,
    color: '#5102ce',
    icoUnicode: 'el-icon-success',
    isSpin: false
  }
}

/**
 * Node type
 * @key key
 * @desc tooltip
 * @color color (tree and gantt)
 */
const tasksType = {
  SHELL: {
    desc: 'SHELL',
    color: '#646464',
    name: 'Shell'
  },
  WATERDROP: {
    desc: 'WATERDROP',
    color: '#646465',
    name: 'Waterdrop'
  },
  SUB_PROCESS: {
    desc: 'SUB_PROCESS',
    color: '#0097e0',
    name: '子流程'
  },
  PROCEDURE: {
    desc: 'PROCEDURE',
    color: '#525CCD',
    name: 'Procedure'
  },
  SQL: {
    desc: 'SQL',
    color: '#7A98A1',
    name: 'SQL'
  },
  SPARK: {
    desc: 'SPARK',
    color: '#E46F13',
    name: 'Spark'
  },
  FLINK: {
    desc: 'FLINK',
    color: '#E46F13',
    name: 'Flink'
  },
  MR: {
    desc: 'MapReduce',
    color: '#A0A5CC',
    name: 'MR'
  },
  PYTHON: {
    desc: 'PYTHON',
    color: '#FED52D',
    name: 'Python'
  },
  DEPENDENT: {
    desc: 'DEPENDENT',
    color: '#2FBFD8',
    name: 'Dependent'
  },
  HTTP: {
    desc: 'HTTP',
    color: '#E46F13',
    name: 'HTTP'
  },
  DATAX: {
    desc: 'DataX',
    color: '#1fc747',
    name: 'DataX'
  },
  SQOOP: {
    desc: 'SQOOP',
    color: '#E46F13',
    name: 'Sqoop'
  },
  CONDITIONS: {
    desc: 'CONDITIONS',
    color: '#E46F13',
    name: 'Conditions'
  },
  DATAQUALITY: {
    desc: 'DATAQUALITY',
    color: '#E46F13',
    name: '质量检测'
  },
  DATACHECK: {
    desc: 'DATACHECK',
    color: '#E46F13',
    name: '数据校验'
  }
}

const createTaskType = (types , code) => {
  let taskTypes = {};
  types.forEach(
    (item , index) => {
      taskTypes[code + '_' + index] = {
        desc: item,
        color: '#646465',
        name: item
      }
    }
  )
  return taskTypes;
}

const tastTypes = [
  {
    name: '通用类',
    show: false,
    tasksTypeList: tasksType,
    type: 1
  },
  {
    name: '流计算',
    show: false,
    tasksTypeList: createTaskType(['输入流' , '逻辑处理' , '实时计算模型'] , 'operator_1'),
    type: 1
  },
  {
    name: '离线计算',
    show: false,
    tasksTypeList: createTaskType(['任务切分' , '序列化' , '单元计算' , '归约计算' , '计算容错'] , 'operator_2'),
    type: 1
  },
  {
    name: '交互式计算',
    show: false,
    tasksTypeList: createTaskType(['迭代作业' , '内存计算' , 'map' , 
    'filter' , 'flatMap' , 'sample' , 'union' , 'groupByKey' , 'reduceByKey' , '共享内存数据模型' , '函数式程序支撑'] , 'operator_3'),
    type: 1
  },
  {
    name: '时间管理',
    show: false,
    tasksTypeList: createTaskType(['时空融合时间价值计算' , '大数据应用时间价值计算' , '时间事件数据算法' , '物联网时序数据算法'] , 'operator_4'),
    type: 1
  },
  {
    name: '时序处理',
    show: false,
    tasksTypeList: createTaskType(['基于统计特征的分类特征提取' , '基于构建模型的分类特征提取' , '基于变换的分类特征提取' , '基于分形理论的分类特征提取' ,
      '分段聚合近似', '分段线性表示', '符号化聚合近似', '时空距离计算' , '动态时间弯曲', '符号化距离', '基于模型的距离度量方法'
    ] , 'operator_5'),
    type: 1
  },
  {
    name: '通用类',
    show: false,
    tasksTypeList: {
      INPUT: {
        desc: 'INPUT',
        color: '#1fc747',
        name: '数据输入'
      },
      OUTPUT: {
        desc: 'OUTPUT',
        color: '#1fc747',
        name: '数据输出'
      },
      DATACHECK: {
        desc: 'DATACHECK',
        color: '#E46F13',
        name: '数据校验'
      }
    },
    type: 4
  },
]

const TaskType = [
  // {
  //   name: '通用类',
  //   type: '',
  //   childrenClass: [
  //     {
  //       name: 'HTTP',
  //       type: 'HTTP'
  //     },
  //     {
  //       name: 'SQL',
  //       type: 'SQL'
  //     },
  //     {
  //       name: 'SPARK',
  //       type: 'SPARK'
  //     }
  //   ]
  // },
  // {
  //   name: '数据验证',
  //   type: '',
  //   childrenClass: [
  //     {
  //       name: '数据输出',
  //       type: 'OUTPUT'
  //     },
  //     {
  //       name: '数据输入',
  //       type: 'INPUT'
  //     },
  //     {
  //       name: '数据校验',
  //       type: 'DATACHECK'
  //     }
  //   ]
  // }
]

export {
  toolOper,
  publishStatus,
  runningType,
  tasksState,
  tasksType,
  tastTypes,
  TaskType
}
