import request from '@/router/axios';

// 获得分类树状
export const getTreeListAPI = () => {
  return request({
    url: '/api/park-spcod/policycatg/prncatgtree',
    method: 'get'
  })
}

// 新增政策分类
export const addTreeListAPI = (data) => {
  return request({
    url: '/api/park-spcod/policycatg/addprncatg',
    method: 'post',
    data
  })
}

// 修改正常分类
export const updTreeListAPI = (data) => {
  return request({
    url: `/api/park-spcod/policycatg/modifyprncatg/${data.id}`,
    method: 'post',
    data:data
  })
}

// 删除
export const delCatgNode = (id) => {
  return request({
    url: `/api/park-spcod/policycatg/delprncatg/${id}`,
    method: 'post'
  })
}
