<template>
  <div v-loading="loading" class="pad-15">
    <el-descriptions title="基本信息" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
      <el-descriptions-item label="合同编号">{{ info.code || "-" }}</el-descriptions-item>
      <el-descriptions-item label="模板名称">{{ info.contractTemplateName || "-" }}</el-descriptions-item>
      <el-descriptions-item label="签约类型">{{ info.offerTypeName || "-" }}</el-descriptions-item>
      <el-descriptions-item label="合同属性">{{ info.contractAttributeName || "-" }}</el-descriptions-item>
      <el-descriptions-item label="合同类型">{{ info.contractTypeName || "-" }}</el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions title="" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
      <el-descriptions-item label="租客类型">{{ info.rentersType || "-" }}</el-descriptions-item>
      <el-descriptions-item label="租客名称">{{ info.rentersName || "-" }}</el-descriptions-item>
      <el-descriptions-item label="企业法人">{{ info.corporationPersonality || "-" }}</el-descriptions-item>
      <el-descriptions-item label="法人联系方式">{{ info.corporationPersonalityContact || "-" }}</el-descriptions-item>
      <el-descriptions-item label="签订人">{{ info.rentersSigned || "-" }}</el-descriptions-item>
      <el-descriptions-item label="签订人联系方式">{{ info.rentersSignedContact || "-" }}</el-descriptions-item>
      <el-descriptions-item label="联系地址">{{ info.rentersAddress || "-" }}</el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions title="" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
      <el-descriptions-item v-if="info.rentDateType == 1" label="租赁时间">{{ info.rentPeriod }}个月</el-descriptions-item>
      <el-descriptions-item label="开始日期">{{ info.startDate | formatDate("YYYY-MM-DD") }}</el-descriptions-item>
      <el-descriptions-item label="结束日期">{{ info.endDate | formatDate("YYYY-MM-DD") }}</el-descriptions-item>
      <el-descriptions-item label="签订日期">{{ info.signedDate }}</el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions title="" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
      <el-descriptions-item label="装修进场日期">{{ info.fixDate | formatDate("YYYY-MM-DD") }}</el-descriptions-item>
      <el-descriptions-item label="计划装修开始时间">{{ info.startFixDate | formatDate("YYYY-MM-DD") }}</el-descriptions-item>
      <el-descriptions-item label="计划装修结束时间">{{ info.endFixDate | formatDate("YYYY-MM-DD") }}</el-descriptions-item>
      <el-descriptions-item label="提前通知调整进场日天数">{{ info.fixNotice || "-" }}天</el-descriptions-item>
      <el-descriptions-item label="提前续租申请">{{ info.renewalApply || "-" }}个月</el-descriptions-item>
      <el-descriptions-item v-if="!isBusiness" label="装修期是否免租">{{ info.fixRentFreeName }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions class="mar-t-20" title="优惠减免" :column="column" direction="vertical" :colon="false" v-if="!isBusiness" :labelStyle="labelStyle">
      <el-descriptions-item label="优惠方式">{{ info.preferentialWayName }}</el-descriptions-item>
    </el-descriptions>
    <div v-if="info.preferentialWay == 1 && info && info.contractReductionPolicies">
      <el-tag :key="tag.id" v-for="(tag, index) in info.contractReductionPolicies" class="mar-r-5" :disable-transitions="false">
        {{ tag.reductionPolicyTitle }}
      </el-tag>
    </div>
    <el-descriptions v-if="info.preferentialWay == 2" title="" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
      <el-descriptions-item label="免租期">{{ info.rentFreePeriod || 0 }}个月</el-descriptions-item>
      <el-descriptions-item label="免租期开始日期">{{ info.rentFreeStartDate || "-" }}</el-descriptions-item>
      <el-descriptions-item label="免租期结束日期">{{ info.rentFreeEndDate || "-" }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions class="mar-t-20" title="房源信息"></el-descriptions>
    <el-table :data="info.contractSubjects" border class="mar-b-20" size="small">
      <el-table-column label="楼宇" prop="buildingName"></el-table-column>
      <el-table-column label="楼层" prop="floorName"></el-table-column>
      <el-table-column label="房间名称" prop="roomName"></el-table-column>
      <el-table-column label="房间面积" prop="roomArea">
        <template slot-scope="scope"> {{ scope.row.roomArea || "-" }}㎡ </template>
      </el-table-column>
      <el-table-column label="计价面积" prop="roomArea">
        <template slot-scope="scope"> {{ scope.row.roomArea || "-" }}㎡ </template>
      </el-table-column>
      <el-table-column label="物业管理费" prop="propertyManagementFee">
        <template slot-scope="scope"> {{ scope.row.propertyManagementFee || "-" }}元/㎡/月 </template>
      </el-table-column>
      <el-table-column label="单价" prop="rentFreeEndDate">
        <template slot-scope="scope"> {{ scope.row.rentalUnitPriceDay || "-" }}元/㎡/日 </template>
      </el-table-column>
      <el-table-column label="是否递增" prop="incrementalIdentification">
        <template slot-scope="scope">
          {{ scope.row.incrementalIdentification == 1 ? "是" : "否" }}
        </template>
      </el-table-column>

      <el-table-column label="递增基础单价" prop="incrementalUnitPrice">
        <template slot-scope="scope"> {{ scope.row.incrementalUnitPrice || "-" }}元/㎡/日 </template>
      </el-table-column>

      <el-table-column label="房间状态" prop="isReleased" align="center">
        <template slot-scope="scope">
          <el-tag effect="dark" size="mini" type="success" v-if="scope.row.isReleased == 0">已占用</el-tag>
          <el-tag effect="dark" size="mini" type="info" v-else>未占用</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="cz" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="danger" size="mini" v-if="scope.row.isReleased == 0" @click="released(1, scope.row)">解除占用</el-button>
          <el-button type="sucess" size="mini" v-if="scope.row.isReleased == 1" @click="released(0, scope.row)">占用房间</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template v-if="!isBusiness">
      <el-descriptions title="金额条款" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
        <el-descriptions-item label="租金总额">￥{{ info.amount || "-" }}元</el-descriptions-item>
        <el-descriptions-item label="账单生成策略">
          {{ info.receiptStrategyName }}
        </el-descriptions-item>
        <el-descriptions-item label="租金支付周期">
          {{ getFrequency() }}
        </el-descriptions-item>
        <el-descriptions-item label="物业费缴纳方式">
          {{ info.propertyFeeWayName }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
        <el-descriptions-item label="提前缴费类型">{{ info.beforePaymentTypeName || "-" }}</el-descriptions-item>
        <el-descriptions-item v-if="info.beforePaymentType == 1" label="提前天数">{{ info.paymentBeforeDay || "-" }}天</el-descriptions-item>
        <el-descriptions-item v-if="info.beforePaymentType == 2" label="固定日期">每月 {{ info.planPaymentDate || "-" }} 日</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
        <el-descriptions-item label="年租金递增时间">{{ info.yearIncrease || "-" }}年后</el-descriptions-item>
        <el-descriptions-item label="每年递增率">{{ info.increaseRate || "-" }}%</el-descriptions-item>
        <el-descriptions-item label="递增策略">{{ info.incrementalStrategyName || "-" }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
    </template>
    <el-descriptions :title="isBusiness ? '保证金' : ''" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
      <el-descriptions-item label="保证金缴纳类型">{{ depositTypes[info.depositType] }}</el-descriptions-item>
      <el-descriptions-item label="保证金缴纳状态">{{ info.depositPayStatusName }}</el-descriptions-item>
      <el-descriptions-item label="保证金" v-if="info.depositType == 0">{{ info.deposit }}个月租金</el-descriptions-item>
      <el-descriptions-item label="保证金" v-if="info.depositType == 1">{{ info.depositAmount }}元</el-descriptions-item>
      <el-descriptions-item label="保证金缴纳日期">{{ info.depositPaymentDate || "-" }}</el-descriptions-item>
      <el-descriptions-item label="保证金补足期限">{{ info.depositSupplyDate || "-" }}</el-descriptions-item>
      <el-descriptions-item v-if="info.depositPayStatus == 2" label="保证金来源">{{ info.depositResource }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions class="mar-t-20" title="收款账户" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
      <el-descriptions-item label="开户名称">{{ info.accountName || "-" }}</el-descriptions-item>
      <el-descriptions-item label="开户银行">{{ info.accountBank || "-" }}</el-descriptions-item>
      <el-descriptions-item label="银行卡号">
        <span class="fts-16" v-if="info.collectionAccountName">{{ info.collectionAccountName | formatBankNum }}</span>
      </el-descriptions-item>
    </el-descriptions>
    <template v-if="info.cancelRentReason">
      <el-descriptions class="mar-t-20" title="退租原因" :column="1" direction="vertical" :colon="false" :labelStyle="labelStyle"> </el-descriptions>
      <div>{{ info.cancelRentReason }}</div>
    </template>
    <template v-if="info.cancellationReason">
      <el-descriptions class="mar-t-20" title="作废原因" :column="1" direction="vertical" :colon="false" :labelStyle="labelStyle"> </el-descriptions>
      <div>{{ info.cancellationReason }}</div>
    </template>
    <el-descriptions class="mar-t-20" title="附件" :column="1" direction="vertical" :colon="false" :labelStyle="labelStyle"> </el-descriptions>
    <ManageFormFile :disabled="info.approvalStatus != 3 ? true : false" :value="info.attachmentIds" @input="saveAttachmentIds"></ManageFormFile>
    <div v-if="isTodo && info.pdfAttachmentId" class="text-center">
      <el-button @click="viewContract">预览合同</el-button>
    </div>
  </div>
</template>
<script>
import { detail, updateAttachments } from "@/api/contract/index.js"
import { getMetaCommonDetail, getPicListByIds, updateMetaCommon } from "@/api/busiMode/metaCommon"
import { getDictionary } from "@/api/system/dictbiz.js"
import ManageFormFile from "./manageFormFile.vue"

export default {
  components: { ManageFormFile },
  data() {
    return {
      loading: false,
      info: {},
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      depositTypes: {
        0: "默认",
        1: "自定义",
      },
      relations: [
        {
          code: "contractTemplateId",
          model: "wp_contract_template",
          props: [{ code: "contractTemplateName", name: "template_name" }],
          type: 1,
        },
        {
          code: "lessorSigned",
          model: "wp_employee",
          props: [{ code: "lessorSignedName", name: "name" }],
          type: 1,
        },
        {
          code: "companyId",
          model: "wp_company",
          props: [
            { code: "companyName", name: "name" },
            { code: "corporationPersonality", name: "legal_person" },
            { code: "corporationPersonalityContact", name: "legal_contact" },
          ],
          type: 1,
        },
        {
          code: "collectionAccountId",
          model: "wp_collection_account",
          props: [
            { code: "collectionAccountName", name: "collection_account" },
            { code: "accountName", name: "account_name" },
            { code: "accountBank", name: "deposit_bank" },
          ],
          type: 1,
        },
        {
          code: "contractAttribute",
          model: "ContractAttribute",
          props: [{ code: "contractAttributeName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "contractType",
          model: "ContractType",
          props: [{ code: "contractTypeName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "receiptStrategy",
          model: "ReceiptStrategy",
          props: [{ code: "receiptStrategyName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "incrementalStrategy",
          model: "ReceiptStrategy",
          props: [{ code: "incrementalStrategyName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "depositPayStatus",
          model: "DepositPayStatus",
          props: [{ code: "depositPayStatusName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "propertyFeeWay",
          model: "PropertyFeeWay",
          props: [{ code: "propertyFeeWayName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "beforePaymentType",
          model: "BeforePaymentType",
          props: [{ code: "beforePaymentTypeName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "offerType",
          model: "OfferType",
          props: [{ code: "offerTypeName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "fixRentFree",
          model: "FixRentFree",
          props: [{ code: "fixRentFreeName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "frequencyUnit",
          model: "FREQUENCY_UNIT",
          props: [{ code: "frequencyUnitName", name: "dictValue" }],
          type: 2,
        },
        {
          code: "preferentialWay",
          model: "PREFERENTIAL_WAY",
          props: [{ code: "preferentialWayName", name: "dictValue" }],
          type: 2,
        },
      ],
      rawdata: {},
    }
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    isBusiness() {
      return Number(this.info.contractType) === 2 ? true : false
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    released(isReleased, row) {
      let r = {
        is_released: isReleased,
        room_id: row.roomId,
      }

      let o = {
        update: JSON.parse(JSON.stringify(r)),
        primaryKeyValue: row.id,
        primaryKey: "id",
      }
      updateMetaCommon("wisdom_park.wp_contract_subject", o).then((res) => {
        if (res.data.code == 200) {
          this.getDetail()
          //this.onLoad(this.page);
        }
      })
    },
    getFrequency() {
      if (["1", "2"].includes(`${this.info.frequencyUnit}`)) {
        return `${this.info.frequency}${this.info.frequencyUnitName}`
      }
    },
    saveAttachmentIds(e) {
      updateAttachments({
        id: this.id,
        attachmentIds: e,
      })
    },
    viewContract() {
      getPicListByIds([this.info.pdfAttachmentId]).then((res) => {
        if (res.data.data && res.data.data[0]) {
          let file = res.data.data[0]
          window.open(`/pdfjs/web/viewer.html?file=${encodeURIComponent(file.link)}`)
        }
      })
    },
    getDetail() {
      if (this.id) {
        this.loading = true
        detail({ id: this.id }).then((res) => {
          this.loading = true
          if (res.data) {
            let info = res.data.data
            this.getRelationData(info)
          }
        })
      }
    },
    getRelationData(info) {
      let len = 0,
        count = 0
      let resHandle = (res) => {
        len++
        info = {
          ...info,
          ...res,
        }
        if (len >= count) {
          this.info = info
          this.loading = false
        }
      }
      if (this.relations.length) {
        this.relations.forEach((item) => {
          if (info[item.code] !== "" && info[item.code] !== null && info[item.code] !== undefined) {
            count++
            if (item.type === 1) {
              this.getMetaCommonDetail(item, info).then(resHandle)
            } else {
              this.getDictionary(item, info).then(resHandle)
            }
          } else {
            item.props.forEach((prop) => {
              info[prop.code] = "-"
            })
          }
        })
      } else {
        this.info = info
        this.loading = false
      }
    },
    getMetaCommonDetail(item, form) {
      return getMetaCommonDetail(`wisdom_park.${item.model}`, {
        pk: "id",
        id: form[item.code],
      }).then((res) => {
        let info = {}
        if (res.data.data) {
          let data = res.data.data
          item.props.forEach((prop) => {
            info[prop.code] = data[prop.name]
          })
        }
        return info
      })
    },
    getDictionary(item, form) {
      return getDictionary({
        code: item.model,
      }).then((res) => {
        let info = {}
        if (res.data && res.data.data) {
          let data = res.data.data.find((d) => {
            if (d["dictKey"] === `${form[item.code]}`) {
              return d
            }
          })
          if (data) {
            item.props.forEach((prop) => {
              info[prop.code] = data[prop.name]
            })
          }
        }
        return info
      })
    },
  },
}
</script>
