<template>
<el-tabs class="pad-15" v-model="activeName">
  <el-tab-pane label="收款计划" name="tab1">
    <el-row v-for="(name , index) of ['deposit' , 'rent']">
      <el-col class="mar-tb-10" :span="24">
        <el-divider content-position="left">{{name === 'deposit' ? '保证金' : '租金'}}</el-divider>
      </el-col>
      <el-col :span="24">
        <el-table
          :data="feeData[name]"
          size="small"
          border
        >
          <el-table-column label="期数" width="80px" prop="name"></el-table-column>
          <el-table-column label="应收日期" width="90px" prop="receivableDate"></el-table-column>
          <template v-if="name === 'rent'" >
            <el-table-column label="账单周期" width="200px">
              <template slot-scope="scope">
                {{scope.row.startTime | formatDate('YYYY-MM-DD')}} 至 {{scope.row.endTime | formatDate('YYYY-MM-DD')}}
              </template>
            </el-table-column>
            <el-table-column label="账单天数" width="200px">
              <template slot-scope="scope">
                {{scope.row.days}}
              </template>
            </el-table-column>
            <el-table-column label="物业费(元)" prop="propertyManagementFeeTotal">
              <template slot-scope="scope">
                ￥{{scope.row.propertyManagementFeeTotal | numConvertToThousandths}}元
              </template>
            </el-table-column>
            <el-table-column label="应收租金(元)" prop="amountReceivable">
              <template slot-scope="scope">
                ￥{{scope.row.amountReceivable | numConvertToThousandths}}元
              </template>
            </el-table-column>
            <el-table-column label="本期应收金额(元)" header-align="right" align="right">
              <template slot-scope="scope">
                ￥<span class="text-red">{{getAllMoney(scope.row) | toFixed | numConvertToThousandths}}</span>元
              </template>
            </el-table-column>
          </template>
          <template v-if="name === 'deposit'">
            <el-table-column label="本期应收金额(元)" header-align="right" align="right" prop="amountReceivable">
              <template slot-scope="scope">
                ￥<span class="text-red">{{scope.row.amountReceivable | numConvertToThousandths}}</span>元
              </template>
            </el-table-column>
          </template>
          
        </el-table>
        <div class="text-right fts-18 pad-10 bor bor-t-n">
          应收总额 ￥{{getAllMoney(feeData[name] , true) | toFixed| numConvertToThousandths}}元
        </div>
      </el-col>
    </el-row>
  </el-tab-pane>
  <el-tab-pane label="账单" name="tab2">
    <ReceiptTable :queryParams="receiptTableQuery" :tableOption="receiptTableOption" :showTotal="false" :addBtn="false"></ReceiptTable>
  </el-tab-pane>
</el-tabs>
</template>
<script>
import { queryCollectionPlan } from '@/api/contract/index.js'
import ReceiptTable from '../finance/receipt-table.vue';
export default {
  components: { ReceiptTable },
  data(){
    return {
      activeName: 'tab1',
      feeData: {
        deposit: [],
        rent: []
      },
      receiptTableQuery: {
        subjectId: this.companyId,
        resourceSubjectId: this.id
      },
      receiptTableOption: {
        calcHeight: 170,
        columnBtn: false
      }
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    }
  },
  watch: {
    // companyId: {
    //   immediate: true,
    //   handler(){
    //     this.receiptTableQuery.subjectId = this.companyId;
    //   }
    // },
    id: {
      immediate: true,
      handler(){
        this.$nextTick(() => {
          this.receiptTableQuery.resourceSubjectId = this.id;
          this.receiptTableQuery.subjectId = this.companyId;
          this.queryCollectionPlan();
        })
      }
    }
  },
  methods: {
    queryCollectionPlan(){
      queryCollectionPlan({
        contractId: this.id
      }).then(
        res => {
          if(res && res.data && res.data.data){
            this.feeData = res.data.data;
          }
        }
      )
    },
    getAllMoney(data , isList){
      if(isList){
        let amount = 0;
        data.forEach(
          item => {
            amount = mathjs.chain(amount).add(item.propertyManagementFeeTotal || 0).add(item.amountReceivable || 0).done();
          }
        )
        return amount;
      }else{
        return mathjs.add(data.propertyManagementFeeTotal || 0 , data.amountReceivable || 0);
      }
    },
  }
}
</script>