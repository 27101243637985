
import i18n from '@/lang'

import request from '@/router/axios';
import { downloadBlob } from '@/util/util'

/**
 * download file
 */
const downloadFile = ($url, $obj) => {
  const param = {
    url: $url,
    obj: $obj
  }

  if (!param.url) {
    this.$message.warning(`${i18n.$t('Unable to download without proper url')}`)
    return
  }

  const generatorInput = function (obj) {
    let result = ''
    const keyArr = Object.keys(obj)
    keyArr.forEach(function (key) {
      result += "<input type='hidden' name = '" + key + "' value='" + obj[key] + "'>"
    })
    return result
  }
  return request({
    url: param.url,
    method: 'get',
    params: param.obj,
    responseType: 'blob'
  }).then(
    res => {
      let name = '';
      if(res.headers['content-disposition']){
        res.headers['content-disposition'].split(';').forEach(
          item => {
            let items = item.split('=');
            if(items[0].replace(/\s/g , '').indexOf('filename') > -1){
              name = items[1] || '';
            }
          }
        )
      }
      if(name){
        name = name.replace(/"/g , '').replace(/'/g , '').replace('utf-8' , '');
        downloadBlob(res.data , name);
      }
    }
  )
  // $(`<form action="${param.url}" target="_blank" method="get">${generatorInput(param.obj)}</form>`).appendTo('body').submit().remove()
}

export { downloadFile }
