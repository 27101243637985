import request from '@/router/axios';

const prefix = '/api/jida-redash/api/';
const auth = 'yX1c79YZlK209D55gQn5BDjwDs4DHplkjqX0eaPA';

export const getDatasourceList = () => {
  return request({
    url: prefix + 'data_sources',
    method: 'get',
    params:{},
    headers: {
      Authorization: auth
    }
  })
}

export const query_results = (params) => {
  return request({
    url: prefix + 'query_results',
    method: 'post',
    data: params,
    headers: {
      Authorization: auth
    }
  })
}

export const jobs = (id) => {
  return request({
    url: prefix + 'jobs/' + id,
    method: 'get',
    params:{},
    headers: {
      Authorization: auth
    }
  })
}

export const results = (query_result_id) => {
  return request({
    url: prefix + 'query_results/' + query_result_id,
    method: 'get',
    params:{},
    headers: {
      Authorization: auth
    }
  })
}
