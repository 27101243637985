import request from '@/router/axios';

const prefix = '/api/metadata-proxy';

export const getAllPackages = () => {
  return request({
    url:prefix + '/api/meta/v2/category/routes?topCategoryId=0',
    //url:'/json/metadata/model.json',
    method: 'get',
    params:{}
  })
};
export const getAllMetadataPackages = () => {
  return request({
    url:prefix + '/api/meta/v2/category/routes?topCategoryId=2',
    //url:'/json/metadata/model.json',
    method: 'get',
    params:{}
  })
};
export const getPackageList = (name) => {
  return request({
    url:prefix + '/api/meta/v2/category/lazy-list?category=0&parentId=0&name=' + name+'&searchDb=true',
    method: 'get',
    params:{}
  })
};
export const getChildPackageList = (params) => {
  return request({
    url:prefix + '/api/meta/v2/category/lazy-list?category=0&parentId=' + params['parentId'] + '&name=' + params['name']+'&searchDb=true',
    //url:'/json/metadata/model.json',
    method: 'get',
    params:{}
  })
};
export const getMetadataAllPackages = () => {
  return request({
    url:prefix + '/api/meta/v2/category/routes?topCategoryId=2',
    //url:'/json/metadata/model.json',
    method: 'get',
    params:{}
  })
};
export const getMetadataPackageList = (name) => {
  return request({
    url:prefix + '/api/meta/v2/category/lazy-list?category=2&parentId=0&name='+name+'&searchDb=true',
    method: 'get',
    params:{}
  })
};
export const getMetadataChildPackageList = (params) => {
  return request({
    url:prefix + '/api/meta/v2/category/lazy-list?category=2&parentId=' + params['parentId'] + '&name=' + params['name']+'&searchDb=true',
    //url:'/json/metadata/model.json',
    method: 'get',
    params:{}
  })
};

export const savePackage = (params) => {
  return request({
    url:prefix + '/api/meta/v2/category/submit',
    method: 'post',
    data:params
  })
};
export const delPackage = (params) => {
  return request({
    url:prefix + '/api/meta/v2/category/remove',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};
// 查询模型列表
export const getModelList = (params) => {
  return request({
    url:prefix + '/api/meta/v2/category/lazy-list?parentId=' + params['parentId'] + '&&' + 'category=' + params['code']+'&searchDb=true',
    //url:'/json/metadata/model.json',
    method: 'get',
    params:{}
  })
};
export const getAvailModelList = (params) => {
  return request({
    url:prefix + '/api/meta/v2/category/avail-list?parentId=' + params['parentId'] + '&&' + 'category=' + params['code'],
    //url:'/json/metadata/model.json',
    method: 'get',
    params:{}
  })
};
export const getModelDetail = (id) => {
  return request({
    url:prefix + `/api/meta/v2/category/detail?id=${id}`,
    //url:'/json/metadata/model.json',
    method: 'get',
    params:{}
  })
};
export const saveModel = (params) => {
  return request({
    url:prefix + '/api/meta/v2/type/entityref/submit',
    method: 'post',
    data:params
  })
};
export const publishModel = (params) => {
  return request({
    url:prefix + '/api/meta/v2/type/entityref/pub',
    method: 'post',
    data:params
  })
};
export const dismissModel = (params) => {
  return request({
    url:prefix + '/api/meta/v2/type/entityref/dismiss',
    method: 'post',
    data:params
  })
};
export const delModel = (params) => {
  return request({
    url:prefix + '/api/meta/v2/type/remove',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};
export const updateProperty = (params,id) => {
  return request({
    url:prefix + '/api/meta/v2/type/entityref/attrlocal/' + id,
    method: 'post',
    data:params
  })
};
export const getPropertyList = () => {
  return request({
    url:'/json/metadata/property.json',
    method: 'get',
    params:{}
  })
};
export const getVersionList = (params) => {
  return request({
    url:prefix + '/api/meta/metadataversion/list',
    method: 'get',
    params:params
  })
};
export const saveVersion = (params) => {
  return request({
    url:prefix + '/api/meta/metadataversion/submit',
    method: 'post',
    data:params
  })
};
