import Layout from "@/page/index/";
import MapLayout from "@/page/map/index";
// 政策服务
import Prncatg from "@/views/spcod/prncatg.vue";
import PolicyConsult from "@/views/spcod/prnpolicyconsult.vue";

export default [
  {
    path: "/dashboard",
    name: "首页",
    component: Layout,
    children: [
      {
        path: "index",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/dashboard/index"),
      },
    ],
  },
  {
    path: "/notice",
    name: "消息通知",
    component: Layout,
    children: [
      {
        path: "notice",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/notice/notice"),
      },
      {
        path: "noticeByUser",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/notice/noticeByUser"),
      },
      {
        path: "messageTemplate",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/notice/messageTemplate"
          ),
      },
    ],
  },
  {
    path: "/flow",
    name: "流程",
    component: Layout,
    children: [
      {
        path: "todo/detail",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/flow/todo-detail.vue"
          ),
      },
    ],
  },
  {
    path: "/mypages",
    name: "",
    component: Layout,
    children: [
      {
        path: ":type",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/page/auto-layout/index.vue"
          ),
      },
      {
        path: ":type/:id",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/page/auto-layout/index.vue"
          ),
      },
    ],
  },
  {
    path: "/assets",
    name: "",
    component: Layout,
    children: [
      {
        path: "action/:type",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/assets/action.vue"),
      },
      {
        path: "action/:formType/:type",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/assets/action/form.vue"
          ),
        meta: {
          storeName: "AssetsActionSearchForm",
          loadName: "onLoad",
          searchName: "search",
          vmRef: "table",
        },
      },
    ],
  },
  {
    path: "/finance",
    name: "",
    component: Layout,
    children: [
      {
        path: "receipt/action/:type",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/finance/receipt-form.vue"
          ),
      },
    ],
  },
  {
    path: "/reduction-policy",
    name: "",
    component: Layout,
    children: [
      {
        path: "action/:type",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reduction-policy/form.vue"
          ),
      },
      {
        path: "relation",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reduction-policy/relation.vue"
          ),
      },
    ],
  },
  {
    path: "/dataService",
    name: "",
    component: Layout,
    children: [
      {
        path: "dataAPICollection",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/dataService/dataAPICollection.vue"
          ),
      },
      {
        path: "dataShare",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/dataShare/datashare.vue"
          ),
      },
    ],
  },
  {
    path: "/desk",
    component: Layout,
    redirect: "/desk/notice",
    children: [
      {
        path: "notice",
        name: "通知公告",
        meta: {
          i18n: "test",
        },
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/desk/notice"),
      },
    ],
  },
  {
    path: "/info",
    component: Layout,
    redirect: "/info/update/index",
    children: [
      {
        path: "update/:type",
        name: "个人信息",
        meta: {
          i18n: "info",
        },
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/system/userinfo"),
      },
    ],
  },
  {
    path: "/work/process/leave",
    component: Layout,
    redirect: "/work/process/leave/form",
    children: [
      {
        path: "form/:processDefinitionId",
        name: "请假流程",
        meta: {
          i18n: "work",
        },
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/work/process/leave/form"
          ),
      },
      {
        path: "handle/:taskId/:processInstanceId/:businessId",
        name: "处理请假流程",
        meta: {
          i18n: "work",
        },
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/work/process/leave/handle"
          ),
      },
      {
        path: "detail/:processInstanceId/:businessId",
        name: "请假流程详情",
        meta: {
          i18n: "work",
        },
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/work/process/leave/detail"
          ),
      },
    ],
  },
  {
    path: "/data",
    component: Layout,
    children: [
      {
        path: "/data/table/list",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/data/table/list"),
      },
      {
        path: "/data/table/design",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/data/table/table/design/design"
          ),
      },
      {
        path: "/data/table/preview",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/data/table/table/preview/preview"
          ),
      },
      {
        path: "/data/table/createBaseInfo",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/data/table/createBaseInfo"
          ),
      },
      {
        path: "/data/form/list",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/data/form/list"),
      },
      {
        path: "/data/form/design",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/data/form/design"),
      },
      {
        path: "/data/table/tableCommon",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/data/table/tableCommon"
          ),
      },
      {
        path: "/data/table/newPage",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/data/table/table/design/newPage"
          ),
      },
      {
        path: "/data/form/formCommon",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/data/form/formCommon"
          ),
      },
      {
        path: "/data/pageComm",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/data/pageComm"),
      },
    ],
  },
  {
    path: "/busiMode",
    component: Layout,
    children: [
      {
        path: "/busiMode/meta/list",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/busiMode/meta/list"),
      },
      {
        path: "/busiMode/templatePlan/list",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/busiMode/templatePlan/list"
          ),
      },
      {
        path: "/busiMode/template/list",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/busiMode/template/list"
          ),
      },
      {
        path: "/busiMode/plan/list",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/busiMode/plan/list"),
      },
    ],
  },
  {
    path: "/tool",
    component: Layout,
    children: [
      {
        path: "/tool/model",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/tool/model"),
      },
      {
        path: "/tool/modelDetail",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/tool/modelDetail"),
      },
    ],
  },
  {
    path: "/contract",
    component: Layout,
    children: [
      {
        path: "/contract/subContract/manage",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/contract/subContract/manage"
          ),
      },
      {
        path: "/contract/:type/manage",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/contract/manage"),
      },
      {
        path: "/contract/manage/:type",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/contract/manageType"),
      },
      {
        path: "/contract/supplementary/:id",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/contract/supplementarys/index.vue"
          ),
        meta: {
          storeName: "ContractSearchForm",
          loadName: "onLoad",
          searchName: "search",
        },
      },
    ],
  },
  {
    path: "/build",
    component: Layout,
    children: [
      {
        path: "/build/buildList",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/build/buildList"),
      },
      {
        path: "/build/roomList",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/build/roomList"),
      },

      {
        path: "/build/space",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/build/space/index"),
      },
      {
        path: "/build/addBuild",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/build/addBuild"),
      },
      {
        path: "/build/detail",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/build/buildDetail"),
      },
      {
        path: "/build/assetInfo",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/build/assetInfo"),
      },
      {
        path: "/build/housingResources",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/build/housingResources/manager"
          ),
      },
    ],
  },
  {
    path: "/enterprise",
    component: Layout,
    children: [
      {
        path: "/enterprise/management",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/enterprise/management"
          ),
      },
      {
        path: "/enterprise/enter",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/enterprise/enter"),
      },
      {
        path: "/enterprise/exit",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/enterprise/exit"),
      },
      {
        path: "/enterprise/enterpriseEnter",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/enterprise/enterpriseEnter"
          ),
      },
      {
        path: "/enterprise/blacklist",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/enterprise/blacklist"
          ),
      },
      {
        path: "/enterprise/single",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/enterprise/single"),
      },
      {
        path: "/enterprise/baseInfo",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/enterprise/single/baseInfo"
          ),
      },
      {
        path: "/enterprise/enterDetail",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/enterprise/enterDetail"
          ),
      },
      {
        path: "/enterprise/checkoutDetail",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/enterprise/checkoutDetail"
          ),
      },
      {
        path: "/enterprise/checkout",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/enterprise/checkout"),
      },
    ],
  },
  {
    path: "/employee",
    component: Layout,
    children: [
      {
        path: "/employee/index",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/employee/index"),
      },
      {
        path: "/employee/garden",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/employee/garden"),
      },
      {
        path: "/employee/property",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/employee/property"),
      },
      {
        path: "/employee/company",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/employee/company"),
      },
      {
        path: "/employee/approvalEmp",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/employee/approvalEmp"
          ),
      },
    ],
  },
  // 访客列表
  {
    path: "/visitor",
    component: Layout,
    children: [
      {
        path: "/visitor/index",
        component: () => import("@/views/visitor/index.vue"),
      },
      {
        path: "/visitor/visitorOrder",
        component: () => import("@/views/visitor/visitorOrder.vue"),
      },
      {
        path: "/visitor/visitorInfo",
        component: () => import("@/views/visitor/visitorInfo.vue"),
      },
      {
        path: "/visitor/register",
        component: () => import("@/views/visitor/register.vue"),
      },
    ],
  },
  // 访客列表
  {
    path: "/door",
    component: Layout,
    children: [
      {
        path: "/door/index",
        component: () => import("@/views/door/index.vue"),
      },
      {
        path: "/door/main",
        component: () => import("@/views/door/main.vue"),
      },
      {
        path: "/door/resourcesDoors",
        component: () => import("@/views/door/resourcesDoors.vue"),
      },
      {
        path: "/door/doorEvent",
        component: () => import("@/views/door/doorEvent.vue"),
      },
      {
        path: "/door/doList",
        component: () => import("@/views/door/doList.vue"),
      },
      {
        path: "/door/doorEventError",
        component: () => import("@/views/door/doorEventError.vue"),
      },
    ],
  },
  {
    path: "/spcod/sportappointcalDetail",
    component: Layout,
    children: [
      {
        path: "/spcod/sportappointcalDetail",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/spcod/sportappointcalDetail"
          ),
      },
    ],
  },
  {
    path: "/spcod",
    component: Layout,
    children: [
      {
        path: "/spcod/refundOrder",
        component: () => import("@/views/spcod/refundOrder.vue"),
      },
      {
        path: "/spcod/orderForSportMana",
        component: () => import("@/views/spcod/orderForSportMana.vue"),
      },
    ],
  },
  // 政策服务
  {
    path: "/prncatg",
    component: Prncatg,
    children: [
      {
        path: "/prncatg",
        component: () => import("@/views/spcod/prncatg.vue"),
      },
    ],
  },
  {
    path: "/prncatg",
    component: Layout,
    children: [
      {
        path: "/prncatg/prnpolicy-add",
        component: () => import("@/views/spcod/prnpolicy-add.vue"),
      },
      {
        path: "/prncatg/prnpolicy-edit",
        component: () => import("@/views/spcod/prnpolicy-edit.vue"),
      },
    ],
  },
  {
    path: "/spcodaccount",
    component: Layout,
    children: [
      {
        path: "/spcodaccount/cardlist",
        component: () => import("@/views/spcod/spcodaccard.vue"),
      },
      {
        path: "/spcodaccount/detailist",
        component: () => import("@/views/spcod/spcodaccountdetail.vue"),
      },
    ],
  },
  {
    path: "/settlesheet",
    component: Layout,
    children: [
      {
        path: "/settlesheet/orderlist",
        component: () => import("@/views/epmall/epmallsettleorder.vue"),
      },
    ],
  },
  {
    path: "/prnpolicyconsult",
    component: PolicyConsult,
    children: [
      {
        path: "/prnpolicyconsult",
        component: () => import("@/views/spcod/prnpolicyconsult.vue"),
      },
    ],
  },
  {
    path: "/prnpolicyconsult",
    component: Layout,
    children: [
      {
        path: "/prnpolicyconsult/prnpolicyconsultreply",
        component: () => import("@/views/spcod/prnpolicyconsultreply.vue"),
      },
    ],
  },
  {
    path: "/epmall/epmallordercomment",
    component: Layout,
    children: [
      {
        path: "/epmall/epmallordercomment",
        component: () => import("@/views/epmall/epmallordercomment.vue"),
      },
    ],
  },
  {
    path: "/deliveryOne",
    component: Layout,
    children: [
      {
        path: "/deliveryOne",
        component: () => import("@/views/epmall/deliveryOne.vue"),
      },
    ],
  },
  //信息发布
  {
    path: "/information",
    component: Layout,
    children: [
      {
        path: "/information/classes",
        component: () => import("@/views/information/classes.vue"),
      },
      {
        path: "/information/equipment",
        component: () => import("@/views/information/equipment.vue"),
      },
      {
        path: "/information/equipmentDetail",
        component: () => import("@/views/information/equipmentDetail.vue"),
      },
      {
        path: "/information/material",
        component: () => import("@/views/information/material.vue"),
      },
      {
        path: "/information/materialForm",
        component: () => import("@/views/information/materialForm.vue"),
      },
      {
        path: "/information/program",
        component: () => import("@/views/information/program.vue"),
      },
      {
        path: "/information/programForm",
        component: () => import("@/views/information/programForm.vue"),
      },
      {
        path: "/information/publish",
        component: () => import("@/views/information/publish.vue"),
      },
      {
        path: "/information/publishForm",
        component: () => import("@/views/information/publishForm.vue"),
      },
      {
        path: "/information/publishDetail",
        component: () => import("@/views/information/publishDetail.vue"),
      },
      {
        path: "/information/feedback",
        component: () => import("@/views/information/feedback.vue"),
      },
      {
        path: "/information/publishInfo",
        component: () => import("@/views/information/publishInfo.vue"),
      },
      {
        path: "/datasource/index",
        component: () => import("@/views/datasource/pages/list/index"),
      },
    ],
  },
  {
    path: "/reportForms",
    component: Layout,
    children: [
      {
        path: "/reportForms/cost",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/reportForms/cost"),
      },

      {
        path: "/reportForms/area",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/reportForms/area"),
      },

      {
        path: "/reportForms/areaForAsset",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/areaForAsset"
          ),
      },
      {
        path: "/reportForms/memberCharge",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/memberCharge"
          ),
      },

      {
        path: "/reportForms/purchaseHistory",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/purchaseHistory"
          ),
      },

      {
        path: "/reportForms/income",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/reportForms/income"),
      },
      {
        path: "/reportForms/incomeByMonth",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/incomeByMonth"
          ),
      },

      {
        path: "/reportForms/machineAccount",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/machineAccount"
          ),
      },
      {
        path: "/reportForms/summaryAssets",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/summaryAssets"
          ),
      },
      {
        path: "/reportForms/detailedLeaseStatement",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/detailedLeaseStatement"
          ),
      },
      {
        path: "/reportForms/enterprisesPayment",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/enterprisesPayment"
          ),
      },

      {
        path: "/reportForms/enterprisesPayment",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/enterprisesPayment"
          ),
      },
      {
        path: "/reportForms/purchaseHistoryForHc",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/reportForms/purchaseHistoryForHc"
          ),
      },
    ],
  },
  {
    path: "/ockpit",
    component: Layout,
    children: [
      {
        path: "/ockpit/index",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/ockpit/index"),
      },
    ],
  },
];
