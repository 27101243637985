export default {
  beforeRouteEnter(to , from , next){
    let path = to.path.replace(/\//g , '');
    next( cvm => {
      let meta = from.meta || null;
      if(meta && meta.storeName && meta.searchName && meta.loadName){
        let searchData = null , storeName = `${path}_${meta.storeName}` , vm = cvm;
        cvm.$nextTick(() => {
          if(meta.vmRef){
            vm = cvm.$refs[meta.vmRef]
          }
          if(vm){
            try {
              searchData = JSON.parse(sessionStorage.getItem(storeName));
            } catch (error) {
              searchData = null;
            }
            if(searchData){
              vm[meta.searchName] = {
                ...vm[meta.searchName],
                ...searchData
              }
              vm[meta.loadName] && vm[meta.loadName]();
            }
          }
          sessionStorage.removeItem(storeName);
        })
      }
    })
  },
  beforeRouteLeave(to , from , next){
    let meta = to.meta || null;
    let path = from.path.replace(/\//g , '');
    if(meta && meta.storeName && meta.searchName){
      let _this = this;
      this.$nextTick(() => {
        if(meta.vmRef){
          _this = this.$refs[meta.vmRef]
        }
        if(_this){
          try {
            sessionStorage.setItem(`${path}_${meta.storeName}` , JSON.stringify(_this[meta.searchName]))
          } catch (error) {
            
          }
        }
      })
    }
    next();
  }
}