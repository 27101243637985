<template>
  <div class="my-process-designer">
    <div class="my-process-designer__container">
      <div class="my-process-designer__canvas" ref="bpmn-canvas"></div>
    </div>
  </div>
</template>

<script>

import NavigatedViewer from "bpmn-js/lib/NavigatedViewer";
import ZoomScrollModule from 'diagram-js/lib/navigation/zoomscroll';

export default {
  data(){
    return {
      bpmnModeler: null
    }
  },
  props: {
    value: String, // xml 字符串
  },
  mounted(){
    this.initBpmnModeler();
    this.createNewDiagram(this.value);
  },
  methods: {
    initBpmnModeler() {
      if (this.bpmnModeler) return;
      this.bpmnModeler = new NavigatedViewer({
        container: this.$refs["bpmn-canvas"],
        additionalModules: [ ZoomScrollModule ]
      });
      this.$emit("init-finished", this.bpmnModeler);
    },
    /* 创建新的流程图 */
    async createNewDiagram(xml) {
      // 将字符串转换成图显示出来
      let xmlString = xml;
      try {
        let { warnings } = await this.bpmnModeler.importXML(xmlString);
        if (warnings && warnings.length) {
          warnings.forEach(warn => console.warn(warn));
        }
      } catch (e) {
        console.error(`[Process Designer Warn]: ${e?.message || e}`);
      }
    },

  }
}
</script>