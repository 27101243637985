import request from "@/router/axios";

export const getPage = (current, size, params) => {
  return request({
    url: "/api/park-spcod/spcodaccountcard/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
export const getResCardInfo = (vipPhone, typeId, bizType) => {
  return request({
    url: "/api/park-spcod/spcodaccountcard/resCardInfo",
    method: "get",
    params: {
      vipPhone,
      typeId,
      bizType,
    },
  });
};

export const getDetail = (cardNo) => {
  return request({
    url: "/api/park-spcod/spcodaccountcard/detail/" + cardNo,
    method: "get",
  });
};

export const getDetailById = (id) => {
  return request({
    url: "/api/park-spcod/spcodaccountcard/detailbyid/" + id,
    method: "get",
  });
};

export const toogleStatus = (id, status) => {
  return request({
    url:
      "/api/park-spcod/spcodaccountcard/tooglestatus/" +
      id +
      "?status=" +
      status,
    method: "get",
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/park-spcod/spcodaccountcard/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/park-spcod/spcodaccountcard/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/park-spcod/spcodaccountcard/submit",
    method: "post",
    data: row,
  });
};

export const saveOverDate = (row) => {
  return request({
    url: "/api/park-spcod/mobaccountcard/saveOverDate",
    method: "post",
    data: row,
  });
};

export const fetchCardType = () => {
  return request({
    url: "/api/park-spcod/spcodaccountcard/carttype",
    method: "get",
  });
};
export const queryYearCard = (params) => {
  return request({
    url: "/api/park-spcod/mobaccountcard/queryYearCard?ids=" + params,
    method: "get",
  });
};
