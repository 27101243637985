const prefix = '/api/park-asset/'

export default {
  assetDetailImport: prefix + 'assetdetail/import',
  updateImport: prefix + 'assetdetail/updateImport',
  repairFinsh: prefix + 'assetrepair/repairFinsh',
  assetdetail: prefix + 'assetdetail/detail/',
  scrapApprovalFinsh: prefix + 'assetscrap/approvalFinsh',
  getList: prefix + 'assetdetail/list',
  queryAssetDetailByPage: prefix + 'assetdetail/queryAssetDetailByPage',
  exportChild: prefix + 'assetdetail/getChildList'
}
