import {
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
} from "@/util/auth";
import { Message } from "element-ui";
import { setStore, getStore } from "@/util/store";
import { isURL, validatenull } from "@/util/validate";
import { deepClone } from "@/util/util";
import website from "@/config/website";
import {
  loginByUsername,
  loginBySocial,
  getUserInfo,
  logout,
  refreshToken,
  getButtons,
} from "@/api/user";
import { getTopMenu, getRoutes } from "@/api/system/menu";
import md5 from "js-md5";

function addPath(ele, first) {
  const menu = website.menu;
  const propsConfig = menu.props;
  const propsDefault = {
    label: propsConfig.label || "name",
    path: propsConfig.path || "path",
    icon: propsConfig.icon || "icon",
    children: propsConfig.children || "children",
  };
  const icon = ele[propsDefault.icon];
  ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon;
  const isChild =
    ele[propsDefault.children] && ele[propsDefault.children].length !== 0;
  if (!isChild) ele[propsDefault.children] = [];
  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    ele[propsDefault.path] = ele[propsDefault.path] + "/index";
  } else {
    ele[propsDefault.children].forEach((child) => {
      addPath(child);
    });
  }
}

const user = {
  state: {
    tenantId: getStore({ name: "tenantId" }) || "",
    userInfo: getStore({ name: "userInfo" }) || [],
    permission: getStore({ name: "permission" }) || {},
    roles: [],
    orgInfo: getStore({ name: "orgInfo" }) || {},
    xzqhInfo: getStore({ name: "xzqhInfo" }) || {},
    menu: getStore({ name: "menu" }) || [],
    menuId: getStore({ name: "menuId" }) || [],
    menuAll: getStore({ name: "menuAll" }) || [],
    sidebarMenu: getStore({ name: "sidebarMenu" }) || [],
    token: getStore({ name: "token" }) || "",
    refreshToken: getStore({ name: "refreshToken" }) || "",
    menuLoad: false,
    userPass: "",
    sidebarMenuType: "sidebar-menu",
    currentTodoInfo: null,
    noticeRefresh: false,
  },
  getters: {
    orgInfo: (state) => state.orgInfo,
    xzqhInfo: (state) => state.xzqhInfo,
  },
  actions: {
    //根据用户名登录
    LoginByUsername({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByUsername(
          userInfo.tenantId,
          userInfo.username,
          md5(userInfo.password),
          userInfo.type,
          userInfo.key,
          userInfo.code
        )
          .then((res) => {
            const data = res.data;
            if (data.error_description) {
              Message({
                message: data.error_description,
                type: "error",
              });
            } else {
              commit("SET_USER_PASS", md5(userInfo.password));
              commit("SET_TOKEN", data.access_token);
              commit("SET_REFRESH_TOKEN", data.refresh_token);
              commit("SET_TENANT_ID", data.tenant_id);
              commit("SET_USER_INFO", data);
              commit("DEL_ALL_TAG");
              commit("CLEAR_LOCK");
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //根据手机号登录
    LoginByPhone({ commit }, userInfo) {
      return new Promise((resolve) => {
        loginByUsername(userInfo.phone, userInfo.code).then((res) => {
          const data = res.data.data;
          commit("SET_TOKEN", data);
          commit("DEL_ALL_TAG");
          commit("CLEAR_LOCK");
          resolve();
        });
      });
    },
    //根据第三方信息登录
    LoginBySocial({ commit }, userInfo) {
      return new Promise((resolve) => {
        loginBySocial(
          userInfo.tenantId,
          userInfo.source,
          userInfo.code,
          userInfo.state
        ).then((res) => {
          const data = res.data;
          if (data.error_description) {
            Message({
              message: data.error_description,
              type: "error",
            });
          } else {
            commit("SET_TOKEN", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_USER_INFO", data);
            commit("DEL_ALL_TAG");
            commit("CLEAR_LOCK");
          }
          resolve();
        });
      });
    },
    //获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            const data = res.data.data;
            commit("SET_ROLES", data.roles);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //刷新token
    refreshToken({ state, commit }) {
      window.console.log("handle refresh token");
      return new Promise((resolve, reject) => {
        refreshToken(state.refreshToken, state.tenantId)
          .then((res) => {
            const data = res.data;
            commit("SET_TOKEN", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_MENU", []);
            commit("SET_MENU_ID", {});
            commit("SET_MENU_ALL", []);
            commit("SET_ROLES", []);
            commit("SET_TAG_LIST", []);
            commit("DEL_ALL_TAG");
            commit("CLEAR_LOCK");
            removeToken();
            removeRefreshToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //注销session
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        commit("SET_MENU_ID", {});
        commit("SET_MENU_ALL", []);
        commit("SET_MENU", []);
        commit("SET_ROLES", []);
        commit("SET_TAG_LIST", []);
        commit("DEL_ALL_TAG");
        commit("CLEAR_LOCK");
        removeToken();
        removeRefreshToken();
        resolve();
      });
    },
    //获取顶部菜单
    GetTopMenu() {
      return new Promise((resolve) => {
        getTopMenu().then((res) => {
          const data = res.data.data || [];
          resolve(data);
        });
      });
    },

    //获取系统菜单
    GetMenu({ commit, dispatch }, topMenuId) {
      return new Promise((resolve) => {
        getRoutes(topMenuId).then((res) => {
          const data = res.data.data;

          let menu = deepClone(data);
          let menuList = [];
          if (menu.length > 0) {
            menu.forEach((ele) => {
              if (ele.code != "appMenu") {
                menuList.push(ele);
                addPath(ele, true);
              }
            });
            commit("SET_MENU_ALL", menuList);
            commit("SET_MENU", menuList);
            dispatch("GetButtons");
            resolve(menuList);
          } else {
            commit("SET_MENU_ALL", []);
            commit("SET_MENU", []);
            dispatch("GetButtons");
            resolve([]);
          }
        });
      });
    },
    //获取系统按钮
    GetButtons({ commit }) {
      return new Promise((resolve) => {
        getButtons().then((res) => {
          const data = res.data.data;
          commit("SET_PERMISSION", data);
          resolve();
        });
      });
    },
    //获取系统按钮
    SetSidebarMenu({ commit }) {
      return new Promise((resolve) => {
        getButtons().then((res) => {
          const data = res.data.data;
          commit("SET_PERMISSION", data);
          resolve();
        });
      });
    },
  },
  mutations: {
    SET_SIDEBAR_MENU_TYPE: (state, type) => {
      state.sidebarMenuType = type;
      setStore({ name: "sidebarMenuType", content: type });
    },
    SET_MENU_LOAD: (state, flag) => {
      // console.log(state , flag);
      state.menuLoad = flag;
      setStore({ name: "menuLoad", content: state.flag });
    },
    SET_NOTICE_REFRESH: (state, flag) => {
      state.noticeRefresh = flag;
      setStore({ name: "noticeRefresh", content: state.flag });
    },
    SET_USER_PASS: (state, flag) => {
      state.userPass = flag;
      setStore({ name: "userPass", content: state.userPass });
    },

    SET_TOKEN: (state, token) => {
      setToken(token);
      state.token = token;
      setStore({ name: "token", content: state.token });
    },
    SET_MENU_ID(state, menuId) {
      state.menuId = menuId;
      setStore({ name: "menuId", content: state.menuId });
    },
    SET_MENU_ALL: (state, menuAll) => {
      state.menuAll = menuAll;
      setStore({ name: "menuAll", content: state.menuAll });
    },
    SET_MENU: (state, menu) => {
      state.menu = menu;
      setStore({ name: "menu", content: state.menu });
      if (validatenull(menu)) return;
      //合并动态路由去重
      let menuAll = state.menuAll;
      menuAll = menuAll.concat(menu).reverse();
      let newMenu = [];
      for (let item1 of menuAll) {
        let flag = true;
        for (let item2 of newMenu) {
          if (item1.name === item2.name || item1.path === item2.path) {
            flag = false;
          }
        }
        if (flag) {
          newMenu.push(item1);
        }
      }
      state.menuAll = newMenu;
      setStore({ name: "menuAll", content: state.menuAll });
    },
    SET_SIDEBAR_MENU: (state, menu) => {
      state.sidebarMenu = menu.children;
      setStore({ name: "sidebarMenu", content: state.sidebarMenu });
    },
    SET_CURRENT_TODO_INFO: (state, data) => {
      state.currentTodoInfo = data;
      setStore({ name: "currentTodoInfo", content: state.currentTodoInfo });
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken);
      state.refreshToken = refreshToken;
      setStore({ name: "refreshToken", content: state.refreshToken });
    },
    SET_TENANT_ID: (state, tenantId) => {
      state.tenantId = tenantId;
      setStore({ name: "tenantId", content: state.tenantId });
    },
    SET_USER_INFO: (state, userInfo) => {
      if (validatenull(userInfo.avatar)) {
        userInfo.avatar = "/assets/images/bg/img-logo.png";
      }
      state.userInfo = userInfo;
      setStore({ name: "userInfo", content: state.userInfo });
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_ORG_INFO: (state, orgInfo) => {
      state.orgInfo = orgInfo;
      setStore({ name: "orgInfo", content: state.orgInfo });
    },
    SET_XZQH_INFO: (state, xzqhInfo) => {
      state.xzqhInfo = xzqhInfo;
      setStore({ name: "xzqhInfo", content: state.xzqhInfo });
    },
    SET_PERMISSION: (state, permission) => {
      let result = [];

      function getCode(list) {
        list.forEach((ele) => {
          if (typeof ele === "object") {
            const chiildren = ele.children;
            const code = ele.code;
            if (chiildren) {
              getCode(chiildren);
            } else {
              result.push(code);
            }
          }
        });
      }

      getCode(permission);
      state.permission = {};
      result.forEach((ele) => {
        state.permission[ele] = true;
      });
      setStore({ name: "permission", content: state.permission });
    },
  },
};
export default user;
