<template>
  <basic-container>
    <el-col :sm="24" :lg="24" v-if="succ">
      <el-result icon="success" title="密码修改成功">
        <template slot="subTitle" style="color: #909399">
          {{ djs }} 秒后自动登出，请重新登录...
        </template>
        <template slot="extra">
          <el-button type="primary" size="medium" @click="tc"
            >立即重新登录</el-button
          >
        </template>
      </el-result>
    </el-col>
    <el-row v-if="!succ">
      <div class="mar-b-20 mar-t-10 mar-l-17">
        首次登录用户，为了您的账户安全，请先重置您的密码！
      </div>
      <el-col :span="14">
        <avue-form :option="option" v-model="form" @submit="handleSubmit">
        </avue-form>
      </el-col>
      <el-col :span="8" class="mar-l-40">
        <div class="mar-b-10">密码规则：</div>
        <p class="text-info lh-24 fts-12">
          1、密码长度为8-20位<br />
          2、密码需同时包含字母、数字和特殊符号<br />
          3、密码不能输入3个及以上的连续数字和字母(如：<span class="text-red"
            >123</span
          >、<span class="text-red">abc</span>) <br />
          4、密码不能输入3个及以上的连续键盘序字符(如：<span class="text-red"
            >asd</span
          >、<span class="text-red">!@#</span>、<span class="text-red">123</span
          >)
        </p>
      </el-col>
    </el-row>
  </basic-container>
</template>

<script>
import { updatePassword } from "@/api/system/user";
import crypto from "@/util/crypto";

import passwordCheck from "@/util/password-check";
import { resetRouter } from "@/router/router";

const passwordOption = {
  column: [
    {
      label: "原密码",
      span: 24,
      row: true,
      type: "password",
      prop: "oldPassword",
    },
    {
      label: "新密码",
      span: 24,
      row: true,
      type: "password",
      prop: "newPassword",
      maxlength: 20,
      minlength: 8,
    },
    {
      label: "确认密码",
      span: 24,
      row: true,
      type: "password",
      prop: "newPassword1",
      maxlength: 20,
      minlength: 8,
    },
    {
      label: "",
      span: 24,
      row: true,
      type: "text",
      prop: "passwordTip",
    },
  ],
};

export default {
  data() {
    return {
      index: 0,
      option: passwordOption,
      form: {},
      succ: false,
      djs: 10,
      timer: null,
    };
  },
  watch: {
    djs: {
      handler: function (newValue, oldName) {
        if (newValue <= 0) {
          this.tc();
        }
      },
      deep: true,
    },
  },

  created() {},
  destroyed() {
    // this.timer.clear();
    clearInterval(this.timer);
  },
  methods: {
    tc() {
      this.$store
        .dispatch("LogOut")
        .then(() => {
          resetRouter();
          this.$router.push({ path: "/login" });
        })
        .catch(() => {
          localStorage.clear();
          window.location.reload();
        });
    },
    handleSubmit(form, done) {
      if (!form.oldPassword) {
        this.$message({
          type: "error",
          message: "请输入原密码",
        });
        done();
        return;
      }
      if (!form.newPassword) {
        this.$message({
          type: "error",
          message: "请输入新密码",
        });
        done();
        return;
      }
      let psd = crypto.encrypt(form.newPassword),
        psd1 = crypto.encrypt(form.newPassword1);
      if (psd !== psd1) {
        this.$message({
          type: "error",
          message: "两次输入密码不一致!",
        });
        done();
        return;
      }
      passwordCheck(form.newPassword)
        .then(() => {
          updatePassword(crypto.encrypt(form.oldPassword), psd, psd1).then(
            (res) => {
              if (res.data.success) {
                this.succ = true;
                this.timer = setInterval(() => {
                  if (this.djs > 0) {
                    this.djs--;
                  }
                }, 1000);
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
              done();
            },
            (error) => {
              done();
            }
          );
        })
        .catch((err) => {
          this.$message(err);
          done();
        });
    },
  },
};
</script>

<style></style>
