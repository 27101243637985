import request from '@/router/axios';
import apiUrl from './apiUrl';

export default {

  apiUrl: apiUrl.taskQuota,

  getList(params){
    return request({
      method: 'get',
      params: params,
      url: apiUrl.taskQuota.list
    })
  },
  getDetail(params){
    return request({
      method: 'POST',
      params: params,
      url: apiUrl.taskQuota.detail
    })
  },
  save(params){
    return request({
      method: 'post',
      data: params,
      url: apiUrl.taskQuota.save
    })
  },
  edit(params){
    return request({
      method: 'post',
      data: params,
      url: apiUrl.taskQuota.edit
    })
  },
  delete(params){
    return request({
      method: 'delete',
      params: {},
      url: apiUrl.taskQuota.delete + '/' + params
    })
  }

}