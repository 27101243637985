<template>
  <div>
    <div class="ov-h" style="display: flex;height: 100px;gap: 10px;">
      <div
        v-for="(file, index) of fileList"
        :key="file.id ? file.id : file.name"
        :title="file.name"
        class="el-upload el-upload--picture-card custom-el-upload custom-el-upload-view-item"
      >
        <i v-if="file.state === 'uploading' || file.state === 'loading'" class="el-icon-loading"></i>
        <template v-if="file.state === 'uploaded'">
          <FormFileImage v-if="getFileType(file.type) === 'image'" :src="file.url"></FormFileImage>
          <img v-if="getFileType(file.type) !== 'image'" :src="getFileTypeIcon(file.type)" alt=""/>
          <!-- <img
            v-if="fileTypes[file.type] === 'txt'"
            src="/assets/images/icon/file/txt.png"
            alt=""
          /> -->
          <!-- <img
            v-if="fileTypes[file.type] === 'ppt'"
            src="/assets/images/icon/file/ppt.png"
            alt=""
          />
          <img
            v-if="fileTypes[file.type] === 'excel'"
            src="/assets/images/icon/file/excel.png"
            alt=""
          />
          <img
            v-if="fileTypes[file.type] === 'word'"
            src="/assets/images/icon/file/word.png"
            alt=""
          /> -->
          <p>{{ file.name }}</p>
          <div class="actionBtn">
            <el-tooltip class="item" v-if="getFileType(file.type) === 'image'" effect="dark" content="预览" placement="top">
              <i class="item el-icon-zoom-in" @click="openImagePreview(file)"></i>
            </el-tooltip>
            <el-tooltip class="item" v-if="download || (getFileType(file.type) !== 'image' && downloadView)" effect="dark" content="下载" placement="top">
              <i class="item el-icon-download" @click="downloadFile(file)"></i>
            </el-tooltip>
            <el-tooltip class="item" v-if="!isDisabled" effect="dark" content="删除" placement="top">
              <i class="item el-icon-delete" @click="
                fileList.splice(index, 1);
                setFormAttachmentIds();
              "></i>
            </el-tooltip>
            <!-- <i
              v-if="fileTypes[file.type] === 'image'"
              @click="openImagePreview(file)"
              class="el-icon-zoom-in"
            ></i> -->
            <!-- <i v-if="download" @click="downloadFile(file)" class="el-icon-download"></i>
            <i
              v-if="!isDisabled"
              class="el-icon-delete"
              @click="
                fileList.splice(index, 1);
                setFormAttachmentIds();
              "
            ></i> -->
          </div>
        </template>
      </div>

      <div
        v-if="!isMax && !isDisabled"
        @click="
          () => {
            this.$refs.file.click();
          }
        "
        class="el-upload el-upload--picture-card custom-el-upload"
      >
        <i class="el-icon-plus"></i>
        <input
          ref="file"
          @change="selectFileChange"
          :accept="acceptVal"
          :multiple="isMultiple"
          type="file"
          name="file"
          class="el-upload__input"
        />
      </div>
    </div>
    <div v-if="placeholderArr.length" class="text-info fts-12 pad-tb-10 lh-20">
      <div v-for="item of placeholderArr">{{item}}</div>
    </div>
  </div>
</template>
<script>
import { getPicListByIds } from "@/api/busiMode/metaCommon";
import { uploadFile } from "@/api/resource/file.js";
import FormFileImage from './form-file-image.vue';
export default {
  components: { FormFileImage },
  name: 'FormFile',
  data() {
    return {
      fileList: [],
      fileTypes: {
        // 图片格式
        jpg: "image",
        jpeg: "image",
        jfif: 'image',
        pjpeg: 'image',
        pjp: 'image',
        png: "image",
        gif: "image",
        svg: 'image',
        apng: 'image',
        bmp: 'image',
        gif: 'image',
        ico: 'image',
        cur: 'image',
        webp: 'image',

        xls: "excel",
        xlsx: "excel",
        doc: "word",
        docx: "word",
        ppt: "ppt",
        pdf: "pdf",
        txt: "txt",
        html: 'html',
        xhtml: 'html',
        htm: 'html',
        shtml: 'html',
        shtm: 'html',
        js: 'js',
        psd: 'psd',
        php: 'php',
        json: 'json',
        md: 'md',
        sql: 'sql'
      },
      linkReplace: {
        source: "10.147.17.103",
        target: "10.100.170.103",
      },
    };
  },
  props: {
    value: {
      type: [String , Array],
    },
    placeholder: {
      type: [String , Array],
    },
    disabled: {
      type: [Boolean, String],
      default: false,
    },
    isList: {
      type: Boolean,
      default: false
    },
    downloadView: {
      type: Boolean,
      default: false
    },
    download: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    max: {
      type: Number,
      default: -1
    },
    accept: {
      type: [String , Array],
      default: '*'
    },
    // 限制文件大小，单位为KB
    maxSize: {
      type: Number,
      default: -1
    },
    autoPlaceholder: {
      type: Boolean,
      default: true
    },
    isImage: {
      type: Boolean,
      default: undefined
    },
    useLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isImageType(){
      if(this.isImage === true || this.isImage === false){
        return this.isImage;
      }
      if(this.accept === '*'){
        return false;
      }
      let accept = [];
      if(typeof this.accept === 'string'){
        accept = this.accept.split(',');
      }
      if(Array.isArray(this.accept)){
        accept = this.accept;
      }
      if(accept.length){
        let isImage = true;
        accept.forEach(
          item => {
            if(item.split('/')[0] !== 'image'){
              isImage = false;
            }
          }
        );
        return isImage;
      }
      return false;
    },
    acceptVal(){
      if(this.accept && typeof this.accept === 'string'){
        return this.accept;
      }
      if(Array.isArray(this.accept) && this.accept.length){
        return this.accept.join(',');
      }
      return '*';
    },
    placeholderArr(){
      let placeholderArr = [];
      if(this.autoPlaceholder){
        if(this.max > 0){
          placeholderArr.push(`最多支持上传${this.max}${this.isImageType ? '张图片' : '个文件'}`);
        }
        if(this.maxSize > 0){
          placeholderArr.push(`单${this.isImageType ? '张图片' : '个文件'}最大不能超过${this.maxSize}KB`);
        }
      }
      if(typeof this.placeholder === 'string'){
        return [this.placeholder , ...placeholderArr];
      }
      if(Array.isArray(this.placeholder)){
        return [...this.placeholder , ...placeholderArr];
      }
      return [...placeholderArr];
    },
    isMultiple(){
      return this.isMax ? this.isMax : this.multiple;
    },
    isDisabled() {
      if (this.disabled === true || this.disabled === "disabled") {
        return true;
      } else {
        return false;
      }
    },
    isMax(){
      if(this.multiple === false && this.fileList.length === 1){
        return true;
      }
      if(this.max > -1 && this.fileList.length >= this.max){
        return true;
      }
      return false;
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.initValue();
      },
    },
  },
  mounted() {},
  methods: {
    initValue(){
      if(Array.isArray(this.value)){
        this.setFileListByValueArr();
      }else{
        if(this.value && typeof this.value === 'string' &&( this.value.includes('http') || this.useLink)){
          this.fileList = [
            {
              state: 'uploaded',
              type: 'png',
              name: this.value,
              id: this.value,
              url: this.value,
              file: null
            }
          ]
        }else{
          this.getPicListByIds();
        }
      }
    },
    setFileListByValueArr(){
      this.fileList = this.value.map(
        item => {
          let type = item.label.split(".").pop().toLocaleLowerCase();
          return {
            state: 'uploaded',
            type: type,
            name: item.label,
            id: item.label,
            url: item.value,
            file: null
          }
        }
      )
    },
    getFileType(type){
      return this.fileTypes[type] || 'file';
    },
    getFileTypeIcon(type){
      if(this.fileTypes[type]){
        return `/assets/images/icon/file/${this.fileTypes[type]}.png`;
      }else{
        return '/assets/images/icon/file/file.png';
      }
    },
    getPicListByIds() {
      let attachment_ids = [];
      if(this.value){
        this.value.split(",").forEach(
          item => {
            if(item){
              attachment_ids.push(item);
            }
          }
        )
      }
      this.fileList = attachment_ids.map(
        item => {
          return {
            type: '',
            state: 'loading',
            name: '',
            id: item,
            url: '',
            file: null
          }
        }
      );
      if (attachment_ids && attachment_ids.length) {
        getPicListByIds(attachment_ids).then((res) => {
          if (res && res.data && res.data.data && res.data.data.length) {
            res.data.data.forEach((item) => {
              let type = item.originalName.split(".").pop().toLocaleLowerCase();
              let fileItem = this.fileList.find(a => a.id === item.id);
              if(fileItem){
                fileItem.type = type;
                fileItem.url = item.link;
                fileItem.name = item.originalName;
                fileItem.state = 'uploaded';
                item.file = item;
              }else{
                let fileItem = {
                  type: type,
                  url: item.link,
                  name: item.originalName,
                  state: "uploaded",
                  id: item.id,
                  file: item,
                };
                this.fileList.push(fileItem);
              }

            });
          }else{
            this.fileList = [];
          }
        });
      }
    },
    openImagePreview(file) {
      this.$ImagePreview([{ url: file.url }], 0, {
        closeOnClickModal: true,
      });
    },
    downloadFile(file) {
      getPicListByIds([file.id]).then((res) => {
        if (res.data.data && res.data.data[0]) {
          let file = res.data.data[0];
          this.downFile(file.link , file.originalName);
        }
      });
    },
    selectFileChange(ev) {
      if(this.max > 0 && this.fileList.length + ev.target.files.length > this.max){
        this.$message.warning(`您选择的文件数量已经超过允许上传的最大数量，请重新选择。`);
        return;
      }
      for (let i = 0; i < ev.target.files.length; i++) {
        let file = ev.target.files[i];
        let fileSize = file.size / 1024;
        if(this.maxSize < 0 || (this.maxSize > -1 && fileSize <= this.maxSize)){
          let type = file.name.split(".").pop().toLocaleLowerCase();
          let fileItem = {
            type: type,
            url: this.fileTypes[type] === "image" ? URL.createObjectURL(file) : null,
            name: file.name,
            file: file,
            state: "",
            id: "",
          };
          this.fileList.push(fileItem);
        }else{
          this.$message.warning(`${file.name} 文件大小超过限制，请选择不大于${this.maxSize}KB的文件`)
        }
      };
      let maxLen = 0 , len = 0;
      this.fileList.forEach((item) => {
        if (!item.id) {
          maxLen++;
          let formData = new FormData();
          formData.append("file", item.file);
          item.state = "uploading";
          uploadFile(formData).then((res) => {
            if (res.data && res.data.data) {
              item.id = res.data.data.attachId;
              item.url = res.data.data.link;
              item.state = "uploaded";
            }
            len++;
            if(len >= maxLen){
              this.setFormAttachmentIds();
            }
          });
        }
      });
    },
    setFormAttachmentIds() {
      if(this.isList || Array.isArray(this.value)){
        let value = this.fileList.map((itemFile) => {
          return {
            label: itemFile.name,
            value: itemFile.url
          }
        })
        this.$emit("input", value);
      }else{
        let value = this.fileList.map((itemFile) => itemFile[this.useLink ? 'url' : 'id']).join(",");
        this.$emit("input", value);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
  vertical-align: baseline;
  box-sizing: border-box;
  display: block;
  // margin-left: 10px;
}
.el-upload--picture-card:hover {
  color: #fff;
  .p-a-b {
    display: block;
  }
}
.p-a-b {
  position: absolute;
  right: 35px;
  top: 0px;
  padding: 0px;
  cursor: pointer;
  display: none;
  i {
    color: inherit !important;
  }
}
.custom-el-upload-view-item {
  overflow: hidden;
  position: relative;
  img {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: block;
    margin: 10px;
  }
  .custom-el-upload-view-item__image{
    width: 100%;
    height: 100%;
    margin: 0;
  }
  p {
    background: rgba(0, 0, 0, 0.3);
    line-height: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    margin: 0;
    padding: 0 3px;
  }
  .actionBtn {
    position: absolute;
    top: 0;
    bottom: 20px;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    gap: 8px;
    &.item{
      cursor: pointer;
      font-size: 14px;
    }
    i {
      color: #fff;
    }
  }
  &:hover {
    .actionBtn {
      display: flex;
    }
  }
}
</style>
