import request from '@/router/axios';

const baseUrl = '/api/convergeflexschduler/convergeflexschduler/';

export const listDataWrite = (params) => {
  return request({
    url:baseUrl + 'dataarea/list-paging',
    method: 'get',
    params: params
  })
};


export const queryDataBaseByDataWriteId = (params) => {
  return request({
    url:baseUrl + 'dataarea/queryDataBaseByDataAreaId',
    method: 'post',
    params: params
  })
}

export const queryTablesByDataBaseId = (params) => {
  return request({
    url:baseUrl + 'datamodel/queryTablesByDataBaseId',
    method: 'post',
    params: params
  })
}

export const createDataWrite = (data) => {
  return request({
    url:baseUrl + 'dataarea/create',
    method: 'post',
    params: data
  })
}

export const updateDataWrite = (data) => {
  return request({
    url:baseUrl + 'dataarea/update',
    method: 'post',
    params: data
  })
}

export const deleteDataWrite = (params) => {
  return request({
    url:baseUrl + 'dataarea/delete',
    method: 'get',
    params: params
  })
}

export const queryDataByDataBaseIdAndTableName = (data) => {
  return request({
    url:baseUrl + 'datamodel/queryDataByDataBaseIdAndTableName',
    method: 'post',
    params: data
  })
}
