<template>
  <el-select v-on="$listeners" v-bind="$attrs" class="dis-b w-100p" v-model="val">
    <el-option v-for="itme of data" :key="itme.id" :label="itme.dictValue" :value="itme.dictKey"></el-option>
  </el-select>
</template>
<script>
import * as bizDictService from "@/api/system/dictbiz";
import * as dictService from "@/api/system/dict";
export default {
  data(){
    return {
      data: [],
      val: null
    }
  },
  watch: {
    val(){
      if(this.val !== this.value){
        this.$nextTick(() => {
          this.$emit('input' , this.val);
        })
      }
    },
    value(){
      this.initVal();
    }
  },
  props: {
    value: {},
    code: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'biz-dict'
    },
    dataType: {
      type: String,
      default: 'string'
    }
  },
  mounted(){
    this.initVal();
    if(this.code){
      this.getDictionary();
    }
  },
  destroyed(){
    let name = `dict-select_${this.type}_${this.code}`;
    localStorage.clear(name);
  },
  methods: {
    initVal(){
      if((this.value ?? '') !== ''){
        switch(this.dataType){
          case 'string':
            this.val = `${this.value}`;
            break;
          case 'number':
            this.val = Number(this.value);
            break;
          default:
            this.val = this.value;
            break;
        }
      }else{
        this.val = this.value;
      }
    },
    getDictionary(){
      let name = `dict-select_${this.type}_${this.code}`;
      if(localStorage[name]){
        this.data = JSON.parse(localStorage.getItem(name))
      }else{
        (this.type === 'biz-dict' ? bizDictService : dictService).getDictionary({
          code: this.code
        }).then(
          res => {
            this.data = res.data.data;
            localStorage.setItem(name , JSON.stringify(this.data));
          }
        )
      }
    },
    
  },
}
</script>