import request from '@/router/axios';
const prefix = '/api/jida-data-asset/';


export const apiUrls = {
  theme: {
    list: prefix + 'topic/list'
  },
  industry: {
    list: prefix + 'industry/list'
  }
}

export const getStatisticsCatalogShareTypeRatio = () => {
  return request({
    url: prefix + 'catalog/statistics-catalog-share-type-ratio',
    method: 'get',
    params: {
    }
  })
}

export const getThems = () => {
  return request({
    url: apiUrls.theme.list,
    method: 'get',
    params: {
    }
  })
};
export const addTheme = (data) => {
  return request({
    url: prefix + 'topic/save',
    method: 'post',
    data:data
  })
};
export const editTheme = (data) => {
  return request({
    url: prefix + 'topic/update',
    method: 'post',
    data:data
  })
};
export const deleteTheme = (data) => {
  return request({
    url: prefix + 'topic/remove',
    method: 'post',
    params:data
  })
};
export const geIndustrys = () => {
  return request({
    url: apiUrls.industry.list,
    method: 'get',
    params: {
    }
  })
};
export const addIndustry = (data) => {
  return request({
    url: prefix + 'industry/save',
    method: 'post',
    data:data
  })
};
export const editIndustry = (data) => {
  return request({
    url: prefix + 'industry/update',
    method: 'post',
    data:data
  })
};
export const deleteIndustry = (data) => {
  return request({
    url: prefix + 'industry/remove',
    method: 'post',
    params:data
  })
};

