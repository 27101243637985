/**
 * 全局配置文件
 */
export default {
  title: "jida",
  logo: "",
  key: 'saber',//配置主键,目前用于存储
  indexTitle: 'jida web',
  clientId: 'jida', // 应用id
  clientSecret: 'saber_secret', // 客户端密钥
  tenantMode: true, // 是否开启租户模式
  tenantId: "000000", // 管理组租户编号
  captchaMode: true, // 是否开启验证码模式
  lockPage: '/lock',
  refreshToken: 5000, // ms
  tokenTime: 1200, // s
  tokenHeader: 'JiDa-Auth',
  //http的status默认放行列表
  statusWhiteList: [],
  //配置首页不可关闭
  isFirstPage: true,
  layoutType:"pageIndex",//配置布局是否支持自定义，pageIndex 默认的结构，custIndex是自定义的结构
  fistPage: {
    label: "运营概览",
    value: "/dashboard/index",
    params: {},
    query: {},
    meta: {
      i18n: ''
    },
    group: [],
    close: false
  },
  //配置菜单的属性
  menu: {
    iconDefault: 'iconfont icon-caidan',
    props: {
      label: 'name',
      path: 'path',
      icon: 'source',
      children: 'children'
    }
  },
  // 第三方系统授权地址
  authUrl: 'http://localhost/JiDa-Auth/oauth/render',
  // 报表设计器地址(cloud端口为8108,boot端口为80)
  reportUrl: 'http://10.50.1.25:8108/ureport',
  // 流程设计器地址
  flowDesignUrl: '/api/jida-flowdesign',
  gardenName:"青岛国际创新园",
  gardenAdress:"青岛市崂山区松岭路169号",
  companyText:
  `1.这里是企业说明
2.这里是企业说明`,
  permission: false // permission为false 权限校验不生效  15579633645
}
