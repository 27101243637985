import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/sportstadium/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/sportstadium/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/sportstadium/detail',
    method: 'get',
    params: {
      id
    }
  })
}

//删除
export const remove = (id) => {
  return request({
    url: '/api/park-spcod/sportstadium/remove/' + id,
    method: 'post'
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/sportstadium/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/sportstadium/submit',
    method: 'post',
    data: row
  })
}

// 修改状态
export const updateStadiumStatu = (id) => {
  return request({
    url: '/api/park-spcod/sportstadium/updatestadium/' + id,
    method: 'post',
    data: {}
  })
}

// 获得时间间隔
export const getimeSeg = (startHour,endHour,timeSeg) => {
  return request({
    url: '/api/park-spcod/sportstadium/getimeSeg' ,
    method: 'post',
    data: {
      'startHour':startHour,
      'endHour':endHour,
      'timeSeg':timeSeg
    }
  })
}