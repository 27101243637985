<template>
  <div>
    <el-timeline class="pad-l-0">
    <el-timeline-item
      v-for="(item, index) in activities"
      :key="index"
      :type="stateTypes[item.taskStatus] || 'info'"
      :timestamp="`【${item.taskName}】${stateNames[item.taskStatus]}`"
      placement="top"
    >
      <el-card v-if="item.status === 1" :body-style="{'padding': '10px'}">
        <div class="fts-12 lh-24" v-if="!item.comments || !item.comments.length">
          <b>{{item.assigneeName}}：</b>{{item.stateName}}
        </div>
        <div class="fts-12 lh-24">
          <div v-for="itemMsg of item.comments"><b class="mar-r-10">{{itemMsg.userName}}:</b><span>{{itemMsg.message}}</span></div>
        </div>
        <div class="mar-t-5 fts-12 text-999">{{item.historyTaskEndTime || item.createTime}}</div>
      </el-card>
      <el-card v-else  :body-style="{'padding': '10px'}">
        <div class="fts-14 lh-24">审批人员：<b>{{item.assigneeName}}</b></div>
      </el-card>
    </el-timeline-item>
  </el-timeline>
  </div>
</template>
<script>
import { queryHistoryList } from '@/api/flow/flow.js';
export default {
  data(){
    return {
      activities: [],
      stateTypes: {
        0: 'info',
        1: 'success',
        2: 'primary',
        3: 'danger',
        4: 'info'
      },
      stateNames: {
        0: '未开始',
        1: '完成',
        2: '进行中',
        3: '拒绝',
        4: '拒绝'
      }
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  mounted(){
    if(this.id){
      this.queryHistoryList();
    }
  },
  methods: {
    queryHistoryList(){
      queryHistoryList({
        processInstanceId: this.id
      }).then(
        res => {
          let currentTask = null;
          this.activities = (res.data.data || []).map(
            (item , index) => {
              // let createTime = item.createTime ? item.createTime : currentTask ? '未开始' : '审核中';
              let stateName = '' , status = Number(item.status);
              if(index !== 0){
                if(item.flag === '1'){
                  stateName = '审批通过'
                }else if(item.flag === '2'){
                  stateName = '审批未通过'
                }
                if(status === 1 && !item.flag){
                  stateName = '审批未通过'
                }
                if(status === 0 && !currentTask){
                  status = 2;
                }
                if(item.status === '0'){
                  currentTask = item;
                }
              }else{
                stateName = '';
                item.taskName = '提交审批';
                item.comments = [
                  {
                    userName: item.assigneeName,
                    message: '提交了审批'
                  }
                ]
              }
              return {
                ...item,
                flag: Number(item.flag),
                taskStatus: item.flag === '2' ? 3 : (status === 1 && !item.flag) ? 4 : status,
                status: status,
                // createTime: createTime,
                stateName: stateName,
              }
            }
          );
        }
      )
    }
  }
}
</script>
