var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c('avue-crud',{ref:"crud",staticClass:"enter-deatil",attrs:{"option":_vm.option,"table-loading":_vm.loading,"data":_vm.data,"page":_vm.page,"rules":_vm.ruleModel},on:{"update:page":function($event){_vm.page=$event},"selection-change":_vm.selectionChange,"row-del":_vm.rowDel,"refresh-change":_vm.refreshChange,"search-reset":_vm.refreshChange,"on-load":_vm.onLoad},scopedSlots:_vm._u([{key:"menuLeft",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.openDislog('add')}}},[_vm._v("新增 ")])]}}]),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('el-dialog',{staticClass:"buildDetail",attrs:{"size":_vm.size,"visible":_vm.dialogVisible,"destroy-on-close":"","top":'8vh',"body-style":{ padding: '0px' },"width":"40%","append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.dialogVisible)?[_c('div',{staticClass:"header-title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"fts-16"},[_vm._v("新增")])])]:_vm._e(),_c('el-row',[_c('el-form',[_c('el-col',{attrs:{"span":22}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"入驻房间","prop":"reason","rules":[
              {
                required: true,
                message: '请选择入驻房间',
                trigger: 'blur',
              },
            ]}},[_c('el-select',{attrs:{"multiple":"","filterable":"","remote":"","clearable":"","reserve-keyword":"","default-first-option":"","placeholder":"请输入入驻房间","remote-method":_vm.remoteMethod,"loading":_vm.remoteLoad},on:{"change":_vm.changeRooms},model:{value:(_vm.ruleForm.roomIds),callback:function ($$v) {_vm.$set(_vm.ruleForm, "roomIds", $$v)},expression:"ruleForm.roomIds"}},_vm._l((_vm.roomList),function(item){return _c('el-option',{key:item.room_id,attrs:{"label":item.label,"value":item.room_id}})}),1)],1)],1),(_vm.ruleForm.roomNames)?_c('el-col',{attrs:{"span":24}},[_vm._l((_vm.ruleForm.roomNames),function(item,index){return [_c('div',{staticClass:"roomtitle"},[_vm._v(" "+_vm._s(item)+" "),_c('i',{staticClass:"el-icon-close",on:{"click":function($event){return _vm.deleteRoom(item, index)}}})])]})],2):_vm._e(),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"备注","prop":"note"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"dialog-footer",staticStyle:{"text-align":"right"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-circle-plus-outline","type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),_c('el-button',{attrs:{"size":"small","icon":"el-icon-circle-close"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }