import request from '@/router/axios';

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-epmall/settlesheet/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/park-epmall/settlesheet/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const add = (row) => {
    return request({
      url: '/api/park-epmall/settlesheet/submit',
      method: 'post',
      data: row
    })
}

//去结算
export const doSettle = (row) => {
  return request({
    url: '/api/park-epmall/settlesheet/dosettle',
    method: 'post',
    data: row
  })
}


export const update = (row) => {
    return request({
      url: '/api/park-epmall/settlesheet/submit',
      method: 'post',
      data: row
    })
  }

//结算提交
export const settle = (sheetId) => {
    return request({
      url: '/api/park-epmall/settlesheet/settle/'+sheetId,
      method: 'post',
      data: {}
    })
  }

 //挑选订单到结算单
export const pickorders = (settleId,orderIds) => {
    return request({
      url: '/api/park-epmall/settlesheet/pickorders/' + settleId,
      method: 'post',
      params: {"orderIds":orderIds}
    })
  } 


//删除结算单
export const delSheet = (settleId) => {
  return request({
    url: '/api/park-epmall/settlesheet/delsheet/' + settleId,
    method: 'post'
  })
} 

