import request from '@/router/axios';
const prefix = '/api/jida-data-asset/';
//  api授权 列表数据
export const dataShareLicensedPage = (params) => {
  return request({
    url: prefix + 'dataShareLicensed/page',
    method: 'get',
    params: params
  })
};
//  api授权 详情
export const dataShareLicensedDetail = (params) => {
  return request({
    url: prefix + 'dataShareLicensed/detail',
    method: 'get',
    params: params
  })
};
//  api授权 新增或修改
export const dataShareLicensedSubmit = (params) => {
  return request({
    url: prefix + 'dataShareLicensed/submit',
    method: 'post',
    data: params
  })
};
//  api授权 删除
export const dataShareLicensedRemove = (params) => {
  return request({
    url: prefix + `dataShareLicensed/remove`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
    // data: params
  })
}
// 获取API集合
export const apicollectionSelect = (params) => {
  return request({
    url: prefix + `apicollection/select`,
    method: 'get',
    params:params
  })
}
// 获取API
export const availableApilist = (params) => {
  return request({
    url: prefix + `datashare/availableApilist`,
    method: 'get',
    params:params
  })
}
// 获取应用TOKEN
export const getAppToken = (params) => {
  return request({
    url: prefix + `data-asset/datashare/getAppToken`,
    method: 'post',
    data:params
  })
}
// 获取API 调用日志
export const datasharelogPage = (params) => {
  return request({
    url: '/api/data-asset/datasharelog/page',
    method: 'get',
    params:params
  })
}
