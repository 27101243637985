import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/sportground/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/sportground/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/sportground/detail/' + id,
    method: 'get',
    params: {
    }
  })
}

//删除
export const remove = (id) => {
  return request({
    url: '/api/park-spcod/sportground/remove/' + id,
    method: 'get'
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/sportground/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/sportground/submit',
    method: 'post',
    data: row
  })
}

export const updateGroundStatus = (id) => {
  return request({
    url: '/api/park-spcod/sportground/updateground/' + id,
    method: 'post',
    data: {}
  })
}
export const saveOrUpdateMinGround = (data) => {
  return request({
    url: '/api/park-spcod/sportground/saveOrUpdateMinGround' ,
    method: 'post',
    data: data
  })
}



// 罗列场地
export const listGround = (pageNo, pageSize,params) => {
	if(!params)
		params = {};
	//默认场馆和场地状态都为1
	params.status='1';
	params.stmStatus='1';

	return request({
		url: '/api/park-spcod/mobsports/groundpage',
		method: 'get',
		params:{
      ...params,
			'current':pageNo,
			'size':pageSize
		},
	})
}
