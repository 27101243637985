import request from '@/router/axios';
const prefix = '/api/sentinel';
export const getDataAuthList = (params) => {
  return request({
    url: prefix + `/authority/rules`,
    method: 'get',
    params
  })
};
export const saveAuth = (params) => {
  return request({
    url: prefix + `/authority/rule`,
    method: 'post',
    data:params
  })
};
export const updateAuth = (params) => {
  return request({
    url: prefix + `/authority/rule/${params.id}`,
    method: 'put',
    data:params
  })
};
export const delAuth = (id) => {
  return request({
    url: prefix + `/authority/rule/${id}`,
    method: 'delete'
  })
};

