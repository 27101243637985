import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-epmall/brand/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-epmall/item/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/park-epmall/item/detail/' + id,
    method: 'get',
    params: {
    }
  })
}

export const remove = (id) => {
  return request({
    url: '/api/park-epmall/item/remove/' + id,
    method: 'post'
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-epmall/item/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-epmall/item/submit',
    method: 'post',
    data: row
  })
}

//更改状态
export const toogleStatus = (id) => {
  return request({
    url: '/api/park-epmall/item/tooglestatus/' + id,
    method: 'post',
    data: {}
  })
}

