import request from '@/router/axios';
const prefix = '/api/convergeflexschduler/convergeflexschduler/';
export const getDataLayerList = (params) => {
  return request({
    url: prefix + `datahierarchy/list-paging`,
    method: 'get',
    params,
    enableEscape: true
  })
};
export const saveDataLayer = (params) => {
  return request({
    url: prefix + `datahierarchy/create`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};
export const updateDataLayer = (params) => {
  return request({
    url: prefix + `datahierarchy/update`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};
export const delDataLayer = (params) => {
  return request({
    url: prefix + `datahierarchy/delete`,
    method: 'get',
    params
  })
};
export const updateEnableStatus = (params) => {
  return request({
    url: prefix + `datahierarchy/updateDataHierarchyEnableStatus`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};
export const updateShowStatus = (params) => {
  return request({
    url: prefix + `datahierarchy/updateDataHierarchyShowStatus`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};
export const queryTablesByDataBaseIdAndDhId = (params) => {
  return request({
    url: prefix + `datahierarchy/queryTablesByDataBaseIdAndDhId`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};
export const queryDataBaseByDataHierarchyId = (params) => {
  return request({
    url: prefix + `datahierarchy/queryDataBaseByDataHierarchyId`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};

//总统计
export const getAllOverviewData = () => {
  return request({
    url: prefix+`dataDailyStatistics/allOverviewData`,
    method: 'get'  
  })
};
//今日统计
export const getTodayOverviewData = () => {
  return request({
    url:  prefix+`dataDailyStatistics/todayOverviewData`,
    method: 'get'
  })
};
//近一个月数据量走势图
export const getLatelyNDayData = (params) => {
  return request({
    url:  prefix+`dataDailyStatistics/latelyNDayData`,
    method: 'get',
    params
  })
};
//数据分层占比分析
export const getDataRowProportion = () => {
  return request({
    url:  prefix+`dataDailyStatistics/dataRowProportion`,
    method: 'get',
  })
};

//数据分层占比分析占用空间
export const getDataSizeProportion = () => {
  return request({
    url:  prefix+`dataDailyStatistics/dataSizeProportion`,
    method: 'get',
  })
};

//表活跃top5 
export const getTalbeRowsTopN = (params) => {
  return request({
    url:  prefix+`dataDailyStatistics/talbeRowsTopN`,
    method: 'get',
    params
  })
};
//存储空间top5 
export const getTableSizeTopN = (params) => {
  return request({
    url:  prefix+`dataDailyStatistics/tableSizeTopN`,
    method: 'get',
    params
  })
};

//  数据分层上传接口
export const uploadFileToDb = (params) => {
  return request({
    url: prefix + `datasources/uploadFileToDb`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};

  