<template>
  <div class="widget-form-container">
    <el-form
      :label-position="data.labelPosition || 'right'"
      :label-width="data.labelWidth ? `${data.labelWidth}px` : '100px'"
      :label-suffix="data.labelSuffix"
      :model="form"
      ref="widgetForm"
      size="small"
    >
      <el-row :gutter="data.gutter">
        <draggable
          :list="data.column"
          :group="{ name: 'form' }"
          ghost-class="ghost"
          :animation="300"
          @add="handleWidgetAdd"
          @end="$emit('change')"
        >
          <template v-for="(column, index) in data.column">
            <div
              class="widget-form-table"
              v-if="column.type == 'dynamic'"
              :key="index"
              :class="{ active: selectWidget.prop == column.prop }"
            >
              <widget-form-table
                :data="data"
                :column="column"
                :index="index"
                :select.sync="selectWidget"
                @change="$emit('change')"
              ></widget-form-table>
            </div>
            <div
              class="widget-form-group"
              v-else-if="column.type == 'group'"
              :key="index"
              :class="{ active: selectWidget.prop == column.prop }"
              @click="handleSelectWidget(index)"
            >
              <widget-form-group
                :data="data"
                :column="column"
                :index="index"
                :select.sync="selectWidget"
                @change="$emit('change')"
              >
              </widget-form-group>
            </div>

            <el-col
              v-else
              :key="index"
              :md="column.span || 12"
              :xs="24"
              :offset="column.offset || 0"
            >
              <el-form-item
                class="widget-form-item"
                :label="column.label"
                :labelWidth="column.labelWidth && column.labelWidth + 'px'"
                :prop="column.prop"
                :class="{
                  itemActive: selectWidget.prop == column.prop,
                  required: column.required,
                }"
                @click.native="handleSelectWidget(index)"
              >
                <el-dropdown style="display: block">
                  <i class="el-icon-setting czan"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="selectWidget.prop == column.prop">
                      <span @click="handleWidgetDelete(index)"
                        ><i class="el-icon-delete"></i>
                        删除
                      </span>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="selectWidget.prop == column.prop">
                      <span @click="handleWidgetClone(index)">
                        <i class="el-icon-document-copy"></i>
                        复制</span
                      ></el-dropdown-item
                    >
                    <el-dropdown-item v-if="selectWidget.prop == column.prop">
                      <span @click="openEdit(index)">
                        <i class="el-icon-edit-outline"></i>
                        编辑</span
                      ></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <i
                  class="el-icon-rank dropIcon"
                  :class="{
                    active: selectWidget.prop == column.prop,
                    required: column.required,
                  }"
                ></i>
                <widget-form-item
                  :item="column"
                  :params="column.params"
                ></widget-form-item>
              </el-form-item>
            </el-col>
          </template>
          <el-col
            v-if="data.menuBtn && data.column.length > 0"
            :span="searchSpan || 24"
            class="text-center"
          >
            <el-button size="small" type="primary" @click="onSubmit">{{
              data.submitText
            }}</el-button>
            <el-button size="small">{{ data.emptyText }}</el-button>
          </el-col>
        </draggable>
      </el-row>
    </el-form>
    <div v-if="rowEditVisible">
      <el-dialog
        title="编辑"
        width="30%"
        append-to-body
        :visible.sync="rowEditVisible"
        destroy-on-close
      >
        <avue-form
          ref="rowForm"
          v-model="columnObj"
          :option="searchOption"
          append-to-body="true"
          @submit="submit"
        >
        </avue-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import WidgetFormItem from "./WidgetFormItem";
import WidgetFormTable from "./WidgetFormTable";
import WidgetFormGroup from "./WidgetFormGroup";
import Draggable from "vuedraggable";

import { searchOption } from "../table/options";
export default {
  name: "widget-form",
  components: { Draggable, WidgetFormItem, WidgetFormTable, WidgetFormGroup },
  props: ["data", "select", "searchSpan"],
  data() {
    return {
      rowEditVisible: false,
      selectWidget: this.select,
      form: {},
      searchOption: searchOption,
      columnObj: {},
      selectIndex: "",
    };
  },
  methods: {
    onSubmit() {},
    submit() {
      this.columnObj.span = parseInt(this.columnObj.span);
      this.data.column[this.selectIndex] = this.columnObj;
      this.rowEditVisible = false;
      Object.keys(this.columnObj).forEach((ele) => {
        if (ele.includes("$")) delete this.columnObj[ele];
      });
      this.$emit("change");
    },
    openEdit(i) {
      console.log(i);
      if (this.data.column[i]) {
        this.selectIndex = i;
        this.data.column[i].type = this.data.column[i].type
          ? this.data.column[i].type
          : "input";
        this.columnObj = this.data.column[i];
        this.rowEditVisible = true;
        console.log(this.columnObj);
      }
    },
    handleSelectWidget(index) {
      this.selectWidget = this.data.column[index];
    },
    handleWidgetAdd(evt) {
      const newIndex = evt.newIndex;
      const data = this.deepClone(this.data.column[newIndex]);
      if (!data.prop)
        data.prop = "a" + Date.now() + Math.ceil(Math.random() * 99999);
      delete data.icon;
      delete data.subfield;
      if (data.type == "title") {
        delete data.label;
        this.form[data.prop] = data.value;
      }
      this.$set(this.data.column, newIndex, data);
      this.handleSelectWidget(newIndex);

      this.$emit("change");
    },
    handleWidgetDelete(index) {
      console.log(index);
      if (this.data.column.length - 1 === index) {
        if (index === 0) this.selectWidget = {};
        else this.handleSelectWidget(index - 1);
      } else this.handleSelectWidget(index + 1);

      this.$nextTick(() => {
        this.data.column.splice(index, 1);
        this.$emit("change");
      });
    },
    handleWidgetClone(index) {
      let cloneData = this.deepClone(this.data.column[index]);
      cloneData.prop = "a" + Date.now() + Math.ceil(Math.random() * 99999);
      this.data.column.splice(index, 0, cloneData);
      this.$nextTick(() => {
        this.handleSelectWidget(index + 1);
        this.$emit("change");
      });
    },
  },
  watch: {
    "columnObj.type"() {
      if (this.columnObj.type === "select") {
        this.columnObj.display = true;
      } else {
        this.columnObj.display = false;
      }
    },
    select(val) {
      this.selectWidget = val;
    },
    selectWidget: {
      handler(val) {
        this.$emit("update:select", val);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
// /deep/ .el-date-editor.el-input,
// .el-date-editor.el-input__inner,
// .el-select {
//   width: 100%;
// }
/deep/.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: auto !important;
}
</style>
