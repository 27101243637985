import store from '../store';
// 指令集文件
import Vue from 'vue'
const directives = {
  // permission: {
  //   inserted(el , binding){
  //     let bindValue = binding.value;
  //     console.log(bindValue);
  //     let code = typeof bindValue === 'object' ? (bindValue.key || bindValue.code || bindValue.btnKey || bindValue.btnCode || '') : (bindValue || '');
  //     if(!store.getters.permission[code]){
  //       // 开启后将对按钮(el-button)进行权限控制
  //       // el.parentNode.removeChild(el);
  //     }
  //   }
  // }
}
export const directive = {
  install(Vue){
    Object.keys(directives).forEach(
      keyName => Vue.directive(keyName , directives[keyName])
    )
  }
}

export const clearDirective = (name , component , deep) => {
  if(component){
    if(component.directives && component.directives[name]){
      delete component.directives[name];
    }
    if(deep && component.components){
      Object.values(component.components).forEach(
        item => {
          clearDirective(name , item , deep);
        }
      )
    }
  }
}

// 拖拽指令
export const drag = Vue.directive('drag',{
  bind:function (el, binding) {
      let oDiv = el;   //当前元素
      oDiv.onmousedown = function (e) {
          let className = oDiv.getAttribute('class')
          if(className.indexOf('fixed')>-1){
              return
          }
          //鼠标按下，计算当前元素距离可视区的距离
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;
          document.onmousemove = function (e) {
              //通过事件委托，计算移动的距离 
              let l = e.clientX - disX;
              let t = e.clientY - disY;
              //移动当前元素  
              oDiv.style.left = l + 'px';
              oDiv.style.top = t + 'px';
              //将此时的位置传出去
              if(binding.value){
                  binding.value({x:e.pageX,y:e.pageY}, el) 
              }
          };
          document.onmouseup = function () {
              document.onmousemove = null;
              document.onmouseup = null;
          };
      };
}
})
// 阻止拖拽
export const stopdrag = Vue.directive('stopdrag',{
  bind:function (el, binding) {
      let element = el;
      element.onmousedown = function(e) {
          e.stopPropagation()
      }
}
})
// 滚动加载
export const loadmore = Vue.directive('loadmore', {
  bind(el, binding) {
      const selectWrap = el.querySelector('.el-scrollbar__wrap')
      selectWrap.addEventListener('scroll', function() {
          // let sign = 100
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
          if (scrollDistance <= 20) {
              binding.value()
          }
      })
  }
})