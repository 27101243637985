import request from '@/router/axios';
const prefix = '/api/jida-data-asset/label/';

export const getLabelTree = (params) => {
  return request({
    method: 'get',
    params: params,
    url: prefix + 'tree'
  })
}

export const saveLabel = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'save'
  })
}
export const updateLabel = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'update'
  })
}
export const removeLabel = (data) => {
  return request({
    method: 'post',
    params: data,
    url: prefix + 'remove'
  })
}