import request from "@/router/axios";

export const getList = (current, size, params) => {
  return request({
    url: "/api/park-spcod/sportappoint/list",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getPage = (current, size, params) => {
  return request({
    url: "/api/park-spcod/sportappoint/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

//场地预约列表{groundId:0}
export const listGroundAppoint = (pageNo, pageSize, params) => {
  return request({
    url: `/api/park-spcod/sportappoint/listgroundappoint`,
    method: "get",
    params: {
      ...params,
      current: pageNo,
      size: pageSize,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: "/api/park-spcod/sportappoint/detail/" + id,
    method: "get",
    params: {},
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/park-spcod/sportappoint/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/park-spcod/sportappoint/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/park-spcod/sportappoint/submit",
    method: "post",
    data: row,
  });
};

export const appointStatis = (yearMonth) => {
  return request({
    url: "/api/park-spcod/sportappoint/appointstatis/" + yearMonth,
    method: "get",
    data: {},
  });
};

//获得某一天全部预订
//yearMonthDayL:2020-01-01
export const appointInfoList = (yearMonthDay) => {
  return request({
    url: "/api/park-spcod/sportappoint/appointinfoList/" + yearMonthDay,
    method: "get",
    data: {},
  });
};

//取消预约
export const cancelAppoint = (id) => {
  return request({
    url: "/api/park-spcod/sportappoint/calcelappoint/" + id,
    method: "get",
    data: {},
  });
};

// 场地预留
export const submitPreAppoint = (params) => {
  return request({
    url: `/api/park-spcod/sportappoint/submitpreappt`,
    method: "post",
    data: {
      ...params,
    },
  });
};

// 取消场地预留
export const cancelPreAppoint = (id) => {
  return request({
    url: `/api/park-spcod/sportappoint/cancelpreappt/` + id,
    method: "post",
  });
};

// 预约
export const addAppoint = (params) => {
  return request({
    url: `/api/park-spcod/mobsports/submitappt`,
    method: "post",
    data: {
      ...params,
    },
  });
};
export const getRandomId = () => {
  return request({
    url: "/api/park-spcod/mobsports/randomId",
    method: "get",
  });
};
//会员卡spcod统一支付
export const mobAccCardPay = (orderSn, orderType) => {
  return request({
    url: `/api/park-spcod/mobspcodaccount/mobaccard/` + orderSn,
    method: "get",
    params: {
      orderType: orderType,
    },
  });
};
// 根据业务ID查询统一支付订单详情
export const queryOrderDetail = (bizId, bizType) => {
  return request({
    url: "/api/park-spcod/spcodpay/queryorder/" + bizId,
    method: "get",
    params: {
      bizType: bizType,
    },
  });
};
/**
 * 退款取消
 */
export const mobAccCardCancel = (appointId, bizType) => {
  return request({
    url: `/api/park-spcod/mobsports/accardrefund/` + appointId,
    method: "get",
    params: {
      bizType: bizType,
    },
  });
};

//动态计算价格
export const calAppointPrice = (params) => {
  return request({
    url: `/api/park-spcod/mobsports/calapptprice`,
    method: "post",
    data: {
      ...params,
    },
  });
};
//动态计算价格
export const paymentDiscount = (params) => {
  return request({
    url: `/api/park-spcod/mobsports/payment-discount`,
    method: "post",
    data: {
      ...params,
    },
  });
};

//动态计算价格
export const reverseDiscount = (params) => {
  return request({
    url: `/api/park-spcod/mobsports/reverse-discount`,
    method: "post",
    data: {
      ...params,
    },
  });
};
