<template>
  <div class="my-process-designer__header">
    <slot name="control-header"></slot>
    <template v-if="!$slots['control-header']">
      <el-button
        @click="back"
        :size="headerButtonSize"
        type="text"
        icon="el-icon-arrow-left"
        >返回</el-button
      >
      <el-divider direction="vertical"></el-divider>

      <template v-if="!preview">
        <el-tooltip effect="light" content="撤销">
          <el-button
            type="text"
            :size="headerButtonSize"
            :disabled="!revocable"
            icon="el-icon-refresh-left"
            @click="processUndo()"
          />
        </el-tooltip>
        <el-tooltip effect="light" content="恢复">
          <el-button
            type="text"
            :size="headerButtonSize"
            :disabled="!recoverable"
            icon="el-icon-refresh-right"
            @click="processRedo()"
          />
        </el-tooltip>
        <el-tooltip effect="light" content="重新绘制">
          <el-button
            type="text"
            :size="headerButtonSize"
            icon="el-icon-refresh"
            @click="processRestart"
          />
        </el-tooltip>
        <el-divider direction="vertical"></el-divider>
      </template>

      <el-tooltip effect="light" content="缩小视图">
        <el-button
          type="text"
          :size="headerButtonSize"
          :disabled="defaultZoom < 0.2"
          icon="el-icon-zoom-out"
          @click="processZoomOut()"
        />
      </el-tooltip>
      <el-button type="text" :size="headerButtonSize">{{
        Math.floor(this.defaultZoom * 10 * 10) + "%"
      }}</el-button>
      <el-tooltip effect="light" content="放大视图">
        <el-button
          type="text"
          :size="headerButtonSize"
          :disabled="defaultZoom > 4"
          icon="el-icon-zoom-in"
          @click="processZoomIn()"
        />
      </el-tooltip>
      <el-tooltip effect="light" content="自适应画布">
        <el-button
          type="text"
          :size="headerButtonSize"
          icon="el-icon-c-scale-to-original"
          @click="processReZoom()"
        /> </el-tooltip
      ><el-divider direction="vertical"></el-divider>
      <el-tooltip effect="light">
        <div slot="content">
          <el-button
            :size="headerButtonSize"
            type="text"
            @click="previewProcessXML"
            >预览XML</el-button
          >
          <br />
          <el-button
            :size="headerButtonSize"
            type="text"
            @click="previewProcessJson"
            >预览JSON</el-button
          >
        </div>
        <el-button :size="headerButtonSize" type="text" icon="el-icon-view"
          >预览</el-button
        >
      </el-tooltip>
      <el-button
        :size="headerButtonSize"
        type="text"
        icon="el-icon-cpu"
        @click="processSimulation"
      >
        {{ this.simulationStatus ? "退出模拟" : "开启模拟" }}
      </el-button>
      <el-divider direction="vertical"></el-divider>

      <el-tooltip effect="light" content="抓手工具">
        <el-button
          type="text"
          :size="headerButtonSize"
          icon="bpmn-icon-hand-tool"
          @click="(ev) => ToolsHandle(ev , 'handTool' , 'activateHand')"
        />
      </el-tooltip>

      <el-tooltip effect="light" content="套索工具">
        <el-button
          type="text"
          :size="headerButtonSize"
          icon="bpmn-icon-lasso-tool"
          @click="(ev) => ToolsHandle(ev , 'lassoTool' , 'activateSelection')"
        />
      </el-tooltip>

      <el-tooltip effect="light" content="激活创建/删除空间工具">
        <el-button
          type="text"
          :size="headerButtonSize"
          icon="bpmn-icon-space-tool"
          @click="(ev) => ToolsHandle(ev , 'spaceTool' , 'activateSelection')"
        />
      </el-tooltip>

      <el-tooltip effect="light" content="全局连接工具">
        <el-button
          type="text"
          :size="headerButtonSize"
          icon="bpmn-icon-connection-multi"
          @click="(ev) => ToolsHandle(ev , 'globalConnect' , 'toggle')"
        />
      </el-tooltip>

      <!-- 右侧 -->
      <div class="pull-right">
        <el-button
          v-if="!preview"
          :size="headerButtonSize"
          @click="$emit('save')"
          type="text"
          icon="el-icon-finished"
          >保存</el-button
        >
        <slot name="right"></slot>
      </div>
      <!-- <el-button-group key="file-control">
        <el-button :size="headerButtonSize" :type="headerButtonType" icon="el-icon-folder-opened" @click="$refs.refFile.click()">打开文件</el-button>
        <el-tooltip effect="light">
          <div slot="content">
            <el-button :size="headerButtonSize" type="text" @click="downloadProcessAsXml()">下载为XML文件</el-button>
            <br />
            <el-button :size="headerButtonSize" type="text" @click="downloadProcessAsSvg()">下载为SVG文件</el-button>
            <br />
            <el-button :size="headerButtonSize" type="text" @click="downloadProcessAsBpmn()">下载为BPMN文件</el-button>
          </div>
          <el-button :size="headerButtonSize" :type="headerButtonType" icon="el-icon-download">下载文件</el-button>
        </el-tooltip>
        <el-tooltip effect="light">
          <div slot="content">
            <el-button :size="headerButtonSize" type="text" @click="previewProcessXML">预览XML</el-button>
            <br />
            <el-button :size="headerButtonSize" type="text" @click="previewProcessJson">预览JSON</el-button>
          </div>
          <el-button :size="headerButtonSize" :type="headerButtonType" icon="el-icon-view">预览</el-button>
        </el-tooltip>
        <el-tooltip v-if="simulation" effect="light" :content="this.simulationStatus ? '退出模拟' : '开启模拟'">
          <el-button :size="headerButtonSize" :type="headerButtonType" icon="el-icon-cpu" @click="processSimulation">
            模拟
          </el-button>
        </el-tooltip>
      </el-button-group> -->
      <!-- <el-button-group key="align-control">
        <el-tooltip effect="light" content="向左对齐">
          <el-button :size="headerButtonSize" class="align align-left" icon="el-icon-s-data" @click="elementsAlign('left')" />
        </el-tooltip>
        <el-tooltip effect="light" content="向右对齐">
          <el-button :size="headerButtonSize" class="align align-right" icon="el-icon-s-data" @click="elementsAlign('right')" />
        </el-tooltip>
        <el-tooltip effect="light" content="向上对齐">
          <el-button :size="headerButtonSize" class="align align-top" icon="el-icon-s-data" @click="elementsAlign('top')" />
        </el-tooltip>
        <el-tooltip effect="light" content="向下对齐">
          <el-button :size="headerButtonSize" class="align align-bottom" icon="el-icon-s-data" @click="elementsAlign('bottom')" />
        </el-tooltip>
        <el-tooltip effect="light" content="水平居中">
          <el-button :size="headerButtonSize" class="align align-center" icon="el-icon-s-data" @click="elementsAlign('center')" />
        </el-tooltip>
        <el-tooltip effect="light" content="垂直居中">
          <el-button :size="headerButtonSize" class="align align-middle" icon="el-icon-s-data" @click="elementsAlign('middle')" />
        </el-tooltip>
      </el-button-group> -->
      <!-- <el-button-group key="scale-control">
        <el-tooltip effect="light" content="缩小视图">
          <el-button :size="headerButtonSize" :disabled="defaultZoom < 0.2" icon="el-icon-zoom-out" @click="processZoomOut()" />
        </el-tooltip>
        <el-button :size="headerButtonSize">{{ Math.floor(this.defaultZoom * 10 * 10) + "%" }}</el-button>
        <el-tooltip effect="light" content="放大视图">
          <el-button :size="headerButtonSize" :disabled="defaultZoom > 4" icon="el-icon-zoom-in" @click="processZoomIn()" />
        </el-tooltip>
        <el-tooltip effect="light" content="重置视图并居中">
          <el-button :size="headerButtonSize" icon="el-icon-c-scale-to-original" @click="processReZoom()" />
        </el-tooltip>
      </el-button-group> -->
      <!-- <el-button-group key="stack-control">
        <el-tooltip effect="light" content="撤销">
          <el-button :size="headerButtonSize" :disabled="!revocable" icon="el-icon-refresh-left" @click="processUndo()" />
        </el-tooltip>
        <el-tooltip effect="light" content="恢复">
          <el-button :size="headerButtonSize" :disabled="!recoverable" icon="el-icon-refresh-right" @click="processRedo()" />
        </el-tooltip>
        <el-tooltip effect="light" content="重新绘制">
          <el-button :size="headerButtonSize" icon="el-icon-refresh" @click="processRestart" />
        </el-tooltip>
      </el-button-group> -->
    </template>
    <!-- 用于打开本地文件-->
    <input
      type="file"
      id="files"
      ref="refFile"
      style="display: none"
      accept=".xml, .bpmn"
      @change="importLocalFile"
    />

    <el-dialog
      title="预览"
      width="60%"
      :visible.sync="previewModelVisible"
      append-to-body
      destroy-on-close
    >
      <highlightjs :language="previewType" :code="previewResult" />
    </el-dialog>
  </div>
</template>
<script>
// 引入json转换与高亮
import X2JS from "x2js";

export default {
  inject: ["BpmnMain"],
  data() {
    return {
      previewResult: "",
      previewType: "xml",
      previewModelVisible: false,
      simulationStatus: false,
      defaultZoom: 1,
      revocable: false,
      recoverable: false,
      bpmnModeler: null,
      designer: null,
    };
  },
  props: {
    headerButtonSize: {
      type: String,
      default: "small",
      validator: (value) =>
        ["default", "medium", "small", "mini"].indexOf(value) !== -1,
    },
    headerButtonType: {
      type: String,
      default: "primary",
      validator: (value) =>
        ["default", "primary", "success", "warning", "danger", "info"].indexOf(
          value
        ) !== -1,
    },
    simulation: {
      type: Boolean,
      default: true,
    },
    preview: Boolean,
  },
  mounted() {
    this.BpmnMain.ready().then((modeler) => {
      if (modeler) {
        this.setDesigner(this.BpmnMain.$refs.processDesigner);
        this.setModeler(this.BpmnMain.modeler);
        console.log(this.bpmnModeler.get('paletteProvider'));
      }
    });
    // this.$nextTick(() => {
    //   this.setDesigner(this.BpmnMain.$refs.processDesigner);
    //   this.setModeler(this.BpmnMain.modeler);
    //   console.log(this.bpmnModeler.get('paletteProvider'));
    // });
  },
  methods: {
    back() {
      this.$emit("back");
    },
    setModeler(modeler) {
      this.bpmnModeler = modeler;
    },
    setDesigner(designer) {
      this.designer = designer;
    },
    ToolsHandle(ev , modelName , actionName){
      // console.log(this.bpmnModeler.get('paletteProvider')[type].activateHand);
      console.log(ev);
      this.bpmnModeler.get(modelName)[actionName](ev);
    },
    previewProcessXML() {
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        this.previewResult = xml;
        this.previewType = "xml";
        this.previewModelVisible = true;
      });
    },
    previewProcessJson() {
      const newConvert = new X2JS();
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        const { definitions } = newConvert.xml2js(xml);
        if (definitions) {
          this.previewResult = JSON.stringify(definitions, null, 4);
        } else {
          this.previewResult = "";
        }

        this.previewType = "json";
        this.previewModelVisible = true;
      });
    },
    processSimulation() {
      this.simulationStatus = !this.simulationStatus;
      this.simulation && this.bpmnModeler.get("toggleMode").toggleMode();
    },
    processZoomOut(zoomStep = 0.1) {
      let newZoom = Math.floor(this.defaultZoom * 100 - zoomStep * 100) / 100;
      if (newZoom < 0.2) {
        throw new Error(
          "[Process Designer Warn ]: The zoom ratio cannot be less than 0.2"
        );
      }
      this.defaultZoom = newZoom;
      this.bpmnModeler.get("canvas").zoom(this.defaultZoom);
    },
    processZoomIn(zoomStep = 0.1) {
      let newZoom = Math.floor(this.defaultZoom * 100 + zoomStep * 100) / 100;
      if (newZoom > 4) {
        throw new Error(
          "[Process Designer Warn ]: The zoom ratio cannot be greater than 4"
        );
      }
      this.defaultZoom = newZoom;
      this.bpmnModeler.get("canvas").zoom(this.defaultZoom);
    },
    processReZoom() {
      this.defaultZoom = 1;
      this.bpmnModeler.get("canvas").zoom("fit-viewport", "auto");
    },
    processRedo() {
      this.bpmnModeler.get("commandStack").redo();
    },
    processUndo() {
      this.bpmnModeler.get("commandStack").undo();
    },
    processRestart() {
      this.recoverable = false;
      this.revocable = false;
      this.designer.createNewDiagram();
    },
    elementsAlign(align) {
      const Align = this.bpmnModeler.get("alignElements");
      const Selection = this.bpmnModeler.get("selection");
      const SelectedElements = Selection.get();
      if (!SelectedElements || SelectedElements.length <= 1) {
        this.$message.warning("请按住 Ctrl 键选择多个元素对齐");
        return;
      }
      this.$confirm("自动对齐可能造成图形变形，是否继续？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => Align.trigger(SelectedElements, align));
    },
    // 加载本地文件
    importLocalFile() {
      const that = this;
      const file = this.$refs.refFile.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        let xmlStr = this.result;
        that.createNewDiagram(xmlStr);
      };
    },
    downloadProcessAsXml() {
      this.downloadProcess("xml");
    },
    downloadProcessAsBpmn() {
      this.downloadProcess("bpmn");
    },
    downloadProcessAsSvg() {
      this.downloadProcess("svg");
    },
  },
};
</script>
