<template>
    <el-select clearable v-bind="$attrs" :multiple="multiple" class="model-search-select" v-model="val" placeholder="请选择" @clear="search()" @change="modelChange">
        <template v-if="!showEmpty">
            <div class="pad-lr-10 pad-tb-5">
                <div>
                    <el-input class="model-search-select-input" size="small" @input="searchInput()" placeholder="输入关键词搜索" v-model="searchText">
                        <el-button @click="search()" slot="append" icon="el-icon-search"></el-button>
                        <!-- <i
              @click="search()"
              slot="suffix"
              class="el-input__icon el-icon-search"
            ></i> -->
                    </el-input>
                </div>
                <slot name="search"></slot>
            </div>
            <el-option v-for="(item, index) in datalist" :key="item[valueKey]" :label="getLabel(item)" :value="(dataType === 'string' ? '' + item[valueKey] : item[valueKey])" :disabled="disabledList.includes(item[valueKey])">
                <template v-if="$scopedSlots.default">
                    <slot v-bind="{ item, index }"></slot>
                </template>
                <!-- <span>{{disabledList[0]}}-{{disabledList.includes('1592459790109478913')}}-{{item[valueKey]}}</span> -->
            </el-option>
            <div v-if="!notPage && page.maxPage > 1" class="model-search-select-page">
                <el-pagination small class="text-center" :current-page.sync="page.current" @current-change="currentPagechange" layout="prev, pager, next" :total="page.total">
                </el-pagination>
            </div>
        </template>
        <template slot="empty">
            <div>
                <template v-if="loading">
                    <template v-if="$scopedSlots.loading">
                        <solt name="loading"></solt>
                    </template>
                    <template v-else>
                        <div class="text-center pad-20">正在加载数据</div>
                    </template>
                </template>
            </div>
            <template v-if="showEmpty">
                <div class="pad-lr-10 pad-tb-5">
                    <div>
                        <el-input class="model-search-select-input" size="small" @input="searchInput()" placeholder="输入关键词搜索" v-model="searchText">
                            <el-button @click="search()" slot="append" icon="el-icon-search"></el-button>
                            <!-- <i
                @click="search()"
                slot="suffix"
                class="el-input__icon el-icon-search"
              ></i> -->
                        </el-input>
                    </div>
                    <slot name="search"></slot>
                </div>
                <div>
                    <template v-if="$scopedSlots.empty">
                        <slot name="empty"></slot>
                    </template>
                    <template v-else>
                        <div class="text-center pad-20">{{emptyText}}</div>
                    </template>
                </div>
            </template>
        </template>
    </el-select>
</template>

<script>
import * as _ from 'lodash';
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import { searchList } from '@/api/system/user';

export default {
    data() {
        return {
            searchText: '',
            datalist: [],
            val: this.multiple ? [] : null,
            loading: false,
            page: {
                total: 0,
                current: 1,
                size: 10,
                maxPage: 1,
            },
            showEmpty: true,
            fillModelCeche: null,
            dataCache: {},
        };
    },
    watch: {
        params: {
            deep: true,
            handler(oldVal, newVal) {
                let oldStr = '',
                    newStr = '';
                try {
                    oldStr = JSON.stringify(oldVal);
                    newStr = JSON.stringify(newVal);
                    if (oldStr !== newStr) {
                        this.searchData();
                    }
                } catch (error) {}
            },
        },
        data: {
            immediate: true,
            handler(oldVal, newVal) {
                if (this.data !== undefined) {
                    this.datalist = this.data;
                    if (this.datalist.length) {
                        this.showEmpty = false;
                    } else {
                        this.showEmpty = true;
                    }
                }
            },
        },
        val() {
            let val = this.getValue();
            if (val !== this.value) {
                this.$nextTick(() => {
                    this.$emit('input', val);
                });
            }
        },
        value() {
            let val = this.getValue();
            if (this.labelKey === 'code') {
                console.log(this.value, val);
            }
            if (this.value !== val) {
                if (this.value && val !== this.value) {
                    this.initModel();
                } else {
                    this.setValue();
                    this.searchData();
                }
            }
        },
    },
    props: {
        value: [String, Number, Array],
        emptyText: {
            type: String,
            default: '暂无数据',
        },
        labelKey: {
            type: String,
            default: 'name',
        },
        valueKey: {
            type: String,
            default: 'id',
        },
        searchFieldName: {
            type: String,
            default: '',
        },
        like: {
            type: Boolean,
            default: true,
        },
        model: {
            type: String,
            default: '',
        },
        params: {
            type: Object,
            default() {
                return {};
            },
        },
        disabledList: {
            type: Array,
            default() {
                return [];
            },
        },
        fillModel: {
            type: Object,
            default() {
                return null;
            },
        },
        fills: {
            type: Array,
            default() {
                return [];
            },
        },
        format: {
            type: String,
            default: '',
        },
        notInitLoadData: {
            type: Boolean,
            default: false,
        },
        getData: {
            type: Function,
            default: null,
        },
        notPage: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            default() {
                return undefined;
            },
        },
        constParams: {
            type: Object,
            default() {
                return {};
            },
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        valueType: {
            type: String,
            default: '',
        },
        dataType: {
            type: String,
            default: ''
        },
        separator: {
            type: String,
            default: ',',
        },
    },
    created() {
        // this.val = this.value;
        if (this.notInitLoadData === false) {
            if ((this.value ?? '') === '') {
                this.searchData();
            }
            if (this.value && !Array.isArray(this.value)) {
                this.initModel();
            }
        }
        if (this.fillModel) {
            this.fillModelCeche = this.deepClone(this.fillModel);
        }
    },
    methods: {
        setValue() {
            if (this.multiple && typeof this.value === 'string') {
                this.val = this.value.split(this.separator);
            } else {
                this.val = this.value;
            }
        },
        getValue() {
            if (
                this.multiple &&
                Array.isArray(this.val) &&
                (typeof this.value === 'string' || this.valueType === 'string')
            ) {
                return this.val.join(this.separator);
            } else {
                return this.val;
            }
        },
        getLabel(item) {
            let format = this.format || `{${this.labelKey}}`;
            return format.replace(/\{(.*?)\}/g, ($1, $2) => {
                let str = $2.split('||');
                return item[str[0].trim()] || str[1] || '';
            });
        },
        initModel() {
            if ((this.value ?? '') !== '') {
                this.page.current = 1;
                this.searchData(this.value, this.valueKey, false).then(res => {
                    if (this.datalist && this.datalist.length) {
                        this.setValue();
                    } else {
                        this.searchData();
                    }
                    if (this.fillModel) {
                        this.updateFillModel();
                    }
                });
            }
        },
        currentPagechange() {
            this.searchData(this.searchText);
        },
        searchInput: _.debounce(
            function () {
                this.search();
            },
            500,
            {
                leading: false,
                trailing: true,
            },
        ),
        search() {
            this.page.current = 1;
            this.searchData(this.searchText);
        },
        searchData(queryStr, searchFieldName, like) {
            return new Promise((resolve, reject) => {
                let isQuery = true;
                if (this.params && typeof this.params === 'object') {
                    Object.keys(this.params).forEach(keyName => {
                        if ((this.params[keyName] ?? '') === '') {
                            isQuery = false;
                        }
                    });
                }
                if (isQuery) {
                    this.loading = true;
                    this.showEmpty = false;
                    if (this.getData) {
                        return this.customSearch(queryStr, searchFieldName, like).then(resolve);
                    } else {
                        if (this.model) {
                            return this.searchModel(queryStr, searchFieldName, like).then(resolve);
                        } else {
                            return this.searchUser(queryStr, searchFieldName, like).then(resolve);
                        }
                    }
                } else {
                    this.showEmpty = true;
                    this.datalist = [];
                    resolve();
                }
            });
        },
        customSearch(queryStr, searchFieldName, like) {
            let body = {};
            let fieldName = searchFieldName || this.searchFieldName || this.labelKey;
            if (queryStr) {
                body[fieldName || 'name'] = queryStr;
            }
            return new Promise((resolve, reject) => {
                this.getData(
                    {
                        params: {
                            ...body,
                            ...this.params,
                            ...this.constParams,
                        },
                        current: this.page.current,
                        size: this.page.size,
                    },
                    res => {
                        this.requestHandle(res);
                        resolve(res);
                    },
                );
            });
        },
        searchModel(queryStr, searchFieldName, like) {
            return new Promise(resolve => {
                let querys = [],
                    datalist = [],
                    len = 0;
                let handle = query => {
                    let wheres = [];
                    let fieldName = searchFieldName || this.searchFieldName || this.labelKey;
                    if (like === undefined) {
                        like = this.like;
                    }
                    if (query && fieldName) {
                        if (like) {
                            wheres.push({ field: fieldName, op: 'LIKE', value: queryStr });
                        } else {
                            wheres.push({ field: fieldName, value: query });
                        }
                    }
                    if (this.params.wheres) {
                        wheres = [...this.params.wheres, ...wheres];
                    } else {
                        Object.keys(this.params).forEach(keyName => {
                            let item = this.params[keyName];
                            if (typeof item !== 'object') {
                                item = {
                                    name: keyName,
                                    value: item,
                                    like: false,
                                };
                            }
                            if (item.like) {
                                wheres.push({ field: keyName, op: 'LIKE', value: item.value });
                            } else {
                                wheres.push({ field: keyName, value: item.value });
                            }
                        });
                    }
                    return getMetaCommonList(`${this.model.indexOf('.') > -1 ? '' : 'wisdom_park.'}${this.model}`, {
                        current: this.page.current,
                        size: this.page.size,
                        wheres: wheres,
                        ...this.constParams,
                    }).then(res => {
                        if (querys.length) {
                            len++;
                            datalist = [...datalist, ...res.data.data.records];
                            this.page.current = res.data.data.current;
                            this.page.total = res.data.data.total;
                            if (len === querys.length) {
                                console.log(123123);
                                this.requestHandle(datalist);
                                resolve();
                            }
                        } else {
                            this.requestHandle(res);
                            resolve();
                        }
                    });
                };
                if (queryStr) {
                    if (Array.isArray(queryStr) || this.multiple) {
                        querys = queryStr.split(this.separator);
                    } else {
                        querys = [queryStr];
                    }
                }
                querys.forEach(item => {
                    handle(item);
                });
                if (!querys.length) {
                    handle();
                }
            });
        },
        searchUser(queryStr, searchFieldName) {
            if (this.multiple && searchFieldName === this.valueKey) {
                return new Promise(resolve => {
                    let val = this.value.split(this.separator);
                    let data = [],
                        len = 0;
                    val.forEach(item => {
                        let _body = {};
                        _body[searchFieldName] = item;
                        searchList({
                            ..._body,
                            // tenantId: this.tenantId,
                            ...this.params,
                            ...this.constParams,
                            current: this.page.current,
                            size: this.page.size,
                        }).then(res => {
                            data = [...data, ...res.data.data.records];
                            len++;
                            if (len >= val.length) {
                                this.requestHandle(data);
                                resolve();
                            }
                        });
                    });
                });
            } else {
                let body = {};
                let fieldName = searchFieldName || this.searchFieldName || this.labelKey;
                if (queryStr) {
                    body[fieldName || 'name'] = queryStr;
                }
                return searchList({
                    ...body,
                    // tenantId: this.tenantId,
                    ...this.params,
                    ...this.constParams,
                    current: this.page.current,
                    size: this.page.size,
                }).then(res => {
                    return this.requestHandle(res);
                });
            }
        },
        requestHandle(res) {
            this.loading = false;
            // this.data = res.data.data.records || [];
            ['', 'data', 'data', 'records'].reduce((prev, current) => {
                let data = prev && current && prev[current] ? prev[current] : prev;
                if (data && Array.isArray(data)) {
                    this.datalist = data;
                }
                return data || null;
            }, res);
            this.datalist.forEach(item => {
                this.dataCache[item[this.valueKey]] = item;
            });
            if (this.datalist.length === 0) {
                this.showEmpty = true;
            }
            // if (this.notPage !== true && !Array.isArray(res)) {
            if (this.notPage !== true) {
                ['', 'data', 'data'].reduce((prev, current) => {
                    let data = prev && current && prev[current] ? prev[current] : prev;
                    if (data && data['total']) {
                        this.page.total = data['total'];
                    }
                    return data || null;
                }, res);
                // if (this.page.total === 0) {
                //   this.showEmpty = true;
                // }
                this.page.maxPage = Math.ceil(this.page.total / this.page.size);
            }
            return res;
        },
        updateFillModel() {
            // let current = this.val
            //   ? this.datalist.find((item) => item[this.valueKey] === this.val)
            //   : null;
            if (this.fillModel && this.fills && this.fills.length) {
                let current = [],
                    val = this.val;
                if (this.val && !Array.isArray(this.val)) {
                    val = [this.val];
                }
                if (val && Array.isArray(val)) {
                    val.forEach(item => {
                        if (this.dataCache[item]) {
                            current.push(this.dataCache[item]);
                        }
                    });
                    this.fills.forEach(item => {
                        if (current) {
                            this.fillModel[item.target] = current
                                .map(itemData => itemData[item.source])
                                .join(this.separator);
                        } else {
                            this.fillModel[item.target] = '';
                        }
                    });
                    this.$emit('update:fillModel', this.fillModel);
                }
            }
        },
        modelChange() {
            this.$nextTick(() => {
                this.$emit('change', {
                    value: this.getValue(),
                    data: Object.values(this.dataCache),
                    labelKey: this.labelKey,
                    valueKey: this.valueKey,
                });
                this.updateFillModel();
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.model-search-select-page {
    position: sticky;
    bottom: 0px;
    padding: 5px 0;
    background: #fff;
}
.model-search-select-input {
    /deep/.el-input__validateIcon {
        display: none;
    }
}
</style>
