import request from '@/router/axios';

const prefix = '/api/metadata-proxy/api/meta/v2/';

export const getModelItemRule = (item) => {
  let rules = [];
  if(!!item.isNotNull){
    rules.push({ required: true, message: `必填项不能为空`, trigger: 'blur' })
  }
  if(item.dataLength){
    rules.push({ max: item.dataLength, message: `只能输入${item.dataLength}个字符`, trigger: 'blur' })
  }
  return rules;
}

export const createTableColumnRule = (column , rule) => {
  column.forEach(
    item => {
      if(rule[column.prop]){
        item['rules'] = rule[column.prop];
      }
    }
  )
  return column;
}

export const queryByModelCode = (params) => {
  return request({
    url: prefix + 'metadatastandard/queryByModelCode',
    method: 'get',
    params: params
  })
}

export const getModelRule = (modelCode) => {
  return new Promise((resolve , reject) => {
    let ruleModel = {};
    queryByModelCode({
      modelCode
    }).then(
      res => {
        if(res && res.data && res.data.data){
          Object.keys(res.data.data).forEach(
            keyName => {
              ruleModel[keyName] = getModelItemRule(res.data.data[keyName]);
            }
          )
        }
        resolve(ruleModel)
      }
    ).catch(reject)
  })
}

export default getModelRule;