<template>
  <el-row>
    <el-col :span="24">
      <el-divider content-position="left"
        ><span class="is-required-text">选择租赁位置</span></el-divider
      >
    </el-col>
    <el-col class="mar-t-10" :span="24">
      <el-table :data="selectRoomList" size="small" border>
        <el-table-column type="index" width="50">
          <template slot="header">
            <el-button
              size="mini"
              @click="
                selectRoomList.push({
                  building: '',
                  buildingName: '',
                  floor: '',
                  floorName: '',
                  room: [],
                  roomSelected: [],
                  floorData: [],
                  roomData: [],
                })
              "
              type="primary"
              icon="el-icon-plus"
              circle
            ></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="
                selectRoomList.splice(scope.$index, 1);
                calculateRoom();
              "
              type="danger"
              icon="el-icon-minus"
              circle
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="楼宇" width="150px">
          <template slot-scope="scope">
            <el-select
              class="dis-b"
              size="small"
              filterable
              v-model="scope.row.building"
              @change="
                roomSelectChange(scope.row, 'building');
                getFloor(scope.$index);
              "
            >
              <el-option
                v-for="item of dictData.building"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="楼层" width="150px">
          <template slot-scope="scope">
            <el-select
              class="dis-b"
              size="small"
              filterable
              v-model="scope.row.floor"
              @change="
                roomSelectChange(scope.row, 'floor');
                getRoom(scope.$index);
              "
            >
              <el-option
                v-for="item of scope.row.floorData"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="房间">
          <template slot-scope="scope">
            <el-checkbox-group
              v-model="scope.row.room"
              @change="roomSelectChange(scope.row, 'room')"
            >
              <el-checkbox
                v-for="item of scope.row.roomData"
                :label="item.id"
                :key="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div style="position: static" class="el-form-item__error">
        请选择租赁位置
      </div> -->
    </el-col>
  </el-row>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
} from "@/api/busiMode/metaCommon";
export default {
  components: {},
  data() {
    return {
      selectRoomList: [],
      roomSelected: [],
      dictData: {
        building: [],
      },
    };
  },

  mounted() {
    this.getDictData();
  },
  methods: {
    getDictData() {
      getMetaCommonList(`wisdom_park.wp_building`, {
        current: 1,
        size: 500,
      }).then((res) => {
        if (res.data && res.data.data) {
          this.dictData["building"] = res.data.data.records;
        }
      });
    },
    roomSelectChange(row, name) {
      let data = [];
      if (name === "building") {
        data = this.dictData.building;
      } else {
        data = row[`${name}Data`] || [];
      }
      if (data) {
        if (name !== "room") {
          data.forEach((item) => {
            if (item.id === row[name]) {
              row[`${name}Name`] = item.name;
            }
          });
        } else {
          this.calculateRoom();
        }
      }
    },
    getRoom(index, notClear) {
      return new Promise((resolve, reject) => {
        let building = this.selectRoomList[index].building,
          floor = this.selectRoomList[index].floor;
        if (!notClear) {
          this.selectRoomList[index].roomData = [];
          this.selectRoomList[index].room = [];
        }
        if (building && floor) {
          this.getMetaCommonList("wp_room", null, {
            wheres: [
              { field: "building_id", value: building },
              { field: "floor_id", value: floor },
            ],
          })
            .then((res) => {
              if (res.data && res.data.data) {
                this.selectRoomList[index].roomData = res.data.data.records;
              }
              resolve();
            })
            .catch((e) => {
              reject;
            });
        }
      });
    },
    getFloor(index, notClear) {
      return new Promise((resolve, reject) => {
        let building = this.selectRoomList[index].building;
        if (!notClear) {
          this.selectRoomList[index].floor = "";
          this.selectRoomList[index].floorData = [];
        }
        if (building) {
          this.getMetaCommonList("wp_floor", null, {
            wheres: [{ field: "building_id", value: building }],
            orders: [{ field: "code+1000", sort: "DESC" }],
          })
            .then((res) => {
              if (res.data && res.data.data) {
                this.selectRoomList[index].floorData =
                  res.data.data.records.map((item) => {
                    return {
                      ...item,
                      name: item.name ? item.name : `${item.code}层`,
                    };
                  });
              }
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        }
      });
    },
    getMetaCommonList(code, prop, params) {
      return getMetaCommonList(`wisdom_park.${code}`, {
        current: 1,
        size: 500,
        ...params,
      }).then((res) => {
        if (res.data && res.data.data && prop) {
          this.dictData[prop] = res.data.data.records;
        }
        return res;
      });
    },
    calculateRoom() {
      let roomSelected = [];
      this.selectRoomList.forEach((itemRoom) => {
        itemRoom.room.forEach((itemRoomId) => {
          let currentRoom = itemRoom.roomData.find(
            (item) => item.id === itemRoomId
          );
          let currentBuilding = this.dictData.building.find(
            (item) => item.id === itemRoom.building
          );
          let oldRoomItem =
            this.roomSelected.find((item) => item.room_id === itemRoomId) || {};
          if (currentRoom && currentBuilding) {
            let roomObj = {
              building_id: itemRoom.building,
              building_name: itemRoom.buildingName,
              floor_id: itemRoom.floor,
              floor_name: itemRoom.floorName,
              room_id: itemRoomId,
              room_name: currentRoom.name,
              room_area: currentRoom.room_area || oldRoomItem.room_area || 0,
              rental_unit_price_day:
                currentRoom.rental_unit_price_day ||
                oldRoomItem.rental_unit_price_day ||
                0,
              property_management_fee:
                currentBuilding.property_management_fee ||
                oldRoomItem.property_management_fee ||
                0,
              amount: 0,
            };
            roomSelected.push(roomObj);
          }
        });
      });
      this.roomSelected = roomSelected;
      console.log(this.roomSelected);
      //  this.calculateAmount();
    },
  },
};
</script>
<style lang="scss" scoped></style>
