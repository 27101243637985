<template>
    <div class="pad-15">
        <el-descriptions title="基本信息" :column="4" direction="vertical" :colon="false" :labelStyle="labelStyle">
            <el-descriptions-item label="企业名称">{{
        ruleForm.name
      }}</el-descriptions-item>
            <el-descriptions-item label="统一社会信用代码">{{
        ruleForm.uniform_social_credit_code
      }}</el-descriptions-item>
            <el-descriptions-item label="企业法人">{{
        ruleForm.legal_person
      }}</el-descriptions-item>
            <el-descriptions-item label="企业法人联系方式">{{
        ruleForm.legal_contact
      }}</el-descriptions-item>
            <el-descriptions-item label="企业规模">{{
        ruleForm.firm_size
      }}</el-descriptions-item>
            <el-descriptions-item label="注册资金(万元)">{{
        ruleForm.registered_capital
      }}</el-descriptions-item>
            <el-descriptions-item label="企业类型">{{
        ruleForm.type
      }}</el-descriptions-item>
            <el-descriptions-item label="企业注册详细地址">{{
        ruleForm.registered_address
      }}</el-descriptions-item>
        </el-descriptions>

        <el-tabs v-model="activeName" @tab-click="handleClick" class="mar-t-10">
            <el-tab-pane label="工商信息" name="gsxx">
                <h3>营业执照</h3>
                <div class="pad-10" v-if="imgform.imgUrl.length == 0">暂无照片</div>
                <avue-form :option="option1" v-model="imgform"></avue-form>
                <!-- <el-divider content-position="left">企业LOGO</el-divider> -->
                <h3>企业LOGO</h3>
                <div class="pad-10" v-if="imgform1.imgUrl.length == 0">暂无照片</div>
                <avue-form :option="option2" v-model="imgform1"></avue-form>
            </el-tab-pane>
            <el-tab-pane label="人员信息" name="employee" class="mar-20f">
                <employee v-if="activeName == 'employee'" :companyNode="{ id: currentRow.id }" :IsAdmin="true" :identity="2" :readOnly="true"></employee>
            </el-tab-pane>
            <el-tab-pane label="入驻信息" name="companyInfo" class="mar-20f">
                <companyInfo v-if="activeName == 'companyInfo'" :companyNode="currentRow"></companyInfo>
            </el-tab-pane>
            <el-tab-pane label="合同信息" name="contract" class="mar-20f o-a">
                <contract v-if="activeName == 'contract'" :companyId="currentRow.id + ''"></contract>
            </el-tab-pane>
            <el-tab-pane label="访客信息" name="visitor" class="mar-20f o-a">
                <visitor :currentRow="currentRow" :companyId="currentRow.id + ''" v-if="activeName == 'visitor'"></visitor>
            </el-tab-pane>
            <el-tab-pane label="缴费明细" name="fourth" class="mar-20f o-a"></el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import * as dictbizService from '@/api/system/dictbiz';
import standardService from '@/api/metadata/standard';
import { getMetaCommonInfo, getPicListByIds } from '@/api/busiMode/metaCommon';
import employee from '../employee/employee';
import contract from '../contract/manage';
import visitor from '../visitor/index';
import companyInfo from './companyInfo';
export default {
    components: { employee, contract, visitor, companyInfo },

    data() {
        return {
            entModel: 'wisdom_park.wp_company',
            labelStyle: {
                color: '#999',
                fontSize: '12px',
            },
            enterpriseTypes: [],
            activeName: 'gsxx',
            ruleForm: {},
            imgform: {
                imgUrl: [],
            },
            imgform1: {
                imgUrl: [],
            },
            viewPortHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            option1: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                disabled: true,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            option2: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                disabled: true,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
        };
    },
    computed: {},
    watch: {
        currentRow: {
            immediate: true,
            handler(val) {
                if (val.id) {
                    this.getMetaCommonInfo();
                }
            },
        },
    },
    mounted() {
        this.getDicList();
        this.queryByModelCode();
        if (this.currentRow.id) {
            this.getMetaCommonInfo();
        }
    },
    props: {
        currentRow: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab);
            this.activeName = tab.name;
        },
        queryByModelCode() {
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
            });
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'firmSize',
                })
                .then(res => {
                    this.firmSizeList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'enterpriseType',
                })
                .then(res => {
                    this.enterpriseTypes = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'qualification',
                })
                .then(res => {
                    this.qualifications = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        getMetaCommonInfo() {
            let obj = {
                pk: 'id',
                id: this.currentRow.id,
            };
            getMetaCommonInfo(this.entModel, obj).then(res => {
                if (res.data.code == 200) {
                    this.enterpriseTypes.forEach(e => {
                        if (e.value == res.data.data.type) {
                            res.data.data.type = e.name;
                        }
                    });

                    this.firmSizeList.forEach(e => {
                        console.log(e, res.data.data.firm_size);
                        if (e.value == res.data.data.firm_size) {
                            res.data.data.firm_size = e.name;
                        }
                    });

                    this.ruleForm = res.data.data;
                    this.height = res.data.data.floor_height;
                    if (res.data.data['business_license']) {
                        getPicListByIds(res.data.data['business_license']).then(res => {
                            if (res.data.code == 200) {
                                let arr = [];
                                res.data.data.forEach(pic => {
                                    arr.push({
                                        label: pic.id,
                                        value: pic.link,
                                    });
                                });
                                this.imgform.imgUrl = arr;
                            }
                        });
                    }
                    if (res.data.data['company_logo']) {
                        getPicListByIds(res.data.data['company_logo']).then(res => {
                            if (res.data.code == 200) {
                                let arr = [];
                                res.data.data.forEach(pic => {
                                    arr.push({
                                        label: pic.id,
                                        value: pic.link,
                                    });
                                });
                                this.imgform1.imgUrl = arr;
                            }
                        });
                    }
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
    margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
    left: 0px;
}
/deep/ .el-divider__text {
    padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
    width: 68px;
    height: 68px;
    line-height: 72px;
    vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 68px;
    height: 68px;
}
</style>
