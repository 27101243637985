import request from '@/router/axios';
const prefix = '/api/metadata-proxy/api/meta';
export const getDBList = (obj) => {
  return request({
    //url:'/json/metadata/dbs.json',
    url: prefix + '/api/atlas/v2/search/basic',
    method: 'post',
    data:obj
  })
};
// /api/meta/v2/entity/search/basic
export const getMetaDataList = (obj) => {
  return request({
    url: prefix + '/v2/entity/search/basic',
    method: 'post',
    data:obj
  })
};

export const deleteMeta = (obj) => {
  return request({
    url: prefix + '/v2/entity/delete',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:obj
  })
};
export const saveMetadata = (obj) => {
  return request({
    url: prefix + '/v2/entity/submit',
    method: 'post',
    data:obj
  })
};
export const getGlossary = () => {
  return request({
    url: prefix + '/api/atlas/v2/glossary',
    method: 'get',
    params: {
    }
  })
};
export const getTermsByCategory = (id) => {
  return request({
    url: prefix + '/api/atlas/v2/glossary/category/' + id,
    method: 'get',
    params: {
    }
  })
};
export const getTables = (id) => {
  return request({
    url: prefix + '/api/atlas/v2/glossary/term/' + id,
    method: 'get',
    params: {
    }
  })
};
export const getMetadataDetail = (id) => {
  return request({
    url: prefix +  '/api/atlas/v2/entity/guid/' + id,
    method: 'get',
    params: {
    }
  })
};
export const getAudits = (id) => {
  return request({
    url: prefix + '/api/atlas/v2/entity/' + id + '/audit',
    method: 'get',
    params: {
    }
  })
};

export const getGraphData = (id) => {
  return request({
    url:prefix + '/api/atlas/v2/lineage/' + id,
    method: 'get',
    params: {
    }
  })
};
