<template>
<div class="pad-lr-15">
  <el-form size="small" :model="form" :rules="rules" ref="form">
    <el-form-item label="企业名称" prop="name">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="企业法人" prop="legal_person">
      <el-input v-model="form.legal_person"></el-input>
    </el-form-item>
    <el-form-item label="法人联系方式" prop="legal_contact">
      <el-input v-model="form.legal_contact"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="$emit('close')">取消</el-button>
      <el-button type="primary" :loading="loading" @click="save">保存企业</el-button>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import {
  saveMetaCommon,
} from "@/api/busiMode/metaCommon";
export default {
  data(){
    return {
      loading: false,
      form: {
        name: '',
        legal_person: '',
        legal_contact: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入企业名称', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    save(){
      this.$refs.form.validate(valid => {
        if(valid){
          this.loading = true;
          saveMetaCommon('wisdom_park.wp_company' , {
            values: this.form
          }).then(
            res => {
              if(res && res.data && res.data.data){
                this.$emit('save' , res.data.data);
                this.loading = false;
              }
            }
          ).catch(e => {
            this.loading = false;
          })
        }else{
          return false;
        }
      })
    }
  }
}
</script>