<template>
    <div style="height: 100%">
        <avue-crud class="enter-deatil" :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" @selection-change="selectionChange" @row-del="rowDel"
                   @search-change="searchChange" @row-update="rowUpdate" @row-save="rowSave" @refresh-change="refreshChange" @search-reset="refreshChange" @on-load="onLoad" :rules="ruleModel">
            <template slot="menuLeft" slot-scope="scope" v-if="!readOnly">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="openDislog('add')">新增人员
                </el-button>
                <el-button v-if="!enterpriseEnter" type="primary" size="small" icon="el-icon-s-tools" @click="createUsers">批量开通账号
                </el-button>
                <el-button v-if="!enterpriseEnter" type="primary" size="small" icon="el-icon-s-tools" @click="excelFaceBoxDia">导入人脸图像
                </el-button>
                <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleImport">导入
                </el-button>
                <el-button class="mar-l-5" icon="el-icon-download" :loading="downLoading" @click="getMetaCommonListExport" size="small">导出</el-button>
                <!-- <el-input
          v-model="searchText"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入人员名称"
          type="text"
          style="width: 250px"
        >
          <el-button
            class="mar-0 pad-0"
            size="small"
            @click="refreshChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input> -->
            </template>
            <template slot-scope="scope" slot="menu">
                <template>
                    <template v-if="scope.row.is_admin == 1">
                        <el-button type="text" size="small" icon="el-icon-download" class="none-border" @click="setAdmin('0', scope.row)">取消管理员
                        </el-button>
                    </template>
                    <template v-if="scope.row.is_admin == 0">
                        <el-button type="text" size="small" icon="el-icon-upload2" class="none-border" @click="setAdmin('1', scope.row)">设为管理员
                        </el-button>
                    </template>
                </template>
                <template>
                    <el-button type="text" size="small" class="none-border" icon="el-icon-view" @click="openDislog('detail', scope.row)">查看
                    </el-button>
                    <el-button type="text" size="small" class="none-border" icon="el-icon-edit" @click="openDislog('update', scope.row)">编辑
                    </el-button>
                    <el-button v-if="
              scope.row.account_status == 1 || scope.row.account_status == 2||scope.row.account_status ==5
            " type="text" size="small" icon="el-icon-delete" class="none-border" @click="rowDel(scope.row)">删除
                    </el-button>

                    <template v-if="scope.row.account_status == 1">
                        <el-button type="text" size="small" icon="el-icon-finished" v-if="
                scope.row.face_ocr_status == 0 ||
                scope.row.face_ocr_status == 1 ||
                scope.row.face_ocr_status == 3 ||
                scope.row.face_ocr_status == 4
              " class="none-border" @click="onClickEndow(scope.row)">下发门禁权限
                        </el-button>
                    </template>

                    <!-- <el-button type="text" size="small" icon="el-icon-close" v-if="scope.row.face_ocr_status != 1" class="none-border" @click="delHKUserAndAuth(scope.row)">离职退出
                    </el-button> -->
                </template>
            </template>
        </avue-crud>
        <el-dialog :size="size" :visible.sync="dialogVisible" destroy-on-close :with-header="false" :top="'8vh'" :body-style="{ padding: '0px' }" width="70%" class="buildDetail">
            <template v-if="dialogVisible">
                <div slot="title" v-if="dialogType != 'space'" class="header-title">
                    <span class="fts-16">{{ title }}</span>
                </div>
                <div>
                    <baseInfo :node="node" :company="company" :companyNode="companyNode" :currentRow="currentRow" :doType="dialogType" :identity="identity" @close="closeDia"></baseInfo>
                </div>
            </template>
        </el-dialog>
        <el-drawer :visible.sync="detailDia" size="60%" :withHeader="false">
            <div slot="title" class="header-title">
                <span class="fts-16">{{ title }}</span>
            </div>
            <el-header class="bor-b" height="50px">
                <el-page-header class="lh-50" @back="detailDia = false" :content="title"></el-page-header>
            </el-header>
            详情
        </el-drawer>
        <el-dialog title="用户数据导入" append-to-body :visible.sync="excelBox" width="555px">
            <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter" :upload-before="uploadBefore">
                <template slot="excelTemplate">
                    <el-button type="primary" @click="handleTemplate">
                        点击下载<i class="el-icon-download el-icon--right"></i>
                    </el-button>
                </template>
            </avue-form>
        </el-dialog>
        <el-dialog title="导入人脸图像" append-to-body :visible.sync="excelFaceBox" width="555px">
            <avue-form :option="excelFaceOption" v-model="excelFaceForm" :upload-after="uploadFaceAfter" :upload-before="uploadFaceBefore">
            </avue-form>
        </el-dialog>
    </div>
</template>

<script>
import {
    getMetaCommonList,
    delMetaCommon,
    saveMetaCommon,
    updateMetaCommon,
    endowAccess,
    getMetaCommonListExport,
    getPicListByIds,
} from '@/api/busiMode/metaCommon';
import standardService from '@/api/metadata/standard';
import { companyImport, createUsers, delHKUserAndAuth } from '@/api/user';
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
// import addBuild from "./addBuild";
import * as dictService from '@/api/system/dictbiz';
import { fastCheckInComPanyProcess } from '@/api/visitor/visitor';

import { mapGetters, mapState } from 'vuex';
import baseInfo from './baseInfo';
export default {
    components: { baseInfo },
    data() {
        return {
            searchText: '',
            form: {},
            entModel: 'wisdom_park.wp_employee',
            excelBox: false,
            currentRow: {},
            selectionList: [],
            loading: true,
            dialogVisible: false,
            detailDia: false,
            query: {},
            dialogType: '',
            size: '60%',
            title: '',
            excelForm: {},
            excelFaceForm: {},
            queryObj: {},
            downId: '',
            excelFaceBox: false,
            viewPortHeight: viewPortHeight - 230,
            excelFaceOption: {
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: '模板上传',
                        prop: 'excelFile',
                        type: 'upload',
                        drag: true,
                        loadText: '模板上传中，请稍等',
                        span: 24,
                        accept: 'zip/*',
                        propsHttp: {
                            res: 'data',
                        },
                        tip: '支持导入ZIP文件，建议文件中包含照片的数量在1000张以内；照片命名规则为姓名_手机号.jpg，需露出眉毛和眼睛；单张照片文件大小20 KB~10MB。',
                        action: '/api/park-company/company/importEmployeesFace',
                    },
                ],
            },
            excelOption: {
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: '模板上传',
                        prop: 'excelFile',
                        type: 'upload',
                        drag: true,
                        loadText: '模板上传中，请稍等',
                        span: 24,
                        propsHttp: {
                            res: 'data',
                        },
                        tip: '请上传 .xls,.xlsx 标准格式文件',
                        action: '/api/park-company/company/importCompanyBak',
                    },
                    {
                        label: '模板下载',
                        prop: 'excelTemplate',
                        formslot: true,
                        span: 24,
                    },
                ],
            },
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            option: {
                height: viewPortHeight - 230,
                dialogWidth: 900,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: false,
                labelWidth: 120,
                menu: true,
                viewBtn: false,
                dialogClickModal: false,
                delBtn: false,
                editBtn: false,
                addBtn: false,
                cancelBtn: false,
                selection: true,
                column: [
                    {
                        width: 100,
                        overHidden: true,
                        align: 'right',
                        label: '主键',
                        prop: 'id',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '员工姓名',
                        prop: 'name',
                        span: 12,
                        search: true,
                        hide: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入员工名称',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        width: 150,
                        label: '管理员状态',
                        prop: 'is_admin',
                        type: 'select',
                        dicData: [
                            {
                                label: '管理员',
                                value: 1,
                            },
                            {
                                label: '非管理员',
                                value: 0,
                            },
                        ],
                    },
                    {
                        width: 100,
                        overHidden: true,
                        align: 'left',
                        label: '工号',
                        prop: 'job_num',
                        hide: true,
                    },
                    {
                        label: '性别',
                        prop: 'sex',
                        type: 'select',
                        dicData: [
                            {
                                label: '男',
                                value: 1,
                            },
                            {
                                label: '女',
                                value: 2,
                            },
                            {
                                label: '未知',
                                value: 3,
                            },
                        ],
                    },
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '联系方式',
                        search: true,
                        editDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入联系方式',
                                trigger: 'blur',
                            },
                        ],
                        span: 8,
                        prop: 'contact_details',
                        hide: false,
                    },

                    {
                        width: 100,
                        overHidden: true,
                        align: 'right',
                        label: '所属企业id',
                        prop: 'company_id',

                        hide: true,
                    },
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '所属企业名称',
                        prop: 'company_name',
                        hide: true,
                    },

                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '附件集合id',
                        prop: 'attachment_id',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '身份证号码',
                        prop: 'identity_card',
                        hide: true,
                    },
                    {
                        width: 100,
                        overHidden: true,
                        align: 'left',
                        label: '年龄',
                        prop: 'age',
                        hide: true,
                    },
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '居住地址',
                        prop: 'address',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '最高学历',
                        prop: 'highest_education',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '政治面貌',
                        prop: 'political_status',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        type: 'date',
                        hide: true,
                        label: '入职时间',
                        prop: 'employment_date',
                        format: 'yyyy年MM月dd日',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        type: 'select',
                        dicData: [
                            {
                                label: '在职',
                                value: 1,
                            },
                            {
                                label: '离职',
                                value: 2,
                            },
                            {
                                label: '退休',
                                value: 3,
                            },
                            {
                                label: '其它',
                                value: 3,
                            },
                        ],
                    },

                    {
                        width: 200,
                        label: '账户开通状态',
                        prop: 'account_status',
                        type: 'select',
                        dicData: [
                            {
                                label: '待开通',
                                value: 0,
                            },
                            {
                                label: '已开通',
                                value: 1,
                            },
                            {
                                label: '已禁用',
                                value: 2,
                            },
                            {
                                label: '开通失败',
                                value: 3,
                            },
                            {
                                label: '待审核',
                                value: 4,
                            },
                            {
                                label: '审核不通过',
                                value: 5,
                            },
                        ],
                    },

                    {
                        width: 200,
                        label: '人脸开通状态',
                        prop: 'face_ocr_status',
                        type: 'select',
                        dicUrl: dictService.getUrl('faceOcrStatus'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        search: true,
                    },
                ],
            },
            model: 'wisdom_park.wp_employee',
            comModel: 'wisdom_park.wp_company',
            employee_export: 'wisdom_park.v_wp_employee_export_stat',
            downLoading: false,
            company: [],
            data: [],
            options: [
                {
                    value: '1',
                    label: '1～3米',
                    
                },
                {
                    value: '2',
                    label: '3～4米',
                },
                {
                    value: '3',
                    label: '4～5米',
                },
                {
                    value: '4',
                    label: '5米以上',
                },
            ],
            ruleModel: {},
        };
    },
    props: {
        node: {
            type: Object,
            default() {
                return {};
            },
        },
        companyNode: {
            type: Object,
            default() {
                return {};
            },
        },
        enterpriseEnter: {
            type: Boolean,
            default() {
                return false;
            },
        },
        selection: {
            type: Boolean,
            default() {
                return true;
            },
        },
        readOnly: {
            type: Boolean,
            default() {
                return false;
            },
        },
        IsAdmin: {
            type: Boolean,
            default() {
                return false;
            },
        },
        height: {
            type: Number,
            default() {
                return viewPortHeight;
            },
        },
        identity: {
            type: Number,
            default() {
                return 0;
            },
        },
        accountStatus: {
            type: Number,
            default() {
                return -999;
            },
        },
        companyInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    watch: {
        node: {
            immediate: true,
            handler(val) {
                if (val.id) {
                    this.getComInfo();
                    this.onLoad();
                }
            },
        },
        identity: {
            immediate: true,
            handler(val) {},
        },
        companyInfo: {
            immediate: true,
            handler(val) {
                if (val.applicant) {
                    // this.findUser();
                }
            },
        },
        companyNode: {
            immediate: true,
            handler(val) {
                if (val.id) {
                    this.initUpload();
                    this.onLoad();
                }
            },
        },
        selection: {
            immediate: true,
            handler(val) {
                //if (!val) {
                this.option.selection = val;
                this.option = JSON.parse(JSON.stringify(this.option));
                //}
            },
        },
        readOnly: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.option.menu = false;
                    this.option.selection = false;
                    this.option = JSON.parse(JSON.stringify(this.option));
                }
            },
        },
        height: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.option.height = this.viewPortHeight - 45;
                    this.option = JSON.parse(JSON.stringify(this.option));
                } else {
                    this.option.height = this.viewPortHeight - 230;
                    this.option = JSON.parse(JSON.stringify(this.option));
                }
                this.$forceUpdate();
            },
        },

        'excelForm.isCovered'() {
            if (this.excelForm.isCovered !== '') {
                const column = this.findObject(this.excelOption.column, 'excelFile');
                column.action = `/api/park-company/company/import?companyId=1`;
            }
        },
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    mounted() {
        this.queryByModelCode();
        this.getDicData();
    },
    methods: {
        getDicData() {
            dictService.getUrlAsyn('ImportTemplate').then(res => {
                if (res.data.data[0].dictKey) {
                    this.downId = res.data.data[0].dictKey;
                }
            });
        },
        getMetaCommonListExport() {
            this.downLoading = true;
            console.log(this.selectionList);
            if (this.selectionList.length > 0) {
                let wheres = [];
                this.selectionList.forEach(e => {
                    wheres.push(e.id);
                });
                this.queryObj.wheres.push({ field: 'id', op: 'IN', value: wheres });
            }

            getMetaCommonListExport(this.employee_export, this.queryObj)
                .then(res => {
                    this.downLoading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = '企业人员档案' + '.xls';
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(e => {
                    this.downLoading = false;
                });
        },
        createUsers() {
            if (this.node.id == 'all') {
                this.$message.success('请选中企业');
                return;
            }
            if (this.selectionList.length == 0) {
                this.$message.success('请至少选择一个用户');
            } else {
                let ids = [];
                let companyId = this.selectionList[0]['company_id'];

                this.selectionList.forEach(e => {
                    ids.push(e.id);
                });
                let obj = {
                    companyId: companyId,
                    userIds: ids.toString(),
                };
                createUsers(obj).then(res => {
                    this.$message.success('批量操作成功');
                    this.onLoad();
                });
            }
        },
        excelFaceBoxDia() {
            if (this.node.id == 'all') {
                this.$message.success('请选中企业');
                return;
            } else {
                this.excelFaceBox = true;
            }
        },
        handleImport() {
            if (this.node.id == 'all') {
                this.$message.success('请选一个组织机构');
            } else {
                this.excelBox = true;
            }
        },
        uploadFaceAfter(res, done, loading, column) {
            console.log(res, column);

            this.excelFaceBox = false;
            this.onLoad();
            done();
        },
        uploadAfter(res, done, loading, column) {
            this.excelBox = false;
            this.onLoad();
            done();
        },
        initUpload() {
            let companyId = '';

            if (this.node.id) {
                companyId = this.company[0].id;
            }
            if (this.companyNode.id) {
                companyId = this.companyNode.id;
            }
            console.log(this.company[0], 'this.company[0]');
            this.excelOption.column[0].action = '/api/park-company/company/importCompanyBak?companyId=' + companyId;
            this.excelFaceOption.column[0].action =
                '/api/park-company/company/importEmployeesFace?companyId=' + companyId;
            this.$forceUpdate();
        },
        uploadBefore(res, done, loading, column) {
            //this.initUpload();
            done();
        },

        uploadFaceBefore(res, done, loading, column) {
            console.log(res);
            done();
            // if (res.type != "application/zip") {
            //   this.$message({
            //     type: "error",
            //     message: "文件格式必须是ZIP",
            //   });
            //   loading();
            // } else {
            //   done();
            // }
        },
        handleTemplate() {
            // getPicListByIds(this.downId).then((res) => {
            //   if (res.data.data[0]) {
            //     window.open(`${res.data.data[0].link}`);
            //   }
            // });
            let url = `https://zhyy.qdgkcy.com/wisdom-park/common/员工信息导入模版V3.0.xlsx`;
            window.open(url);
        },
        queryByModelCode() {
            console.log(this.model, 'params');
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
                console.log(this.ruleModel);
            });
        },
        getComInfo() {
            if (this.node.id) {
                let body = {
                    current: 1,
                    size: 20,
                    wheres: [{ field: 'dept_id', value: this.node.id }],
                };
                getMetaCommonList(this.comModel, body).then(res => {
                    if (res.data.data.records.length > 0) {
                        this.company = res.data.data.records;
                        this.initUpload();
                    } else {
                        if (this.node.parentId) {
                            let b = {
                                current: 1,
                                size: 20,
                                wheres: [{ field: 'dept_id', value: this.node.parentId }],
                            };
                            getMetaCommonList(this.comModel, b).then(res => {
                                this.company = res.data.data.records;
                                console.log('this.initUpload();this.initUpload();');
                                this.initUpload();
                            });
                        }
                    }
                });
            }
        },
        openDislog(type, row) {
            this.currentRow = {};
            this.dialogType = type;
            if (type == 'detail') {
                this.currentRow = row;
                this.dialogVisible = true;
                this.title = '详情';
            } else if (type == 'update') {
                this.currentRow = row;
                this.title = '修改';
                this.dialogVisible = true;
            } else {
                this.title = '新增';
                if (this.node.id == 'all') {
                    this.$message.success('请选一个组织机构');
                } else {
                    this.title = '新增';
                    this.dialogVisible = true;
                }
            }
        },
        changName(row) {
            this.title = row.name;
        },
        setAdmin(num, row) {
            //return false;
            let obj = {};
            Object.keys(row).forEach(ele => {
                if (!ele.includes('$') && row[ele] != '') {
                    obj[ele] = row[ele];
                }
            });
            if (obj.sex) {
                obj.sex = obj.sex == '男' ? 1 : 2;
            }
            obj.is_admin = Number(num); //设为管理员
            let o = {
                update: obj,
                primaryKeyValue: row['id'],
                primaryKey: 'id',
            };
            updateMetaCommon(this.model, o).then(res => {
                if (res.data.code == 200) {
                    let o = {
                        isApproved: true,
                        id: row.id,
                    };
                    fastCheckInComPanyProcess(o).then(ComPanyProcess => {
                        if (ComPanyProcess.data.code == 200) {
                            if (row.account_status == 0) {
                                let obj = {
                                    companyId: row.company_id,
                                    userIds: row.id,
                                };
                                createUsers(obj).then(res => {
                                    this.$message.success('设置成功');
                                    this.onLoad();
                                });
                            } else {
                                let mess = num == 1 ? '该用户已经是管理员' : '已经取消该用户的管理员身份';
                                this.$message({
                                    type: 'success',
                                    message: mess,
                                });
                                this.onLoad();
                            }
                        }
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: '操作失败',
                    });
                }
            });
        },
        handleBuild() {},
        rowSave(row, done, loading) {
            console.log(row);
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    done();
                    window.console.log(error);
                    loading();
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    window.console.log(error);
                    loading();
                },
            );
        },
        onClickEndow(row) {
            endowAccess(row.id).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功!',
                });
                this.onLoad();
            });
        },
        delHKUserAndAuth(row) {
            delHKUserAndAuth(row.id).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功!',
                });
                this.onLoad();
            });
        },
        rowDel(row, index) {
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    delHKUserAndAuth(row.id).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        // let pk = 'id';
                        // delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then(res => {
                        //     //this.delHKUserAndAuth(row);

                        // });
                        this.onLoad();
                    });
                })
                .catch(() => {});
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },

        selectionChange(list) {
            this.selectionList = list;
            this.$emit('empoyees', this.selectionList);
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    let ids = [];
                    this.selectionList.forEach(e => {
                        if (e.id) {
                            ids.push(e.id);
                        }
                    });
                    delMetaCommon(this.model, { pk: pk, ids: ids }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.data.splice(index, 1);
                    });
                })
                .then(() => {
                    this.onLoad(this.page);

                    this.$refs.crud.toggleSelection();
                });
        },

        refreshChange() {
            this.form = {};
            this.onLoad();
        },
        searchChange(params, done) {
            this.page.currentPage = 1;
            this.form = params;
            this.onLoad(params);
            done();
        },
        closeDia() {
            this.dialogVisible = false;
            this.onLoad();
        },
        save(obj) {
            obj.company_identity = saveMetaCommon(this.entModel, {
                values: obj,
            }).then(res => {
                if (res.data.code == 200) {
                    this.onLoad();
                }
            });
        },
        findUser() {
            if (this.companyInfo.applicant) {
                let body = {
                    current: this.page.currentPage,
                    size: this.page.pageSize,
                };
                let wheres = [];
                wheres.push({
                    field: 'name',
                    op: 'LIKE',
                    value: this.companyInfo.applicant,
                });
                if (this.node.id && this.node.id != 'all') {
                    wheres.push({
                        field: 'dept_id',
                        value: this.node.id,
                    });
                }
                if (this.companyNode.id) {
                    wheres.push({
                        field: 'company_id',
                        value: this.companyNode.id,
                    });
                }
                body['wheres'] = wheres;
                getMetaCommonList(this.model, body).then(res => {
                    const data = res.data.data.records;
                    if (data.length == 0) {
                        let obj = {
                            company_id: this.companyInfo.companyId,
                            name: this.companyInfo.applicant,
                            contact_details: this.companyInfo.phoneNum,
                            is_admin: 1,
                        };
                        this.save(obj);
                    }
                });
            }
        },
        onLoad() {
            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                orders: [],
            };
            if (this.IsAdmin) {
                body.orders.push({ field: 'is_admin', sort: 'DESC' });
            }
            body.orders.push({ field: 'update_time', sort: 'DESC' });
            let wheres = [];
            if (this.form) {
                for (let k in this.form) {
                    wheres.push({
                        field: k,
                        op: 'LIKE',
                        value: this.form[k],
                    });
                }
            }

            if (this.node.id && this.node.id != 'all') {
                wheres.push({
                    field: 'dept_id',
                    value: this.node.id,
                });
            }
            if (this.companyNode.id) {
                wheres.push({
                    field: 'company_id',
                    value: this.companyNode.id,
                });
            }
            if (this.identity != 999) {
                if (this.identity || this.identity == 0) {
                    wheres.push({
                        field: 'company_identity',
                        value: this.identity,
                    });
                }
            }

            if (this.accountStatus != -999) {
                wheres.push({
                    field: 'account_status',
                    value: this.accountStatus,
                });
            }
            body['wheres'] = wheres;
            this.queryObj = body;
            if (body['wheres'].length > 0) {
                this.loading = true;
                getMetaCommonList(this.model, body).then(res => {
                    this.data = [];
                    const data = res.data.data;
                    this.page.total = data.total;
                    data.records.forEach(e => {
                        if (e.face_ocr_status) {
                            e.face_ocr_status = e.face_ocr_status + '';
                        }
                        if (e.face_ocr_status == 0) {
                            e.face_ocr_status = '0';
                        }
                    });
                    this.data = data.records;
                    data.records.forEach(e => {
                        this.options.forEach(o => {
                            if (e.hight == o.value) {
                                e.hight = o.label;
                            }
                        });
                    });
                    this.loading = false;
                });
            }
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
    font-size: 18px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
.enter-deatil {
    height: 100%;
}

.enter-deatil {
    /deep/.el-form {
        .el-table {
            // height: auto !important;
        }
    }
    /deep/.el-card {
        .el-table {
            height: 100%;
        }
    }
}
</style>
