import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/park-spcod/codappoint/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: '/api/park-spcod/codappoint/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/park-spcod/codappoint/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/park-spcod/codappoint/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/park-spcod/codappoint/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/park-spcod/codappoint/submit',
    method: 'post',
    data: row
  })
}

//更新预约状态
export const updateApptStatus = (apptId,status) => {
  return request({
    url: '/api/park-spcod/codappoint/updateapptstatus/' + apptId + '?status=' + status,
    method: 'get',
    params: {}
  })
}

//更新预约状态
export const deleteAppoint = (apptId) => {
  return request({
    url: '/api/park-spcod/codappoint/deleteAppoint' ,
    method: 'get',
    params: {'apptId':apptId}
  })
}


