import request from '@/router/axios';
const prefix = '/api/jida-system';
export const getDataAppList = (params) => {
  return request({
    url: prefix + `/app/page`,
    method: 'get',
    params
  })
};
export const saveApp = (params) => {
  return request({
    url: prefix + `/app/save`,
    method: 'post',
    data:params
  })
};
export const updateApp = (params) => {
  return request({
    url: prefix + `/app/update`,
    method: 'post',
    data:params
  })
};
export const delApp = (params) => {
  return request({
    url: prefix + `/app/remove`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return formData
    }],
    data:params
  })
};
// 获取用户列表
export const userLists = (params) => {
  return request({
    url:'/api/jida-user/user-list',
    method: 'get',
    params
  })
};
// 应用详情
export const detailApp = (params) => {
  return request({
    url: prefix + `/app/detail`,
    method: 'get',
    params: params
  })
};
