import { themeColors } from './theme_colors';

import store from '../store';

class ColorManage{

  _colors = themeColors;

  customColor = {};
  index = 0;

  get color(){
    return this._getColor();
  }

  get theme(){
    return store.state.common.themeName || 'default';
  }

  _getColor(){
    let theme = this.theme;
    let customColor = this.customColor[theme] ? this.customColor[theme] : this.customColor['default'] ? this.customColor['default'] : [];
    let _color = this._colors[theme] ? this._colors[theme] : this._colors['default'] ? this._colors['default'] : [];
    return _color.concat(customColor);
  }

  addColor(color){
    let theme = this.theme;
    if(!this.customColor[theme]){
      this.customColor[theme] = [];
    }
    if(typeof color === 'string'){
      this.customColor[theme].push(color);
    }
    if(typeof color === 'object'){
      this.customColor[theme] = this.customColor[theme].concat(color);
    }
    return this;
  }

  random(){
    let color = this.color;
    return color[Math.floor(Math.random() * color.length)];
  }

  setTheme(themeName){
    this._theme = themeName;
    if(!this._colors[themeName]){
      this._colors[themeName] = [];
    }
    if(!this.customColor[themeName]){
      this.customColor[themeName] = [];
    }
    return this;
  }

}

window.ColorManage = ColorManage;

export default new ColorManage;