import request from '@/router/axios';
const prefix = '/api/jida-data-asset/labeltype/';

export const getPage = (params) => {
  return request({
    method: 'get',
    params: params,
    url: prefix + 'page'
  })
}

export const listLabeltype = () => {
  return request({
    method: 'get',
    params: {},
    url: prefix + 'list'
  })
}

export const saveLabeltype = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'save'
  })
}
export const updateLabeltype = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'update'
  })
}
export const removeLabeltype = (data) => {
  return request({
    method: 'post',
    params: data,
    url: prefix + 'remove'
  })
}