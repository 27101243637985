
import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer'; // 引入默认的renderer
 
const HIGH_PRIORITY = 1500; // 最高优先级

import { append as svgAppend, create as svgCreate } from 'tiny-svg';
export default class CustomRenderer extends BaseRenderer {
  CustomElements = {};
  // 继承BaseRenderer
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);
    this.bpmnRenderer = bpmnRenderer;
  }
 
  canRender(element) {
    return !element.labelTarget;
  }
 
  drawShape(parentNode, element) {
    const type = element.type; // 获取到类型
    const CustomElements = this.CustomElements;
    // 所有节点都会走这个函数，所以此时只限制，需要自定义的才去自定义，否则仍显示bpmn默认图标
    if (CustomElements[type]) {
      const task = this.bpmnRenderer.handlers['bpmn:Task'](parentNode , element);
      const {url, attr} = CustomElements[type];
      const customIcon = svgCreate('image', {
        ...attr,
        x: 5,
        y: 5,
        width: 22,
        height: 22,
        href: url
      });
      svgAppend(parentNode , customIcon)
      element['width'] = 100;
      element['height'] = 80;
      return task;
    }
    const shape = this.bpmnRenderer.drawShape(parentNode, element);
    return shape;
  }
 
  getShapePath(shape) {
    return this.bpmnRenderer.getShapePath(shape);
  }
}
 
CustomRenderer.$inject = ['eventBus', 'bpmnRenderer'];