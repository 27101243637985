<template>
  <div>
    <enterpriseEnterBaseInfo
      :info="info"
      :companyId="companyCopyId"
      @getCompanyId="getCompanyId"
      @getCompanyRooms="getCompanyRooms"
      :enterprise="enterprise"
      ref="enterpriseEnterBaseInfo"
    ></enterpriseEnterBaseInfo>
    <!-- <el-col :span="18" class="pad-b-15 hidden">
      <el-descriptions
        v-if="info.contractSubjects"
        class="mar-t-20"
        title="房源信息"
      ></el-descriptions>
      <el-table
        v-if="info.contractSubjects"
        :data="info.contractSubjects"
        border
        size="small"
      >
        <el-table-column label="楼宇" prop="buildingName"></el-table-column>
        <el-table-column label="楼层" prop="floorId"></el-table-column>
        <el-table-column label="房间名称" prop="roomName"></el-table-column>
        <el-table-column label="房间面积" prop="roomArea">
          <template slot-scope="scope"> {{ scope.row.roomArea }}㎡ </template>
        </el-table-column>
        <el-table-column label="计价面积" prop="roomArea">
          <template slot-scope="scope"> {{ scope.row.roomArea }}㎡ </template>
        </el-table-column>
        <el-table-column label="物业管理费" prop="propertyManagementFee">
          <template slot-scope="scope">
            {{ scope.row.propertyManagementFee }}元/㎡·月
          </template>
        </el-table-column>
        <el-table-column label="单价" prop="rentFreeEndDate">
          <template slot-scope="scope">
            {{ scope.row.rentFreeEndDate }}元/㎡·日
          </template>
        </el-table-column>
      </el-table>

      <contractSubjects
        v-if="!info.contractSubjects"
        ref="contractSubjects"
      ></contractSubjects>
    </el-col> -->
  </div>
</template>

<script>
import { getList, detail } from "@/api/contract/index.js";
import contractSubjects from "./contractSubjects";
import enterpriseEnterBaseInfo from "./enterpriseEnterBaseInfo";
export default {
  components: { contractSubjects, enterpriseEnterBaseInfo },

  data() {
    return {
      info: {},
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      companyCopyId: "",
      model: "wisdom_park.wp_company_checkin",
    };
  },
  props: {
    companyId: {
      type: String,
      default: "",
    },
    enterprise: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    companyId: {
      immediate: true,
      handler(val) {
        if (val) {
          delete val.contractSubjects;
          this.companyCopyId = this.companyId;
          this.onLoad();
        }
      },
    },
  },
  computed: {},
  mounted() {},
  methods: {
    getCompanyId(val) {
      if (val && val != "undefined") {
        this.companyCopyId = val;
        this.$emit("changeCurrentRowId", this.companyCopyId);
        this.onLoad();
      }
    },
    getCompanyRooms(val) {
      this.$emit("getCompanyRooms", val);
    },
    onLoad() {
      let searchBody = {};
      if (this.companyCopyId && this.companyCopyId != "undefined") {
        this.info = {};
        searchBody["companyId"] = this.companyCopyId;
        return getList({
          current: 1,
          size: 10,
          ...searchBody,
        })
          .then((res) => {
            if (res.data && res.data.code === 200) {
              if (res.data.data.records.length == 0) {
                // this.info=
              } else {
                let info = res.data.data.records[0];
                this.getDetail(info.id);
              }
            }
          })
          .catch((e) => {
            this.tableLoading = false;
          });
      }
    },
    getDetail(id) {
      if (id) {
        detail({ id: id }).then((res) => {
          if (res.data) {
            console.log(res.data);
            this.info = res.data.data;
            //this.getRelationData(info);
          }
        });
      }
    },
    save() {
      let enterpriseEnterBaseInfo = this.$refs.enterpriseEnterBaseInfo;
      let rooms = [];
      let roomsName = [];
      // if (enterpriseEnterBaseInfo.info.contractSubjects) {
      //   enterpriseEnterBaseInfo.info.contractSubjects.forEach((e) => {
      //     console.log(e);
      //     rooms.push(e.id);
      //     roomsName.push(e.roomName);
      //   });
      //   enterpriseEnterBaseInfo.ruleForm.roomIds = rooms.toString();
      //   enterpriseEnterBaseInfo.ruleForm.roomNames = roomsName.toString();
      // }
      // if (this.$refs.contractSubjects) {
      //   if (this.$refs.contractSubjects.roomSelected.length > 0) {
      //     this.$refs.contractSubjects.roomSelected.forEach((e) => {
      //       rooms.push(e.room_id);
      //       roomsName.push(e.room_name);
      //     });
      //     enterpriseEnterBaseInfo.ruleForm.roomIds = rooms.toString();
      //     enterpriseEnterBaseInfo.ruleForm.roomNames = roomsName.toString();
      //   }
      // }

      //企业logo
      //console.log("qie", enterpriseEnterBaseInfo.imgform);
      // enterpriseEnterBaseInfo.ruleForm.roomIds =
      //   enterpriseEnterBaseInfo.ruleForm.roomIds.toString();
      // enterpriseEnterBaseInfo.ruleForm.roomNames =
      //   enterpriseEnterBaseInfo.ruleForm.roomNames.toString();
      if (enterpriseEnterBaseInfo.imgform) {
        let imgList = [];
        enterpriseEnterBaseInfo.imgform.imgUrl.forEach((e) => {
          if (e.label) {
            imgList.push(e.label);
          }
        });
        enterpriseEnterBaseInfo.ruleForm.attachmentId = imgList.toString();
      }
      let baseInfo = enterpriseEnterBaseInfo.ruleForm;
      console.log(baseInfo);
      return baseInfo;
    },
  },
};
</script>
<style lang="scss" scoped></style>
