import axios from '@/router/axios'
import apiUrls from './apiUrl';
import qs from 'qs';
import { getDeptTree } from '../system/dept';

export default {
  list(params){
    return axios({
      method: 'get',
      params: params,
      url: apiUrls.list
    })
  },
  page(params){
    return axios({
      method: 'get',
      params: params,
      url: apiUrls.page
    })
  },
  remove(params){
    return axios({
      method: 'post',
      data: qs.stringify(params),
      url: apiUrls.remove
    })
  },
  submit(params){
    return axios({
      method: 'post',
      data: params,
      url: apiUrls.submit
    })
  },
  get(params){
    return axios({
      method: 'get',
      params: params,
      url: apiUrls.detail
    })
  },
  createToken(params){
    return axios({
      method: 'post',
      data: params,
      url: apiUrls.createToken
    })
  },
  query(params){
    return axios({
      method: 'post',
      data: params.body,
      url: apiUrls.query2 + params.tableName,
      headers: params.headers
    })
  },
  getDeptTree(params){
    return axios({
      method: 'get',
      params: params,
      url: apiUrls.deptTree
    })
  },
  getDeptList(params){
    return axios({
      method: 'get',
      params: params,
      url: apiUrls.deptList
    })
  },
  upAndDown(params){
    return axios({
      method: 'post',
      data: params,
      url: apiUrls.upAndDown
    })
  }
}

