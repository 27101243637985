import request from '@/router/axios';
const prefix = '/api/jida-data-asset/grade/';

export const getList = (params) => {
  return request({
    method: 'get',
    params: params,
    url: prefix + 'list'
  })
}

export const getPage = (params) => {
  return request({
    method: 'get',
    params: params,
    url: prefix + 'page'
  })
}

export const listGradetype = () => {
  return request({
    method: 'get',
    params: {},
    url: prefix + 'list'
  })
}

export const saveGradetype = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'save'
  })
}
export const updateGradetype = (data) => {
  return request({
    method: 'post',
    data: data,
    url: prefix + 'update'
  })
}
export const removeGradetype = (data) => {
  return request({
    method: 'post',
    params: data,
    url: prefix + 'remove'
  })
}
