import request from '@/router/axios';
const prefix = '/api/sentinel';
export const getMachineResourceList = (params) => {
  return request({
    url: prefix + `/resource/machineResource.json`,
    method: 'get',
    params
  })
};
export const getServiceList = (params) => {
  return request({
    url: prefix + `/app/briefinfos.json`,
    method: 'get',
    params
  })
};
