import axios from '@/router/axios'
import apiUrls from './apiUrl';
import qs from 'qs';

export default {
  // getStatFinanceLeaseExpenseDetail(params){
  //   return axios({
  //     method: 'post',
  //     params: params,
  //     url: apiUrls.getStatFinanceLeaseExpenseDetail
  //   })
  // },
  getStatFinanceLeaseExpenseDetail(params){
    return axios({
      method: 'post',
      data: params.body,
      url: apiUrls.getStatFinanceLeaseExpenseDetail,
      headers: params.headers
    })
  },
  //面积测算
  getStatFinanceCalculateArea(params){
    return axios({
      method: 'post',
      data: params.body,
      url: apiUrls.getStatFinanceCalculateArea,
      headers: params.headers
    })
  },
}

