import { render, staticRenderFns } from "./companyChangeList.vue?vue&type=template&id=29888c84"
import script from "./companyChangeList.vue?vue&type=script&lang=js"
export * from "./companyChangeList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports