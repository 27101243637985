const prefix = '/api/jida-data-asset/datashare/';

export default {
  _prefix: prefix,
  list: prefix + 'list',
  page: prefix + 'page',
  remove: prefix + 'remove',
  submit: prefix + 'submit',
  detail: prefix + 'detail',
  createToken: prefix + 'createToken',
  query: prefix + 'query/',
  query2: prefix + 'query2/',
  deptTree: '/api/jida-system/dept/tree',
  deptList: '/api/jida-system/dept/list',
  upAndDown: prefix + 'upAndDown/',
}
