<template>
    <el-row>
        <el-col :span="18">
            <el-form class="pad-10" :model="ruleForm" ref="ruleForm" label-width="100px" label-position="top" size="small" labelPosition="right" :rules="ruleModel">
                <el-row>
                    <el-col :span="22" class="pad-l-0 pad-r-0">
                        <el-col :span="12">
                            <el-form-item label="申请时间" prop="createTime">
                                <el-input disabled v-model="ruleForm.createTime" placeholder="请填写申请时间"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" class="pad-l-0 pad-r-0">
                            <el-col :span="12">
                                <el-form-item label="入驻企业" prop="companyId" :rules="[
                    {
                      required: true,
                      message: '请输入入驻企业',
                      trigger: 'blur',
                    },
                  ]">
                                    <el-select v-if="!enterprise" @change="changeCom" v-model="ruleForm.companyId" filterable placeholder="请选择入驻企业">
                                        <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <span v-if="enterprise">{{ userInfo.companyName }}</span>
                                </el-form-item>
                            </el-col>
                            <el-button v-if="!enterprise" type="primary" size="small" @click="openDislog('add')">快速新建企业</el-button>
                        </el-col>
                        <el-col :span="24" class="pad-l-0 pad-r-0">
                            <el-col :span="12">
                                <el-form-item label="计划入驻时间" prop="planCheckinTime">
                                    <el-date-picker v-model="ruleForm.planCheckinTime" type="date" placeholder="计划入驻时间" s>
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="申请人" prop="applicant" :rules="[
                  {
                    required: true,
                    message: '请输入申请人',
                    trigger: 'blur',
                  },
                ]">
                                <el-input clearable v-model="ruleForm.applicant" placeholder="请输入申请人"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话" prop="phoneNum" :rules="[
                  {
                    required: true,
                    message: '请输入联系电话',
                    trigger: 'blur',
                  },
                ]">
                                <el-input clearable v-model="ruleForm.phoneNum" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="合同情况" prop="contract_code">
                                <el-radio-group v-model="ruleForm.type" @change="change1">
                                    <el-radio :label="1" :key="1">线上</el-radio>
                                    <el-radio :label="2" :key="2">线下</el-radio>
                                    <el-radio :label="3" :key="3">无合同</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" v-if="ruleForm.type == 2 || ruleForm.type == 1">
                            <el-form-item label="合同编码" prop="contractCode">
                                <!-- @blur="onBlur" -->
                                <!-- <span>{{ ruleForm.contract_code }}</span> -->
                                <el-input placeholder="请输入合同编码" v-model="ruleForm.contractCode"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="22">
                        <el-col :span="12">
                            <el-form-item label="入驻房间" prop="reason" :rules="[
                  {
                    required: true,
                    message: '请选择入驻房间',
                    trigger: 'blur',
                  },
                ]">
                                <el-select v-model="ruleForm.roomIds" multiple filterable remote clearable reserve-keyword default-first-option placeholder="请输入入驻房间" :remote-method="remoteMethod"
                                           :loading="remoteLoad" @change="changeRooms">
                                    <el-option v-for="item in roomList" :key="item.room_id" :label="item.label" :value="item.room_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" class="mar-t-10f" v-if="ruleForm.roomNames">
                            <template v-for="(item, index) of ruleForm.roomNames">
                                <div class="roomtitle">
                                    {{ item }}
                                    <i class="el-icon-close" @click="deleteRoom(item, index)"></i>
                                </div>
                            </template>
                        </el-col>
                        <el-col :span="24" class="pad-r-0 mar-t-10" v-if="ruleForm.type == 3">
                            <el-form-item label="申请说明" prop="reason">
                                <el-input type="textarea" :rows="5" placeholder="请输入申请说明" v-model="ruleForm.reason"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>

                <!-- <el-col :span="12" class="mar-t-10f" v-if="ruleForm.roomIds">
          roomNames
          <template v-for="(item, index) of roomList">
            <div
              v-if="ruleForm.roomIds.indexOf(item.id) > -1"
              class="roomtitle"
              :key="item.id"
            >
              {{ item.label }}
              <i class="el-icon-close" @click="deleteRoom(item, index)"></i>
            </div>
          </template>
        </el-col> -->

                <el-col :span="20">
                    <el-divider v-if="ruleForm.type == 2" content-position="left">合同扫描件</el-divider>
                    <avue-form v-if="ruleForm.type == 2" :option="option1" v-model="imgform"></avue-form>
                </el-col>
            </el-form>
        </el-col>
        <!-- <el-col :span="6">
      <div class="sm">
        <h6>企业入驻说明</h6>
        <pre>{{ companyText }}</pre>
      </div>
    </el-col> -->
        <!-- <el-divider content-position="left">基础信息</el-divider> -->
        <el-dialog :visible.sync="detailDia" width="60%" class="enterDetail" destroy-on-close append-to-body :close-on-click-modal="false">
            <div slot="title" class="header-title">
                <span class="fts-16">{{ title }}</span>
            </div>
            <addEnterprise @close="close" :currentRow="{}"></addEnterprise>
        </el-dialog>
    </el-row>
</template>

<script>
import * as dictbizService from '@/api/system/dictbiz';
import standardService from '@/api/metadata/standard';
import { getMetaCommonInfo, getPicListByIds } from '@/api/busiMode/metaCommon';
import { dateFormat } from '@/util/date';
import { getList, detail } from '@/api/contract/index.js';
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import addEnterprise from './addEnterprise';
import { mapGetters, mapState } from 'vuex';
import { getUser } from '@/api/system/user';
import website from '@/config/website';

export default {
    components: { addEnterprise },
    data() {
        return {
            remoteLoad: false,
            ruleModel: {},
            roomList: [],
            roomFilter: '',
            companyText: website.companyText,
            option1: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,

                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '请上传pdf文件、word文件, 文件大小不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            option: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,

                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传pdf文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            imgform: {
                imgUrl: [],
            },
            imgform1: {
                imgUrl: [],
            },
            detailDia: false,
            title: '',
            dialogImageUrl: '',
            dialogVisible: false,
            ruleForm: {
                contractCode: '',
            },
            params: {
                zoom: 4,
                zoomEnable: false,
                dragEnable: true,
            },
            firmSizeList: [],
            enterpriseTypes: [],
            qualifications: [],
            entModel: 'wisdom_park.wp_company',
            checkinModel: 'wisdom_park.wp_company_checkin',
            codeError: false,
            companyList: [],
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    watch: {
        companyId: {
            immediate: true,
            handler(val) {
                if (val && val != 'undefined') {
                    this.ruleForm.companyId = val + '';
                }
            },
        },
        'ruleForm.roomIds': {
            immediate: true,
            handler(val) {
                if (val) {
                    if (val.length > 0) {
                        this.$emit('getCompanyRooms', val);
                    }
                }
            },
        },
        info: {
            deep: true,
            immediate: true,
            handler(val) {
                if (val.code) {
                    this.ruleForm.contractCode = val.code;
                    this.ruleForm.type = 2;
                } else {
                    this.ruleForm.contractCode = '';
                    delete this.ruleForm.type;
                }
                if (val.contractSubjects) {
                    let rooms = [];
                    let roomNames = [];

                    val.contractSubjects.forEach(e => {
                        console.log(e);
                        rooms.push(e.roomId);
                        // roomNames.push(e.building_name + '-' + e.room_name);
                        roomNames.push(e.buildingName + '-' + e.roomName);
                    });
                    this.ruleForm.roomNames = roomNames;
                    this.ruleForm.roomIds = rooms;
                }
                this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
            },
        },
    },
    mounted() {
        if (this.userInfo.user_name) {
            this.ruleForm.applicant = this.userInfo.user_name;
            this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
        }
        if (this.userInfo.user_id) {
            this.getUserPhone(this.userInfo.user_id);
        }
        if (this.userInfo.companyId && this.userInfo.tenant_id == '000002') {
            this.ruleForm.companyId = this.userInfo.companyId;
            this.getMetaCommonListcheckin(this.ruleForm.companyId);
            this.$emit('getCompanyId', this.ruleForm.companyId);
        }

        this.getDicList();
        this.getMetaCommonList();
        //this.queryByModelCode();
    },
    props: {
        info: {
            type: Object,
            default() {
                return {};
            },
        },
        companyId: {
            type: String,
            default() {
                return '';
            },
        },
        enterprise: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    methods: {
        change1() {
            this.$forceUpdate();
        },
        getComName(id) {
            let name = '';
            this.companyList.forEach(c => {
                if (c.id == id) {
                    name = c.name;
                }
            });
            return name;
        },
        changeRooms(val) {
            console.log(val);
            let roomNames = [];
            let roomids = [];
            this.roomList.forEach(e => {
                val.forEach(v => {
                    if (e.room_id == v) {
                        roomids.push(e.room_id);
                        roomNames.push(e.label);
                    }
                });
            });
            this.ruleForm.roomNames = roomNames;
            this.ruleForm.roomIds = roomids;
        },
        deleteRoom(item, index) {
            this.ruleForm.roomNames.splice(index, 1);
            this.ruleForm.roomIds.splice(index, 1);
        },
        remoteMethod(val) {
            this.remoteLoad = true;
            this.getRooms(val);
        },
        getRooms(val) {
            this.remoteLoad = true;
            let body = {
                current: 1,
                size: 50,
            };
            body.wheres = [
                { field: 'room_name', op: 'LIKE', value: val },
                { field: 'status', value: 0 },
                { field: 'is_cancel', value: 0 },
            ];
            getMetaCommonList('wisdom_park.v_wp_room_stat', body).then(res => {
                let data = res.data.data;
                data.records.forEach(e => {
                    e.label = '';
                    if (e.building_name) {
                        e.label = e.building_name;
                    }
                    if (e.floor_name) {
                        e.label = e.label + '-' + e.floor_name;
                    }
                    e.label = e.label + '-' + e.room_name;
                });
                console.log(data.records);
                this.roomList = data.records;
                this.remoteLoad = false;
            });
        },
        changeCom() {
            // this.ruleForm = {
            //   companyId: this.ruleForm.companyId + "",
            //   createTime: this.ruleForm.createTime,
            // };
            this.ruleForm.roomIds = '';
            this.ruleForm.roomNames = [];
            this.getMetaCommonListcheckin(this.ruleForm.companyId);
            this.$emit('getCompanyId', this.ruleForm.companyId);
        },
        toHump(name) {
            return name.replace(/\_(\w)/g, function (all, letter) {
                return letter.toUpperCase();
            });
        },
        getMetaCommonListcheckin(id) {
            let body = {
                current: 1,
                size: 20,
                wheres: [{ field: 'company_id', value: id }],
            };
            getMetaCommonList(this.checkinModel, body).then(res => {
                const data = res.data.data;
                // this.ruleForm = data.records[0];
                if (res.data.code == 200) {
                    if (data.records.length > 0) {
                        let obj = {};
                        Object.keys(data.records[0]).forEach(ele => {
                            obj[this.toHump(ele)] = data.records[0][ele];
                        });
                        if (obj.roomIds) {
                            obj.roomIds = obj.roomIds.split(',');
                        } else {
                            obj.roomIds = '';
                        }
                        if (obj.roomNames) {
                            obj.roomNames = obj.roomNames.split(',');
                        } else {
                            obj.roomNames = '';
                        }
                        if (obj.planCheckinTime) {
                            obj.planCheckinTime = new Date(obj.planCheckinTime);
                        }
                        if (obj['attachmentId']) {
                            getPicListByIds(obj['attachmentId']).then(res => {
                                if (res.data.code == 200) {
                                    let arr = [];
                                    res.data.data.forEach(pic => {
                                        arr.push({
                                            label: pic.id,
                                            value: pic.link,
                                        });
                                    });
                                    this.imgform.imgUrl = arr;
                                }
                            });
                        }
                        this.ruleForm = obj;
                    }
                }
            });
        },
        close(e) {
            this.detailDia = false;
            if (e.id) {
                this.getMetaCommonList();
                this.ruleForm.companyId = e.id;
                this.$emit('getCompanyId', this.ruleForm.companyId);
            }
        },
        openDislog(type, row) {
            this.detailDia = true;
            this.title = '新增';
            this.$nextTick(() => (this.currentRow = {}));
        },
        getMetaCommonList() {
            let body = {
                current: 1,
                size: 1000,
            };
            // 状态（0:无效；1.已入驻；2.入驻中；3.退出中；4.已退出）

            body.wheres = [{ field: 'status', op: 'IN', value: [0, 4] }];
            getMetaCommonList(this.entModel, body).then(res => {
                const data = res.data.data;
                this.companyList = data.records;
            });
        },
        onBlur(e) {
            this.checkContractCode();
        },
        checkContractCode() {
            let searchBody = {};
            if (this.ruleForm.contractCode) {
                searchBody['code'] = this.ruleForm.contractCode;
                return getList({
                    current: 1,
                    size: 10,
                    ...searchBody,
                }).then(res => {
                    if (res.data && res.data.code === 200) {
                        if (res.data.data.records.length <= 0) {
                            this.codeError = true;
                        } else {
                            this.info.code = res.data.data.records[0].code;
                        }
                    }
                });
            }
        },
        getUserPhone(id) {
            getUser(id).then(res => {
                this.ruleForm.phoneNum = res.data.data.phone;
                this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
            });
        },
        queryByModelCode() {
            standardService.getModelRule(this.checkinModel).then(res => {
                this.ruleModel = res;
            });
        },
        getDicList() {
            this.ruleForm.createTime = dateFormat(new Date(), 'yyyy-MM-dd');
            dictbizService
                .getDictionary({
                    code: 'firmSize',
                })
                .then(res => {
                    this.firmSizeList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'enterpriseType',
                })
                .then(res => {
                    this.enterpriseTypes = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'qualification',
                })
                .then(res => {
                    this.qualifications = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        getMetaCommonInfo() {
            let obj = {
                pk: 'id',
                id: this.currentRow.id,
            };
            getMetaCommonInfo(this.entModel, obj).then(res => {
                if (res.data.code == 200) {
                    this.ruleForm = res.data.data;
                    this.height = res.data.data.floor_height;
                    if (res.data.data['business_license']) {
                        getPicListByIds(res.data.data['business_license']).then(res => {
                            if (res.data.code == 200) {
                                let arr = [];
                                res.data.data.forEach(pic => {
                                    arr.push({
                                        label: pic.id,
                                        value: pic.link,
                                    });
                                });
                                this.imgform.imgUrl = arr;
                            }
                        });
                    }
                    if (res.data.data['company_logo']) {
                        getPicListByIds(res.data.data['company_logo']).then(res => {
                            if (res.data.code == 200) {
                                let arr = [];
                                res.data.data.forEach(pic => {
                                    arr.push({
                                        label: pic.id,
                                        value: pic.link,
                                    });
                                });
                                this.imgform1.imgUrl = arr;
                            }
                        });
                    }
                }
            });
        },
        submit() {
            return new Promise(resolve => {
                this.$refs['ruleForm'].validate(valid => {
                    if (valid) {
                        resolve({
                            valid: true,
                            form: this.ruleForm,
                        });
                    } else {
                        let fields = this.$refs['ruleForm'].fields,
                            firstError = {
                                field: '',
                                message: '',
                                name: '',
                            };
                        for (let i = 0; i < fields.length; i++) {
                            if (fields[i].validateState === 'error') {
                                firstError.message = fields[i].validateMessage;
                                firstError.field = fields[i].prop;
                                firstError.name = fields[i].label;
                                break;
                            }
                        }
                        resolve({
                            valid: false,
                            form: this.ruleForm,
                            error: firstError,
                        });
                    }
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
    margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
    left: 0px;
}
/deep/ .el-divider__text {
    padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
    width: 68px;
    height: 68px;
    line-height: 72px;
    vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 68px;
    height: 68px;
}
.el-form-item__error {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: inherit;
    top: 100%;
    left: 0;
    margin-left: 10px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
/deep/ .el-select {
    width: 100%;
}
/deep/ .el-radio {
    margin-right: 15px;
}
.sm {
    height: 350px;
    background: #eff6ff;
    padding: 10px 15px;
    position: absolute;
    width: 23%;
    border: 1px solid #a8c9eb;
    border-radius: 2px;
}
/deep/ .el-select__tags {
    height: 32px;
    .el-tag--info {
        display: none;
    }
}
.roomtitle {
    border: 1px solid #1976d3;
    padding: 5px 10px;
    height: auto;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    margin-left: 10px;
    margin-top: 10px;
    i {
        color: #1976d3;
        margin-left: 10px;
        cursor: pointer;
    }
}
.btnClass {
    display: flow-root;
    height: 50px;
    line-height: 50px;
    padding: 0px 15px;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-dialog__body {
    padding: 0px;
}
</style>
